import React from 'react';
import { GrapeSerializer } from './utils';

function replaceUrlToTag(str: string) {
  const urlStarts = str.toLowerCase().indexOf('url": "') + 7;
  if (urlStarts > 6) {
    const urlEnds = str.indexOf('"', urlStarts);
    const urlStr = str.slice(urlStarts, urlEnds);
    return (
      <React.Fragment>
        {str.slice(0, urlStarts)}
        <a href={urlStr} target="_blank" rel="noreferrer">
          {urlStr}
        </a>
        {replaceUrlToTag(str.slice(urlEnds))}
      </React.Fragment>
    );
  } else {
    return str;
  }
}

function RawJson(content: any) {
  if (!content) {
    return <></>;
  }
  let jsonStr = JSON.stringify(content, GrapeSerializer, 2);
  return (
    <div>
      <pre>{replaceUrlToTag(jsonStr)}</pre>
    </div>
  );
}

const exports = RawJson;
export default exports;
