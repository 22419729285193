import { CheckOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Divider, Form, Input, Spin, notification } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { TagEntries } from 'components/TagEntries';
import componentStyle from 'styles/common.module.css';
import { ContentTypes, GifType, Sticker } from 'types/material';
import { useState } from 'react';
import { isValidHttpUrl } from 'utils/utils';
import { ulid } from 'ulid';
import {
  getGifDuration,
  getMediaMetadata,
  getMediaResolution,
} from 'utils/media';
import { EditableText } from 'components/EditableText';
import { UrlStickerView } from './UrlStickerView';
import { fetchChannelId, fetchGiphyPack } from '../../../utils/GiphyImages';
import { Size } from 'types/common';
import { Grapeseed } from 'api/grapeseed';

export interface UrlSticker {
  tags: string[];
  id: string;
  url: string;
  size?: Size;
  bytes?: number;
}

export function NewUrlStickerForm() {
  const [form] = useForm();
  const [list, setList] = useState<UrlSticker[]>([]);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [noti, ctxHolder] = notification.useNotification();

  const newSticker = async (from: UrlSticker) => {
    if (!isValidHttpUrl(from.url)) return undefined;
    try {
      const meta = await getMediaMetadata(from.url);
      const size = from.size ?? (await getMediaResolution(from.url));
      return new Sticker({
        tags: from.tags,
        id: from.id,
        createdAt: new Date(),
        thumbnailUrl: from.url,
        content: {
          type: from.url.includes('giphy.com')
            ? ContentTypes.Giphy
            : meta.contentType,
          size: size,
          url: from.url,
          gifType: from.url.split('.').pop() as GifType,
          duration: from.url.endsWith('.gif')
            ? await getGifDuration(from.url)
            : 0,
        },
        bytes: from.bytes ?? meta.bytes,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const addSticker = (s: UrlSticker) => {
    setList((prev) => [...prev, s]);
  };

  const changeSticker = (index: number, s: UrlSticker) => {
    setList((prev) => {
      const newList = prev.slice();
      newList.splice(index, 1, s);
      return newList;
    });
  };

  const removeSticker = (id: string) => {
    setList((prev) => prev.filter((s) => s.id !== id));
  };

  const fetchStickersFromGiphy = async (url: string) => {
    setIsFetching(true);
    const cid = await fetchChannelId(url);
    if (cid) {
      const giphyPack = await fetchGiphyPack(cid);
      if (giphyPack) {
        setList(
          giphyPack.map((image, index) => {
            return {
              tags: [],
              id: ulid(),
              size: { width: image.width, height: image.height },
              url: image.url,
              bytes: image.size,
            };
          })
        );
      }
    }
    setIsFetching(false);
  };

  const onSubmit = async (values: any) => {
    if (!list || list.length < 1) {
      return;
    }
    setIsLoading(true);
    const submitPromises = list.map((elem) => {
      return new Promise<Sticker>(async (resolve, reject) => {
        elem.tags = values.tags
          ? Array.from(new Set([...elem.tags, ...values.tags]))
          : elem.tags;
        const sticker = await newSticker(elem);
        const result = await Grapeseed.POST('/api/stickers', {
          body: sticker,
          fallback: (e) => reject(e),
        });
        if (result) {
          resolve(result as Sticker);
        }
      });
    });
    const results = await Promise.allSettled(submitPromises);
    const fulfilled = results.filter(
      (r) => r.status === 'fulfilled'
    ) as PromiseFulfilledResult<Sticker>[];
    noti.success({
      message: `${fulfilled.length} sticker(s) created.`,
      placement: 'top',
      duration: 2,
    });
    const rejected = results.filter(
      (r) => r.status === 'rejected'
    ) as PromiseRejectedResult[];
    if (rejected.length > 0) {
      noti.error({
        message: `Failed to create ${rejected.length} stickers`,
        description: (
          <p>
            {rejected
              .map((r) => r.reason as string)
              .reduce((acc, cur) => acc + ', ' + cur)}
          </p>
        ),
        placement: 'top',
      });
    }
    setList([]);
    form.resetFields();
    setIsLoading(false);
  };

  return (
    <>
      {ctxHolder}
      <Form
        form={form}
        onFinish={onSubmit}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
          }
        }}
        labelCol={{ span: 5 }}
        labelAlign="left"
        colon={false}
        style={{ margin: '1rem 0' }}
      >
        <Spin spinning={isFetching}>
          <Form.Item
            label="Fetch Collection from Giphy.com"
            extra={
              <span className={componentStyle.warningtextRed}>
                * Caution: fetching stickers from Giphy will remove/replace
                current sticker list!
              </span>
            }
          >
            <Input.Search
              allowClear
              enterButton
              onSearch={(value) => {
                fetchStickersFromGiphy(value);
              }}
              style={{ width: '90%' }}
            />
          </Form.Item>
        </Spin>
        <Divider orientation="left" plain>
          Stickers
        </Divider>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {list.map((elem, index) => (
            <UrlStickerView
              key={elem.id}
              sticker={elem}
              onChange={(s) => changeSticker(index, s)}
              onRemove={(id) => removeSticker(id)}
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                minHeight: '40px',
                maxWidth: '90%',
              }}
            />
          ))}
        </div>
        <div
          style={{
            margin: '1rem 0rem 2rem 2rem',
            width: '90%',
          }}
        >
          <EditableText
            str=""
            icon={<PlusOutlined />}
            placeholder="Add a new sticker"
            onChange={(value) => {
              if (isValidHttpUrl(value)) {
                addSticker({
                  tags: [],
                  id: ulid(),
                  url: value,
                });
              }
            }}
            triggerType="icon"
          />
        </div>
        <Divider orientation="left" plain>
          Apply to all
        </Divider>
        <Form.Item
          name="tags"
          label="Tags"
          extra={
            <span className={componentStyle.hinttextOrange}>
              * Press enter to add tag.
            </span>
          }
        >
          <TagEntries layout="horizontal" />
        </Form.Item>
        <Form.Item>
          <Button
            className={componentStyle.buttonOrange}
            type="primary"
            icon={<CheckOutlined />}
            htmlType="submit"
            loading={isLoading}
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
      <Divider />
    </>
  );
}
