import { Modal } from 'antd';

export class ApiError extends Error {
  statusCode: number;
  statusText: string;

  constructor(statusCode: number, statusText: string, message?: string) {
    super(message);
    Object.setPrototypeOf(this, ApiError.prototype);
    this.name = 'ApiError';
    this.statusCode = statusCode;
    this.statusText = statusText;
  }

  showAlert() {
    Modal.error({
      title: `${this.statusCode} ${this.statusText}`,
      content: this.message,
    });
  }
}

export function OnError(e: any) {
  if (e instanceof ApiError) {
    e.showAlert();
  } else if (e instanceof Error) {
    Modal.error({
      title: e.name,
      content: e.message,
    });
  } else {
    Modal.error({
      title: 'Unknown Error',
      content: String(e),
    });
  }
}
