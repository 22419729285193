import { CSSProperties, useEffect, useState } from 'react';

export interface SvgInlineProps {
  url: string;
  fillColor?: string;
  style?: CSSProperties;
}

export function SvgInline(props: SvgInlineProps) {
  const [svg, setSvg] = useState<string>('');

  useEffect(() => {
    fetch(props.url)
      .then((res) =>
        res
          .text()
          .then((text) =>
            text.replaceAll('fill: none', `fill: ${props.fillColor}`)
          )
      )
      .then(setSvg);
  }, [props.fillColor, props.url]);

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        width: props.style?.width,
        height: props.style?.height,
      }}
      dangerouslySetInnerHTML={{ __html: svg }}
    />
  );
}
