import { getMediaResolution } from 'utils/media';
import { Creator, Size } from './common';
import { Pack } from './pack';
import {
  Identifiable,
  Material,
  hasContent,
  hasThumbnail,
  isMaterial,
  isVendable,
} from './interfaces';

export interface BundleItem {
  thumbnailUrl?: string; // nullable but required to submit
  thumbnailSize?: Size; // nullable but required to submit
  isFree?: boolean;
  ref: string;
}

export class Bundle implements Identifiable {
  id: string;
  title: string;
  description?: string;
  createdAt: Date;
  isBanner: Boolean;
  items: BundleItem[];

  constructor(props: {
    id: string;
    title: string;
    description?: string;
    createdAt: Date;
    isBanner: Boolean;
    items: BundleItem[];
  }) {
    this.id = props.id;
    this.title = props.title;
    this.description = props.description;
    this.createdAt = props.createdAt;
    this.isBanner = props.isBanner;
    this.items = props.items;
  }

  get identifier() {
    return this.id;
  }

  get isRouteType() {
    return this.items.length === 1 && this.items[0].ref.startsWith('/route/');
  }
}

export class BundleList implements Identifiable {
  id: string;
  title: string;
  createdAt: Date;
  bundles: Bundle[];

  constructor(props: {
    id: string;
    title: string;
    createdAt: Date;
    bundles: Bundle[];
  }) {
    this.id = props.id;
    this.title = props.title;
    this.createdAt = props.createdAt;
    this.bundles = props.bundles?.map((b) => new Bundle(b));
  }

  get identifier() {
    return this.id;
  }
}

export async function NewBundleItem(
  source: Material | Pack | Creator
): Promise<BundleItem> {
  if (isMaterial(source)) {
    return NewBundleItemFromMaterial(source as Material);
  } else if (source instanceof Pack) {
    return NewBundleItemFromPack(source as Pack);
  } else if (source instanceof Creator) {
    return NewBundleItemFromCreator(source as Creator);
  }
  return Promise.reject('unavailable source type');
}

async function NewBundleItemFromMaterial(from: Material): Promise<BundleItem> {
  let thumbnailSize: Size | undefined;
  let thumbnailUrl: string | undefined;
  let isFree: boolean | undefined;
  if (hasThumbnail(from)) {
    thumbnailUrl = from.thumbnailUrl;
    try {
      thumbnailSize = await getMediaResolution(thumbnailUrl);
    } catch (e) {
      console.log(`${e}: ${thumbnailUrl}`);
    }
  } else if (hasContent(from)) {
    thumbnailUrl = from.content.url;
    thumbnailSize = await getMediaResolution(thumbnailUrl);
    try {
      thumbnailSize = await getMediaResolution(thumbnailUrl);
    } catch (e) {
      console.log(`${e}: ${thumbnailUrl}`);
    }
  }
  if (isVendable(from)) {
    isFree = from.isFree;
  }
  return {
    thumbnailUrl: thumbnailUrl,
    thumbnailSize: thumbnailSize,
    isFree: isFree,
    ref: `/materials/${from.id}?type=${from.type}`,
  };
}

async function NewBundleItemFromPack(from: Pack): Promise<BundleItem> {
  let ref = `/packs/${from.id}`;
  let thumbnailSize: Size | undefined;
  if (from.materialType) {
    ref += `?type=${from.materialType}`;
  }
  if (from.thumbnailUrl) {
    try {
      thumbnailSize = await getMediaResolution(from.thumbnailUrl);
    } catch {
      thumbnailSize = undefined;
    }
  }
  return {
    thumbnailUrl: from.thumbnailUrl,
    thumbnailSize: thumbnailSize,
    isFree: from.isFree,
    ref: ref,
  };
}

async function NewBundleItemFromCreator(from: Creator): Promise<BundleItem> {
  let ref = `/creators/${from.code}`;
  let thumbnailSize: Size | undefined;
  if (from.coverUrl) {
    try {
      thumbnailSize = await getMediaResolution(from.coverUrl);
    } catch {
      thumbnailSize = undefined;
    }
  }
  return {
    thumbnailUrl: from.coverUrl,
    thumbnailSize: thumbnailSize,
    ref: ref,
  };
}

export function UnionBundleItems(list: BundleItem[], elem: BundleItem[]) {
  const seen = new Map<string, boolean>();

  return [...list, ...elem].filter((item) => {
    if (seen.has(item.ref)) {
      return false;
    }
    seen.set(item.ref, true);
    return true;
  });
}
