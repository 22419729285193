import { GradientType } from 'utils/colors';
import { Color, Pattern, Size } from './common';
import { Material, HasThumbnail, Vendable } from './interfaces';

export const MaterialTypes = {
  Sticker: 'sticker',
  Font: 'font',
  Background: 'background',
  Template: 'template',
  Layout: 'layout',
  Placeholder: 'placeholder',
  Color: 'color',
  Combo: 'combo',
  Text: 'text',
  Shape: 'shape',
  Gradient: 'gradient',
} as const;
export type MaterialType = (typeof MaterialTypes)[keyof typeof MaterialTypes];

export function abbStr(mType: MaterialType | undefined) {
  switch (mType) {
    case MaterialTypes.Sticker:
      return 'ST';
    case MaterialTypes.Font:
      return 'FT';
    case MaterialTypes.Background:
      return 'BG';
    case MaterialTypes.Template:
      return 'TP';
    case MaterialTypes.Layout:
      return 'LO';
    case MaterialTypes.Placeholder:
      return 'PH';
    case MaterialTypes.Color:
      return 'CL';
    case MaterialTypes.Combo:
      return 'CB';
    case MaterialTypes.Text:
      return 'TX';
    case MaterialTypes.Shape:
      return 'SH';
    case MaterialTypes.Gradient:
      return 'GR';
    default:
      return '--';
  }
}

export const ContentTypes = {
  ImagePng: 'image/png',
  ImageJpg: 'image/jpg',
  ImageGif: 'image/gif',
  ImageSvg: 'image/svg',
  VideoMp4: 'video/mp4',
  Giphy: 'giphy',
  PatternPng: 'pattern/png',
  LottieJson: 'lottie/json',
  LottieDot: 'lottie/dotlottie',
  None: '',
} as const;
export type ContentType = (typeof ContentTypes)[keyof typeof ContentTypes];

export const GifTypes = {
  Gif: 'gif',
  Mp4: 'mp4',
  Webp: 'webp',
} as const;
export type GifType = (typeof GifTypes)[keyof typeof GifTypes];

interface AuxTags {
  fromPackTitle?: string[];
}

export class Sticker implements Material, HasThumbnail, Vendable {
  type: MaterialType;
  tags: string[];
  auxTags?: AuxTags;
  authorId?: string;
  picked?: number;
  id: string;
  v?: number;
  createdAt: Date;
  thumbnailUrl: string;
  isFree: boolean;

  content: Content;
  bytes: number;

  constructor(props: {
    tags: string[];
    auxTags?: AuxTags;
    authorId?: string;
    picked?: number;
    id: string;
    v?: number;
    createdAt: Date;
    thumbnailUrl?: string; // nuallable: sticker bulk upload 시 API server 에서 생성
    isFree?: boolean;

    content: Content;
    bytes: number;
  }) {
    this.type = MaterialTypes.Sticker;
    this.tags = props.tags;
    this.auxTags = props.auxTags;
    this.authorId = props.authorId;
    this.picked = props.picked;
    this.id = props.id;
    this.v = props.v;
    this.createdAt = props.createdAt;
    this.thumbnailUrl = props.thumbnailUrl ?? '';
    this.isFree = props.isFree ?? false;
    this.content = props.content;
    this.bytes = props.bytes;
  }
}

export class Font implements Material, HasThumbnail, Vendable {
  type: MaterialType;
  tags: string[];
  auxTags?: AuxTags;
  authorId?: string;
  picked?: number;
  id: string;
  v?: number;
  createdAt: Date;
  isFree: boolean;

  title: string;
  fontName?: string;
  originUrl?: string;
  thumbnailUrl: string;
  bytes?: number;

  constructor(props: {
    tags: string[];
    auxTags?: AuxTags;
    authorId?: string;
    picked?: number;
    id: string;
    v?: number;
    createdAt: Date;
    isFree?: boolean;

    title: string;
    fontName?: string;
    originUrl?: string;
    thumbnailUrl?: string;
    bytes?: number;
  }) {
    this.type = MaterialTypes.Font;
    this.tags = props.tags;
    this.auxTags = props.auxTags;
    this.authorId = props.authorId;
    this.picked = props.picked;
    this.id = props.id;
    this.v = props.v;
    this.createdAt = props.createdAt;
    this.isFree = props.isFree ?? false;
    this.title = props.title;
    this.fontName = props.fontName;
    this.originUrl = props.originUrl;
    this.thumbnailUrl = props.thumbnailUrl ?? '';
    this.bytes = props.bytes;
  }
}

export class Background implements Material, HasThumbnail, Vendable {
  type: MaterialType;
  tags: string[];
  auxTags?: AuxTags;
  authorId?: string;
  picked?: number;
  id: string;
  v?: number;
  createdAt: Date;
  isFree: boolean;

  bytes: number;
  content: Content;
  thumbnailUrl: string; // nullable: background bulk upload 시 API server 에서 생성
  backgroundColor?: Color;
  pattern?: Pattern;

  constructor(props: {
    tags: string[];
    auxTags?: AuxTags;
    authorId?: string;
    picked?: number;
    id: string;
    v?: number;
    createdAt: Date;
    isFree?: boolean;

    bytes: number;
    content: Content;
    thumbnailUrl?: string; // nullable: background bulk upload 시 API server 에서 생성
    backgroundColor?: Color;
    pattern?: Pattern;
  }) {
    this.type = MaterialTypes.Background;
    this.tags = props.tags;
    this.auxTags = props.auxTags;
    this.authorId = props.authorId;
    this.picked = props.picked;
    this.id = props.id;
    this.v = props.v;
    this.createdAt = props.createdAt;
    this.isFree = props.isFree ?? false;
    this.bytes = props.bytes;
    this.content = props.content;
    this.thumbnailUrl = props.thumbnailUrl ?? '';
    this.backgroundColor = props.backgroundColor;
    this.pattern = props.pattern;
  }
}

export class Template implements Material, HasThumbnail, Vendable {
  type: MaterialType;
  tags: string[];
  auxTags?: AuxTags;
  authorId?: string;
  picked?: number;
  id: string;
  v?: number;
  createdAt: Date;
  isFree: boolean;

  title?: string;
  canvasSize: Size;
  canvasUrl: string;
  thumbnailUrl: string;
  placeholderCount?: number;
  maskedContentCount?: number;

  constructor(props: {
    tags: string[];
    auxTags?: AuxTags;
    authorId?: string;
    picked?: number;
    id: string;
    v?: number;
    createdAt: Date;
    isFree?: boolean;

    title?: string;
    canvasSize: Size;
    canvasUrl: string;
    thumbnailUrl: string;
    placeholderCount?: number;
    maskedContentCount?: number;
  }) {
    this.type = MaterialTypes.Template;
    this.tags = props.tags;
    this.auxTags = props.auxTags;
    this.authorId = props.authorId;
    this.picked = props.picked;
    this.id = props.id;
    this.v = props.v;
    this.createdAt = props.createdAt;
    this.isFree = props.isFree ?? false;
    this.title = props.title;
    this.canvasSize = props.canvasSize;
    this.canvasUrl = props.canvasUrl;
    this.thumbnailUrl = props.thumbnailUrl;
    this.placeholderCount = props.placeholderCount;
    this.maskedContentCount = props.maskedContentCount;
  }
}

export class Layout implements Material, HasThumbnail, Vendable {
  type: MaterialType;
  tags: string[];
  auxTags?: AuxTags;
  authorId?: string;
  picked?: number;
  id: string;
  v?: number;
  createdAt: Date;
  thumbnailUrl: string;
  isFree: boolean;

  title: string;
  canvasSize: Size;
  svgUrl: string;
  placeholderCount?: number;

  constructor(props: {
    tags: string[];
    auxTags?: AuxTags;
    authorId?: string;
    picked?: number;
    id: string;
    v?: number;
    createdAt: Date;
    thumbnailUrl: string;
    isFree?: boolean;

    title: string;
    canvasSize: Size;
    svgUrl: string;
    placeholderCount?: number;
  }) {
    this.type = MaterialTypes.Layout;
    this.tags = props.tags;
    this.auxTags = props.auxTags;
    this.authorId = props.authorId;
    this.picked = props.picked;
    this.id = props.id;
    this.v = props.v;
    this.createdAt = props.createdAt;
    this.thumbnailUrl = props.thumbnailUrl;
    this.isFree = props.isFree ?? false;
    this.title = props.title;
    this.canvasSize = props.canvasSize;
    this.svgUrl = props.svgUrl;
    this.placeholderCount = props.placeholderCount;
  }
}

export class Placeholder implements Material, Vendable {
  type: MaterialType;
  tags: string[];
  auxTags?: AuxTags;
  authorId?: string;
  picked?: number;
  id: string;
  v?: number;
  createdAt: Date;
  isFree: boolean;

  content: Content;
  bytes: number;

  constructor(props: {
    tags: string[];
    auxTags?: AuxTags;
    authorId?: string;
    picked?: number;
    id: string;
    v?: number;
    createdAt: Date;
    isFree?: boolean;

    content: Content;
    bytes: number;
  }) {
    this.type = MaterialTypes.Placeholder;
    this.tags = props.tags;
    this.auxTags = props.auxTags;
    this.authorId = props.authorId;
    this.picked = props.picked;
    this.id = props.id;
    this.v = props.v;
    this.createdAt = props.createdAt;
    this.isFree = props.isFree ?? false;
    this.content = props.content;
    this.bytes = props.bytes;
  }
}

export class MaterialColor implements Material {
  type: MaterialType;
  tags: string[];
  auxTags?: AuxTags;
  authorId?: string;
  picked?: number;
  code: string;

  constructor(props: {
    tags: string[];
    auxTags?: AuxTags;
    authorId?: string;
    picked?: number;
    code: string;
  }) {
    this.type = MaterialTypes.Color;
    this.tags = props.tags;
    this.auxTags = props.auxTags;
    this.authorId = props.authorId;
    this.picked = props.picked;
    this.code = props.code;
  }

  get id() {
    return this.code;
  }
}

export class Combo implements Material, HasThumbnail, Vendable {
  type: MaterialType;
  tags: string[];
  auxTags?: AuxTags;
  authorId?: string;
  picked?: number;
  id: string;
  v?: number;
  createdAt: Date;
  isFree: boolean;
  title?: string;
  thumbnailUrl: string;
  thumbnailSize: Size;
  layersUrl: string;

  constructor(props: {
    tags: string[];
    auxTags?: AuxTags;
    authorId?: string;
    picked?: number;
    id: string;
    v?: number;
    createdAt: Date;
    isFree?: boolean;
    title?: string;
    thumbnailUrl: string;
    thumbnailSize: Size;
    layersUrl: string;
  }) {
    this.type = MaterialTypes.Combo;
    this.tags = props.tags;
    this.auxTags = props.auxTags;
    this.authorId = props.authorId;
    this.picked = props.picked;
    this.id = props.id;
    this.v = props.v;
    this.createdAt = props.createdAt;
    this.isFree = props.isFree ?? false;
    this.title = props.title;
    this.thumbnailUrl = props.thumbnailUrl;
    this.thumbnailSize = props.thumbnailSize;
    this.layersUrl = props.layersUrl;
  }
}

export class MaterialText implements Material {
  type: MaterialType;
  tags: string[];
  auxTags?: AuxTags;
  authorId?: string;
  picked?: number;
  id: string;
  text: string;

  constructor(props: {
    tags: string[];
    auxTags?: AuxTags;
    authorId?: string;
    picked?: number;
    id: string;
    text: string;
  }) {
    this.type = MaterialTypes.Text;
    this.tags = props.tags;
    this.auxTags = props.auxTags;
    this.authorId = props.authorId;
    this.picked = props.picked;
    this.id = props.id;
    this.text = props.text;
  }
}

export class Shape implements Material, Vendable {
  type: MaterialType;
  tags: string[];
  auxTags?: AuxTags;
  authorId?: string;
  picked?: number;
  id: string;
  v?: number;
  createdAt: Date;
  isFree: boolean;

  content: Content;
  bytes: number;

  constructor(props: {
    tags: string[];
    auxTags?: AuxTags;
    authorId?: string;
    picked?: number;
    id: string;
    v?: number;
    createdAt: Date;
    isFree?: boolean;

    content: Content;
    bytes: number;
  }) {
    this.type = MaterialTypes.Shape;
    this.tags = props.tags;
    this.auxTags = props.auxTags;
    this.authorId = props.authorId;
    this.picked = props.picked;
    this.id = props.id;
    this.v = props.v;
    this.createdAt = props.createdAt;
    this.isFree = props.isFree ?? false;
    this.content = props.content;
    this.bytes = props.bytes;
  }
}

export class Gradient implements Material {
  type: MaterialType;
  tags: string[];
  auxTags?: AuxTags;
  authorId?: string;
  picked?: number;
  id: string;
  codes: string[];
  gradientType: GradientType;

  constructor(props: {
    tags: string[];
    auxTags?: AuxTags;
    authorId?: string;
    picked?: number;
    id: string;
    codes: string[];
    gradientType?: GradientType;
  }) {
    this.type = MaterialTypes.Gradient;
    this.tags = props.tags;
    this.auxTags = props.auxTags;
    this.authorId = props.authorId;
    this.picked = props.picked;
    this.id = props.id;
    this.codes = props.codes;
    this.gradientType = props.gradientType ?? GradientType.ToBottom;
  }
}

export interface Content {
  type: ContentType;
  size: Size;
  url: string;
  gifType?: GifType;
  duration?: number;
}

export function NewMaterial(object: any) {
  const mtype = object.type;
  switch (mtype) {
    case MaterialTypes.Background:
      return new Background(object);
    case MaterialTypes.Font:
      return new Font(object);
    case MaterialTypes.Layout:
      return new Layout(object);
    case MaterialTypes.Template:
      return new Template(object);
    case MaterialTypes.Placeholder:
      return new Placeholder(object);
    case MaterialTypes.Sticker:
      return new Sticker(object);
    case MaterialTypes.Color:
      return new MaterialColor(object);
    case MaterialTypes.Combo:
      return new Combo(object);
    case MaterialTypes.Text:
      return new MaterialText(object);
    case MaterialTypes.Shape:
      return new Shape(object);
    case MaterialTypes.Gradient:
      return new Gradient(object);
    default:
      return null;
  }
}

export const TemplateFileAcceptTypes = 'image/svg+xml';
export const LayoutFileAcceptTypes = 'image/svg+xml';
export const StickerContentAcceptTypes = 'image/png';
export const FontFileAcceptTypes = '.otf,.ttf';
export const BackgroundContentAcceptTypes = 'image/jpg,image/jpeg,image/png';
export const PlaceholderContentAcceptTypes = 'image/svg+xml';
export const ShapeContentAcceptTypes = 'image/svg+xml';
export const ThumbnailAcceptTypes =
  'image/jpg,image/jpeg,image/png,image/webp,image/gif,video/mp4';
export const FontThumbnailAcceptTypes = 'image/svg+xml';

export const PresetImageAcceptTypes = 'image/jpg,image/jpeg,image/png';

export const LookUpTableImageAcceptTypes = 'image/jpg,image/jpeg,image/png';
export const LookUpTableDataAcceptTypes = 'image/png,.cube';

export const ThumbnailFileSuffix = '_th';

export const ContainerTemplates = 'templates';
export const ContainerLayouts = 'layouts';
export const ContainerFonts = 'fonts';
export const ContainerBackgrounds = 'backgrounds';
export const ContainerPlaceholders = 'placeholders';
export const ContainerStickers = 'stickers';
export const ContainerCombos = 'combos';
export const ContainerShapes = 'shapes';

export const ContainerPacks = 'packs';
export const ContainerBundles = 'bundles';

export type BulkUploadableTypes = Sticker & Background;
