import { Pack } from 'types/pack';
import { MutableRefObject } from 'react';
import viewStyle from 'styles/view.module.css';
import { Spin } from 'antd';
import { PackView } from './PackView';

export interface Selections {
  packs: Pack[];
  onItemSelect: (p: Pack) => void;
}

interface PackListProps {
  className?: string;
  list: Pack[];
  itemSize: 'full' | 'compact';
  itemDraggable?: boolean;
  selections?: Selections;
  onDelete?: (id: string) => void;
  lastItemRef?: MutableRefObject<any>;
  hasMore?: boolean;
}

export function PackList(props: PackListProps) {
  let style: React.CSSProperties = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    margin: '1rem',
  };

  return (
    <div className={props.className}>
      {props.list?.map((pack, index) => {
        return (
          <PackView
            key={pack.id}
            pack={pack}
            size={props.itemSize}
            selected={props.selections?.packs.includes(pack)}
            onClick={props.selections?.onItemSelect}
            onDelete={props.onDelete}
          />
        );
      })}
      {props.hasMore && props.lastItemRef && (
        <div ref={props.lastItemRef} style={style}>
          <Spin />
        </div>
      )}
    </div>
  );
}

PackList.defaultProps = {
  className: `${viewStyle.viewbox} ${viewStyle.wrap}`,
  itemSize: 'full',
  itemDraggable: false,
  itemSelectable: false,
};
