import { Button, Popover, Tag, Typography } from 'antd';
import { MaterialViewProps, onMaterialDragStart } from './MaterialView';
import viewStyle from 'styles/view.module.css';
import thumbnailStyles from 'styles/thumbnail.module.css';
import { copyId, hasContent, hasThumbnail, isVendable } from 'types/interfaces';
import { ThumbnailView } from 'components/Thumbnail/ThumbnailView';
import {
  Gradient,
  MaterialColor,
  MaterialText,
  MaterialTypes,
  abbStr,
} from 'types/material';
import { GradientBox } from './GradientBox';
import RawJson from 'utils/RawJson';
import { CopyOutlined, LinkOutlined } from '@ant-design/icons';
import useNotification from 'antd/es/notification/useNotification';
import { ThumbnailSizes } from 'components/Thumbnail/Constants';

export function MaterialCompactView(props: MaterialViewProps) {
  let m = props.material;
  const colorViewStyle = {
    width: '80px',
    height: '80px',
    borderRadius: '5px',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: 'var(--border-color)',
    backgroundColor: `#${(props.material as MaterialColor).code}`,
  };
  const [noti, ctxHolder] = useNotification();
  // const [selected] = useState<boolean>(props.selected);

  let mainClassName =
    `${viewStyle.viewitem} ${viewStyle.compact}` +
    (props.draggable ? ` ${viewStyle.draggable}` : '') +
    (props.selected ? ` ${viewStyle.selected}` : '');

  return (
    <>
      {ctxHolder}
      <div
        className={mainClassName}
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'space-around',
        }}
        draggable={props.draggable}
        onDragStart={(e) => onMaterialDragStart(e, m)}
        onClick={() => props.onClick?.(m)}
      >
        {hasThumbnail(m) && (
          <ThumbnailView
            size={ThumbnailSizes.compact}
            src={m.thumbnailUrl}
            preview={false}
            isFree={isVendable(m) && m.isFree}
          />
        )}
        {!hasThumbnail(m) && hasContent(m) && (
          <ThumbnailView
            size={ThumbnailSizes.compact}
            src={m.content.url}
            preview={false}
            isFree={isVendable(m) && m.isFree}
            svgFillColor={
              m.type === MaterialTypes.Placeholder
                ? 'var(--placeholder-fill-color)'
                : undefined
            }
          />
        )}
        {m instanceof MaterialColor && <div style={colorViewStyle} />}
        {m instanceof Gradient && (
          <GradientBox
            width={80}
            height={80}
            colors={m.codes}
            type={m.gradientType}
          />
        )}
        {m instanceof MaterialText && (
          <div
            className={thumbnailStyles.box}
            style={{ width: 80, height: 80, padding: '0.2rem' }}
          >
            <Typography.Paragraph ellipsis={{ rows: 3 }} style={{ margin: 0 }}>
              {m.text}
            </Typography.Paragraph>
          </div>
        )}
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-around',
            alignItems: 'center',
            marginTop: '0.5rem',
            padding: '0 0.5rem',
            width: '100%',
          }}
          onClick={(e) => e.stopPropagation()}
        >
          <Popover content={RawJson(m)} trigger="click">
            <Tag color={`var(--${m.type}-badge-color)`}>
              <span style={{ fontSize: '0.7rem' }}>{abbStr(m.type)}</span>
            </Tag>
          </Popover>
          <Button
            size="small"
            type="link"
            onClick={() => copyId(m, noti)}
            icon={<CopyOutlined />}
            style={{ marginRight: '0.2rem' }}
          ></Button>
          <a href={`/materials/${m.id}`} target="_blank" rel="noreferrer">
            <LinkOutlined />
          </a>
        </div>
      </div>
    </>
  );
}
