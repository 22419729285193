export interface User {
  id: string;
  email: string;
  username: string;
  phonenumber: string;
  country?: string;
  state: string;
  createdAt: Date;
  updatedAt: Date;
  profile?: string;
  language?: string;
  pushOption?: boolean;
  fullname?: string;
  birth?: string;
  gender?: string;
  ip?: string;
  locationToggle?: boolean;
  profileType?: string;
  profileUpdatedAt?: Date;
  snapchatName?: string;
  instagramName?: string;
  musicallyName?: string;
  kikName?: string;
  themeType?: number;
  bio?: string;
  roles?: string[];
  retricaFacebookId?: string;
  retricaVKontakteId?: string;
  retricaAppleId?: string;
  retricaHuaweiId?: string;
}

export function userProfileUrl(u: User) {
  if (!u.profile || u.profile.length < 1) {
    return undefined;
  }
  switch (process.env.REACT_APP_ENV) {
    case 'alpha':
    case 'local':
      return 'https://storage-alpha.retrica.io/'.concat(u.profile);
    case 'production':
      return 'https://storage.retrica.io/'.concat(u.profile);
  }
}

export const UserRoles = {
  Admin: 'admin',
  Creator: 'creator',
} as const;
export type UserRole = (typeof UserRoles)[keyof typeof UserRoles];
