import { LookUpTable } from 'types/proto/retriver-struct_pb';
import { MutableRefObject } from 'react';
import viewStyle from 'styles/view.module.css';
import { Spin } from 'antd';
import { LookUpTableCardView } from './LookUpTableCardView';

export interface LookUpTableListViewProps {
  className?: string;
  list: LookUpTable[];
  onDelete?: (key: React.Key) => void;
  hasMore: boolean;
  lastItemRef?: MutableRefObject<any>;
}

export function LookUpTableListView(props: LookUpTableListViewProps) {
  return (
    <>
      <div className={props.className}>
        {props.list.map((lut: LookUpTable, index: number) => {
          return (
            <LookUpTableCardView
              key={lut.id}
              lookuptable={lut}
              onDelete={props.onDelete}
            />
          );
        })}
        {props.hasMore && props.lastItemRef && (
          <div
            key={'LastItemRef'}
            ref={props.lastItemRef}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              margin: '1rem',
            }}
          >
            <Spin />
          </div>
        )}
      </div>
    </>
  );
}

LookUpTableListView.defaultProps = {
  className: `${viewStyle.viewbox} ${viewStyle.wrap}`,
};
