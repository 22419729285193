import { LoadingOutlined, TagOutlined } from '@ant-design/icons';
import { Form, Input } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { Grapeseed } from 'api/grapeseed';
import { useState } from 'react';
import { Material } from 'types/interfaces';
import { NewMaterial } from 'types/material';
import { Pack } from 'types/pack';

export interface ItemsTaggerProps {
  pack: Pack;
  refreshCallback: () => void;
}

export function ItemsTagger(props: ItemsTaggerProps) {
  const [loading, setLoading] = useState<boolean>(false);
  const [form] = useForm();

  const fetchItems = async (ids: string[]) => {
    const result = await Grapeseed.GET(`/api/materials`, {
      queryParams: new Map<string, string>([['ids', ids.join()]]),
    });
    if (result) {
      return result.map((r: any) => NewMaterial(r));
    }
    return null;
  };

  const addTagToItems = async (e: any) => {
    const newTag = e.target.value.toString().trim();
    if (newTag.length === 0) {
      form.resetFields();
      return;
    }
    const mids = props.pack.itemIds;
    if (mids) {
      setLoading(true);
      const items = await fetchItems(mids);
      if (!items) {
        return;
      }
      items.forEach((m: Material) => {
        const tags = [...new Set([...m.tags, newTag])];
        if (tags.length === m.tags.length) {
          return;
        }
        Grapeseed.PUT(`/api/${m.type}s/${m.id}`, {
          body: { tags: tags },
        });
      });
      setLoading(false);
      form.resetFields();
      props.refreshCallback();
    }
  };

  return (
    <div style={{ display: 'flex' }}>
      <Form form={form} layout="inline">
        {loading && (
          <LoadingOutlined
            style={{
              marginRight: '0.6rem',
              color: 'var(--highlight-color-blue)',
            }}
          />
        )}
        {!loading && (
          <TagOutlined
            style={{
              marginRight: '0.6rem',
              color: 'var(--highlight-color-blue)',
            }}
          />
        )}
        <Form.Item name="tagInput">
          <Input
            type="text"
            placeholder="Add tag to all items"
            onPressEnter={addTagToItems}
            disabled={loading}
          />
        </Form.Item>
      </Form>
    </div>
  );
}
