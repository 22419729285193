import { RequestProto } from 'types/proto/retriver_pb';
import { ApiRequest } from '../retriver';
import {
  AddLookUpTableRequest,
  DeleteLookUpTableRequest,
  GetLookUpTablesRequest,
  SearchLookUpTablesRequest,
} from 'types/proto/retriver-app-common_pb';

export function NewAddLookUpTableRequest(props: {
  id: string;
  name: string;
  previewUrl: string | undefined;
  originUrl: string | undefined;
  lutData: string | Uint8Array;
}): ApiRequest {
  return {
    path: 'a2/ac/addLookUpTable',
    protoBody: new RequestProto({
      addLookUpTableRequest: new AddLookUpTableRequest({
        id: props.id,
        name: props.name,
        previewUrl: props.previewUrl,
        originUrl: props.originUrl,
        lut:
          typeof props.lutData === 'string'
            ? { case: 'lutUrl', value: props.lutData as string }
            : { case: 'lutBytes', value: props.lutData as Uint8Array },
      }),
    }),
  };
}

export function NewGetLookUpTableRequest(id: string): ApiRequest {
  return {
    path: 'a2/ac/getLookUpTable',
    protoBody: new RequestProto({
      getLookUpTablesRequest: new GetLookUpTablesRequest({
        ids: [id],
      }),
    }),
  };
}

export function NewSearchLookUpTableRequest(
  offset: string,
  size: number
): ApiRequest {
  return {
    path: 'a2/ac/searchLookUpTable',
    protoBody: new RequestProto({
      searchLookUpTablesRequest: new SearchLookUpTablesRequest({
        offset: offset,
        size: size,
      }),
    }),
  };
}

export function NewDeleteLookUpTableRequest(id: string): ApiRequest {
  return {
    path: 'a2/ac/deleteLookUpTable',
    protoBody: new RequestProto({
      deleteLookUpTableRequest: new DeleteLookUpTableRequest({
        id: id,
      }),
    }),
  };
}
