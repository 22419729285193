import { Grapeseed } from 'api/grapeseed';
import {
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useCallback,
  useRef,
  useState,
} from 'react';
import { Pack } from 'types/pack';
import { ApiPageSizeForPacks } from 'utils/constants';
import { Union } from 'utils/utils';
import { useIntersectionObserver } from './useIntersectionObserver';
import useDeepCompareEffect from './useDeepCompareEffect';
import { MaterialType } from 'types/material';
import { ApiError, OnError } from 'utils/errors';

interface useFetchPacksProps {
  id?: string;
  title?: string;
  type?: string;
  authorId?: string;
  materialType?: MaterialType;
  sort?: string[];
  lastItemRef?: MutableRefObject<null>;
}

export const useFetchPacks = (
  props: useFetchPacksProps
): [Pack[], Dispatch<SetStateAction<Pack[]>>, boolean] => {
  const [list, setList] = useState<Pack[]>([]);
  const [hasError, setHasError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const page = useRef(0);
  const hasMore = useRef(true);

  const fetchPacks = useCallback(
    async (
      page: number,
      size: number,
      id?: string,
      title?: string,
      type?: string,
      authorId?: string,
      materialType?: MaterialType,
      sort?: string[]
    ) => {
      if (!id && !title && !type && !authorId && !materialType && !sort) {
        return [];
      }
      if (id) {
        // if query has 'id' field, ignore all other query fields.
        setIsLoading(true);
        const result = await Grapeseed.GET(`/api/packs/${id}`, {
          fallback: (e) => {
            if (e instanceof ApiError && e.statusCode === 404) {
              hasMore.current = false;
            } else {
              OnError(e);
            }
          },
        });
        setIsLoading(false);
        return [result];
      }
      const queryParams: Map<string, string> = new Map();
      if (title) {
        queryParams.set('title', title);
      }
      if (type) {
        queryParams.set('type', type);
      }
      if (authorId) {
        queryParams.set('authorId', authorId);
      }
      if (materialType) {
        queryParams.set('materialType', materialType);
      }
      if (sort && sort.length > 0) {
        queryParams.set('sort', sort.toString());
      }
      queryParams.set('from', (page * size).toString());
      queryParams.set('size', size.toString());

      setIsLoading(true);
      const result = await Grapeseed.GET(`/api/packs`, {
        queryParams: queryParams,
        fallback: (e) => {
          if (e instanceof ApiError && e.statusCode === 404) {
            hasMore.current = false;
          } else {
            OnError(e);
          }
        },
      });
      if (!result) {
        setHasError(true);
      }
      setIsLoading(false);
      return result?.map((element: any) => new Pack(element));
    },
    []
  );

  const fetchNextPage = useCallback(
    async (
      size: number,
      id?: string,
      title?: string,
      type?: string,
      authorId?: string,
      materialType?: MaterialType,
      sort?: string[]
    ) => {
      page.current++;
      const data = (await fetchPacks(
        page.current,
        size,
        id,
        title,
        type,
        authorId,
        materialType,
        sort
      )) as Pack[];
      hasMore.current = data !== undefined && !(data.length < size);
      setList((prev) => Union(prev, data ?? []) as Pack[]);
    },
    [fetchPacks]
  );

  const fetchInit = useCallback(
    async (
      size: number,
      id?: string,
      title?: string,
      type?: string,
      authorId?: string,
      materialType?: MaterialType,
      sort?: string[]
    ) => {
      page.current = 0;
      const data = (await fetchPacks(
        page.current,
        size,
        id,
        title,
        type,
        authorId,
        materialType,
        sort
      )) as Pack[];
      hasMore.current = data !== undefined && !(data.length < size);
      setList(data);
    },
    [fetchPacks]
  );

  useDeepCompareEffect(() => {
    hasMore.current = true;
    fetchInit(
      ApiPageSizeForPacks,
      props.id,
      props.title,
      props.type,
      props.authorId,
      props.materialType,
      props.sort
    );
  }, [
    fetchInit,
    props.id,
    props.sort,
    props.title,
    props.materialType,
    props.type,
    props.authorId,
  ]);

  useIntersectionObserver({
    root: null,
    target: props.lastItemRef?.current,
    onIntersect: ([{ isIntersecting }]) => {
      if (isIntersecting && !isLoading && !hasError) {
        fetchNextPage(
          ApiPageSizeForPacks,
          props.id,
          props.title,
          props.type,
          props.authorId,
          props.materialType,
          props.sort
        );
      }
    },
  });

  return [list, setList, hasMore.current];
};
