import { Card, Typography, Image } from 'antd';
import { MouseEventHandler } from 'react';
import { Notification } from 'types/message';

export interface NotificationCardViewProps {
  notification: Notification;
  style?: React.CSSProperties;
  onClick?: MouseEventHandler<HTMLDivElement>;
}

export function NotificationCardView(props: NotificationCardViewProps) {
  return (
    <Card hoverable size="small" style={props.style} onClick={props.onClick}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div>
          <div>
            <Typography.Title style={{ margin: 0, fontSize: '0.9rem' }}>
              {props.notification.title}
            </Typography.Title>
          </div>
          <div>
            <Typography.Text>{props.notification.body}</Typography.Text>
          </div>
        </div>
        <div style={{ flex: '0 0 40px', marginLeft: 'auto' }}>
          {props.notification.image && (
            <Image width={40} src={props.notification.image} alt="image" />
          )}
        </div>
      </div>
    </Card>
  );
}
