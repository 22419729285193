import { Button, Form } from 'antd';
import { TagEntries } from 'components/TagEntries';
import { useRef, useState } from 'react';
import componentStyle from 'styles/common.module.css';
import { CheckOutlined, DeleteOutlined } from '@ant-design/icons';
import { Gradient } from 'types/material';
import { useForm } from 'antd/lib/form/Form';
import { ColorPicker } from 'components/ColorPicker';
import TextArea from 'antd/lib/input/TextArea';
import { GradientType, normalizeColorCode } from 'utils/colors';
import { ulid } from 'ulid';
import { Grapeseed } from 'api/grapeseed';
import { GradientBox } from 'components/MaterialViews/GradientBox';

interface NewGradientFormProps {
  onAdd: (g: Gradient) => void;
}

export function NewGradientForm(props: NewGradientFormProps) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [form] = useForm();
  const [colors, setColors] = useState<string[]>([]);
  const type = useRef<GradientType>(GradientType.ToBottom);

  const addColor = (color: string | undefined) => {
    if (color !== undefined && colors.length < 5) {
      const newValue = [...colors, color];
      setColors(newValue);
      form.setFieldValue(
        'codeStrings',
        newValue.map((c) => `#${c}`)
      );
    }
  };

  const removeColor = (index: number) => {
    const newValue = [...colors.slice(0, index), ...colors.slice(index + 1)];
    setColors(newValue);
    form.setFieldValue(
      'codeStrings',
      newValue.map((c) => `#${c}`)
    );
  };

  const isString = (str: string | undefined): str is string => {
    return !!str;
  };

  const onCodeInput = (codes: string) => {
    const input = codes.split(',').map((c) => normalizeColorCode(c.trim()));
    if (!input.includes(undefined)) {
      setColors(input.filter(isString));
    }
  };

  const onSubmit = async (values: any) => {
    setIsLoading(true);
    const body: Gradient = new Gradient({
      tags: values.tags ?? [],
      id: ulid(),
      codes: colors,
      gradientType: type.current,
    });
    const result = await Grapeseed.POST(`/api/gradients`, { body: body });
    if (result) {
      props.onAdd(new Gradient(result));
    }
    setIsLoading(false);
    setColors([]);
    form.resetFields();
  };

  return (
    <>
      <Form
        form={form}
        onFinish={onSubmit}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
          }
        }}
        labelCol={{ span: 3 }}
        labelAlign="left"
        colon={false}
        style={{ margin: '1rem 0' }}
      >
        <Form.Item label="Colors">
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <ColorPicker
              onAddColor={(value) => addColor(normalizeColorCode(value))}
            />
          </div>
        </Form.Item>
        <Form.Item
          label="Color codes"
          name="codeStrings"
          extra={
            <span className={componentStyle.hinttextOrange}>
              * Enter color codes directly, separated with ',' e.g.
              "#FFFFFFFF,#9AB1E974"
            </span>
          }
        >
          <TextArea onChange={(v) => onCodeInput(v.target.value)} />
        </Form.Item>
        <Form.Item
          name="tags"
          label="Tags"
          extra={
            <span className={componentStyle.hinttextOrange}>
              * Press enter to add tag.
            </span>
          }
        >
          <TagEntries layout="horizontal" />
        </Form.Item>
        <Form.Item label="Preview">
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {colors.map((color, index) => {
              return (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginRight: '0.6rem',
                  }}
                >
                  <div
                    key={`${index}${color}`}
                    style={{
                      width: '20px',
                      height: '20px',
                      borderStyle: 'solid',
                      borderWidth: '1px',
                      borderColor: 'var(--border-color)',
                      backgroundColor: `#${color}`,
                    }}
                  />
                  <Button
                    icon={<DeleteOutlined />}
                    danger
                    type="text"
                    size="small"
                    onClick={() => removeColor(index)}
                  />
                </div>
              );
            })}
          </div>
          {colors.length > 1 && (
            <GradientBox
              width={80}
              height={80}
              colors={colors}
              type={type.current}
              onClick={(d) => (type.current = d)}
            />
          )}
        </Form.Item>
        <Form.Item>
          <Button
            className={componentStyle.buttonOrange}
            type="primary"
            icon={<CheckOutlined />}
            htmlType="submit"
            loading={isLoading}
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    </>
  );
}
