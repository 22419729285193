import { PlusOutlined, UserOutlined } from '@ant-design/icons';
import { Avatar, Button, Card, Descriptions, Dropdown } from 'antd';
import { IdPopover } from 'components/IdPopover';
import { SubTitle } from 'components/PageTitles';
import { ThumbnailSizes } from 'components/Thumbnail/Constants';
import { ThumbnailView } from 'components/Thumbnail/ThumbnailView';
import { User, userProfileUrl, UserRoles } from 'types/user';
import { FallbackImage } from 'utils/constants';
import { RoleTag } from './RoleTag';
import { useContext, useState } from 'react';
import { AuthContext } from 'components/Auth/AuthProvider';
import { Grapeseed } from 'api/grapeseed';

export interface UserViewProps {
  user: User;
  size: 'compact' | 'full';
  onClick?: (u: User) => void;
}

export function UserView(props: UserViewProps) {
  switch (props.size) {
    case 'compact':
      return UserCompactView(props);
    case 'full':
      return UserFullView(props);
  }
}

function UserCompactView(props: UserViewProps) {
  return (
    <Card
      title={props.user.id}
      size="small"
      style={{ flex: '0 1 200px', margin: '0.5rem 1rem' }}
      onClick={(_) => props.onClick?.(props.user)}
    >
      <Avatar
        src={userProfileUrl(props.user)}
        icon={<UserOutlined />}
        style={{ marginRight: '1rem' }}
      />
      {props.user.username}
    </Card>
  );
}

function UserFullView(props: UserViewProps) {
  const { authState } = useContext(AuthContext);
  const isAdmin = authState?.roles?.includes(UserRoles.Admin) ?? false;
  const [user, setUser] = useState<User>(props.user);
  const rolesNotHas = Object.values(UserRoles).filter(
    (v) => !user.roles?.includes(v)
  );

  const [rolesLoading, setRolesLoading] = useState<boolean>(false);

  const onRoleTagClose = async (role: string) => {
    const update = {
      roles: user.roles?.filter((r: string) => r !== role),
    };
    setRolesLoading(true);
    const result = await Grapeseed.PUT(`/api/users/${user.id}`, {
      body: update,
    });
    setRolesLoading(false);
    if (result) {
      setUser(result);
    }
  };

  const onAppendRole = async (role: string) => {
    const update = {
      roles: user.roles?.concat(role) ?? [role],
    };
    setRolesLoading(true);

    const result = await Grapeseed.PUT(`/api/users/${user.id}`, {
      body: update,
    });
    setRolesLoading(false);
    if (result) {
      setUser(result);
    }
  };

  const items = rolesNotHas.map((role: string) => {
    return {
      key: role,
      label: <RoleTag role={role} onClick={onAppendRole} />,
    };
  });

  return (
    <>
      <SubTitle>@{user.username}</SubTitle>
      <p>{user.bio}</p>
      <div style={{ display: 'flex', marginTop: '1rem' }}>
        <ThumbnailView
          size={ThumbnailSizes.full}
          src={
            user.profile
              ? 'https://storage-alpha.retrica.io/'.concat(user.profile)
              : FallbackImage
          }
        />
        <Descriptions
          size="small"
          bordered
          column={1}
          style={{ flex: '0 0 70%', marginLeft: '1rem' }}
          labelStyle={{ width: '30%' }}
        >
          <Descriptions.Item label="ID">
            <IdPopover
              id={user.id}
              content={<></>}
              showHref={{ path: 'users' }}
            />
          </Descriptions.Item>
          <Descriptions.Item label="Email">{user.email}</Descriptions.Item>
          <Descriptions.Item label="Username">
            {user.username}
          </Descriptions.Item>
          <Descriptions.Item label="State">{user.state}</Descriptions.Item>
        </Descriptions>
      </div>
      <div style={{ display: 'flex', marginTop: '1rem' }}>
        <Descriptions
          title="User Information"
          size="small"
          bordered
          column={1}
          style={{ flex: '5 1 50%' }}
          labelStyle={{ width: '30%' }}
        >
          <Descriptions.Item label="Full name">
            {user.fullname}
          </Descriptions.Item>
          <Descriptions.Item label="Phone #">
            {user.phonenumber}
          </Descriptions.Item>
          <Descriptions.Item label="Country">{user.country}</Descriptions.Item>
          <Descriptions.Item label="Language">
            {user.language}
          </Descriptions.Item>
          <Descriptions.Item label="Push option">
            {user.pushOption}
          </Descriptions.Item>
          <Descriptions.Item label="Birth">{user.birth}</Descriptions.Item>
          <Descriptions.Item label="Gender">{user.gender}</Descriptions.Item>
          <Descriptions.Item label="Location toggle">
            {user.locationToggle}
          </Descriptions.Item>
          <Descriptions.Item label="Profile type">
            {user.profileType}
          </Descriptions.Item>
          <Descriptions.Item label="Created at">
            {user.createdAt.toLocaleString()}
          </Descriptions.Item>
          <Descriptions.Item label="Updated at">
            {user.updatedAt.toLocaleString()}
          </Descriptions.Item>
          <Descriptions.Item label="IP">{user.ip}</Descriptions.Item>
          <Descriptions.Item label="Profile updated">
            {user.profileUpdatedAt?.toLocaleString()}
          </Descriptions.Item>
          <Descriptions.Item label="Theme type">
            {user.themeType}
          </Descriptions.Item>
          <Descriptions.Item label="Roles">
            <div style={{ display: 'flex' }}>
              {user.roles?.map((v: string) => (
                <RoleTag
                  key={v}
                  role={v}
                  closable={isAdmin}
                  onClose={onRoleTagClose}
                />
              ))}
              {items.length > 0 && isAdmin && (
                <Dropdown menu={{ items }} trigger={['click']}>
                  <Button
                    loading={rolesLoading}
                    type="primary"
                    icon={<PlusOutlined />}
                    size="small"
                    style={{ marginLeft: 'auto' }}
                  />
                </Dropdown>
              )}
            </div>
          </Descriptions.Item>
        </Descriptions>
        <div style={{ flex: '1 1 10px' }} />
        <Descriptions
          title="Social Connection"
          size="small"
          bordered
          column={1}
          style={{ flex: '5 1 50%' }}
          labelStyle={{ width: '30%' }}
        >
          <Descriptions.Item label="Snapchat name">
            {user.snapchatName}
          </Descriptions.Item>
          <Descriptions.Item label="Instagram name">
            {user.instagramName}
          </Descriptions.Item>
          <Descriptions.Item label="Musically name">
            {user.musicallyName}
          </Descriptions.Item>
          <Descriptions.Item label="Kik name">{user.kikName}</Descriptions.Item>
          <Descriptions.Item label="Facebook ID">
            {user.retricaFacebookId}
          </Descriptions.Item>
          <Descriptions.Item label="VKontakte ID">
            {user.retricaVKontakteId}
          </Descriptions.Item>
          <Descriptions.Item label="Apple ID">
            {user.retricaAppleId}
          </Descriptions.Item>
          <Descriptions.Item label="Huawei ID">
            {user.retricaHuaweiId}
          </Descriptions.Item>
        </Descriptions>
      </div>
    </>
  );
}
