export const TopicDefault = 'app-push-all-iOS';
export interface Notification {
  title: string;
  body: string;
  image?: string;
}

export interface Status {
  sentAt?: Date;
  scheduledAt?: Date;
  canceledAt?: Date;
}

export interface Message {
  id?: string; // 서버에서 생성
  topic: string;
  notification: Notification;
  sound?: string;
  ref?: string;
  status: Status;
  requestedAt?: Date; // 서버에서 생성
}
