import React from 'react';
import { SubTitle } from 'components/PageTitles';
import { useLocation } from 'react-router';
import { Bundle } from 'types/bundle';
import { BundleEditView } from './components/BundleEditView';

function New() {
  const locationState = useLocation().state as {
    bundle: Bundle;
  };

  return (
    <React.Fragment>
      <SubTitle>
        {locationState?.bundle ? 'Edit Bundle' : 'Add a New Bundle'}
      </SubTitle>
      <BundleEditView bundle={locationState?.bundle} />
    </React.Fragment>
  );
}

const exports = New;
export default exports;
