import { useNavigate } from 'react-router-dom';
import { MenuInfo } from 'rc-menu/lib/interface';
import { Menu } from 'antd';
export interface AppMenuItem {
  key: string;
  url?: string;
  label: string;
  children?: AppMenuItem[];
}

export interface AppMenuProps {
  items: AppMenuItem[];
}

export function AppMenu(props: AppMenuProps) {
  const navigate = useNavigate();
  const getAppMenuItem = (
    key: string,
    items: AppMenuItem[]
  ): AppMenuItem | null => {
    for (let i = 0; i < items.length; i++) {
      const item: AppMenuItem = items[i];
      if (key === item.key) return item;
      if (item.children != null) {
        const found = getAppMenuItem(key, item.children!);
        if (found != null) return found;
      }
    }
    return null;
  };
  const handleClick = (info: MenuInfo) => {
    const item = getAppMenuItem(info.key, props.items);
    if (!item?.url) return;
    navigate(item!.url!);
  };
  return (
    <Menu
      onClick={handleClick}
      mode="inline"
      defaultOpenKeys={props.items.map((item) => item.key)}
      items={props.items}
      style={{ height: '100%', borderRight: 0 }}
    />
  );
}
