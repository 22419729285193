import { useState } from 'react';
import { Button, Checkbox, Form, Input } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { CheckOutlined } from '@ant-design/icons';
import { TagEntries } from 'components/TagEntries';
import { FileUploader, acceptTypesInfo } from 'components/FileUploader';
import componentStyle from 'styles/common.module.css';
import {
  ContainerLayouts,
  Layout,
  LayoutFileAcceptTypes,
  ThumbnailAcceptTypes,
  ThumbnailFileSuffix,
} from 'types/material';
import { Grapeseed } from 'api/grapeseed';
import { getMediaResolution } from 'utils/media';

export interface NewLayoutFormProps {
  onAdd: (l: Layout) => void;
}

function NewLayoutForm(props: NewLayoutFormProps) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [form] = useForm();
  const [id, setId] = useState<string>();

  const onSubmit = async (values: any) => {
    if (!id) {
      console.log('no id set!');
      return;
    }
    const body: Layout = new Layout({
      id: id,
      tags: values.tags ?? [],
      createdAt: new Date(),
      thumbnailUrl: values.thumbnail[0].url,
      isFree: values.isFree ?? false,

      title: values.title,
      canvasSize: await getMediaResolution(values.svg[0]),
      svgUrl: values.svg[0].url,
    });

    setIsLoading(true);
    const result = await Grapeseed.POST(`/api/layouts`, { body: body });
    if (result) {
      props.onAdd(new Layout(result));
      setId(undefined);
      form.resetFields();
    }
    setIsLoading(false);
  };

  return (
    <Form
      form={form}
      onFinish={onSubmit}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          e.preventDefault();
        }
      }}
      labelWrap={true}
      labelCol={{ span: 3 }}
      labelAlign="left"
      colon={false}
      style={{ margin: '1rem 0' }}
    >
      <Form.Item
        label="Title"
        name="title"
        wrapperCol={{ span: 5 }}
        hasFeedback={true}
        rules={[{ required: true, message: 'Title cannot be empty.' }]}
      >
        <Input type="text" placeholder="Layout title" />
      </Form.Item>
      <Form.Item
        name="tags"
        label="Tags"
        extra={
          <span className={componentStyle.hinttextOrange}>
            * Press enter to add tag.
          </span>
        }
      >
        <TagEntries layout="horizontal" />
      </Form.Item>

      <Form.Item
        label="SVG File"
        name="svg"
        wrapperCol={{ span: 7 }}
        extra={acceptTypesInfo(LayoutFileAcceptTypes)}
        rules={[{ required: true, message: 'Layout SVG file is required' }]}
      >
        <FileUploader
          container={ContainerLayouts}
          accept={LayoutFileAcceptTypes}
          onAdd={(_, id) => setId(id)}
          onRemove={(_) => setId(undefined)}
        />
      </Form.Item>

      {id && (
        <Form.Item
          label="Thumbnail"
          name="thumbnail"
          wrapperCol={{ span: 7 }}
          extra={acceptTypesInfo(ThumbnailAcceptTypes)}
          rules={[{ required: true, message: 'Thumbnail is required' }]}
        >
          <FileUploader
            accept={ThumbnailAcceptTypes}
            container={ContainerLayouts}
            filename={id}
            suffix={ThumbnailFileSuffix}
          />
        </Form.Item>
      )}

      <Form.Item
        label="Free"
        name="isFree"
        valuePropName="checked"
        wrapperCol={{ span: 5 }}
      >
        <Checkbox />
      </Form.Item>

      <Form.Item>
        <Button
          className={componentStyle.buttonOrange}
          type="primary"
          icon={<CheckOutlined />}
          htmlType="submit"
          loading={isLoading}
        >
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
}

const exports = NewLayoutForm;
export default exports;
