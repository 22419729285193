import React, { useRef } from 'react';
import { Layout, MaterialTypes } from 'types/material';
import { SubTitle } from 'components/PageTitles';
import { MaterialList } from 'components/MaterialViews/MaterialList';
import NewLayoutForm from './components/NewLayoutForm';
import { useFetchMaterials } from 'hooks/useFetchMaterials';

function New() {
  const lastItemRef = useRef(null);
  const [list, setList, hasMore] = useFetchMaterials({
    type: [MaterialTypes.Layout],
    lastItemRef: lastItemRef,
  });

  return (
    <React.Fragment>
      <SubTitle>Add a new layout</SubTitle>
      <NewLayoutForm onAdd={(l: Layout) => setList([l, ...list])} />
      <hr style={{ borderTop: 'grey' }} />
      <MaterialList
        list={list}
        itemEditable
        onRemove={(id: string) => {
          setList(list.filter((t) => t.id !== id));
        }}
        hasMore={hasMore}
        lastItemRef={lastItemRef}
      />
    </React.Fragment>
  );
}

const exports = New;
export default exports;
