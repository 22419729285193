import { BundleItem } from 'types/bundle';
import { DragEvent } from 'react';
import { Button } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import viewStyle from 'styles/view.module.css';
import { BundleItemView } from './BundleItemView';
import { Size } from 'types/common';

export interface BundleItemsConstructViewProps {
  list: BundleItem[];
  onDragOver?: (e: DragEvent<HTMLDivElement>) => void;
  onDrop?: (e: DragEvent<HTMLDivElement>) => void;
  onRemove?: (ref: string) => void;
  onItemMove?: (src: number, dest: number) => void;
  onChangeThumbnail?: (url: string, size: Size, index: number) => void;
}

export function BundleItemsConstructView(props: BundleItemsConstructViewProps) {
  const onDragStart = (e: DragEvent<HTMLDivElement>, index: number) => {
    e.dataTransfer.setData('application/json', JSON.stringify({ src: index }));
  };
  const onDragOver = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };
  const onDrop = (e: DragEvent<HTMLDivElement>, index: number) => {
    const transfer = e.dataTransfer.getData('application/json');
    const obj = JSON.parse(transfer);
    if (obj.src >= 0) {
      props.onItemMove?.(obj.src, index);
      e.stopPropagation();
    }
  };
  return (
    <div
      className={`${viewStyle.viewbox} ${viewStyle.wrap} ${viewStyle.maxHeight600}`}
      onDragOver={props.onDragOver}
      onDrop={props.onDrop}
    >
      {props.list.map((elem, index) => {
        return (
          <div
            key={`${elem.ref}${elem.thumbnailUrl}${elem.thumbnailSize}`}
            style={{ display: 'flex', cursor: 'move', position: 'relative' }}
            draggable
            onDragStart={(e) => onDragStart(e, index)}
            onDragOver={onDragOver}
            onDrop={(e) => onDrop(e, index)}
          >
            <BundleItemView
              item={elem}
              onChangeThumbnail={(url: string, size: Size) =>
                props.onChangeThumbnail?.(url, size, index)
              }
            />
            <div style={{ position: 'absolute', top: 12, right: 12 }}>
              <Button
                size="small"
                icon={<DeleteOutlined />}
                danger
                onClick={() => props.onRemove?.(elem.ref)}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
}
