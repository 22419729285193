import React from 'react';
import { SubTitle } from 'components/PageTitles';
import { NewUrlStickerForm } from './components/NewUrlStickerForm';

export function NewWithUrls() {
  return (
    <React.Fragment>
      <SubTitle>Add stickers with external link</SubTitle>
      <NewUrlStickerForm />
    </React.Fragment>
  );
}
