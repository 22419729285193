import { Button, Checkbox, Form } from 'antd';
import { FileUploader, acceptTypesInfo } from 'components/FileUploader';
import { TagEntries } from 'components/TagEntries';
import { useState } from 'react';
import componentStyle from 'styles/common.module.css';
import { CheckOutlined } from '@ant-design/icons';
import {
  ContainerStickers,
  Sticker,
  StickerContentAcceptTypes,
  ThumbnailAcceptTypes,
  ThumbnailFileSuffix,
} from 'types/material';
import { useForm } from 'antd/lib/form/Form';
import { Grapeseed } from 'api/grapeseed';
import { getMediaResolution } from 'utils/media';

interface FieldData {
  name: string | number | (string | number)[];
  value?: any;
  touched?: boolean;
  validating?: boolean;
  errors?: string[];
}

interface NewStickerFormProps {
  onAdd: (s: Sticker) => void;
}

function NewStickerForm(props: NewStickerFormProps) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [thumbnailDisabled, setThumbnailDisabled] = useState<boolean>(false);
  const [form] = useForm();
  const [id, setId] = useState<string>();

  const onSubmit = async (values: any) => {
    if (!id) {
      console.log('no id set!');
      return;
    }
    let body: Sticker = new Sticker({
      id: id,
      tags: values.tags ?? [],
      createdAt: new Date(),
      thumbnailUrl: values.thumbnail ? values.thumbnail[0]?.url : undefined,
      isFree: values.isFree ?? false,
      content: {
        type: values.file[0].type,
        size: await getMediaResolution(values.file[0]),
        url: values.file[0].url,
        duration: values.duration ?? null,
      },
      bytes: values.file[0].size,
    });
    setIsLoading(true);
    const result = await Grapeseed.POST(`/api/stickers`, { body: body });
    setIsLoading(false);
    if (result) {
      props.onAdd(new Sticker(result));
      setId(undefined);
      form.resetFields();
    }
  };

  const onFieldChange = (fields: FieldData[]) => {
    if (fields.length > 0) {
      fields.forEach((field, index, fields) => {
        const names = field.name as string[];
        if (names.length > 0 && names[0] === 'file') {
          if (field.value && field.value.length > 0) {
            if (field.value[0].type === 'application/json') {
              setThumbnailDisabled(true);
            } else {
              setThumbnailDisabled(false);
            }
          }
        }
      });
    }
  };

  return (
    <Form
      form={form}
      onFinish={onSubmit}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          e.preventDefault();
        }
      }}
      onFieldsChange={onFieldChange}
      labelCol={{ span: 3 }}
      labelAlign="left"
      colon={false}
      style={{ margin: '1rem 0' }}
    >
      <Form.Item
        label="Sticker"
        name="file"
        extra={acceptTypesInfo(StickerContentAcceptTypes)}
        rules={[{ required: true, message: 'Sticker content is required' }]}
      >
        <FileUploader
          accept={StickerContentAcceptTypes}
          container={ContainerStickers}
          onAdd={(_, id) => setId(id)}
          onRemove={(_) => setId(undefined)}
        />
      </Form.Item>
      {id && !thumbnailDisabled && (
        <Form.Item
          name="thumbnail"
          label="Thumbnail"
          dependencies={['file']}
          extra={
            thumbnailDisabled ? (
              <span className={componentStyle.hinttextOrange}>
                * Thumbnail would be ignored if Sticker file type is Lottie JSON
              </span>
            ) : (
              acceptTypesInfo(ThumbnailAcceptTypes)
            )
          }
        >
          <FileUploader
            container={ContainerStickers}
            accept={ThumbnailAcceptTypes}
            filename={id}
            suffix={ThumbnailFileSuffix}
          />
        </Form.Item>
      )}
      <Form.Item
        name="tags"
        label="Tags"
        extra={
          <span className={componentStyle.hinttextOrange}>
            * Press enter to add tag.
          </span>
        }
      >
        <TagEntries layout="horizontal" />
      </Form.Item>
      <Form.Item
        label="Free"
        name="isFree"
        valuePropName="checked"
        wrapperCol={{ span: 5 }}
      >
        <Checkbox />
      </Form.Item>
      <Form.Item>
        <Button
          className={componentStyle.buttonOrange}
          type="primary"
          icon={<CheckOutlined />}
          htmlType="submit"
          loading={isLoading}
        >
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
}

const exports = NewStickerForm;
export default exports;
