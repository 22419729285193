import {
  Button,
  Divider,
  Input,
  InputRef,
  Popconfirm,
  Select,
  notification,
} from 'antd';
import viewStyle from 'styles/view.module.css';
import {
  CheckOutlined,
  DeleteOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import React, { useState, DragEvent, useRef } from 'react';
import { Bundle, BundleList } from 'types/bundle';
import { useFetchBundleLists } from 'hooks/useFetchBundleLists';
import { Grapeseed } from 'api/grapeseed';
import { ulid } from 'ulid';
import { BundlesVerticalList } from './BundlesVerticalList';
import { Union } from 'utils/utils';

export function BundleListView() {
  const [bundles, setBundles] = useState<Bundle[]>([]);
  const [bundleLists, setBundleLists] = useFetchBundleLists({});
  const [selectedId, setSelectedValue] = useState();
  const newListTitleRef = useRef<InputRef>(null);
  const [newListTitle, setNewListTitle] = useState<string>('');

  const swapItems = (src: number, dest: number) => {
    const old = bundles;
    const movingItem = old.splice(src, 1)[0];
    setBundles([...old.slice(0, dest), movingItem, ...old.slice(dest)]);
  };

  const onDropToItem = (e: DragEvent<HTMLDivElement>, index: number) => {
    const transfer = e.dataTransfer.getData('application/json');
    const obj = JSON.parse(transfer);
    if (obj.dataType === 'index') {
      swapItems(obj.data, index);
      e.stopPropagation();
    }
  };

  const onDropToList = (e: DragEvent<HTMLDivElement>) => {
    if (!selectedId) {
      notification.warning({
        placement: 'top',
        message: 'Select Bundle List first',
      });
      return;
    }
    const transfer = e.dataTransfer.getData('application/json');
    const obj = JSON.parse(transfer);
    if (obj.dataType === 'bundle') {
      setBundles((prev) => Union(prev, [new Bundle(obj.data)]) as Bundle[]);
    }
  };

  const onItemDragStart = (
    e: DragEvent<HTMLDivElement>,
    b: Bundle,
    i: number
  ) => {
    e.dataTransfer.setData(
      'application/json',
      JSON.stringify({ dataType: 'index', data: i })
    );
  };

  const onNewListNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewListTitle(event.target.value);
  };

  const addBundleList = async () => {
    const exist = bundleLists.find(
      (b) => b.title.toLowerCase() === newListTitle.toLowerCase()
    );
    if (exist) {
      notification.warning({
        placement: 'top',
        message: `Bundle list '${newListTitle}' is already exists.`,
        duration: 2,
      });
      return;
    }
    // API POST call
    const body: BundleList = new BundleList({
      id: ulid(),
      title: newListTitle,
      createdAt: new Date(),
      bundles: [],
    });
    const result = await Grapeseed.POST('/api/bundlelists', {
      body: body,
    });
    if (result) {
      setBundleLists(
        (prev) => Union(prev, [new BundleList(result)]) as BundleList[]
      );
    }
    setNewListTitle('');
    setTimeout(() => {
      newListTitleRef.current?.focus();
    }, 0);
  };

  const saveSelectedBundleList = async () => {
    const b = bundleLists?.find((b) => b.id === selectedId);
    if (b) {
      b.bundles = bundles;
      const result = await Grapeseed.PUT(`/api/bundlelists/${selectedId}`, {
        body: b,
      });
      if (result) {
        setBundles(result.bundles ?? []);
        notification.info({
          placement: 'top',
          message: 'Change Saved',
          duration: 2,
        });
      }
    }
  };

  const deleteSelectedBundleList = async () => {
    if (selectedId) {
      const result = await Grapeseed.DELETE(`/api/bundlelists/${selectedId}`);
      if (result) {
        setBundleLists((prev) => prev.filter((b) => b.id !== selectedId));
        setSelectedValue(undefined);
        setBundles([]);
      }
    }
  };

  return (
    <div
      className={`${viewStyle.viewbox}`}
      style={{
        overflow: 'scroll',
        flex: '1 1 0',
      }}
    >
      <div style={{ display: 'flex' }}>
        <Select
          style={{ width: '90%' }}
          dropdownRender={(menu) => (
            <>
              {menu}
              <Divider style={{ margin: '8px 0' }} />
              <div
                style={{ padding: '0 8px 4px', display: 'flex', width: '100%' }}
              >
                <Input
                  placeholder="Please enter item"
                  ref={newListTitleRef}
                  value={newListTitle}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                      e.stopPropagation();
                    }
                  }}
                  onChange={onNewListNameChange}
                />
                <Button
                  type="text"
                  icon={<PlusOutlined />}
                  onClick={addBundleList}
                >
                  Add BundleList
                </Button>
              </div>
            </>
          )}
          options={bundleLists?.map((l) => ({ label: l.title, value: l.id }))}
          onChange={(value) => {
            setSelectedValue(value);
            const b = bundleLists?.find((b) => b.id === value);
            if (b) {
              setBundles(b.bundles ?? []);
            }
          }}
          value={selectedId}
        />
        <div style={{ width: '20px' }} />
        <Button
          type="link"
          icon={<CheckOutlined />}
          onClick={saveSelectedBundleList}
        />
        <Popconfirm
          title="Are you sure to DELETE？"
          icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
          onConfirm={(e) => deleteSelectedBundleList()}
        >
          <Button type="text" icon={<DeleteOutlined />} danger />
        </Popconfirm>
      </div>
      <BundlesVerticalList
        list={bundles}
        itemDraggable
        onItemDragStart={onItemDragStart}
        onDropToItem={onDropToItem}
        onDropToList={onDropToList}
        itemEjectable
        onRemove={(id: string) =>
          setBundles((prev) => prev.filter((b) => b.id !== id))
        }
      />
    </div>
  );
}
