import { DragEvent, MutableRefObject } from 'react';
import { Bundle } from 'types/bundle';
import { Button, Spin } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { BundleView } from './BundleView';

interface BundlesVerticalListProps {
  list: Bundle[];
  onRemove?: (id: string) => void;
  lastItemRef?: MutableRefObject<any>;
  hasMore?: boolean;
  itemEditable?: boolean;
  itemDraggable?: boolean;
  onItemDragStart?: (
    e: DragEvent<HTMLDivElement>,
    b: Bundle,
    index: number
  ) => void;
  onDropToItem?: (e: DragEvent<HTMLDivElement>, index: number) => void;
  onDropToList?: (e: DragEvent<HTMLDivElement>) => void;
  itemEjectable?: boolean;
}

export function BundlesVerticalList(props: BundlesVerticalListProps) {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        height: '100%',
        width: '100%',
      }}
      onDragOver={(e) => e.preventDefault()}
      onDrop={props.onDropToList}
    >
      {props.list?.map((bundle, index) => {
        return (
          <div
            key={bundle.id}
            style={{ display: 'flex', alignItems: 'flex-start' }}
          >
            <BundleView
              bundle={bundle}
              onRemove={props.onRemove}
              editable={props.itemEditable}
              draggable={props.itemDraggable}
              onDragStart={(e) => props.onItemDragStart?.(e, bundle, index)}
              onDrop={(e) => props.onDropToItem?.(e, index)}
            />
            {props.itemEjectable && (
              <Button
                style={{
                  marginTop: '2rem',
                  marginLeft: '-4rem',
                }}
                icon={<DeleteOutlined />}
                shape="circle"
                type="primary"
                danger
                onClick={() => props.onRemove?.(bundle.id)}
              />
            )}
          </div>
        );
      })}
      {props.hasMore && props.lastItemRef && (
        <div
          ref={props.lastItemRef}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            height: '100px',
            margin: '1rem',
          }}
        >
          <Spin />
        </div>
      )}
    </div>
  );
}
