import { SubTitle } from 'components/PageTitles';
import React, { useState } from 'react';
import { Button, Form, Input, Typography } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import componentStyle from 'styles/common.module.css';
import { CheckOutlined } from '@ant-design/icons';
import { useFetchCreators } from 'hooks/useFetchCreators';
import { Grapeseed } from 'api/grapeseed';
import { CreatorCardList } from './components/CreatorCardList';

export function CreatorList() {
  const [list, setList] = useFetchCreators({});
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [form] = useForm();

  const handleAdd = async (values: any) => {
    setSubmitting(true);
    const result = await Grapeseed.POST('/api/creators', {
      body: {
        code: values.code,
        email: values.email,
      },
    });
    if (result) {
      setList([result, ...list]);
      form.resetFields();
    }
    setSubmitting(false);
  };

  const handleDelete = async (key: React.Key) => {
    const result = await Grapeseed.DELETE(`/api/creators/${key}`);
    if (result) {
      setList(list.filter((item) => item.code !== key));
      form.resetFields();
    }
  };

  return (
    <React.Fragment>
      <SubTitle>Manage creator code</SubTitle>
      <Form
        form={form}
        onFinish={handleAdd}
        layout="inline"
        style={{ display: 'flex', alignItems: 'center', margin: '1rem 0' }}
      >
        <Typography.Text strong style={{ margin: '0 1rem' }}>
          Add a New Creator:
        </Typography.Text>
        <Form.Item
          name="code"
          rules={[
            { required: true },
            {
              pattern: /^[a-zA-Z0-9]+$/,
              message: 'Code must consist only of letters and numbers.',
            },
          ]}
        >
          <Input placeholder="Code"></Input>
        </Form.Item>
        <Form.Item name="email" rules={[{ required: true }, { type: 'email' }]}>
          <Input placeholder="Email"></Input>
        </Form.Item>
        <Form.Item>
          <Button
            className={componentStyle.buttonOrange}
            type="primary"
            icon={<CheckOutlined />}
            htmlType="submit"
            loading={submitting}
          ></Button>
        </Form.Item>
      </Form>
      <div>
        <CreatorCardList list={list} itemEditable onDelete={handleDelete} />
      </div>
    </React.Fragment>
  );
}
