import { Button, Checkbox, Form } from 'antd';
import { FileUploader, acceptTypesInfo } from 'components/FileUploader';
import { TagEntries } from 'components/TagEntries';
import { useState } from 'react';
import componentStyle from 'styles/common.module.css';
import { CheckOutlined } from '@ant-design/icons';
import {
  ContainerShapes,
  Shape,
  ShapeContentAcceptTypes,
} from 'types/material';
import { useForm } from 'antd/lib/form/Form';
import { Grapeseed } from 'api/grapeseed';
import { getMediaResolution } from 'utils/media';

interface NewShapeFormProps {
  onAdd: (s: Shape) => void;
}

export function NewShapeForm(props: NewShapeFormProps) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [form] = useForm();
  const [id, setId] = useState<string>();

  const submit = async (values: any) => {
    if (!id) {
      console.log('no id set!');
      return;
    }
    const body: Shape = new Shape({
      id: id,
      tags: values.tags ?? [],
      createdAt: new Date(),
      isFree: values.isFree ?? false,
      content: {
        type: values.file[0].type,
        size: await getMediaResolution(values.file[0]),
        url: values.file[0].url,
      },
      bytes: values.file[0].size,
    });
    setIsLoading(true);
    const result = await Grapeseed.POST(`/api/shapes`, { body: body });
    setIsLoading(false);
    if (result) {
      props.onAdd(new Shape(result));
      setId(undefined);
      form.resetFields();
    }
  };

  return (
    <Form
      form={form}
      onFinish={submit}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          e.preventDefault();
        }
      }}
      labelCol={{ span: 3 }}
      labelAlign="left"
      colon={false}
      style={{ margin: '1rem 0' }}
    >
      <Form.Item
        label="Shape"
        name="file"
        extra={acceptTypesInfo(ShapeContentAcceptTypes)}
        rules={[{ required: true, message: 'Shape content is required' }]}
      >
        <FileUploader
          container={ContainerShapes}
          accept={ShapeContentAcceptTypes}
          onAdd={(_, id) => setId(id)}
          onRemove={(_) => setId(undefined)}
        />
      </Form.Item>
      <Form.Item
        name="tags"
        label="Tags"
        extra={
          <span className={componentStyle.hinttextOrange}>
            * Press enter to add tag.
          </span>
        }
      >
        <TagEntries layout="horizontal" />
      </Form.Item>
      <Form.Item
        label="Free"
        name="isFree"
        valuePropName="checked"
        wrapperCol={{ span: 5 }}
      >
        <Checkbox />
      </Form.Item>
      <Form.Item>
        <Button
          className={componentStyle.buttonOrange}
          type="primary"
          icon={<CheckOutlined />}
          htmlType="submit"
          loading={isLoading}
        >
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
}
