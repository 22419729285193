import React, { useRef } from 'react';
import { Gradient, MaterialTypes } from 'types/material';
import { SubTitle } from 'components/PageTitles';
import { MaterialList } from 'components/MaterialViews/MaterialList';
import { useFetchMaterials } from 'hooks/useFetchMaterials';
import { NewGradientForm } from './components/NewGradientForm';

function New() {
  const lastItemRef = useRef(null);
  const [list, setList, hasMore] = useFetchMaterials({
    type: [MaterialTypes.Gradient],
    lastItemRef: lastItemRef,
  });

  return (
    <React.Fragment>
      <SubTitle>Add a gradient</SubTitle>
      <NewGradientForm
        onAdd={(g: Gradient) => setList((prev) => [g, ...prev])}
      />
      <hr style={{ borderTop: 'grey' }} />
      <MaterialList
        list={list}
        itemEditable
        onRemove={(id: string) => setList(list.filter((c) => c.id !== id))}
        lastItemRef={lastItemRef}
        hasMore={hasMore}
      />
    </React.Fragment>
  );
}

const exports = New;
export default exports;
