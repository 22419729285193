import Dragger from 'antd/lib/upload/Dragger';
import { FileUploaderProps, acceptTypesInfo } from './FileUploader';
import { InboxOutlined } from '@ant-design/icons';
import React, { useCallback, useRef } from 'react';
import { RcFile, UploadChangeParam } from 'antd/lib/upload';
import { UploadRequestOption as RcCustomRequestOptions } from 'rc-upload/lib/interface';
import { Grapeseed } from 'api/grapeseed';
import { ApiError, OnError } from 'utils/errors';
import { UploadFile } from 'antd/lib/upload/interface';
import { monotonicFactory } from 'ulid';

export function BulkUploader(props: FileUploaderProps) {
  const urlMap = useRef<Map<string, { url: string; token: string }>>(new Map()); // file.uid : upload url
  const idMap = useRef<Map<string, string>>(new Map()); // file.uid : material ID
  const uploaded = useRef<Map<string, UploadFile>>(new Map());
  const ulid = monotonicFactory();

  const getUrl = useCallback(
    async (file: UploadFile) => {
      const uploadName = idMap.current.get(file.uid);
      if (!uploadName) {
        console.log('ulid not exist', file.name, file.uid);
        return null;
      }
      const ext = file.name.split('.').pop();
      const result = await Grapeseed.GET('/api/files/uploadurl', {
        queryParams: new Map<string, string>([
          ['container', props.container],
          ['name', `${uploadName}_${Date.now().toString()}.${ext}`],
        ]),
      });
      if (result) {
        const url = result['url'];
        const token = result['token'];
        urlMap.current.set(file.uid, { url: url, token: token });
        return `${url}`;
      } else {
        console.log(`failed to get upload url for ${file.name}`);
        return null;
      }
    },
    [props.container]
  );

  const beforeUpload = useCallback(
    async (file: UploadFile, filelist: UploadFile[]) => {
      filelist.forEach((f) => {
        if (f.uid && !idMap.current.get(f.uid)) {
          idMap.current.set(f.uid, ulid());
        }
      });
      const url = await getUrl(file);
      if (!url) {
        return false;
      }
    },
    [getUrl, ulid]
  );

  const doUpload = useCallback(async (options: RcCustomRequestOptions) => {
    const { onSuccess, onProgress, onError } = options;
    const file = options.file as RcFile;
    const url = urlMap.current.get(file.uid);
    if (!url) {
      console.log(`url for ${file.name} not exist!!`);
      return;
    }
    const requestUrlString = `${url.url}?${url.token}`;
    const params = new URL(requestUrlString).searchParams;
    const apiVersionString = params.get('sv') ?? '2021-04-10';

    const request = new XMLHttpRequest();
    request.open('PUT', requestUrlString);
    request.setRequestHeader('x-ms-version', apiVersionString);
    request.setRequestHeader('x-ms-date', new Date().toUTCString());
    request.setRequestHeader('Content-Type', file.type);
    request.setRequestHeader('x-ms-blob-type', 'BlockBlob');
    request.setRequestHeader('x-ms-access-tier', 'Cold');
    request.setRequestHeader(
      'x-ms-blob-cache-control',
      'public s-maxage=31536000, max-age=31536000'
    );

    request.upload.addEventListener('progress', (e) => {
      onProgress?.({ percent: (e.loaded / e.total) * 100 });
    });

    request.timeout = 15000;
    request.ontimeout = (e) => {
      OnError(e);
    };

    request.onreadystatechange = (e) => {
      if (request.readyState === 4) {
        if (request.status >= 200 && request.status < 300) {
          onSuccess?.(e);
        } else {
          const err = new ApiError(
            request.status,
            request.statusText,
            request.response
          );
          onError?.(err);
          OnError(err);
        }
      }
    };

    request.send(file);
  }, []);

  const handleChange = useCallback(
    (info: UploadChangeParam<UploadFile>) => {
      let file = info.file;
      if (file.status === 'done') {
        file.url = urlMap.current.get(file.uid)!.url;
        uploaded.current.set(file.uid, file);
        props.onAdd?.(file, idMap.current.get(file.uid)!.split('_')[0]);
      } else if (file.status === 'removed') {
        props.onRemove?.(idMap.current.get(file.uid)!);
        urlMap.current.delete(file.uid);
        idMap.current.delete(file.uid);
        uploaded.current.delete(file.uid);
      }
      info.fileList.forEach((f) => {
        const recorded = uploaded.current.get(f.uid);
        if (recorded) {
          f.status = recorded.status;
          f.url = recorded.url;
        }
      });
    },
    [props]
  );

  const handleRemove = useCallback(
    (file: UploadFile) => {
      const url = file.url;
      const filename = url?.split('/').pop();
      if (filename) {
        Grapeseed.DELETE(`/api/files/${filename}`, {
          queryParams: new Map<string, string>([
            ['container', props.container],
          ]),
        });
      }
    },
    [props]
  );

  return (
    <React.Fragment>
      <div style={{ width: '60%' }}>
        <Dragger
          name="files"
          multiple={true}
          accept={props.accept}
          onChange={handleChange}
          beforeUpload={beforeUpload}
          customRequest={doUpload}
          onRemove={handleRemove}
          listType={'picture-card'}
          disabled={props.disabled}
        >
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">
            Click or drag file to this area to upload
          </p>
          {acceptTypesInfo(props.accept ?? '')}
        </Dragger>
      </div>
    </React.Fragment>
  );
}
