import { SubTitle } from 'components/PageTitles';
import { UserListView } from './components/UserListView';
import { useFetchUsers } from 'hooks/useFetchUsers';
import { useRef } from 'react';

export function Recent() {
  const lastItemRef = useRef(null);
  const [list, , hasMore] = useFetchUsers({
    lastItemRef: lastItemRef,
  });
  return (
    <>
      <SubTitle>Recently Joined Users</SubTitle>
      <UserListView
        list={list}
        hasMore={hasMore}
        lastItemRef={lastItemRef}
        onItemClick="popover"
      />
    </>
  );
}
