import { notification } from 'antd';
import { Grapeseed } from 'api/grapeseed';
import { SubTitle } from 'components/PageTitles';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Pack } from 'types/pack';
import { ApiError, OnError } from 'utils/errors';
import { PackView } from './components/PackView';

function SingleItem() {
  const navigate = useNavigate();
  const params = useParams();
  const id = params.id;

  const [item, setItem] = useState<Pack>();

  useEffect(() => {
    async function fetchItem() {
      const result = await Grapeseed.GET(`/api/packs/${id}`, {
        fallback: (e) => {
          if (e instanceof ApiError && e.statusCode === 404) {
            notification.info({
              placement: 'top',
              message: `Could not find pack with ID: ${id}`,
              duration: 2,
              onClose: () => navigate(-1),
            });
          } else {
            OnError(e);
          }
        },
      });
      if (result) {
        setItem(result);
      }
    }
    fetchItem();
  }, [id, navigate]);

  return item ? (
    <React.Fragment>
      <SubTitle>{id}</SubTitle>
      <PackView pack={item!} size={'full'} />
    </React.Fragment>
  ) : (
    <React.Fragment></React.Fragment>
  );
}

const exports = SingleItem;
export default exports;
