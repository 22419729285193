import { Button, Checkbox, Form } from 'antd';
import { UploadFile } from 'antd/lib/upload/interface';
import { BulkUploader } from 'components/BulkUploader';
import { TagEntries } from 'components/TagEntries';
import React, { useCallback, useRef, useState } from 'react';
import { BulkUploadableTypes } from 'types/material';
import componentStyle from 'styles/common.module.css';
import { CheckOutlined } from '@ant-design/icons';
import { useForm } from 'antd/lib/form/Form';
import { Grapeseed } from 'api/grapeseed';
import { OnError } from 'utils/errors';
import { RadioPicker } from 'components/RadioPicker';
import { ParseColorText } from 'utils/utils';
import { ColorPicker } from 'components/ColorPicker';

export interface BulkUploadFormProps<T extends BulkUploadableTypes> {
  endpoint: string;
  accept: string;
  container: string;
  elemConstructor: (file: UploadFile, id: string) => Promise<T>;
  onAdd: (list: T[]) => void;
}

function BulkUploadForm<T extends BulkUploadableTypes>(
  props: BulkUploadFormProps<T>
) {
  const uploadingList = useRef<T[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [form] = useForm();

  const addToList = useCallback(
    async (file: UploadFile, id: string) => {
      await props
        .elemConstructor(file, id)
        .then((elem) => {
          uploadingList.current.push(elem);
        })
        .catch((reason) => {
          OnError(reason);
        });
    },
    [props]
  );

  const removeFromList = useCallback((id: string) => {
    uploadingList.current = uploadingList.current.filter(
      (elem) => elem.id !== id
    );
  }, []);

  const onSubmit = async (values: any) => {
    if (!uploadingList.current) {
      return;
    }
    const createdAt = new Date();
    setIsLoading(true);
    const submitPromises = uploadingList.current.map((elem: T) => {
      return new Promise<T>(async (resolve, reject) => {
        elem.tags = values.tags ?? [];
        elem.isFree = values.isFree ?? false;
        elem.createdAt = createdAt;
        if (values.pattern && values.pattern !== 'No Pattern') {
          elem.pattern = {
            style: values.pattern,
          };
        }
        const bgColor = ParseColorText(values.backgroundColor);
        if (bgColor) {
          elem.backgroundColor = bgColor;
        }
        const result = await Grapeseed.POST(props.endpoint, {
          body: elem,
          fallback: (e) => reject(e),
        });
        if (result) {
          resolve(result as T);
        }
      });
    });
    const results = await Promise.allSettled(submitPromises);
    const fulfilled = results.filter(
      (r) => r.status === 'fulfilled'
    ) as PromiseFulfilledResult<T>[];
    props.onAdd(
      fulfilled
        .map((f) => f.value)
        .sort((a, b) => {
          return a.id > b.id ? -1 : 1;
        })
    );
    setIsLoading(false);
    form.resetFields();
    uploadingList.current = [];

    if (results.length !== fulfilled.length) {
      const rejected = results.filter(
        (r) => r.status === 'rejected'
      ) as PromiseRejectedResult[];
      OnError(
        rejected
          .map((r) => r.reason as string)
          .reduce((acc, cur) => acc + ', ' + cur)
      );
    }
  };

  return (
    <React.Fragment>
      <Form
        form={form}
        onFinish={onSubmit}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
          }
        }}
        labelCol={{ span: 3 }}
        labelAlign="left"
        colon={false}
        style={{ margin: '1rem 0' }}
      >
        <Form.Item name="files">
          <BulkUploader
            accept={props.accept}
            container={props.container}
            onAdd={addToList}
            onRemove={removeFromList}
          />
        </Form.Item>
        <Form.Item name="pattern" label="Pattern">
          <RadioPicker
            name="pattern"
            options={[
              'No Pattern',
              'tile',
              'verticalTile',
              'horizontalTile',
              'custom',
            ]}
          />
        </Form.Item>
        <Form.Item
          label="Background Color"
          name="backgroundColor"
          wrapperCol={{ span: 5 }}
          initialValue="#FFFFFFFF"
        >
          <ColorPicker />
        </Form.Item>
        <Form.Item
          name="tags"
          label="Tags"
          extra={
            <span className={componentStyle.hinttextOrange}>
              * Press enter to add tag.
            </span>
          }
        >
          <TagEntries layout="horizontal" />
        </Form.Item>
        <Form.Item
          label="Free"
          name="isFree"
          valuePropName="checked"
          wrapperCol={{ span: 5 }}
        >
          <Checkbox />
        </Form.Item>
        <Form.Item>
          <Button
            className={componentStyle.buttonOrange}
            type="primary"
            icon={<CheckOutlined />}
            htmlType="submit"
            loading={isLoading}
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    </React.Fragment>
  );
}

const exports = BulkUploadForm;
export default exports;
