import { useRef, useState } from 'react';
import { GradientType, gradientStyle } from 'utils/colors';

export interface GradientBoxProps {
  width: string | number;
  height: string | number;
  colors: string[];
  type: GradientType;
  onClick?: (d: GradientType) => void;
}

export function GradientBox(props: GradientBoxProps) {
  const typeIdx = useRef<number>(
    Object.values(GradientType).findIndex((d) => d === props.type)
  );
  const [type, setType] = useState<GradientType>(props.type);

  return (
    <div
      style={{
        ...gradientStyle(props.width, props.height, type, props.colors),
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '5px',
        borderStyle: 'solid',
        borderWidth: '1px',
        borderColor: 'var(--border-color)',
        marginBottom: '0.5rem',
      }}
      onClick={() => {
        typeIdx.current =
          (typeIdx.current + 1) % Object.values(GradientType).length;
        const d =
          Object.values(GradientType).at(typeIdx.current) ??
          GradientType.ToBottom;
        setType(d);
        props.onClick?.(d);
      }}
    />
  );
}
