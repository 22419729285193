import { Divider, Form, Input, Select } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { SubTitle } from 'components/PageTitles';
import React, { useEffect, useState } from 'react';
import { User, UserRoles } from 'types/user';
import componentStyle from 'styles/common.module.css';
import { Grapeseed } from 'api/grapeseed';
import { UserView } from './components/UserView';
import { UserListView } from './components/UserListView';
import { UserRole } from 'types/proto/retriver-enum_pb';

export function Search() {
  const [form] = useForm();
  const [searchResult, setSearchResult] = useState<User[]>([]);

  const onSearch = async (keyword: string) => {
    form.setFieldValue('role', undefined);
    const resp = await Grapeseed.GET(`/api/users`, {
      queryParams: new Map<string, string>([['keyword', keyword]]),
    });
    if (resp) {
      setSearchResult(resp);
    }
  };
  const onChangeRole = async (role: string | undefined) => {
    if (!role) {
      setSearchResult([]);
      return;
    }
    form.setFieldValue('keyword', undefined);
    const resp = await Grapeseed.GET(`/api/users`, {
      queryParams: new Map<string, string>([['roles', role]]),
    });
    if (resp) {
      setSearchResult(resp);
    }
  };

  const RolesSelectOptions = Object.values(UserRoles).map((v) => ({
    value: v,
  }));

  return (
    <React.Fragment>
      <SubTitle>Manage user accounts</SubTitle>
      <Form
        form={form}
        labelCol={{ span: 2 }}
        labelAlign="left"
        colon={false}
        style={{ margin: '1rem 0' }}
      >
        <Form.Item
          label="Search"
          name="keyword"
          wrapperCol={{ span: 5 }}
          extra={
            <span className={componentStyle.hinttextOrange}>
              ID, Email, Username
            </span>
          }
        >
          <Input
            type="text"
            onPressEnter={(e) => onSearch((e.target as HTMLInputElement).value)}
          />
        </Form.Item>
        <Form.Item label="Role" name="role" wrapperCol={{ span: 5 }}>
          <Select
            options={RolesSelectOptions}
            allowClear
            onChange={onChangeRole}
          />
        </Form.Item>
      </Form>
      <Divider plain orientation="left">
        Search results
      </Divider>
      {searchResult && (
        <UserListView list={searchResult} onItemClick="popover" />
      )}
    </React.Fragment>
  );
}
