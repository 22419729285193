import React from 'react';
import appStyles from 'styles/app.module.css';

export function PageTitle(props: { children?: React.ReactNode }) {
  return (
    <React.Fragment>
      <span className={appStyles.pageTitle}>{props.children}</span>
      <hr style={{ borderTop: 'grey' }} />
    </React.Fragment>
  );
}

export function SubTitle(props: { children?: React.ReactNode }) {
  return (
    <span
      className={appStyles.pageSubTitle}
      style={{ flex: '0 0 auto', marginRight: 'auto' }}
    >
      {props.children}
    </span>
  );
}
