import { AutoComplete, Form, FormInstance, Input } from 'antd';
import { SelectProps } from 'antd/lib';
import { Grapeseed } from 'api/grapeseed';
import { useDebounce } from 'hooks/useDebounce';
import { useState } from 'react';

interface suggest {
  value: string;
  count: number;
}

interface option {
  text: string;
}

export interface InputFormProps {
  onAdd: (t: string) => void;
  form: FormInstance<any>;
  loading: boolean;
}

export function InputForm(props: InputFormProps) {
  const [suggestions, setSuggesions] = useState<SelectProps<object>['options']>(
    []
  );

  const onSearch = async (value: string) => {
    value = value.trim();
    if (value.length < 1) {
      setSuggesions([]);
      return;
    }
    // get suggestion from server
    const queryParams: Map<string, string> = new Map();
    queryParams.set('prefix', value);
    queryParams.set('size', '20');

    const result = await Grapeseed.GET('/api/materials/suggest', {
      queryParams: queryParams,
    });
    if (result) {
      const options: option[] = result.options;
      const texts = options.map((o) => {
        return { value: o.text };
      });
      const countPromises = texts.map((t) => {
        return new Promise<suggest>(async (resolve, reject) => {
          const queries = new Map<string, string>();
          queries.set('tags', t.value);
          queries.set('type', 'sticker');
          const count = await Grapeseed.GET(`/api/materials/count`, {
            queryParams: queries,
          });
          resolve({ value: t.value, count: count.count });
        });
      });
      const countResults = await Promise.allSettled(countPromises);
      const fulfilled = countResults.filter(
        (r) => r.status === 'fulfilled'
      ) as PromiseFulfilledResult<suggest>[];
      setSuggesions(fulfilled.map((f) => renderSuggest(f.value)));
    }
  };

  const debouncedOnSearch = useDebounce(onSearch, 200);

  const renderSuggest = (suggest: suggest) => {
    return {
      value: suggest.value,
      label: (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          {suggest.value}
          <span># {suggest.count}</span>
        </div>
      ),
    };
  };

  return (
    <Form form={props.form}>
      <Form.Item name="tagSearch">
        <AutoComplete
          options={suggestions}
          style={{ width: `30%` }}
          onSelect={props.onAdd}
          onSearch={debouncedOnSearch}
        >
          <Input.Search
            enterButton
            loading={props.loading}
            onSearch={props.onAdd}
          />
        </AutoComplete>
      </Form.Item>
    </Form>
  );
}
