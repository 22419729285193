import { BundleItem } from 'types/bundle';
import { Button, Form, Modal, Popover, Tag } from 'antd';
import { FileImageOutlined, LinkOutlined } from '@ant-design/icons';
import viewStyle from 'styles/view.module.css';
import { Path, QueryParams } from 'utils/utils';
import { useState } from 'react';
import { useForm } from 'antd/lib/form/Form';
import { FileUploader, acceptTypesInfo } from 'components/FileUploader';
import {
  ContainerBundles,
  MaterialType,
  MaterialTypes,
  ThumbnailAcceptTypes,
  ThumbnailFileSuffix,
  abbStr,
} from 'types/material';
import { ThumbnailView } from 'components/Thumbnail/ThumbnailView';
import RawJson from 'utils/RawJson';
import { Size } from 'types/common';
import { getMediaResolution } from 'utils/media';
import { ThumbnailSizes } from 'components/Thumbnail/Constants';

interface BundleItemViewProps {
  item: BundleItem;
  onChangeThumbnail?: (url: string, size: Size) => void;
}

function colorVar(type: string): string {
  return `var(--${type}-badge-color)`;
}

export function BundleItemView(props: BundleItemViewProps) {
  const refQueries = QueryParams(props.item.ref);
  const itemType = props.item.ref.split('/')[1];
  const materialType: MaterialType = refQueries?.get('type') as MaterialType;
  const refPath = Path(props.item.ref);
  const id = refPath.split('/').pop();
  const [modalForm] = useForm();
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const handleModalFormSubmit = async (values: any) => {
    setModalOpen(false);
    modalForm.resetFields();
    const size = await getMediaResolution(values.thumbnail[0]);
    props.onChangeThumbnail?.(values.thumbnail[0].url, size);
  };

  const renderTag = () => {
    const tagStyle = { margin: '0 0.2rem' };
    switch (itemType) {
      case 'packs':
        return (
          <Tag color={colorVar('pack')} style={tagStyle}>
            <span style={{ fontSize: '0.7rem' }}>{abbStr(materialType)}</span>
          </Tag>
        );
      case 'materials':
        return (
          <Tag color={colorVar(materialType)} style={tagStyle}>
            <span style={{ fontSize: '0.7rem' }}>{abbStr(materialType)}</span>
          </Tag>
        );
      case 'creators':
        return (
          <Tag color={colorVar('creator')} style={tagStyle}>
            <span style={{ fontSize: '0.7rem' }}>{'CR'}</span>
          </Tag>
        );
    }
  };

  return (
    <>
      <Modal
        open={modalOpen}
        title="Change Thumbnail"
        footer={[
          <Button
            key="cancel"
            onClick={() => {
              modalForm.resetFields();
              setModalOpen(false);
            }}
          >
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={() => {
              modalForm.validateFields().then((values) => {
                handleModalFormSubmit(values);
              });
            }}
          >
            Submit
          </Button>,
        ]}
      >
        <Form form={modalForm} layout="horizontal">
          <Form.Item
            name="thumbnail"
            label="Thumbnail"
            extra={acceptTypesInfo(ThumbnailAcceptTypes)}
            required
          >
            <FileUploader
              container={ContainerBundles}
              accept={ThumbnailAcceptTypes}
              filename={id}
              suffix={ThumbnailFileSuffix}
              direction="column"
            />
          </Form.Item>
        </Form>
      </Modal>
      <div
        className={`${viewStyle.viewitem} ${viewStyle.compact} ${viewStyle.draggable}`}
        style={{
          justifyContent: 'space-around',
        }}
      >
        <ThumbnailView
          size={ThumbnailSizes.compact}
          src={props.item.thumbnailUrl ?? ''}
          preview={false}
          isFree={props.item.isFree}
          svgFillColor={
            materialType === MaterialTypes.Placeholder
              ? 'var(--placeholder-fill-color)'
              : undefined
          }
        />
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-around',
            alignItems: 'center',
            marginTop: '0.5rem',
            padding: '0 0.5rem',
            width: '100%',
          }}
          onClick={(e) => e.stopPropagation()}
        >
          <Popover content={RawJson(props.item)} trigger="click">
            {renderTag()}
          </Popover>
          <Button
            size="small"
            type="link"
            onClick={() => setModalOpen(true)}
            icon={<FileImageOutlined />}
            style={{ marginRight: '0.2rem' }}
          />
          <a href={refPath} target="_blank" rel="noreferrer">
            <LinkOutlined />
          </a>
        </div>
      </div>
    </>
  );
}
