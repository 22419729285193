import {
  NewGetPresetRecommendationRequest,
  NewUpdatePresetRecommendationRequest,
} from 'api/retriver/requestFactory/presets';
import { Retriver } from 'api/retriver/retriver';
import { SubTitle } from 'components/PageTitles';
import { useCallback, useEffect, useState } from 'react';
import { TaggedPresetsView } from './components/TaggedPresetsView';
import { Badge } from 'antd';
import {
  DeleteFilled,
  DownCircleFilled,
  UpCircleFilled,
} from '@ant-design/icons';
import { TagEntries } from 'components/TagEntries';

export function Recommendation() {
  const [tags, setTags] = useState<string[] | undefined>();
  const [isSyncing, setIsSyncing] = useState<boolean>(false);

  useEffect(() => {
    async function fetch() {
      const result = (await Retriver.do(NewGetPresetRecommendationRequest()))
        ?.getPresetRecommendationResponse;
      if (result) {
        setTags(result.tags);
      }
    }
    fetch();
  }, []);

  const updateRecommendation = useCallback((tags?: string[]) => {
    async function update() {
      setIsSyncing(true);
      const result = (
        await Retriver.do(NewUpdatePresetRecommendationRequest(tags))
      )?.updatePresetRecommendationResponse;
      if (result) {
        setTags(result.tags);
      }
      setIsSyncing(false);
    }
    update();
  }, []);

  const onCloseTag = useCallback(
    (tag: string) => {
      updateRecommendation(tags?.filter((t) => t !== tag));
    },
    [tags, updateRecommendation]
  );

  const swapTags = useCallback(
    (i: number, j: number) => {
      if (!tags) return undefined;
      tags[j] = tags.splice(i, 1, tags[j])[0];
      updateRecommendation(tags);
    },
    [tags, updateRecommendation]
  );

  return (
    <>
      <SubTitle>Recommendation Tags for discover preset</SubTitle>
      <TagEntries
        layout={'horizontal'}
        value={tags}
        disabled={isSyncing}
        onChange={(values: string[]) => updateRecommendation(values)}
        useCaps={true}
      />
      {tags?.map((t: string, i: number) => {
        return (
          <div key={t} style={{ marginTop: '1rem' }}>
            <Badge.Ribbon
              style={{ padding: '0.6rem' }}
              text={
                <>
                  {t}
                  {i === 0 ? null : (
                    <UpCircleFilled
                      style={{ marginLeft: '0.6rem' }}
                      onClick={() => swapTags(i, i - 1)}
                    />
                  )}
                  {i >= tags?.length - 1 ? null : (
                    <DownCircleFilled
                      style={{ marginLeft: '0.6rem' }}
                      onClick={() => swapTags(i, i + 1)}
                    />
                  )}
                  <DeleteFilled
                    style={{ marginLeft: '0.6rem', color: 'salmon' }}
                    onClick={() => onCloseTag(t)}
                  />
                </>
              }
              placement="start"
            >
              <TaggedPresetsView tag={t} />
            </Badge.Ribbon>
          </div>
        );
      })}
    </>
  );
}
