import { Table } from 'antd';
import { UserCreated } from './CreatedPacks';
import { ColumnsType } from 'antd/es/table';

export function UserCreatedList(props: { list: [string, UserCreated][] }) {
  interface rowType {
    key: React.Key;
    userId: string;
    userName: string;
    fullName: string;
    created: number;
  }

  const columns: ColumnsType<rowType> = [
    {
      title: 'User ID',
      dataIndex: 'userId',
    },
    {
      title: 'Name',
      dataIndex: 'userName',
    },
    {
      title: 'Full Name',
      dataIndex: 'fullName',
    },
    {
      title: 'Created',
      dataIndex: 'created',
      sorter: {
        compare: (a: rowType, b: rowType) => a.created - b.created,
      },
      defaultSortOrder: 'descend',
      sortDirections: ['descend', 'ascend'],
    },
  ];

  return (
    <Table
      style={{ marginTop: '10px' }}
      size="small"
      pagination={false}
      columns={columns}
      dataSource={props.list.map((value: [string, UserCreated]) => {
        return {
          key: value[0],
          userId: value[0],
          userName: value[1].user?.username ?? '',
          fullName: value[1].user?.fullname ?? '',
          created: value[1].created,
        };
      })}
      summary={(data: readonly rowType[]) => {
        let total = 0;
        data.forEach((value: rowType) => {
          total += value.created;
        });
        return (
          <Table.Summary.Row>
            <Table.Summary.Cell index={0} colSpan={3}>
              Total
            </Table.Summary.Cell>
            <Table.Summary.Cell index={1}>{total}</Table.Summary.Cell>
          </Table.Summary.Row>
        );
      }}
    />
  );
}
