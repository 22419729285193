import { PageTitle } from 'components/PageTitles';
import { Outlet } from 'react-router';

export function Main() {
  return (
    <>
      <PageTitle>Messaging</PageTitle>
      <Outlet />
    </>
  );
}
