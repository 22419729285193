import { Popover, Tag, Typography } from 'antd';
import { PresetFullView, PresetViewProps } from './PresetFullView';
import viewStyle from 'styles/view.module.css';
import { ThumbnailView } from 'components/Thumbnail/ThumbnailView';
import { ThumbnailSizes } from 'components/Thumbnail/Constants';

export function PresetTinyView(props: PresetViewProps) {
  return (
    <Popover
      trigger="hover"
      content={props.preset.metadata?.tags?.map((t) => (
        <Tag>{t}</Tag>
      ))}
      placement="bottom"
    >
      <Popover
        trigger="click"
        content={
          <PresetFullView preset={props.preset} onDelete={props.onDelete} />
        }
      >
        <div className={viewStyle.viewitem}>
          <div
            style={{
              display: 'flex',
              alignItems: 'baseline',
              justifyContent: 'center',
              width: '80px',
            }}
          >
            <Typography.Text strong ellipsis>
              {props.preset.metadata?.title}
            </Typography.Text>
          </div>
          <ThumbnailView
            size={ThumbnailSizes.compact}
            src={props.preset.metadata?.previewUrl ?? ''}
            style={{ marginBottom: '0.4rem' }}
            preview={false}
          />
        </div>
      </Popover>
    </Popover>
  );
}
