import React from 'react';
import { SubTitle } from 'components/PageTitles';
import {} from './components/NewBackgroundForm';
import { NewUrlBackgroundForm } from './components/NewUrlBackgroundForm';

export function NewWithUrls() {
  return (
    <React.Fragment>
      <SubTitle>Add backgrounds with external link</SubTitle>
      <NewUrlBackgroundForm />
    </React.Fragment>
  );
}
