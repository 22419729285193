import { Button, Popover, Typography } from 'antd';
import { LookUpTableView, LookUpTableViewProps } from './LookUpTableView';
import viewStyle from 'styles/view.module.css';
import { ThumbnailView } from 'components/Thumbnail/ThumbnailView';
import { ThumbnailSizes } from 'components/Thumbnail/Constants';
import { IdPopover } from 'components/IdPopover';
import RawJson from 'utils/RawJson';
import Link from 'antd/es/typography/Link';
import {
  DeleteOutlined,
  DownloadOutlined,
  ExpandOutlined,
} from '@ant-design/icons';
import { useState } from 'react';
import { Retriver } from 'api/retriver/retriver';
import { NewDeleteLookUpTableRequest } from 'api/retriver/requestFactory/lookuptables';
import { ErrorCode } from 'types/proto/retriver-enum_pb';

export function LookUpTableCardView(props: LookUpTableViewProps) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const onDelete = async (id: string) => {
    setIsLoading(true);
    const result = (await Retriver.do(NewDeleteLookUpTableRequest(id)))
      ?.deleteLookUpTableResponse;
    setIsLoading(false);
    if (result?.errorCode === ErrorCode.SUCCESS) {
      props.onDelete?.(id);
    }
  };

  return (
    <div className={viewStyle.viewitem}>
      <Typography.Title level={5} style={{ marginTop: '0.5rem' }}>
        {props.lookuptable.name}
      </Typography.Title>
      <ThumbnailView
        size={ThumbnailSizes.full}
        src={props.lookuptable.previewUrl}
      />
      <IdPopover
        id={props.lookuptable.id}
        content={RawJson(props.lookuptable)}
      />
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          width: '90%',
          margin: '0.5rem 0',
        }}
      >
        <Popover
          content={<LookUpTableView lookuptable={props.lookuptable} />}
          trigger="click"
        >
          <Link>
            <ExpandOutlined />
          </Link>
        </Popover>
        <Link
          href={props.lookuptable.lutUrl}
          target="_blank"
          style={{ margin: '0 1rem' }}
        >
          <DownloadOutlined />
        </Link>
        <Button
          danger
          size="small"
          style={{ marginLeft: 'auto' }}
          onClick={(_) => onDelete(props.lookuptable.id)}
          loading={isLoading}
        >
          <DeleteOutlined />
        </Button>
      </div>
    </div>
  );
}
