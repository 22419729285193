import { Identifiable } from './interfaces';
import { MaterialType } from './material';

interface Config {
  spacing?: number;
  oblique?: number;
}

export class Pack implements Identifiable {
  id: string;
  type: string;
  title: string;
  thumbnailUrl?: string;
  description: string;
  priority: number;
  createdAt?: Date; // backend-generating property
  isFree?: boolean;
  authorId?: string; // backend-generating property
  modifiedAt?: Date; // backend-generating property
  md5Sum?: string; // backend-generating property
  materialType?: MaterialType;
  byTags?: string[];
  byMaterialIds?: string[];
  byDictionary?: Map<string, string>;
  config?: Config;
  // TODO: add items[]

  constructor(props: {
    id: string;
    type: string;
    title: string;
    thumbnailUrl?: string;
    description: string;
    priority: number;
    createdAt?: Date;
    isFree?: boolean;
    authorId?: string;
    modifiedAt?: Date;
    md5Sum?: string;
    materialType?: MaterialType;
    byTags?: string[];
    byMaterialIds?: string[];
    byDictionary?: Map<string, string>;
    config?: Config;
  }) {
    this.id = props.id;
    this.type = props.type;
    this.title = props.title;
    this.thumbnailUrl = props.thumbnailUrl;
    this.description = props.description;
    this.priority = props.priority;
    this.createdAt = props.createdAt;
    this.isFree = props.isFree;
    this.authorId = props.authorId;
    this.modifiedAt = props.modifiedAt;
    this.md5Sum = props.md5Sum;
    this.materialType = props.materialType;
    this.byTags = props.byTags;
    this.byMaterialIds = props.byMaterialIds;
    this.byDictionary = props.byDictionary;
    this.config = props.config;
  }

  get identifier() {
    return this.id;
  }

  get itemIds() {
    return this.byMaterialIds
      ? this.byMaterialIds
      : this.byDictionary
      ? Array.from(this.byDictionary.values())
      : undefined;
  }
}
