import { PageTitle } from 'components/PageTitles';
import dayjs, { Dayjs } from 'dayjs';
import React from 'react';
import { Outlet } from 'react-router';

export interface range {
  start: Dayjs;
  end: Dayjs;
}

export const dateStringFormat = 'YYYY-MM-DD';
export const lastday = dayjs().subtract(1, 'd');
export const rangePresets: {
  label: string;
  value: [Dayjs, Dayjs];
}[] = [
  { label: 'Last 7 Days', value: [lastday.subtract(6, 'd'), lastday] },
  { label: 'Last 14 Days', value: [lastday.subtract(13, 'd'), lastday] },
  { label: 'Last 21 Days', value: [lastday.subtract(20, 'd'), lastday] },
  {
    label: 'Last 1 Month',
    value: [lastday.subtract(1, 'M').add(1, 'd'), lastday],
  },
  {
    label: 'Last 6 Months',
    value: [lastday.subtract(6, 'M').add(1, 'd'), lastday],
  },
  {
    label: 'Last 1 Year',
    value: [lastday.subtract(1, 'y').add(1, 'd'), lastday],
  },
];

function Main() {
  return (
    <React.Fragment>
      <PageTitle>Data lab</PageTitle>
      <Outlet />
    </React.Fragment>
  );
}

const exports = Main;
export default exports;
