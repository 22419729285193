import { Image } from 'antd';
import { ImagePreviewType } from 'rc-image';
import { FallbackImage } from 'utils/constants';
import { FreeRibbon } from '../FreeRibbon';
import { SvgInline } from './SvgInline';
import thumbStyle from 'styles/thumbnail.module.css';
import { CSSProperties, useEffect, useState } from 'react';
import { ThumbnailSize } from './Constants';
import { getMediaResolution } from 'utils/media';

export interface ThumbnailViewProps {
  size: ThumbnailSize;
  src: string;
  preview?: boolean | ImagePreviewType;
  isFree?: boolean;
  svgFillColor?: string;
  style?: CSSProperties;
}

export function ThumbnailView(props: ThumbnailViewProps) {
  const thumbBoxClass = thumbStyle.box;
  const thumbImgClass = thumbStyle.image;
  const boxStyle = {
    ...props.style,
    width: props.style?.width ?? props.size.width,
    height: props.style?.height ?? props.size.height,
  };
  const imgSize = {
    width:
      typeof boxStyle.width === 'number' ? boxStyle.width - 4 : boxStyle.width,
    height:
      typeof boxStyle.height === 'number'
        ? boxStyle.height - 4
        : boxStyle.height,
  };
  const [src, setSrc] = useState<string>(
    props.src.replaceAll('.heics', '.png')
  );

  const [wh, setWh] = useState<{
    width?: number | string;
    height?: number | string;
  }>({
    width: 0,
    height: 0,
  });
  useEffect(() => {
    getMediaResolution(src)
      .then((value) =>
        setWh(
          value.width > value.height
            ? { width: imgSize.width }
            : { height: imgSize.height }
        )
      )
      .catch((e) => console.log(`${e}: ${props.src}`));
  }, [imgSize.height, imgSize.width, props.src, src]);

  const ext = src.split('.').pop()?.toLowerCase();
  switch (ext) {
    case 'mp4':
      return (
        <FreeRibbon isFree={props.isFree}>
          <div className={thumbBoxClass} style={boxStyle}>
            <video
              src={src}
              className={thumbImgClass}
              controls
              autoPlay
              loop
              draggable={false}
              {...wh}
            />
          </div>
        </FreeRibbon>
      );
    case 'jpg':
    case 'jpeg':
    case 'png':
    case 'webp':
    case 'gif':
      return (
        <FreeRibbon isFree={props.isFree}>
          <div className={thumbBoxClass} style={boxStyle}>
            <Image
              src={src}
              className={thumbImgClass}
              fallback={FallbackImage}
              preview={props.src.endsWith('.heics') ? false : props.preview}
              draggable={false}
              {...wh}
            />
          </div>
        </FreeRibbon>
      );
    case 'svg':
      return (
        <FreeRibbon isFree={props.isFree}>
          <div className={thumbBoxClass} style={boxStyle}>
            <SvgInline
              url={src}
              fillColor={props.svgFillColor}
              style={{
                width: imgSize.width,
                height: imgSize.height,
              }}
            />
          </div>
        </FreeRibbon>
      );
    default:
      return (
        <FreeRibbon isFree={props.isFree}>
          <div className={thumbBoxClass} style={boxStyle}>
            <img
              src={FallbackImage}
              alt=""
              draggable={false}
              className={thumbImgClass}
              width={imgSize.width}
              height={imgSize.height}
            />
          </div>
        </FreeRibbon>
      );
  }
}
