import { Grapeseed } from 'api/grapeseed';
import {
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Creator } from 'types/common';
import { ApiError, OnError } from 'utils/errors';
import { Union } from 'utils/utils';
import { ApiPageSizeForCreators } from 'utils/constants';
import { useIntersectionObserver } from './useIntersectionObserver';

interface useFetchCreatorsProps {
  code?: string;
  email?: string;
  name?: string;
  lastItemRef?: MutableRefObject<null>;
}

export const useFetchCreators = (
  props: useFetchCreatorsProps
): [Creator[], Dispatch<SetStateAction<Creator[]>>, boolean] => {
  const [list, setList] = useState<Creator[]>([]);
  const [hasError, setHasError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const page = useRef(0);
  const hasMore = useRef(true);

  const fetchCreators = useCallback(
    async (
      page: number,
      size: number,
      code?: string,
      email?: string,
      name?: string
    ) => {
      const apiFallback = (e: Error) => {
        if (e instanceof ApiError && e.statusCode === 404) {
          // do nothing
        } else {
          OnError(e);
        }
      };

      let result: any;

      setIsLoading(true);

      if (code && code.length > 0) {
        result = await Grapeseed.GET(`/api/creators/${code}`, {
          fallback: apiFallback,
        });
      } else {
        const queryParams: Map<string, string> = new Map();
        if (email && email.length > 0) {
          queryParams.set('email', email);
        }
        if (name && name.length > 0) {
          queryParams.set('name', name);
        }
        queryParams.set('from', (page * size).toString());
        queryParams.set('size', size.toString());

        result = await Grapeseed.GET('/api/creators', {
          queryParams: queryParams,
          fallback: apiFallback,
        });
      }

      if (!result) {
        setHasError(true);
        return [];
      }

      setIsLoading(false);
      return Array.isArray(result)
        ? result
            .map((element: any) => new Creator(element))
            .filter((c: Creator) => c != null && c !== undefined)
        : [result];
    },
    []
  );

  const fetchNextPage = useCallback(
    async (size: number, code?: string, email?: string, name?: string) => {
      page.current++;
      const data = await fetchCreators(page.current, size, code, email, name);
      hasMore.current = data !== undefined && !(data.length < size);
      setList((prev) => Union(prev, data) as Creator[]);
    },
    [fetchCreators]
  );

  const fetchInit = useCallback(
    async (size: number, code?: string, email?: string, name?: string) => {
      page.current = 0;
      const data = await fetchCreators(page.current, size, code, email, name);
      hasMore.current = data !== undefined && !(data.length < size);
      setList(data);
    },
    [fetchCreators]
  );

  useEffect(() => {
    hasMore.current = true;
    fetchInit(ApiPageSizeForCreators, props.code, props.email, props.name);
  }, [fetchInit, props.code, props.email, props.name]);

  useIntersectionObserver({
    root: null,
    target: props.lastItemRef?.current,
    onIntersect: ([{ isIntersecting }]) => {
      if (isIntersecting && !isLoading && !hasError) {
        fetchNextPage(
          ApiPageSizeForCreators,
          props.code,
          props.email,
          props.name
        );
      }
    },
  });

  return [list, setList, hasMore.current];
};
