import { Grapeseed } from 'api/grapeseed';
import { encodedQueryString } from 'utils/strings';

export interface GiphyImage {
  width: number;
  height: number;
  size: number;
  url: string;
}

export async function fetchChannelId(value: string | undefined) {
  if (!value) {
    return undefined;
  }
  const url = new URL(value);
  const queries = new Map([['url', url.toString()]]);
  const resp = await Grapeseed.GET('/api/admins/giphypackid', {
    queryParams: queries,
  });
  if (resp) {
    return resp.channelId;
  }
  return undefined;
}

const giphyApiUrl = 'https://api.giphy.com/v1';
const giphyApiKey = process.env.REACT_APP_GIPHY_API_KEY;

export async function fetchGiphyById(id: string) {
  if (id.length === 0) {
    return undefined;
  }
  const url = new URL(`${giphyApiUrl}/gifs/${id}`);
  url.searchParams.append('api_key', giphyApiKey);

  const resp = await fetch(url);
  if (resp.ok) {
    const result = await resp.text();
    const giphyJson = JSON.parse(result);
    let url: string = giphyJson.data.images.original.url;
    let thumbnailUrl: string = giphyJson.data.images.preview_gif.url;
    url = url.split('?')[0];
    thumbnailUrl = thumbnailUrl.split('?')[0];
    return { url: url, thumbnailUrl: thumbnailUrl };
  }
  return undefined;
}

export async function fetchGiphyPack(channelId: string | undefined) {
  if (!channelId) {
    return undefined;
  }
  let offset = 0;
  let limit = 30;

  const url = new URL(`${giphyApiUrl}/stickers/packs/${channelId}/stickers`);
  url.searchParams.append('api_key', giphyApiKey);

  let ghipyImages: GiphyImage[] = [];
  let allImagesFetched = false;
  while (!allImagesFetched) {
    url.searchParams.set('offset', offset.toString());
    url.searchParams.set('limit', limit.toString());
    const queryString = encodedQueryString(url.searchParams);
    const urlString =
      queryString && queryString.length > 0
        ? `${url.origin}${url.pathname}?${encodedQueryString(url.searchParams)}`
        : `${url.origin}${url.pathname}`;

    try {
      const resp = await fetch(urlString);
      if (resp.ok) {
        const result = await resp.text();
        const giphyResponse = JSON.parse(result);
        if (giphyResponse.pagination.next_cursor === null) {
          allImagesFetched = true;
        } else {
          offset = parseInt(giphyResponse.pagination.next_cursor);
        }
        ghipyImages.push(
          ...giphyResponse.data.map((elem: any) => {
            const image = elem.images.original;
            return {
              width: parseInt(image.width),
              height: parseInt(image.height),
              size: parseInt(image.size),
              url: image.url.split('?')[0],
            };
          })
        );
      }
    } catch (e) {
      console.log(e);
    }
  }
  return ghipyImages;
}
//   if (giphyResponse.pagination.next_cursor != null) {
//     // fetch next page, loop...
//   }
//   setList(giphyImages);
// }
