import { Creator } from 'types/common';
import { CreatorCardView } from './CreatorCardView';
import { MutableRefObject } from 'react';
import { Spin } from 'antd';
import viewStyle from 'styles/view.module.css';

export interface Selections {
  creators: Creator[];
  onItemSelect: (c: Creator) => void;
}

export interface CreatorCardListProps {
  className?: string;
  list: Creator[];
  itemEditable: boolean;
  itemDraggable: boolean;
  showTag: boolean;
  selections?: Selections;
  onDelete?: (key: React.Key) => void;
  lastItemRef?: MutableRefObject<any>;
  hasMore?: boolean;
}

export function CreatorCardList(props: CreatorCardListProps) {
  return (
    <div className={props.className}>
      {props.list.map((creator, index) => {
        return (
          <CreatorCardView
            key={creator.code}
            creator={creator}
            editable={props.itemEditable}
            onDelete={props.onDelete}
            showTag={props.showTag}
            draggable={props.itemDraggable}
            selected={props.selections?.creators.includes(creator)}
            onClick={props.selections?.onItemSelect}
          />
        );
      })}
      {props.hasMore && props.lastItemRef && (
        <div
          key={'materialListLastItmeRef'}
          ref={props.lastItemRef}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            margin: '1rem',
          }}
        >
          <Spin />
        </div>
      )}
    </div>
  );
}

CreatorCardList.defaultProps = {
  className: `${viewStyle.viewbox} ${viewStyle.wrap}`,
  itemEditable: false,
  showTag: false,
  itemDraggable: false,
  itemSelectable: false,
};
