import { MutableRefObject } from 'react';
import { Spin } from 'antd';
import { Material } from 'types/interfaces';
import { MaterialView } from './MaterialView';
import viewStyle from 'styles/view.module.css';

export interface Selections {
  materials: Material[];
  onItemSelect: (m: Material) => void;
}

export interface MaterialListProps {
  className?: string;
  list: Material[];
  itemSize: 'compact' | 'full';
  itemEditable: boolean;
  itemDraggable: boolean;
  selections?: Selections;
  onRemove?: (id: string) => void;
  lastItemRef?: MutableRefObject<any>;
  hasMore?: boolean;
}

export function MaterialList(props: MaterialListProps) {
  return (
    <div className={props.className} style={{ width: '100%' }}>
      {props.list?.map((m: Material) => {
        return (
          <MaterialView
            key={m.id}
            material={m}
            size={props.itemSize}
            editable={props.itemEditable}
            draggable={props.itemDraggable}
            selected={props.selections?.materials.includes(m)}
            onClick={props.selections?.onItemSelect}
            onRemove={props.onRemove}
          />
        );
      })}
      {props.hasMore && props.lastItemRef && (
        <div
          key={'materialListLastItmeRef'}
          ref={props.lastItemRef}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            margin: '1rem',
            minWidth: '30%',
            minHeight: '100px',
          }}
        >
          <Spin />
        </div>
      )}
    </div>
  );
}

MaterialList.defaultProps = {
  className: `${viewStyle.viewbox} ${viewStyle.wrap}`,
  itemSize: 'full',
  itemEditable: false,
  itemDraggable: false,
};
