import { OnError } from 'utils/errors';
import { encodedQueryString } from 'utils/strings';
import { GrapeReviver, GrapeSerializer, OnResponse } from 'utils/utils';

let authToken: string;

const useCachingEndpoints = ['/api/stats/materials', '/api/stats/keywords'];

export interface ApiOptions {
  queryParams?: Map<string, string> | null;
  body?: any;
  fallback?: (e: Error) => void;
}

export const Grapeseed = {
  setAuthToken(token: string) {
    authToken = token;
  },

  headers(authToken: string) {
    const requestHeader: HeadersInit = {
      Accept: 'application/json',
    };
    if (authToken) {
      requestHeader.Authorization = `Bearer ${authToken}`;
    }
    return requestHeader;
  },

  async GET(path: string, options?: ApiOptions) {
    const url = new URL(path, process.env.REACT_APP_GRAPESEED_URL);
    options?.queryParams?.forEach((v, k, map) => {
      url.searchParams.append(k, v);
    });
    // avoid using browser cache
    if (!useCachingEndpoints.includes(path)) {
      url.searchParams.append('time', `${new Date().getTime()}`);
    }
    return this.do(
      url,
      {
        method: 'GET',
        headers: this.headers(authToken),
      },
      options?.fallback
    );
  },

  async POST(path: string, options?: ApiOptions) {
    const url = new URL(path, process.env.REACT_APP_GRAPESEED_URL);
    return this.do(
      url,
      {
        method: 'POST',
        headers: this.headers(authToken),
        body: options?.body
          ? JSON.stringify(options.body, GrapeSerializer)
          : null,
      },
      options?.fallback
    );
  },

  async PUT(path: string, options?: ApiOptions) {
    const url = new URL(path, process.env.REACT_APP_GRAPESEED_URL);
    options?.queryParams?.forEach((v, k, map) => {
      url.searchParams.append(k, v);
    });
    return this.do(
      url,
      {
        method: 'PUT',
        headers: this.headers(authToken),
        body: options?.body
          ? JSON.stringify(options.body, GrapeSerializer)
          : null,
      },
      options?.fallback
    );
  },

  async DELETE(path: string, options?: ApiOptions) {
    const url = new URL(path, process.env.REACT_APP_GRAPESEED_URL);
    options?.queryParams?.forEach((v, k, map) => {
      url.searchParams.append(k, v);
    });
    return this.do(
      url,
      {
        method: 'DELETE',
        headers: this.headers(authToken),
      },
      options?.fallback
    );
  },

  async do(url: URL, init: RequestInit, fallback?: (e: Error) => void) {
    const queryString = encodedQueryString(url.searchParams);
    const urlString =
      queryString && queryString.length > 0
        ? `${url.origin}${url.pathname}?${encodedQueryString(url.searchParams)}`
        : `${url.origin}${url.pathname}`;
    try {
      const res = await fetch(urlString, {
        credentials: 'include',
        ...init,
      });
      const result = await OnResponse(res);
      if (result.length > 0) {
        return JSON.parse(result, GrapeReviver);
      }
    } catch (e) {
      if (e instanceof Error && fallback) {
        fallback(e);
      } else {
        OnError(e);
      }
    }
  },
};
