import { SearchOutlined, UndoOutlined } from '@ant-design/icons';
import { Button, Form, Input } from 'antd';
import { useForm } from 'antd/es/form/Form';
import componentStyle from 'styles/common.module.css';

export interface SearchFormProps {
  onSearch: (values: any) => void;
}

export function SearchForm(props: SearchFormProps) {
  const [form] = useForm();
  return (
    <Form
      form={form}
      labelCol={{ span: 2 }}
      labelAlign="left"
      colon={false}
      wrapperCol={{ span: 8 }}
      onFinish={(values: any) => props.onSearch(values)}
    >
      <Form.Item label="Title" name="title">
        <Input type="text" />
      </Form.Item>
      <Form.Item label="Description" name="description">
        <Input type="text" />
      </Form.Item>
      <Form.Item
        label="Tags"
        name="tags"
        wrapperCol={{ span: 12 }}
        extra={
          <span className={componentStyle.hinttextOrange}>
            <ul>
              <li>
                Use '+(plus)' to search presets having any of input tags (OR
                search), or use ',(comma)' to search presets having all of the
                input tags (AND search)
              </li>
              <li>
                Combined searching (using AND/OR simultaneously) is not
                supported
              </li>
            </ul>
          </span>
        }
      >
        <Input type="text" />
      </Form.Item>
      <div style={{ display: 'flex' }}>
        <Form.Item>
          <Button
            className={componentStyle.buttonOrange}
            type="primary"
            icon={<SearchOutlined />}
            htmlType="submit"
          >
            Search
          </Button>
        </Form.Item>
        <Form.Item style={{ marginLeft: '1rem' }}>
          <Button
            type="primary"
            icon={<UndoOutlined />}
            onClick={() => {
              form.resetFields();
              form.submit();
            }}
          >
            Reset
          </Button>
        </Form.Item>
      </div>
    </Form>
  );
}
