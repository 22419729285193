import { Descriptions } from 'antd';
import MaterialLegend from 'components/MaterialViews/MaterialLegend';
import { SubTitle } from 'components/PageTitles';
import { useFetchPacks } from 'hooks/useFetchPacks';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { MaterialType } from 'types/material';
import { SearchPackForm } from './components/SearchPackForm';
import { PackList } from 'pages/Packs/components/PackList';
import { Grapeseed } from 'api/grapeseed';

function Packs() {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const title = params.get('title') ?? undefined;
  const type = params.get('type') ?? undefined;
  const authorId = params.get('authorId') ?? undefined;
  const materialType = params.get('materialType') ?? undefined;
  const lastItemRef = useRef(null);
  const [list, setList, hasMore] = useFetchPacks({
    title: title,
    type: type,
    authorId: authorId,
    materialType: materialType as MaterialType,
    lastItemRef: lastItemRef,
  });
  const [total, setTotal] = useState<number>(0);
  const [freeCount, setFreeCount] = useState<number>(0);
  const [premiumCount, setPremiumCount] = useState<number>(0);

  const onSubmit = (values: any) => {
    if (values.id) {
      navigate(`/packs/${values.id}`);
    } else {
      const searchUrl = new URL('/search/packs', window.origin);
      if (values.title) {
        searchUrl.searchParams.append('title', values.title);
      }
      if (values.type) {
        searchUrl.searchParams.append('type', values.type);
      }
      if (values.authorId) {
        searchUrl.searchParams.append('authorId', values.authorId);
      }
      if (values.materialType && values.materialType !== 'All') {
        searchUrl.searchParams.append(
          'materialType',
          values.materialType.toLowerCase()
        );
      }
      const dest = searchUrl.pathname + '?' + searchUrl.searchParams.toString();
      navigate(dest);
    }
  };

  useEffect(() => {
    if (list?.length > 0) {
      async function fetchCount() {
        const queries = new Map<string, string>();
        title && queries.set('title', title);
        type && queries.set('type', type);
        authorId && queries.set('authorId', authorId);
        materialType && queries.set('materialType', materialType);
        const total = await Grapeseed.GET(`/api/packs/count`, {
          queryParams: queries,
        });
        const frees = await Grapeseed.GET(`/api/packs/count`, {
          queryParams: new Map<string, string>([
            ...queries,
            ['isFree', 'true'],
          ]),
        });
        const premiums = await Grapeseed.GET(`/api/packs/count`, {
          queryParams: new Map<string, string>([
            ...queries,
            ['isFree', 'false'],
          ]),
        });
        setTotal(total.count);
        setFreeCount(frees.count);
        setPremiumCount(premiums.count);
      }
      fetchCount();
    }
  }, [list?.length, materialType, title, type, authorId]);

  return (
    <React.Fragment>
      <Descriptions
        title={<SubTitle>Search packs</SubTitle>}
        extra={<MaterialLegend />}
      />
      <SearchPackForm
        labelCol={{ span: 3 }}
        wrapperCol={{ span: 15 }}
        onSubmit={onSubmit}
      />
      <hr style={{ borderTop: 'grey' }} />
      {`Searched: ${total} results (${freeCount} free(s) and ${premiumCount} premium(s))`}
      <PackList
        list={list}
        lastItemRef={lastItemRef}
        hasMore={hasMore}
        onDelete={(id: string) =>
          setList(list.filter((pack) => pack.id !== id))
        }
        itemSize={'full'}
      />
    </React.Fragment>
  );
}

const exports = Packs;
export default exports;
