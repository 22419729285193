import { Button, Divider } from 'antd';
import viewStyle from 'styles/view.module.css';
import { ThumbnailView } from 'components/Thumbnail/ThumbnailView';
import { IdPopover } from 'components/IdPopover';
import RawJson from 'utils/RawJson';
import { EditableText } from 'components/EditableText';
import { TagEntries } from 'components/TagEntries';
import { StopOutlined } from '@ant-design/icons';
import { CSSProperties, useState } from 'react';
import { UrlSticker } from './NewUrlStickerForm';
import { isValidHttpUrl } from 'utils/utils';
import { ThumbnailSizes } from 'components/Thumbnail/Constants';

export interface UrlStickerViewProps {
  sticker: UrlSticker;
  onChange: (s: UrlSticker) => void;
  onRemove: (id: string) => void;
  style?: CSSProperties;
}

export function UrlStickerView(props: UrlStickerViewProps) {
  const [sticker, setSticker] = useState<UrlSticker>(props.sticker);

  return (
    <div className={viewStyle.cardViewItem} style={{ ...props.style }}>
      {sticker.url && sticker.id && (
        <>
          <ThumbnailView src={sticker.url} size={ThumbnailSizes.compact} />
          <Divider type="vertical" />
          <IdPopover
            id={sticker.id.slice(0, sticker.id.length - 4).trim()}
            content={RawJson(sticker)}
            ellipsis={{ suffix: `${sticker.id.slice(-4).trim()}` }}
            style={{ width: '120px' }}
          />
          <Divider type="vertical" />
        </>
      )}
      <div style={{ minWidth: 500 }}>
        <EditableText
          str={sticker.url}
          placeholder="Add a new sticker"
          onChange={(value) => {
            if (isValidHttpUrl(value)) {
              let newOne = {
                tags: sticker.tags,
                id: sticker.id,
                url: value,
              };
              setSticker(newOne);
              props.onChange(newOne);
            }
          }}
          triggerType="icon"
        />
      </div>
      {sticker.url && (
        <>
          <Divider type="vertical" />
          <TagEntries
            layout={'horizontal'}
            onChange={(t) => {
              let newOne = {
                tags: t,
                id: sticker.id,
                url: sticker.url,
              };
              setSticker(newOne);
              props.onChange(newOne);
            }}
          />
          <Divider type="vertical" />
          <Button
            size="small"
            danger
            onClick={() => props.onRemove(sticker.id)}
          >
            <StopOutlined />
          </Button>
        </>
      )}
    </div>
  );
}
