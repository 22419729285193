import { Grapeseed } from 'api/grapeseed';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { Message } from 'types/message';
import { ApiError, OnError } from 'utils/errors';

export const useFetchMessages = (): [
  Message[],
  Dispatch<SetStateAction<Message[]>>
] => {
  const [list, setList] = useState<Message[]>([]);

  const fetchMessages = useCallback(async () => {
    const result = await Grapeseed.GET('/api/messages', {
      fallback: (e) => {
        if (e instanceof ApiError && e.statusCode === 404) {
          // do nothing
        } else {
          OnError(e);
        }
      },
    });
    setList(result);
  }, [setList]);

  useEffect(() => {
    fetchMessages();
  }, [fetchMessages]);

  return [list, setList];
};
