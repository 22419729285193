import { CheckOutlined } from '@ant-design/icons';
import { Button, Divider, Form, Input, notification } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { TagEntries } from 'components/TagEntries';
import componentStyle from 'styles/common.module.css';
import { Background, ContentTypes, GifType } from 'types/material';
import { useState } from 'react';
import { isValidHttpUrl } from 'utils/utils';
import { ulid } from 'ulid';
import { getGifDuration, getMediaMetadata } from 'utils/media';
import { GiphyItem, GiphyItemView } from './GiphyItemView';
import { Grapeseed } from 'api/grapeseed';
import { fetchGiphyById } from 'utils/GiphyImages';

interface content {
  type: string;
  url: string;
  gifType: GifType;
  duration: number;
}

interface requestBody {
  type: string;
  id: string;
  tags: string[];
  content: content;
  thumbnailUrl: string;
}

export function NewUrlBackgroundForm() {
  const [form] = useForm();
  const [list, setList] = useState<GiphyItem[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [noti, ctxHolder] = notification.useNotification();

  const newPostRequestBody = async (
    from: GiphyItem
  ): Promise<requestBody | undefined> => {
    if (!isValidHttpUrl(from.url)) return undefined;
    try {
      const meta = await getMediaMetadata(from.url);
      return {
        tags: from.tags,
        id: from.id,
        type: 'background',
        content: {
          type: from.url.includes('giphy.com')
            ? ContentTypes.Giphy
            : meta.contentType,
          url: from.url,
          gifType: from.url.split('.').pop() as GifType,
          duration: from.url.endsWith('.gif')
            ? await getGifDuration(from.url)
            : 0,
        },
        thumbnailUrl: from.thumbnailUrl,
      };
    } catch (err) {
      console.log(err);
    }
  };

  const addBackground = (b: GiphyItem) => {
    setList((prev) => [...prev, b]);
  };

  const changeBackground = (index: number, b: GiphyItem) => {
    setList((prev) => {
      const newList = prev.slice();
      newList.splice(index, 1, b);
      return newList;
    });
  };

  const removeBackground = (id: string) => {
    setList((prev) => prev.filter((s) => s.id !== id));
  };

  const onInputGiphyId = async (id: string) => {
    const urls = await fetchGiphyById(id);
    if (urls) {
      addBackground({
        id: ulid(),
        tags: [],
        url: urls.url,
        thumbnailUrl: urls.thumbnailUrl,
      });
      form.setFieldValue('giphyId', '');
    }
    // TODO: noti
  };

  const onSubmit = async (values: any) => {
    if (!list || list.length < 1) {
      return;
    }
    setIsLoading(true);
    const submitPromises = list.map((elem) => {
      return new Promise<Background>(async (resolve, reject) => {
        elem.tags = values.tags
          ? Array.from(new Set([...elem.tags, ...values.tags]))
          : elem.tags;
        const requestBody = await newPostRequestBody(elem);
        const result = await Grapeseed.POST('/api/backgrounds', {
          body: requestBody,
          fallback: (e) => reject(e),
        });
        if (result) {
          resolve(result as Background);
        }
      });
    });
    const results = await Promise.allSettled(submitPromises);
    const fulfilled = results.filter(
      (r) => r.status === 'fulfilled'
    ) as PromiseFulfilledResult<Background>[];
    noti.success({
      message: `${fulfilled.length} background(s) created.`,
      placement: 'top',
      duration: 2,
    });
    const rejected = results.filter(
      (r) => r.status === 'rejected'
    ) as PromiseRejectedResult[];
    if (rejected.length > 0) {
      noti.error({
        message: `Failed to create ${rejected.length} background(s)`,
        description: (
          <p>
            {rejected
              .map((r) => r.reason as string)
              .reduce((acc, cur) => acc + ', ' + cur)}
          </p>
        ),
        placement: 'top',
      });
    }
    setList([]);
    form.resetFields();
    setIsLoading(false);
  };

  return (
    <>
      {ctxHolder}
      <Form
        form={form}
        onFinish={onSubmit}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
          }
        }}
        labelCol={{ span: 2 }}
        labelAlign="left"
        colon={false}
        style={{ margin: '1rem 0' }}
      >
        <Form.Item label="Giphy ID" name="giphyId" wrapperCol={{ span: 3 }}>
          <Input
            type="text"
            placeholder="Giphy ID"
            onPressEnter={(e) =>
              onInputGiphyId((e.target as HTMLInputElement).value)
            }
          />
        </Form.Item>
        <Divider orientation="left" plain>
          Backgrounds
        </Divider>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {list.map((elem, index) => (
            <GiphyItemView
              key={elem.id}
              background={elem}
              onChange={(s) => changeBackground(index, s)}
              onRemove={(id) => removeBackground(id)}
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                minHeight: '40px',
                maxWidth: '90%',
              }}
            />
          ))}
        </div>
        <Divider orientation="left" plain>
          Apply to all
        </Divider>
        <Form.Item
          name="tags"
          label="Tags"
          extra={
            <span className={componentStyle.hinttextOrange}>
              * Press enter to add tag.
            </span>
          }
        >
          <TagEntries layout="horizontal" />
        </Form.Item>
        <Form.Item>
          <Button
            className={componentStyle.buttonOrange}
            type="primary"
            icon={<CheckOutlined />}
            htmlType="submit"
            loading={isLoading}
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
      <Divider />
    </>
  );
}
