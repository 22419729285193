import { RequestProto } from 'types/proto/retriver_pb';
import { ApiRequest } from '../retriver';
import {
  FileDeleteRequest,
  FileUploadUrlRequest,
} from 'types/proto/retriver-app-common_pb';

export function NewFileUploadUrlRequest(dir: string, path: string): ApiRequest {
  return {
    path: 'a2/ac/fileUploadUrl',
    protoBody: new RequestProto({
      fileUploadUrlRequest: new FileUploadUrlRequest({
        directory: dir,
        filePath: path,
      }),
    }),
  };
}

export function NewFileDeleteRequest(url: string): ApiRequest {
  return {
    path: 'a2/ac/fileDelete',
    protoBody: new RequestProto({
      fileDeleteRequest: new FileDeleteRequest({
        url: url,
      }),
    }),
  };
}
