import { Gradient } from 'types/material';
import { DragEvent, useRef, useState } from 'react';
import { Material } from 'types/interfaces';
import { useForm } from 'antd/lib/form/Form';
import { Grapeseed } from 'api/grapeseed';
import { HaveSameContents } from 'utils/utils';
import viewStyle from 'styles/view.module.css';
import React from 'react';
import { Badge, Button, Collapse, Form, Popconfirm, Space } from 'antd';
import RawJson from 'utils/RawJson';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { TagEntries } from 'components/TagEntries';
import { GradientBox } from './GradientBox';
import { IdPopover } from 'components/IdPopover';
import { GradientType } from 'utils/colors';

interface GradientViewProps {
  gradient: Gradient;
  editable?: boolean;
  draggable?: boolean;
  onDragStart?: (e: DragEvent<HTMLDivElement>, m: Material) => void;
  onRemove?: (id: string) => void;
}

export function GradientView(props: GradientViewProps) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isModified, setIsModified] = useState<boolean>(false);
  const [form] = useForm();
  const [gradient, setGradient] = useState<Gradient>(props.gradient);
  const type = useRef<GradientType>(props.gradient.gradientType);

  const onSubmit = async (values: any) => {
    setIsLoading(true);
    const result = await Grapeseed.PUT(`/api/gradients/${gradient.id}`, {
      body: {
        tags: values.tags ?? gradient.tags,
        gradientType: type.current,
        codes: gradient.codes,
      },
      fallback: () => {
        form.setFieldsValue({ tags: gradient.tags });
      },
    });
    if (result) {
      setGradient(new Gradient(result));
    }
    setIsLoading(false);
    setIsModified(false);
  };

  const onRemove = async (event?: React.MouseEvent<HTMLElement>) => {
    const result = await Grapeseed.DELETE(`/api/gradients/${gradient.id}`);
    if (result) {
      props.onRemove?.(gradient.id);
    }
  };

  const checkIsModified = (_: any, values: any) => {
    if (!HaveSameContents(values.tags, gradient.tags)) {
      setIsModified(true);
    } else {
      setIsModified(false);
    }
  };

  let divClass = `${viewStyle.viewitem} ${viewStyle.full}`;
  if (props.draggable) {
    divClass = `${viewStyle.viewitem} ${viewStyle.draggable}`;
  }

  return (
    <>
      <div
        className={divClass}
        draggable={props.draggable}
        onDragStart={(e) => props.onDragStart?.(e, gradient)}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'stretch',
            padding: '15px 15px 0 15px',
          }}
        >
          <Badge
            count={gradient.picked ?? 0}
            showZero
            color="var(--gradient-badge-color)"
          >
            <GradientBox
              width={100}
              height={100}
              colors={gradient.codes}
              type={gradient.gradientType}
              onClick={(d) => {
                type.current = d;
                if (type.current === gradient.gradientType) {
                  setIsModified(false);
                } else {
                  setIsModified(true);
                }
              }}
            />
          </Badge>
          <Space style={{ fontSize: '90%' }}>
            <Badge color="var(--gradient-badge-color)" />
            <IdPopover
              id={gradient.id}
              content={RawJson(gradient)}
              showHref={{ path: 'materials' }}
            />
          </Space>
        </div>
        <div style={{ flex: '0 0 auto', padding: '20px', width: '100%' }}>
          <Form
            form={form}
            colon={false}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
              }
            }}
            onValuesChange={checkIsModified}
            onFinish={onSubmit}
          >
            <Collapse
              bordered={false}
              accordion
              items={[
                {
                  key: 'tags',
                  label: 'Tags',
                  children: (
                    <Form.Item
                      name="tags"
                      label="Tags"
                      initialValue={gradient.tags}
                    >
                      <TagEntries
                        layout="vertical"
                        disabled={!props.editable}
                      />
                    </Form.Item>
                  ),
                },
              ]}
            />
            {props.editable && (
              <div
                style={{
                  marginTop: '1rem',
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}
              >
                <Form.Item style={{ marginRight: '1em' }}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    disabled={!isModified}
                    loading={isLoading}
                  >
                    Save changes
                  </Button>
                </Form.Item>
                <Form.Item>
                  <Popconfirm
                    title="Are you sure to DELETE？"
                    icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                    onConfirm={onRemove}
                  >
                    <Button type="primary" danger>
                      Delete
                    </Button>
                  </Popconfirm>
                </Form.Item>
              </div>
            )}
          </Form>
        </div>
      </div>
    </>
  );
}
