import { Badge } from 'antd';
import React from 'react';

export function FreeRibbon(props: {
  isFree?: boolean;
  children?: React.ReactNode;
}) {
  return props.isFree ? (
    <Badge.Ribbon text="Free" placement="start">
      {props.children}
    </Badge.Ribbon>
  ) : (
    <React.Fragment>{props.children}</React.Fragment>
  );
}
