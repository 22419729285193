import { useState } from 'react';
import { Button, Form, Input } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { CheckOutlined } from '@ant-design/icons';
import { TagEntries } from 'components/TagEntries';
import { FileUploader, acceptTypesInfo } from 'components/FileUploader';
import componentStyle from 'styles/common.module.css';
import {
  ContainerTemplates,
  Template,
  TemplateFileAcceptTypes,
  ThumbnailAcceptTypes,
  ThumbnailFileSuffix,
} from 'types/material';
import { RadioPicker } from 'components/RadioPicker';
import { Grapeseed } from 'api/grapeseed';

export interface NewTemplateFormProps {
  onAdd: (t: Template) => void;
}

function NewTemplateForm(props: NewTemplateFormProps) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [form] = useForm();
  const [id, setId] = useState<string>();

  const onSubmit = async (values: any) => {
    if (!id) {
      console.log('no id set!');
      return;
    }
    const body = {
      id: id,
      tags: values.tags ?? [],
      title: values.title,
      thumbnailUrl: values.thumbnail[0].url,
      canvas: {
        type: 'template',
        size: {
          width: values.width,
          height: values.height,
        },
      },
      svgUrl: values.svg[0].url,
    };

    setIsLoading(true);
    const result = await Grapeseed.POST(`/api/templates`, { body: body });
    setIsLoading(false);
    if (result) {
      props.onAdd(new Template(result));
      setId(undefined);
      form.resetFields();
    }
  };

  return (
    <Form
      form={form}
      onFinish={onSubmit}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          e.preventDefault();
        }
      }}
      labelWrap={true}
      labelCol={{ span: 3 }}
      labelAlign="left"
      colon={false}
      style={{ margin: '1rem 0' }}
      disabled
    >
      <Form.Item
        label="Title"
        name="title"
        wrapperCol={{ span: 5 }}
        hasFeedback={true}
        rules={[{ required: true, message: 'Title cannot be empty.' }]}
      >
        <Input type="text" placeholder="Template title" />
      </Form.Item>

      <Form.Item name="width" label="Width" rules={[{ required: true }]}>
        <RadioPicker name="width" options={[1080]} suffix={'px'} />
      </Form.Item>

      <Form.Item name="height" label="Height" rules={[{ required: true }]}>
        <RadioPicker
          name="height"
          options={[607, 810, 864, 1080, 1350, 1440, 1920]}
          suffix={'px'}
        />
      </Form.Item>

      <Form.Item
        name="tags"
        label="Tags"
        extra={
          <span className={componentStyle.hinttextOrange}>
            * Press enter to add tag.
          </span>
        }
      >
        <TagEntries layout="horizontal" />
      </Form.Item>

      <Form.Item
        label="SVG File"
        name="svg"
        wrapperCol={{ span: 7 }}
        extra={acceptTypesInfo(TemplateFileAcceptTypes)}
        rules={[{ required: true }]}
      >
        <FileUploader
          container={ContainerTemplates}
          accept={TemplateFileAcceptTypes}
          onAdd={(_, id) => setId(id)}
          onRemove={(_) => setId(undefined)}
        />
      </Form.Item>

      {id && (
        <Form.Item
          label="Thumbnail"
          name="thumbnail"
          wrapperCol={{ span: 7 }}
          extra={acceptTypesInfo(ThumbnailAcceptTypes)}
          rules={[{ required: true }]}
        >
          <FileUploader
            accept={ThumbnailAcceptTypes}
            container={ContainerTemplates}
            filename={id}
            suffix={ThumbnailFileSuffix}
          />
        </Form.Item>
      )}

      <Form.Item>
        <Button
          className={componentStyle.buttonOrange}
          type="primary"
          icon={<CheckOutlined />}
          htmlType="submit"
          loading={isLoading}
        >
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
}

const exports = NewTemplateForm;
export default exports;
