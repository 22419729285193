import { Form, Input } from 'antd';
import { SearchFormProps } from '../Search';

export function SearchCreatorsForm(props: SearchFormProps) {
  return (
    <Form
      onFinish={props.onSubmit}
      labelCol={props.labelCol}
      labelAlign="left"
      wrapperCol={props.wrapperCol}
      colon={false}
      style={{ margin: '1rem 0' }}
    >
      <Form.Item label="Code" name="code">
        <Input type="text" placeholder="Creator code" />
      </Form.Item>
    </Form>
  );
}
