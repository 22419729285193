// @generated by protoc-gen-es v1.10.0 with parameter "target=ts,import_extension=.ts"
// @generated from file retriver-app-gaia.proto (package retrica, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64 } from "@bufbuild/protobuf";
import { ContentType, ErrorCode, ReportType } from "./retriver-enum_pb.ts";
import { Content, Friend, GaiaModel_Alert, GaiaModel_FeedItem, GaiaModel_HashTag, GaiaModel_Profile, GaiaModel_Shot, GaiaModel_ShotComment, GaiaModel_ShotsByOwner } from "./retriver-struct_pb.ts";

/**
 * @generated from message retrica.Gaia
 */
export class Gaia extends Message<Gaia> {
  constructor(data?: PartialMessage<Gaia>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.Gaia";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Gaia {
    return new Gaia().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Gaia {
    return new Gaia().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Gaia {
    return new Gaia().fromJsonString(jsonString, options);
  }

  static equals(a: Gaia | PlainMessage<Gaia> | undefined, b: Gaia | PlainMessage<Gaia> | undefined): boolean {
    return proto3.util.equals(Gaia, a, b);
  }
}

/**
 * /h1/ga/feedItems
 * feed item 리스트를 내려준다.
 *
 * @generated from message retrica.Gaia.FeedItemsRequest
 */
export class Gaia_FeedItemsRequest extends Message<Gaia_FeedItemsRequest> {
  /**
   * offset 정보 (없으면 가장 최신 offset부터 시작됨)
   *
   * @generated from field: string offset = 1;
   */
  offset = "";

  /**
   * 최상단 정보를 불러오는지 여부 (true이면 top 아이템을 보여줌)
   *
   * @generated from field: bool is_top = 2;
   */
  isTop = false;

  constructor(data?: PartialMessage<Gaia_FeedItemsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.Gaia.FeedItemsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "offset", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "is_top", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Gaia_FeedItemsRequest {
    return new Gaia_FeedItemsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Gaia_FeedItemsRequest {
    return new Gaia_FeedItemsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Gaia_FeedItemsRequest {
    return new Gaia_FeedItemsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: Gaia_FeedItemsRequest | PlainMessage<Gaia_FeedItemsRequest> | undefined, b: Gaia_FeedItemsRequest | PlainMessage<Gaia_FeedItemsRequest> | undefined): boolean {
    return proto3.util.equals(Gaia_FeedItemsRequest, a, b);
  }
}

/**
 * @generated from message retrica.Gaia.FeedItemsResponse
 */
export class Gaia_FeedItemsResponse extends Message<Gaia_FeedItemsResponse> {
  /**
   * @generated from field: retrica.ErrorCode error_code = 1;
   */
  errorCode = ErrorCode.UNKNOWN;

  /**
   * feed item 리스트
   *
   * @generated from field: repeated retrica.GaiaModel.FeedItem feed_items = 2;
   */
  feedItems: GaiaModel_FeedItem[] = [];

  /**
   * offset 정보
   *
   * @generated from field: string offset = 3;
   */
  offset = "";

  constructor(data?: PartialMessage<Gaia_FeedItemsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.Gaia.FeedItemsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "error_code", kind: "enum", T: proto3.getEnumType(ErrorCode) },
    { no: 2, name: "feed_items", kind: "message", T: GaiaModel_FeedItem, repeated: true },
    { no: 3, name: "offset", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Gaia_FeedItemsResponse {
    return new Gaia_FeedItemsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Gaia_FeedItemsResponse {
    return new Gaia_FeedItemsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Gaia_FeedItemsResponse {
    return new Gaia_FeedItemsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: Gaia_FeedItemsResponse | PlainMessage<Gaia_FeedItemsResponse> | undefined, b: Gaia_FeedItemsResponse | PlainMessage<Gaia_FeedItemsResponse> | undefined): boolean {
    return proto3.util.equals(Gaia_FeedItemsResponse, a, b);
  }
}

/**
 * /h1/ga/foryouShots
 * for you shot 리스트를 내려준다.
 *
 * @generated from message retrica.Gaia.ForyouShotsRequest
 */
export class Gaia_ForyouShotsRequest extends Message<Gaia_ForyouShotsRequest> {
  /**
   * offset 정보
   *
   * @generated from field: string offset = 1;
   */
  offset = "";

  constructor(data?: PartialMessage<Gaia_ForyouShotsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.Gaia.ForyouShotsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "offset", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Gaia_ForyouShotsRequest {
    return new Gaia_ForyouShotsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Gaia_ForyouShotsRequest {
    return new Gaia_ForyouShotsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Gaia_ForyouShotsRequest {
    return new Gaia_ForyouShotsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: Gaia_ForyouShotsRequest | PlainMessage<Gaia_ForyouShotsRequest> | undefined, b: Gaia_ForyouShotsRequest | PlainMessage<Gaia_ForyouShotsRequest> | undefined): boolean {
    return proto3.util.equals(Gaia_ForyouShotsRequest, a, b);
  }
}

/**
 * @generated from message retrica.Gaia.ForyouShotsResponse
 */
export class Gaia_ForyouShotsResponse extends Message<Gaia_ForyouShotsResponse> {
  /**
   * @generated from field: retrica.ErrorCode error_code = 1;
   */
  errorCode = ErrorCode.UNKNOWN;

  /**
   * shot 리스트
   *
   * @generated from field: repeated retrica.GaiaModel.Shot shots = 2;
   */
  shots: GaiaModel_Shot[] = [];

  /**
   * offset 정보
   *
   * @generated from field: string offset = 3;
   */
  offset = "";

  constructor(data?: PartialMessage<Gaia_ForyouShotsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.Gaia.ForyouShotsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "error_code", kind: "enum", T: proto3.getEnumType(ErrorCode) },
    { no: 2, name: "shots", kind: "message", T: GaiaModel_Shot, repeated: true },
    { no: 3, name: "offset", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Gaia_ForyouShotsResponse {
    return new Gaia_ForyouShotsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Gaia_ForyouShotsResponse {
    return new Gaia_ForyouShotsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Gaia_ForyouShotsResponse {
    return new Gaia_ForyouShotsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: Gaia_ForyouShotsResponse | PlainMessage<Gaia_ForyouShotsResponse> | undefined, b: Gaia_ForyouShotsResponse | PlainMessage<Gaia_ForyouShotsResponse> | undefined): boolean {
    return proto3.util.equals(Gaia_ForyouShotsResponse, a, b);
  }
}

/**
 * /h1/ga/mostLikedShots
 * get most liked shots list by period
 *
 * @generated from message retrica.Gaia.MostLikedShotsRequest
 */
export class Gaia_MostLikedShotsRequest extends Message<Gaia_MostLikedShotsRequest> {
  /**
   * @generated from field: int64 offset = 1;
   */
  offset = protoInt64.zero;

  /**
   * @generated from field: int64 limit = 2;
   */
  limit = protoInt64.zero;

  /**
   * "weekly" or "monthly"
   *
   * @generated from field: string period = 3;
   */
  period = "";

  constructor(data?: PartialMessage<Gaia_MostLikedShotsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.Gaia.MostLikedShotsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "offset", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "limit", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "period", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Gaia_MostLikedShotsRequest {
    return new Gaia_MostLikedShotsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Gaia_MostLikedShotsRequest {
    return new Gaia_MostLikedShotsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Gaia_MostLikedShotsRequest {
    return new Gaia_MostLikedShotsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: Gaia_MostLikedShotsRequest | PlainMessage<Gaia_MostLikedShotsRequest> | undefined, b: Gaia_MostLikedShotsRequest | PlainMessage<Gaia_MostLikedShotsRequest> | undefined): boolean {
    return proto3.util.equals(Gaia_MostLikedShotsRequest, a, b);
  }
}

/**
 * @generated from message retrica.Gaia.MostLikedShotsResponse
 */
export class Gaia_MostLikedShotsResponse extends Message<Gaia_MostLikedShotsResponse> {
  /**
   * @generated from field: retrica.ErrorCode error_code = 1;
   */
  errorCode = ErrorCode.UNKNOWN;

  /**
   * shot list
   *
   * @generated from field: repeated retrica.GaiaModel.Shot shots = 2;
   */
  shots: GaiaModel_Shot[] = [];

  /**
   * @generated from field: int64 offset = 3;
   */
  offset = protoInt64.zero;

  /**
   * @generated from field: string period = 4;
   */
  period = "";

  constructor(data?: PartialMessage<Gaia_MostLikedShotsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.Gaia.MostLikedShotsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "error_code", kind: "enum", T: proto3.getEnumType(ErrorCode) },
    { no: 2, name: "shots", kind: "message", T: GaiaModel_Shot, repeated: true },
    { no: 3, name: "offset", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "period", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Gaia_MostLikedShotsResponse {
    return new Gaia_MostLikedShotsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Gaia_MostLikedShotsResponse {
    return new Gaia_MostLikedShotsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Gaia_MostLikedShotsResponse {
    return new Gaia_MostLikedShotsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: Gaia_MostLikedShotsResponse | PlainMessage<Gaia_MostLikedShotsResponse> | undefined, b: Gaia_MostLikedShotsResponse | PlainMessage<Gaia_MostLikedShotsResponse> | undefined): boolean {
    return proto3.util.equals(Gaia_MostLikedShotsResponse, a, b);
  }
}

/**
 * /h1/ga/mostLikedPeople
 * get most liked Users with shots list by period
 *
 * @generated from message retrica.Gaia.MostLikedPeopleRequest
 */
export class Gaia_MostLikedPeopleRequest extends Message<Gaia_MostLikedPeopleRequest> {
  /**
   * @generated from field: int64 offset = 1;
   */
  offset = protoInt64.zero;

  /**
   * @generated from field: int64 limit = 2;
   */
  limit = protoInt64.zero;

  /**
   * (required) "weekly" or "monthly"
   *
   * @generated from field: string period = 3;
   */
  period = "";

  constructor(data?: PartialMessage<Gaia_MostLikedPeopleRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.Gaia.MostLikedPeopleRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "offset", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "limit", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "period", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Gaia_MostLikedPeopleRequest {
    return new Gaia_MostLikedPeopleRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Gaia_MostLikedPeopleRequest {
    return new Gaia_MostLikedPeopleRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Gaia_MostLikedPeopleRequest {
    return new Gaia_MostLikedPeopleRequest().fromJsonString(jsonString, options);
  }

  static equals(a: Gaia_MostLikedPeopleRequest | PlainMessage<Gaia_MostLikedPeopleRequest> | undefined, b: Gaia_MostLikedPeopleRequest | PlainMessage<Gaia_MostLikedPeopleRequest> | undefined): boolean {
    return proto3.util.equals(Gaia_MostLikedPeopleRequest, a, b);
  }
}

/**
 * @generated from message retrica.Gaia.MostLikedPeopleResponse
 */
export class Gaia_MostLikedPeopleResponse extends Message<Gaia_MostLikedPeopleResponse> {
  /**
   * @generated from field: retrica.ErrorCode error_code = 1;
   */
  errorCode = ErrorCode.UNKNOWN;

  /**
   * @generated from field: repeated retrica.GaiaModel.ShotsByOwner celebs = 2;
   */
  celebs: GaiaModel_ShotsByOwner[] = [];

  /**
   * @generated from field: int64 offset = 3;
   */
  offset = protoInt64.zero;

  /**
   * @generated from field: string period = 4;
   */
  period = "";

  constructor(data?: PartialMessage<Gaia_MostLikedPeopleResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.Gaia.MostLikedPeopleResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "error_code", kind: "enum", T: proto3.getEnumType(ErrorCode) },
    { no: 2, name: "celebs", kind: "message", T: GaiaModel_ShotsByOwner, repeated: true },
    { no: 3, name: "offset", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "period", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Gaia_MostLikedPeopleResponse {
    return new Gaia_MostLikedPeopleResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Gaia_MostLikedPeopleResponse {
    return new Gaia_MostLikedPeopleResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Gaia_MostLikedPeopleResponse {
    return new Gaia_MostLikedPeopleResponse().fromJsonString(jsonString, options);
  }

  static equals(a: Gaia_MostLikedPeopleResponse | PlainMessage<Gaia_MostLikedPeopleResponse> | undefined, b: Gaia_MostLikedPeopleResponse | PlainMessage<Gaia_MostLikedPeopleResponse> | undefined): boolean {
    return proto3.util.equals(Gaia_MostLikedPeopleResponse, a, b);
  }
}

/**
 * /h1/ga/profile
 * 프로필 정보를 가져온다.
 *
 * @generated from message retrica.Gaia.ProfileRequest
 */
export class Gaia_ProfileRequest extends Message<Gaia_ProfileRequest> {
  /**
   * 유저 id
   *
   * @generated from field: string user_id = 1;
   */
  userId = "";

  /**
   * 유저네임, request 에 user_id 가 있을 경우 무시됩니다.
   *
   * @generated from field: string username = 2;
   */
  username = "";

  constructor(data?: PartialMessage<Gaia_ProfileRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.Gaia.ProfileRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "username", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Gaia_ProfileRequest {
    return new Gaia_ProfileRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Gaia_ProfileRequest {
    return new Gaia_ProfileRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Gaia_ProfileRequest {
    return new Gaia_ProfileRequest().fromJsonString(jsonString, options);
  }

  static equals(a: Gaia_ProfileRequest | PlainMessage<Gaia_ProfileRequest> | undefined, b: Gaia_ProfileRequest | PlainMessage<Gaia_ProfileRequest> | undefined): boolean {
    return proto3.util.equals(Gaia_ProfileRequest, a, b);
  }
}

/**
 * @generated from message retrica.Gaia.ProfileResponse
 */
export class Gaia_ProfileResponse extends Message<Gaia_ProfileResponse> {
  /**
   * @generated from field: retrica.ErrorCode error_code = 1;
   */
  errorCode = ErrorCode.UNKNOWN;

  /**
   * 프로필 정보
   *
   * @generated from field: retrica.GaiaModel.Profile profile = 2;
   */
  profile?: GaiaModel_Profile;

  constructor(data?: PartialMessage<Gaia_ProfileResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.Gaia.ProfileResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "error_code", kind: "enum", T: proto3.getEnumType(ErrorCode) },
    { no: 2, name: "profile", kind: "message", T: GaiaModel_Profile },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Gaia_ProfileResponse {
    return new Gaia_ProfileResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Gaia_ProfileResponse {
    return new Gaia_ProfileResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Gaia_ProfileResponse {
    return new Gaia_ProfileResponse().fromJsonString(jsonString, options);
  }

  static equals(a: Gaia_ProfileResponse | PlainMessage<Gaia_ProfileResponse> | undefined, b: Gaia_ProfileResponse | PlainMessage<Gaia_ProfileResponse> | undefined): boolean {
    return proto3.util.equals(Gaia_ProfileResponse, a, b);
  }
}

/**
 * /h1/ga/publicShots
 * 해당 유저의 공개된 shots 가져온다.
 *
 * @generated from message retrica.Gaia.PublicShotsRequest
 */
export class Gaia_PublicShotsRequest extends Message<Gaia_PublicShotsRequest> {
  /**
   * 유저 id
   *
   * @generated from field: string user_id = 1;
   */
  userId = "";

  /**
   * offset 정보
   *
   * @generated from field: string offset = 2;
   */
  offset = "";

  constructor(data?: PartialMessage<Gaia_PublicShotsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.Gaia.PublicShotsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "offset", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Gaia_PublicShotsRequest {
    return new Gaia_PublicShotsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Gaia_PublicShotsRequest {
    return new Gaia_PublicShotsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Gaia_PublicShotsRequest {
    return new Gaia_PublicShotsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: Gaia_PublicShotsRequest | PlainMessage<Gaia_PublicShotsRequest> | undefined, b: Gaia_PublicShotsRequest | PlainMessage<Gaia_PublicShotsRequest> | undefined): boolean {
    return proto3.util.equals(Gaia_PublicShotsRequest, a, b);
  }
}

/**
 * @generated from message retrica.Gaia.PublicShotsResponse
 */
export class Gaia_PublicShotsResponse extends Message<Gaia_PublicShotsResponse> {
  /**
   * @generated from field: retrica.ErrorCode error_code = 1;
   */
  errorCode = ErrorCode.UNKNOWN;

  /**
   * shot 리스트
   *
   * @generated from field: repeated retrica.GaiaModel.Shot shots = 2;
   */
  shots: GaiaModel_Shot[] = [];

  /**
   * offset 정보
   *
   * @generated from field: string offset = 3;
   */
  offset = "";

  constructor(data?: PartialMessage<Gaia_PublicShotsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.Gaia.PublicShotsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "error_code", kind: "enum", T: proto3.getEnumType(ErrorCode) },
    { no: 2, name: "shots", kind: "message", T: GaiaModel_Shot, repeated: true },
    { no: 3, name: "offset", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Gaia_PublicShotsResponse {
    return new Gaia_PublicShotsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Gaia_PublicShotsResponse {
    return new Gaia_PublicShotsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Gaia_PublicShotsResponse {
    return new Gaia_PublicShotsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: Gaia_PublicShotsResponse | PlainMessage<Gaia_PublicShotsResponse> | undefined, b: Gaia_PublicShotsResponse | PlainMessage<Gaia_PublicShotsResponse> | undefined): boolean {
    return proto3.util.equals(Gaia_PublicShotsResponse, a, b);
  }
}

/**
 * /h1/ga/favoriteShots
 * 해당 유저가 좋아하는 shots 가져온다.
 *
 * @generated from message retrica.Gaia.FavoriteShotsRequest
 */
export class Gaia_FavoriteShotsRequest extends Message<Gaia_FavoriteShotsRequest> {
  /**
   * 유저 id
   *
   * @generated from field: string user_id = 1;
   */
  userId = "";

  /**
   * offset 정보
   *
   * @generated from field: string offset = 2;
   */
  offset = "";

  constructor(data?: PartialMessage<Gaia_FavoriteShotsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.Gaia.FavoriteShotsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "offset", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Gaia_FavoriteShotsRequest {
    return new Gaia_FavoriteShotsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Gaia_FavoriteShotsRequest {
    return new Gaia_FavoriteShotsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Gaia_FavoriteShotsRequest {
    return new Gaia_FavoriteShotsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: Gaia_FavoriteShotsRequest | PlainMessage<Gaia_FavoriteShotsRequest> | undefined, b: Gaia_FavoriteShotsRequest | PlainMessage<Gaia_FavoriteShotsRequest> | undefined): boolean {
    return proto3.util.equals(Gaia_FavoriteShotsRequest, a, b);
  }
}

/**
 * @generated from message retrica.Gaia.FavoriteShotsResponse
 */
export class Gaia_FavoriteShotsResponse extends Message<Gaia_FavoriteShotsResponse> {
  /**
   * @generated from field: retrica.ErrorCode error_code = 1;
   */
  errorCode = ErrorCode.UNKNOWN;

  /**
   * shot 리스트
   *
   * @generated from field: repeated retrica.GaiaModel.Shot shots = 2;
   */
  shots: GaiaModel_Shot[] = [];

  /**
   * offset 정보
   *
   * @generated from field: string offset = 3;
   */
  offset = "";

  constructor(data?: PartialMessage<Gaia_FavoriteShotsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.Gaia.FavoriteShotsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "error_code", kind: "enum", T: proto3.getEnumType(ErrorCode) },
    { no: 2, name: "shots", kind: "message", T: GaiaModel_Shot, repeated: true },
    { no: 3, name: "offset", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Gaia_FavoriteShotsResponse {
    return new Gaia_FavoriteShotsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Gaia_FavoriteShotsResponse {
    return new Gaia_FavoriteShotsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Gaia_FavoriteShotsResponse {
    return new Gaia_FavoriteShotsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: Gaia_FavoriteShotsResponse | PlainMessage<Gaia_FavoriteShotsResponse> | undefined, b: Gaia_FavoriteShotsResponse | PlainMessage<Gaia_FavoriteShotsResponse> | undefined): boolean {
    return proto3.util.equals(Gaia_FavoriteShotsResponse, a, b);
  }
}

/**
 * /h1/ga/shotComments
 * shot 에 대한 댓글을 가져온다.
 *
 * @generated from message retrica.Gaia.ShotCommentsRequest
 */
export class Gaia_ShotCommentsRequest extends Message<Gaia_ShotCommentsRequest> {
  /**
   * @generated from field: string shot_id = 1;
   */
  shotId = "";

  /**
   * offset 위치
   *
   * @generated from field: int64 offset = 2;
   */
  offset = protoInt64.zero;

  constructor(data?: PartialMessage<Gaia_ShotCommentsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.Gaia.ShotCommentsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "shot_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "offset", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Gaia_ShotCommentsRequest {
    return new Gaia_ShotCommentsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Gaia_ShotCommentsRequest {
    return new Gaia_ShotCommentsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Gaia_ShotCommentsRequest {
    return new Gaia_ShotCommentsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: Gaia_ShotCommentsRequest | PlainMessage<Gaia_ShotCommentsRequest> | undefined, b: Gaia_ShotCommentsRequest | PlainMessage<Gaia_ShotCommentsRequest> | undefined): boolean {
    return proto3.util.equals(Gaia_ShotCommentsRequest, a, b);
  }
}

/**
 * @generated from message retrica.Gaia.ShotCommentsResponse
 */
export class Gaia_ShotCommentsResponse extends Message<Gaia_ShotCommentsResponse> {
  /**
   * @generated from field: retrica.ErrorCode error_code = 1;
   */
  errorCode = ErrorCode.UNKNOWN;

  /**
   * comment 리스트
   *
   * @generated from field: repeated retrica.GaiaModel.ShotComment comments = 2;
   */
  comments: GaiaModel_ShotComment[] = [];

  /**
   * next offset 위치
   *
   * @generated from field: int64 offset = 3;
   */
  offset = protoInt64.zero;

  constructor(data?: PartialMessage<Gaia_ShotCommentsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.Gaia.ShotCommentsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "error_code", kind: "enum", T: proto3.getEnumType(ErrorCode) },
    { no: 2, name: "comments", kind: "message", T: GaiaModel_ShotComment, repeated: true },
    { no: 3, name: "offset", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Gaia_ShotCommentsResponse {
    return new Gaia_ShotCommentsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Gaia_ShotCommentsResponse {
    return new Gaia_ShotCommentsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Gaia_ShotCommentsResponse {
    return new Gaia_ShotCommentsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: Gaia_ShotCommentsResponse | PlainMessage<Gaia_ShotCommentsResponse> | undefined, b: Gaia_ShotCommentsResponse | PlainMessage<Gaia_ShotCommentsResponse> | undefined): boolean {
    return proto3.util.equals(Gaia_ShotCommentsResponse, a, b);
  }
}

/**
 * /a2/ga/writeShotComment
 * shot 에 댓글을 쓴다.
 *
 * @generated from message retrica.Gaia.WriteShotCommentRequest
 */
export class Gaia_WriteShotCommentRequest extends Message<Gaia_WriteShotCommentRequest> {
  /**
   * @generated from field: string shot_id = 1;
   */
  shotId = "";

  /**
   * @generated from field: string msg = 2;
   */
  msg = "";

  constructor(data?: PartialMessage<Gaia_WriteShotCommentRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.Gaia.WriteShotCommentRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "shot_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "msg", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Gaia_WriteShotCommentRequest {
    return new Gaia_WriteShotCommentRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Gaia_WriteShotCommentRequest {
    return new Gaia_WriteShotCommentRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Gaia_WriteShotCommentRequest {
    return new Gaia_WriteShotCommentRequest().fromJsonString(jsonString, options);
  }

  static equals(a: Gaia_WriteShotCommentRequest | PlainMessage<Gaia_WriteShotCommentRequest> | undefined, b: Gaia_WriteShotCommentRequest | PlainMessage<Gaia_WriteShotCommentRequest> | undefined): boolean {
    return proto3.util.equals(Gaia_WriteShotCommentRequest, a, b);
  }
}

/**
 * @generated from message retrica.Gaia.WriteShotCommentResponse
 */
export class Gaia_WriteShotCommentResponse extends Message<Gaia_WriteShotCommentResponse> {
  /**
   * @generated from field: retrica.ErrorCode error_code = 1;
   */
  errorCode = ErrorCode.UNKNOWN;

  /**
   * comment
   *
   * @generated from field: retrica.GaiaModel.ShotComment comment = 2;
   */
  comment?: GaiaModel_ShotComment;

  constructor(data?: PartialMessage<Gaia_WriteShotCommentResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.Gaia.WriteShotCommentResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "error_code", kind: "enum", T: proto3.getEnumType(ErrorCode) },
    { no: 2, name: "comment", kind: "message", T: GaiaModel_ShotComment },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Gaia_WriteShotCommentResponse {
    return new Gaia_WriteShotCommentResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Gaia_WriteShotCommentResponse {
    return new Gaia_WriteShotCommentResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Gaia_WriteShotCommentResponse {
    return new Gaia_WriteShotCommentResponse().fromJsonString(jsonString, options);
  }

  static equals(a: Gaia_WriteShotCommentResponse | PlainMessage<Gaia_WriteShotCommentResponse> | undefined, b: Gaia_WriteShotCommentResponse | PlainMessage<Gaia_WriteShotCommentResponse> | undefined): boolean {
    return proto3.util.equals(Gaia_WriteShotCommentResponse, a, b);
  }
}

/**
 * /a2/ga/deleteShotComment
 * shot에 댓글을 삭제한다.
 *
 * @generated from message retrica.Gaia.DeleteShotCommentRequest
 */
export class Gaia_DeleteShotCommentRequest extends Message<Gaia_DeleteShotCommentRequest> {
  /**
   * @generated from field: string shot_id = 1;
   */
  shotId = "";

  /**
   * @generated from field: string comment_id = 2;
   */
  commentId = "";

  constructor(data?: PartialMessage<Gaia_DeleteShotCommentRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.Gaia.DeleteShotCommentRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "shot_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "comment_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Gaia_DeleteShotCommentRequest {
    return new Gaia_DeleteShotCommentRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Gaia_DeleteShotCommentRequest {
    return new Gaia_DeleteShotCommentRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Gaia_DeleteShotCommentRequest {
    return new Gaia_DeleteShotCommentRequest().fromJsonString(jsonString, options);
  }

  static equals(a: Gaia_DeleteShotCommentRequest | PlainMessage<Gaia_DeleteShotCommentRequest> | undefined, b: Gaia_DeleteShotCommentRequest | PlainMessage<Gaia_DeleteShotCommentRequest> | undefined): boolean {
    return proto3.util.equals(Gaia_DeleteShotCommentRequest, a, b);
  }
}

/**
 * @generated from message retrica.Gaia.DeleteShotCommentResponse
 */
export class Gaia_DeleteShotCommentResponse extends Message<Gaia_DeleteShotCommentResponse> {
  /**
   * @generated from field: retrica.ErrorCode error_code = 1;
   */
  errorCode = ErrorCode.UNKNOWN;

  constructor(data?: PartialMessage<Gaia_DeleteShotCommentResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.Gaia.DeleteShotCommentResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "error_code", kind: "enum", T: proto3.getEnumType(ErrorCode) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Gaia_DeleteShotCommentResponse {
    return new Gaia_DeleteShotCommentResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Gaia_DeleteShotCommentResponse {
    return new Gaia_DeleteShotCommentResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Gaia_DeleteShotCommentResponse {
    return new Gaia_DeleteShotCommentResponse().fromJsonString(jsonString, options);
  }

  static equals(a: Gaia_DeleteShotCommentResponse | PlainMessage<Gaia_DeleteShotCommentResponse> | undefined, b: Gaia_DeleteShotCommentResponse | PlainMessage<Gaia_DeleteShotCommentResponse> | undefined): boolean {
    return proto3.util.equals(Gaia_DeleteShotCommentResponse, a, b);
  }
}

/**
 * /a2/ga/privateShots
 * 자신의 비공개 shots 가져온다.
 *
 * @generated from message retrica.Gaia.PrivateShotsRequest
 */
export class Gaia_PrivateShotsRequest extends Message<Gaia_PrivateShotsRequest> {
  /**
   * offset 정보
   *
   * @generated from field: string offset = 1;
   */
  offset = "";

  constructor(data?: PartialMessage<Gaia_PrivateShotsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.Gaia.PrivateShotsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "offset", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Gaia_PrivateShotsRequest {
    return new Gaia_PrivateShotsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Gaia_PrivateShotsRequest {
    return new Gaia_PrivateShotsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Gaia_PrivateShotsRequest {
    return new Gaia_PrivateShotsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: Gaia_PrivateShotsRequest | PlainMessage<Gaia_PrivateShotsRequest> | undefined, b: Gaia_PrivateShotsRequest | PlainMessage<Gaia_PrivateShotsRequest> | undefined): boolean {
    return proto3.util.equals(Gaia_PrivateShotsRequest, a, b);
  }
}

/**
 * @generated from message retrica.Gaia.PrivateShotsResponse
 */
export class Gaia_PrivateShotsResponse extends Message<Gaia_PrivateShotsResponse> {
  /**
   * @generated from field: retrica.ErrorCode error_code = 1;
   */
  errorCode = ErrorCode.UNKNOWN;

  /**
   * shot 리스트
   *
   * @generated from field: repeated retrica.GaiaModel.Shot shots = 2;
   */
  shots: GaiaModel_Shot[] = [];

  /**
   * offset 정보
   *
   * @generated from field: string offset = 3;
   */
  offset = "";

  constructor(data?: PartialMessage<Gaia_PrivateShotsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.Gaia.PrivateShotsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "error_code", kind: "enum", T: proto3.getEnumType(ErrorCode) },
    { no: 2, name: "shots", kind: "message", T: GaiaModel_Shot, repeated: true },
    { no: 3, name: "offset", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Gaia_PrivateShotsResponse {
    return new Gaia_PrivateShotsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Gaia_PrivateShotsResponse {
    return new Gaia_PrivateShotsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Gaia_PrivateShotsResponse {
    return new Gaia_PrivateShotsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: Gaia_PrivateShotsResponse | PlainMessage<Gaia_PrivateShotsResponse> | undefined, b: Gaia_PrivateShotsResponse | PlainMessage<Gaia_PrivateShotsResponse> | undefined): boolean {
    return proto3.util.equals(Gaia_PrivateShotsResponse, a, b);
  }
}

/**
 * /a2/ga/likeShot
 * shot 을 좋아한다.
 *
 * @generated from message retrica.Gaia.LikeShotRequest
 */
export class Gaia_LikeShotRequest extends Message<Gaia_LikeShotRequest> {
  /**
   * shot id
   *
   * @generated from field: string shot_id = 1;
   */
  shotId = "";

  constructor(data?: PartialMessage<Gaia_LikeShotRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.Gaia.LikeShotRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "shot_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Gaia_LikeShotRequest {
    return new Gaia_LikeShotRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Gaia_LikeShotRequest {
    return new Gaia_LikeShotRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Gaia_LikeShotRequest {
    return new Gaia_LikeShotRequest().fromJsonString(jsonString, options);
  }

  static equals(a: Gaia_LikeShotRequest | PlainMessage<Gaia_LikeShotRequest> | undefined, b: Gaia_LikeShotRequest | PlainMessage<Gaia_LikeShotRequest> | undefined): boolean {
    return proto3.util.equals(Gaia_LikeShotRequest, a, b);
  }
}

/**
 * @generated from message retrica.Gaia.LikeShotResponse
 */
export class Gaia_LikeShotResponse extends Message<Gaia_LikeShotResponse> {
  /**
   * @generated from field: retrica.ErrorCode error_code = 1;
   */
  errorCode = ErrorCode.UNKNOWN;

  /**
   * shot 정보
   *
   * @generated from field: retrica.GaiaModel.Shot shot = 2;
   */
  shot?: GaiaModel_Shot;

  constructor(data?: PartialMessage<Gaia_LikeShotResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.Gaia.LikeShotResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "error_code", kind: "enum", T: proto3.getEnumType(ErrorCode) },
    { no: 2, name: "shot", kind: "message", T: GaiaModel_Shot },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Gaia_LikeShotResponse {
    return new Gaia_LikeShotResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Gaia_LikeShotResponse {
    return new Gaia_LikeShotResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Gaia_LikeShotResponse {
    return new Gaia_LikeShotResponse().fromJsonString(jsonString, options);
  }

  static equals(a: Gaia_LikeShotResponse | PlainMessage<Gaia_LikeShotResponse> | undefined, b: Gaia_LikeShotResponse | PlainMessage<Gaia_LikeShotResponse> | undefined): boolean {
    return proto3.util.equals(Gaia_LikeShotResponse, a, b);
  }
}

/**
 * /h1/ga/viewShot
 * shot 을 본다.
 *
 * @generated from message retrica.Gaia.ViewShotRequest
 */
export class Gaia_ViewShotRequest extends Message<Gaia_ViewShotRequest> {
  /**
   * shot id
   *
   * @generated from field: string shot_id = 1;
   */
  shotId = "";

  constructor(data?: PartialMessage<Gaia_ViewShotRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.Gaia.ViewShotRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "shot_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Gaia_ViewShotRequest {
    return new Gaia_ViewShotRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Gaia_ViewShotRequest {
    return new Gaia_ViewShotRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Gaia_ViewShotRequest {
    return new Gaia_ViewShotRequest().fromJsonString(jsonString, options);
  }

  static equals(a: Gaia_ViewShotRequest | PlainMessage<Gaia_ViewShotRequest> | undefined, b: Gaia_ViewShotRequest | PlainMessage<Gaia_ViewShotRequest> | undefined): boolean {
    return proto3.util.equals(Gaia_ViewShotRequest, a, b);
  }
}

/**
 * @generated from message retrica.Gaia.ViewShotResponse
 */
export class Gaia_ViewShotResponse extends Message<Gaia_ViewShotResponse> {
  /**
   * @generated from field: retrica.ErrorCode error_code = 1;
   */
  errorCode = ErrorCode.UNKNOWN;

  /**
   * shot 정보
   *
   * @generated from field: retrica.GaiaModel.Shot shot = 2;
   */
  shot?: GaiaModel_Shot;

  constructor(data?: PartialMessage<Gaia_ViewShotResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.Gaia.ViewShotResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "error_code", kind: "enum", T: proto3.getEnumType(ErrorCode) },
    { no: 2, name: "shot", kind: "message", T: GaiaModel_Shot },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Gaia_ViewShotResponse {
    return new Gaia_ViewShotResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Gaia_ViewShotResponse {
    return new Gaia_ViewShotResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Gaia_ViewShotResponse {
    return new Gaia_ViewShotResponse().fromJsonString(jsonString, options);
  }

  static equals(a: Gaia_ViewShotResponse | PlainMessage<Gaia_ViewShotResponse> | undefined, b: Gaia_ViewShotResponse | PlainMessage<Gaia_ViewShotResponse> | undefined): boolean {
    return proto3.util.equals(Gaia_ViewShotResponse, a, b);
  }
}

/**
 * /a2/ga/shareShot
 * shot 을 공유한다.
 *
 * @generated from message retrica.Gaia.ShareShotRequest
 */
export class Gaia_ShareShotRequest extends Message<Gaia_ShareShotRequest> {
  /**
   * shot id
   *
   * @generated from field: string shot_id = 1;
   */
  shotId = "";

  constructor(data?: PartialMessage<Gaia_ShareShotRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.Gaia.ShareShotRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "shot_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Gaia_ShareShotRequest {
    return new Gaia_ShareShotRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Gaia_ShareShotRequest {
    return new Gaia_ShareShotRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Gaia_ShareShotRequest {
    return new Gaia_ShareShotRequest().fromJsonString(jsonString, options);
  }

  static equals(a: Gaia_ShareShotRequest | PlainMessage<Gaia_ShareShotRequest> | undefined, b: Gaia_ShareShotRequest | PlainMessage<Gaia_ShareShotRequest> | undefined): boolean {
    return proto3.util.equals(Gaia_ShareShotRequest, a, b);
  }
}

/**
 * @generated from message retrica.Gaia.ShareShotResponse
 */
export class Gaia_ShareShotResponse extends Message<Gaia_ShareShotResponse> {
  /**
   * @generated from field: retrica.ErrorCode error_code = 1;
   */
  errorCode = ErrorCode.UNKNOWN;

  /**
   * shot 정보
   *
   * @generated from field: retrica.GaiaModel.Shot shot = 2;
   */
  shot?: GaiaModel_Shot;

  constructor(data?: PartialMessage<Gaia_ShareShotResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.Gaia.ShareShotResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "error_code", kind: "enum", T: proto3.getEnumType(ErrorCode) },
    { no: 2, name: "shot", kind: "message", T: GaiaModel_Shot },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Gaia_ShareShotResponse {
    return new Gaia_ShareShotResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Gaia_ShareShotResponse {
    return new Gaia_ShareShotResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Gaia_ShareShotResponse {
    return new Gaia_ShareShotResponse().fromJsonString(jsonString, options);
  }

  static equals(a: Gaia_ShareShotResponse | PlainMessage<Gaia_ShareShotResponse> | undefined, b: Gaia_ShareShotResponse | PlainMessage<Gaia_ShareShotResponse> | undefined): boolean {
    return proto3.util.equals(Gaia_ShareShotResponse, a, b);
  }
}

/**
 * /a2/ga/uploadShot
 * shot 업로드 한다.
 *
 * @generated from message retrica.Gaia.UploadShotRequest
 */
export class Gaia_UploadShotRequest extends Message<Gaia_UploadShotRequest> {
  /**
   * 컨텐츠
   *
   * @generated from field: retrica.Content content = 1;
   */
  content?: Content;

  /**
   * public or private
   *
   * @generated from field: bool is_public = 2;
   */
  isPublic = false;

  /**
   * 설명
   *
   * @generated from field: string description = 3;
   */
  description = "";

  /**
   * shot 에 적용된 preset ID
   *
   * @generated from field: string preset_id = 4;
   */
  presetId = "";

  constructor(data?: PartialMessage<Gaia_UploadShotRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.Gaia.UploadShotRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "content", kind: "message", T: Content },
    { no: 2, name: "is_public", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "preset_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Gaia_UploadShotRequest {
    return new Gaia_UploadShotRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Gaia_UploadShotRequest {
    return new Gaia_UploadShotRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Gaia_UploadShotRequest {
    return new Gaia_UploadShotRequest().fromJsonString(jsonString, options);
  }

  static equals(a: Gaia_UploadShotRequest | PlainMessage<Gaia_UploadShotRequest> | undefined, b: Gaia_UploadShotRequest | PlainMessage<Gaia_UploadShotRequest> | undefined): boolean {
    return proto3.util.equals(Gaia_UploadShotRequest, a, b);
  }
}

/**
 * @generated from message retrica.Gaia.UploadShotResponse
 */
export class Gaia_UploadShotResponse extends Message<Gaia_UploadShotResponse> {
  /**
   * @generated from field: retrica.ErrorCode error_code = 1;
   */
  errorCode = ErrorCode.UNKNOWN;

  /**
   * shot 정보
   *
   * @generated from field: retrica.GaiaModel.Shot shot = 2;
   */
  shot?: GaiaModel_Shot;

  constructor(data?: PartialMessage<Gaia_UploadShotResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.Gaia.UploadShotResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "error_code", kind: "enum", T: proto3.getEnumType(ErrorCode) },
    { no: 2, name: "shot", kind: "message", T: GaiaModel_Shot },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Gaia_UploadShotResponse {
    return new Gaia_UploadShotResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Gaia_UploadShotResponse {
    return new Gaia_UploadShotResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Gaia_UploadShotResponse {
    return new Gaia_UploadShotResponse().fromJsonString(jsonString, options);
  }

  static equals(a: Gaia_UploadShotResponse | PlainMessage<Gaia_UploadShotResponse> | undefined, b: Gaia_UploadShotResponse | PlainMessage<Gaia_UploadShotResponse> | undefined): boolean {
    return proto3.util.equals(Gaia_UploadShotResponse, a, b);
  }
}

/**
 * /a2/ga/deleteShot
 * shot 삭제한다.
 *
 * @generated from message retrica.Gaia.DeleteShotRequest
 */
export class Gaia_DeleteShotRequest extends Message<Gaia_DeleteShotRequest> {
  /**
   * shot id
   *
   * @generated from field: string shot_id = 1;
   */
  shotId = "";

  constructor(data?: PartialMessage<Gaia_DeleteShotRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.Gaia.DeleteShotRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "shot_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Gaia_DeleteShotRequest {
    return new Gaia_DeleteShotRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Gaia_DeleteShotRequest {
    return new Gaia_DeleteShotRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Gaia_DeleteShotRequest {
    return new Gaia_DeleteShotRequest().fromJsonString(jsonString, options);
  }

  static equals(a: Gaia_DeleteShotRequest | PlainMessage<Gaia_DeleteShotRequest> | undefined, b: Gaia_DeleteShotRequest | PlainMessage<Gaia_DeleteShotRequest> | undefined): boolean {
    return proto3.util.equals(Gaia_DeleteShotRequest, a, b);
  }
}

/**
 * @generated from message retrica.Gaia.DeleteShotResponse
 */
export class Gaia_DeleteShotResponse extends Message<Gaia_DeleteShotResponse> {
  /**
   * @generated from field: retrica.ErrorCode error_code = 1;
   */
  errorCode = ErrorCode.UNKNOWN;

  constructor(data?: PartialMessage<Gaia_DeleteShotResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.Gaia.DeleteShotResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "error_code", kind: "enum", T: proto3.getEnumType(ErrorCode) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Gaia_DeleteShotResponse {
    return new Gaia_DeleteShotResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Gaia_DeleteShotResponse {
    return new Gaia_DeleteShotResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Gaia_DeleteShotResponse {
    return new Gaia_DeleteShotResponse().fromJsonString(jsonString, options);
  }

  static equals(a: Gaia_DeleteShotResponse | PlainMessage<Gaia_DeleteShotResponse> | undefined, b: Gaia_DeleteShotResponse | PlainMessage<Gaia_DeleteShotResponse> | undefined): boolean {
    return proto3.util.equals(Gaia_DeleteShotResponse, a, b);
  }
}

/**
 * /a2/ga/makePublicOfShot
 * private shot을 public shot으로 변경한다.
 *
 * @generated from message retrica.Gaia.MakePublicOfShotRequest
 */
export class Gaia_MakePublicOfShotRequest extends Message<Gaia_MakePublicOfShotRequest> {
  /**
   * shot id
   *
   * @generated from field: string shot_id = 1;
   */
  shotId = "";

  constructor(data?: PartialMessage<Gaia_MakePublicOfShotRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.Gaia.MakePublicOfShotRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "shot_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Gaia_MakePublicOfShotRequest {
    return new Gaia_MakePublicOfShotRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Gaia_MakePublicOfShotRequest {
    return new Gaia_MakePublicOfShotRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Gaia_MakePublicOfShotRequest {
    return new Gaia_MakePublicOfShotRequest().fromJsonString(jsonString, options);
  }

  static equals(a: Gaia_MakePublicOfShotRequest | PlainMessage<Gaia_MakePublicOfShotRequest> | undefined, b: Gaia_MakePublicOfShotRequest | PlainMessage<Gaia_MakePublicOfShotRequest> | undefined): boolean {
    return proto3.util.equals(Gaia_MakePublicOfShotRequest, a, b);
  }
}

/**
 * @generated from message retrica.Gaia.MakePublicOfShotResponse
 */
export class Gaia_MakePublicOfShotResponse extends Message<Gaia_MakePublicOfShotResponse> {
  /**
   * @generated from field: retrica.ErrorCode error_code = 1;
   */
  errorCode = ErrorCode.UNKNOWN;

  /**
   * shot 정보
   *
   * @generated from field: retrica.GaiaModel.Shot shot = 2;
   */
  shot?: GaiaModel_Shot;

  constructor(data?: PartialMessage<Gaia_MakePublicOfShotResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.Gaia.MakePublicOfShotResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "error_code", kind: "enum", T: proto3.getEnumType(ErrorCode) },
    { no: 2, name: "shot", kind: "message", T: GaiaModel_Shot },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Gaia_MakePublicOfShotResponse {
    return new Gaia_MakePublicOfShotResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Gaia_MakePublicOfShotResponse {
    return new Gaia_MakePublicOfShotResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Gaia_MakePublicOfShotResponse {
    return new Gaia_MakePublicOfShotResponse().fromJsonString(jsonString, options);
  }

  static equals(a: Gaia_MakePublicOfShotResponse | PlainMessage<Gaia_MakePublicOfShotResponse> | undefined, b: Gaia_MakePublicOfShotResponse | PlainMessage<Gaia_MakePublicOfShotResponse> | undefined): boolean {
    return proto3.util.equals(Gaia_MakePublicOfShotResponse, a, b);
  }
}

/**
 * /a2/ga/shot
 * 요청하는 shot 정보를 내려 준다.
 *
 * @generated from message retrica.Gaia.ShotRequest
 */
export class Gaia_ShotRequest extends Message<Gaia_ShotRequest> {
  /**
   * shot_id
   *
   * @generated from field: string shot_id = 1;
   */
  shotId = "";

  constructor(data?: PartialMessage<Gaia_ShotRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.Gaia.ShotRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "shot_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Gaia_ShotRequest {
    return new Gaia_ShotRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Gaia_ShotRequest {
    return new Gaia_ShotRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Gaia_ShotRequest {
    return new Gaia_ShotRequest().fromJsonString(jsonString, options);
  }

  static equals(a: Gaia_ShotRequest | PlainMessage<Gaia_ShotRequest> | undefined, b: Gaia_ShotRequest | PlainMessage<Gaia_ShotRequest> | undefined): boolean {
    return proto3.util.equals(Gaia_ShotRequest, a, b);
  }
}

/**
 * @generated from message retrica.Gaia.ShotResponse
 */
export class Gaia_ShotResponse extends Message<Gaia_ShotResponse> {
  /**
   * @generated from field: retrica.ErrorCode error_code = 1;
   */
  errorCode = ErrorCode.UNKNOWN;

  /**
   * shot 정보
   *
   * @generated from field: retrica.GaiaModel.Shot shot = 2;
   */
  shot?: GaiaModel_Shot;

  constructor(data?: PartialMessage<Gaia_ShotResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.Gaia.ShotResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "error_code", kind: "enum", T: proto3.getEnumType(ErrorCode) },
    { no: 2, name: "shot", kind: "message", T: GaiaModel_Shot },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Gaia_ShotResponse {
    return new Gaia_ShotResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Gaia_ShotResponse {
    return new Gaia_ShotResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Gaia_ShotResponse {
    return new Gaia_ShotResponse().fromJsonString(jsonString, options);
  }

  static equals(a: Gaia_ShotResponse | PlainMessage<Gaia_ShotResponse> | undefined, b: Gaia_ShotResponse | PlainMessage<Gaia_ShotResponse> | undefined): boolean {
    return proto3.util.equals(Gaia_ShotResponse, a, b);
  }
}

/**
 * /h1/ga/hashtag
 * hashtag 정보를 가져온다.
 *
 * @generated from message retrica.Gaia.HashtagRequest
 */
export class Gaia_HashtagRequest extends Message<Gaia_HashtagRequest> {
  /**
   * tag 이름
   *
   * @generated from field: string tag = 1;
   */
  tag = "";

  constructor(data?: PartialMessage<Gaia_HashtagRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.Gaia.HashtagRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "tag", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Gaia_HashtagRequest {
    return new Gaia_HashtagRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Gaia_HashtagRequest {
    return new Gaia_HashtagRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Gaia_HashtagRequest {
    return new Gaia_HashtagRequest().fromJsonString(jsonString, options);
  }

  static equals(a: Gaia_HashtagRequest | PlainMessage<Gaia_HashtagRequest> | undefined, b: Gaia_HashtagRequest | PlainMessage<Gaia_HashtagRequest> | undefined): boolean {
    return proto3.util.equals(Gaia_HashtagRequest, a, b);
  }
}

/**
 * @generated from message retrica.Gaia.HashtagResponse
 */
export class Gaia_HashtagResponse extends Message<Gaia_HashtagResponse> {
  /**
   * @generated from field: retrica.ErrorCode error_code = 1;
   */
  errorCode = ErrorCode.UNKNOWN;

  /**
   * hashtag 정보
   *
   * @generated from field: retrica.GaiaModel.HashTag hashtag = 2;
   */
  hashtag?: GaiaModel_HashTag;

  constructor(data?: PartialMessage<Gaia_HashtagResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.Gaia.HashtagResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "error_code", kind: "enum", T: proto3.getEnumType(ErrorCode) },
    { no: 2, name: "hashtag", kind: "message", T: GaiaModel_HashTag },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Gaia_HashtagResponse {
    return new Gaia_HashtagResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Gaia_HashtagResponse {
    return new Gaia_HashtagResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Gaia_HashtagResponse {
    return new Gaia_HashtagResponse().fromJsonString(jsonString, options);
  }

  static equals(a: Gaia_HashtagResponse | PlainMessage<Gaia_HashtagResponse> | undefined, b: Gaia_HashtagResponse | PlainMessage<Gaia_HashtagResponse> | undefined): boolean {
    return proto3.util.equals(Gaia_HashtagResponse, a, b);
  }
}

/**
 * /a2/ga/hashtagRangeSearch
 * hashtag range 검색을 한다
 *
 * @generated from message retrica.Gaia.HashtagRangeSearchRequest
 */
export class Gaia_HashtagRangeSearchRequest extends Message<Gaia_HashtagRangeSearchRequest> {
  /**
   * range search 할 tag 이름
   *
   * @generated from field: string tag = 1;
   */
  tag = "";

  constructor(data?: PartialMessage<Gaia_HashtagRangeSearchRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.Gaia.HashtagRangeSearchRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "tag", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Gaia_HashtagRangeSearchRequest {
    return new Gaia_HashtagRangeSearchRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Gaia_HashtagRangeSearchRequest {
    return new Gaia_HashtagRangeSearchRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Gaia_HashtagRangeSearchRequest {
    return new Gaia_HashtagRangeSearchRequest().fromJsonString(jsonString, options);
  }

  static equals(a: Gaia_HashtagRangeSearchRequest | PlainMessage<Gaia_HashtagRangeSearchRequest> | undefined, b: Gaia_HashtagRangeSearchRequest | PlainMessage<Gaia_HashtagRangeSearchRequest> | undefined): boolean {
    return proto3.util.equals(Gaia_HashtagRangeSearchRequest, a, b);
  }
}

/**
 * @generated from message retrica.Gaia.HashtagRangeSearchResponse
 */
export class Gaia_HashtagRangeSearchResponse extends Message<Gaia_HashtagRangeSearchResponse> {
  /**
   * @generated from field: retrica.ErrorCode error_code = 1;
   */
  errorCode = ErrorCode.UNKNOWN;

  /**
   * hashtag 정보 리스트
   *
   * @generated from field: repeated retrica.GaiaModel.HashTag hashtags = 2;
   */
  hashtags: GaiaModel_HashTag[] = [];

  constructor(data?: PartialMessage<Gaia_HashtagRangeSearchResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.Gaia.HashtagRangeSearchResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "error_code", kind: "enum", T: proto3.getEnumType(ErrorCode) },
    { no: 2, name: "hashtags", kind: "message", T: GaiaModel_HashTag, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Gaia_HashtagRangeSearchResponse {
    return new Gaia_HashtagRangeSearchResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Gaia_HashtagRangeSearchResponse {
    return new Gaia_HashtagRangeSearchResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Gaia_HashtagRangeSearchResponse {
    return new Gaia_HashtagRangeSearchResponse().fromJsonString(jsonString, options);
  }

  static equals(a: Gaia_HashtagRangeSearchResponse | PlainMessage<Gaia_HashtagRangeSearchResponse> | undefined, b: Gaia_HashtagRangeSearchResponse | PlainMessage<Gaia_HashtagRangeSearchResponse> | undefined): boolean {
    return proto3.util.equals(Gaia_HashtagRangeSearchResponse, a, b);
  }
}

/**
 * /h1/ga/recentHashtagShots
 * 최근 hashtag 리스트는 가져온다.
 *
 * @generated from message retrica.Gaia.RecentHashtagShotsRequest
 */
export class Gaia_RecentHashtagShotsRequest extends Message<Gaia_RecentHashtagShotsRequest> {
  /**
   * tag 이름
   *
   * @generated from field: string tag = 1;
   */
  tag = "";

  /**
   * offset 정보
   *
   * @generated from field: string offset = 2;
   */
  offset = "";

  constructor(data?: PartialMessage<Gaia_RecentHashtagShotsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.Gaia.RecentHashtagShotsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "tag", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "offset", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Gaia_RecentHashtagShotsRequest {
    return new Gaia_RecentHashtagShotsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Gaia_RecentHashtagShotsRequest {
    return new Gaia_RecentHashtagShotsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Gaia_RecentHashtagShotsRequest {
    return new Gaia_RecentHashtagShotsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: Gaia_RecentHashtagShotsRequest | PlainMessage<Gaia_RecentHashtagShotsRequest> | undefined, b: Gaia_RecentHashtagShotsRequest | PlainMessage<Gaia_RecentHashtagShotsRequest> | undefined): boolean {
    return proto3.util.equals(Gaia_RecentHashtagShotsRequest, a, b);
  }
}

/**
 * @generated from message retrica.Gaia.RecentHashtagShotsResponse
 */
export class Gaia_RecentHashtagShotsResponse extends Message<Gaia_RecentHashtagShotsResponse> {
  /**
   * @generated from field: retrica.ErrorCode error_code = 1;
   */
  errorCode = ErrorCode.UNKNOWN;

  /**
   * shot 리스트
   *
   * @generated from field: repeated retrica.GaiaModel.Shot shots = 2;
   */
  shots: GaiaModel_Shot[] = [];

  /**
   * offset 정보
   *
   * @generated from field: string offset = 3;
   */
  offset = "";

  constructor(data?: PartialMessage<Gaia_RecentHashtagShotsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.Gaia.RecentHashtagShotsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "error_code", kind: "enum", T: proto3.getEnumType(ErrorCode) },
    { no: 2, name: "shots", kind: "message", T: GaiaModel_Shot, repeated: true },
    { no: 3, name: "offset", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Gaia_RecentHashtagShotsResponse {
    return new Gaia_RecentHashtagShotsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Gaia_RecentHashtagShotsResponse {
    return new Gaia_RecentHashtagShotsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Gaia_RecentHashtagShotsResponse {
    return new Gaia_RecentHashtagShotsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: Gaia_RecentHashtagShotsResponse | PlainMessage<Gaia_RecentHashtagShotsResponse> | undefined, b: Gaia_RecentHashtagShotsResponse | PlainMessage<Gaia_RecentHashtagShotsResponse> | undefined): boolean {
    return proto3.util.equals(Gaia_RecentHashtagShotsResponse, a, b);
  }
}

/**
 * /h1/ga/popularHashtagShots
 * 인기있는 hashtag 리스트는 가져온다.
 *
 * @generated from message retrica.Gaia.PopularHashtagShotsRequest
 */
export class Gaia_PopularHashtagShotsRequest extends Message<Gaia_PopularHashtagShotsRequest> {
  /**
   * tag 이름
   *
   * @generated from field: string tag = 1;
   */
  tag = "";

  /**
   * offset 정보
   *
   * @generated from field: string offset = 2;
   */
  offset = "";

  constructor(data?: PartialMessage<Gaia_PopularHashtagShotsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.Gaia.PopularHashtagShotsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "tag", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "offset", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Gaia_PopularHashtagShotsRequest {
    return new Gaia_PopularHashtagShotsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Gaia_PopularHashtagShotsRequest {
    return new Gaia_PopularHashtagShotsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Gaia_PopularHashtagShotsRequest {
    return new Gaia_PopularHashtagShotsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: Gaia_PopularHashtagShotsRequest | PlainMessage<Gaia_PopularHashtagShotsRequest> | undefined, b: Gaia_PopularHashtagShotsRequest | PlainMessage<Gaia_PopularHashtagShotsRequest> | undefined): boolean {
    return proto3.util.equals(Gaia_PopularHashtagShotsRequest, a, b);
  }
}

/**
 * @generated from message retrica.Gaia.PopularHashtagShotsResponse
 */
export class Gaia_PopularHashtagShotsResponse extends Message<Gaia_PopularHashtagShotsResponse> {
  /**
   * @generated from field: retrica.ErrorCode error_code = 1;
   */
  errorCode = ErrorCode.UNKNOWN;

  /**
   * shot 리스트
   *
   * @generated from field: repeated retrica.GaiaModel.Shot shots = 2;
   */
  shots: GaiaModel_Shot[] = [];

  /**
   * offset 정보
   *
   * @generated from field: string offset = 3;
   */
  offset = "";

  constructor(data?: PartialMessage<Gaia_PopularHashtagShotsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.Gaia.PopularHashtagShotsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "error_code", kind: "enum", T: proto3.getEnumType(ErrorCode) },
    { no: 2, name: "shots", kind: "message", T: GaiaModel_Shot, repeated: true },
    { no: 3, name: "offset", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Gaia_PopularHashtagShotsResponse {
    return new Gaia_PopularHashtagShotsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Gaia_PopularHashtagShotsResponse {
    return new Gaia_PopularHashtagShotsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Gaia_PopularHashtagShotsResponse {
    return new Gaia_PopularHashtagShotsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: Gaia_PopularHashtagShotsResponse | PlainMessage<Gaia_PopularHashtagShotsResponse> | undefined, b: Gaia_PopularHashtagShotsResponse | PlainMessage<Gaia_PopularHashtagShotsResponse> | undefined): boolean {
    return proto3.util.equals(Gaia_PopularHashtagShotsResponse, a, b);
  }
}

/**
 * /a2/ga/alerts
 * alerts 리스트를 내려준다.
 *
 * @generated from message retrica.Gaia.AlertsRequest
 */
export class Gaia_AlertsRequest extends Message<Gaia_AlertsRequest> {
  /**
   * offset 위치
   *
   * @generated from field: int64 offset = 1;
   */
  offset = protoInt64.zero;

  constructor(data?: PartialMessage<Gaia_AlertsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.Gaia.AlertsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "offset", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Gaia_AlertsRequest {
    return new Gaia_AlertsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Gaia_AlertsRequest {
    return new Gaia_AlertsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Gaia_AlertsRequest {
    return new Gaia_AlertsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: Gaia_AlertsRequest | PlainMessage<Gaia_AlertsRequest> | undefined, b: Gaia_AlertsRequest | PlainMessage<Gaia_AlertsRequest> | undefined): boolean {
    return proto3.util.equals(Gaia_AlertsRequest, a, b);
  }
}

/**
 * @generated from message retrica.Gaia.AlertsResponse
 */
export class Gaia_AlertsResponse extends Message<Gaia_AlertsResponse> {
  /**
   * @generated from field: retrica.ErrorCode error_code = 1;
   */
  errorCode = ErrorCode.UNKNOWN;

  /**
   * @generated from field: repeated retrica.GaiaModel.Alert alerts = 2;
   */
  alerts: GaiaModel_Alert[] = [];

  /**
   * next offset 위치
   *
   * @generated from field: int64 offset = 3;
   */
  offset = protoInt64.zero;

  constructor(data?: PartialMessage<Gaia_AlertsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.Gaia.AlertsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "error_code", kind: "enum", T: proto3.getEnumType(ErrorCode) },
    { no: 2, name: "alerts", kind: "message", T: GaiaModel_Alert, repeated: true },
    { no: 3, name: "offset", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Gaia_AlertsResponse {
    return new Gaia_AlertsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Gaia_AlertsResponse {
    return new Gaia_AlertsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Gaia_AlertsResponse {
    return new Gaia_AlertsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: Gaia_AlertsResponse | PlainMessage<Gaia_AlertsResponse> | undefined, b: Gaia_AlertsResponse | PlainMessage<Gaia_AlertsResponse> | undefined): boolean {
    return proto3.util.equals(Gaia_AlertsResponse, a, b);
  }
}

/**
 * /a2/ga/init
 * 시작시 가져올 데이터를 내려준다.
 *
 * @generated from message retrica.Gaia.InitRequest
 */
export class Gaia_InitRequest extends Message<Gaia_InitRequest> {
  constructor(data?: PartialMessage<Gaia_InitRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.Gaia.InitRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Gaia_InitRequest {
    return new Gaia_InitRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Gaia_InitRequest {
    return new Gaia_InitRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Gaia_InitRequest {
    return new Gaia_InitRequest().fromJsonString(jsonString, options);
  }

  static equals(a: Gaia_InitRequest | PlainMessage<Gaia_InitRequest> | undefined, b: Gaia_InitRequest | PlainMessage<Gaia_InitRequest> | undefined): boolean {
    return proto3.util.equals(Gaia_InitRequest, a, b);
  }
}

/**
 * @generated from message retrica.Gaia.InitResponse
 */
export class Gaia_InitResponse extends Message<Gaia_InitResponse> {
  /**
   * @generated from field: retrica.ErrorCode error_code = 1;
   */
  errorCode = ErrorCode.UNKNOWN;

  /**
   * alert badge
   *
   * @generated from field: int64 alert_badge = 2;
   */
  alertBadge = protoInt64.zero;

  /**
   * push interval
   *
   * @generated from field: int64 push_interval = 3;
   */
  pushInterval = protoInt64.zero;

  /**
   * true 면 resumable upload, false 면 direct upload
   *
   * @generated from field: bool resumable_upload = 4;
   */
  resumableUpload = false;

  constructor(data?: PartialMessage<Gaia_InitResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.Gaia.InitResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "error_code", kind: "enum", T: proto3.getEnumType(ErrorCode) },
    { no: 2, name: "alert_badge", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "push_interval", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "resumable_upload", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Gaia_InitResponse {
    return new Gaia_InitResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Gaia_InitResponse {
    return new Gaia_InitResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Gaia_InitResponse {
    return new Gaia_InitResponse().fromJsonString(jsonString, options);
  }

  static equals(a: Gaia_InitResponse | PlainMessage<Gaia_InitResponse> | undefined, b: Gaia_InitResponse | PlainMessage<Gaia_InitResponse> | undefined): boolean {
    return proto3.util.equals(Gaia_InitResponse, a, b);
  }
}

/**
 * /a2/ga/followingShots
 * following shot 리스트를 내려준다.
 *
 * @generated from message retrica.Gaia.FollowingShotsRequest
 */
export class Gaia_FollowingShotsRequest extends Message<Gaia_FollowingShotsRequest> {
  /**
   * offset 정보
   *
   * @generated from field: string offset = 1;
   */
  offset = "";

  constructor(data?: PartialMessage<Gaia_FollowingShotsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.Gaia.FollowingShotsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "offset", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Gaia_FollowingShotsRequest {
    return new Gaia_FollowingShotsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Gaia_FollowingShotsRequest {
    return new Gaia_FollowingShotsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Gaia_FollowingShotsRequest {
    return new Gaia_FollowingShotsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: Gaia_FollowingShotsRequest | PlainMessage<Gaia_FollowingShotsRequest> | undefined, b: Gaia_FollowingShotsRequest | PlainMessage<Gaia_FollowingShotsRequest> | undefined): boolean {
    return proto3.util.equals(Gaia_FollowingShotsRequest, a, b);
  }
}

/**
 * @generated from message retrica.Gaia.FollowingShotsResponse
 */
export class Gaia_FollowingShotsResponse extends Message<Gaia_FollowingShotsResponse> {
  /**
   * @generated from field: retrica.ErrorCode error_code = 1;
   */
  errorCode = ErrorCode.UNKNOWN;

  /**
   * shot 리스트
   *
   * @generated from field: repeated retrica.GaiaModel.Shot shots = 2;
   */
  shots: GaiaModel_Shot[] = [];

  /**
   * offset 정보
   *
   * @generated from field: string offset = 3;
   */
  offset = "";

  constructor(data?: PartialMessage<Gaia_FollowingShotsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.Gaia.FollowingShotsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "error_code", kind: "enum", T: proto3.getEnumType(ErrorCode) },
    { no: 2, name: "shots", kind: "message", T: GaiaModel_Shot, repeated: true },
    { no: 3, name: "offset", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Gaia_FollowingShotsResponse {
    return new Gaia_FollowingShotsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Gaia_FollowingShotsResponse {
    return new Gaia_FollowingShotsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Gaia_FollowingShotsResponse {
    return new Gaia_FollowingShotsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: Gaia_FollowingShotsResponse | PlainMessage<Gaia_FollowingShotsResponse> | undefined, b: Gaia_FollowingShotsResponse | PlainMessage<Gaia_FollowingShotsResponse> | undefined): boolean {
    return proto3.util.equals(Gaia_FollowingShotsResponse, a, b);
  }
}

/**
 * /h1/ga/likeFriends
 * 해당 유저를 좋아요한 친구 리스트
 *
 * @generated from message retrica.Gaia.LikeFriendsRequest
 */
export class Gaia_LikeFriendsRequest extends Message<Gaia_LikeFriendsRequest> {
  /**
   * 해당 user_id
   *
   * @generated from field: string target_id = 1;
   */
  targetId = "";

  /**
   * offset 위치
   *
   * @generated from field: int64 offset = 2;
   */
  offset = protoInt64.zero;

  constructor(data?: PartialMessage<Gaia_LikeFriendsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.Gaia.LikeFriendsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "target_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "offset", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Gaia_LikeFriendsRequest {
    return new Gaia_LikeFriendsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Gaia_LikeFriendsRequest {
    return new Gaia_LikeFriendsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Gaia_LikeFriendsRequest {
    return new Gaia_LikeFriendsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: Gaia_LikeFriendsRequest | PlainMessage<Gaia_LikeFriendsRequest> | undefined, b: Gaia_LikeFriendsRequest | PlainMessage<Gaia_LikeFriendsRequest> | undefined): boolean {
    return proto3.util.equals(Gaia_LikeFriendsRequest, a, b);
  }
}

/**
 * @generated from message retrica.Gaia.LikeFriendsResponse
 */
export class Gaia_LikeFriendsResponse extends Message<Gaia_LikeFriendsResponse> {
  /**
   * @generated from field: retrica.ErrorCode error_code = 1;
   */
  errorCode = ErrorCode.UNKNOWN;

  /**
   * @generated from field: repeated retrica.Friend list = 2;
   */
  list: Friend[] = [];

  /**
   * 다음 offset 위치
   *
   * @generated from field: int64 offset = 3;
   */
  offset = protoInt64.zero;

  constructor(data?: PartialMessage<Gaia_LikeFriendsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.Gaia.LikeFriendsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "error_code", kind: "enum", T: proto3.getEnumType(ErrorCode) },
    { no: 2, name: "list", kind: "message", T: Friend, repeated: true },
    { no: 3, name: "offset", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Gaia_LikeFriendsResponse {
    return new Gaia_LikeFriendsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Gaia_LikeFriendsResponse {
    return new Gaia_LikeFriendsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Gaia_LikeFriendsResponse {
    return new Gaia_LikeFriendsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: Gaia_LikeFriendsResponse | PlainMessage<Gaia_LikeFriendsResponse> | undefined, b: Gaia_LikeFriendsResponse | PlainMessage<Gaia_LikeFriendsResponse> | undefined): boolean {
    return proto3.util.equals(Gaia_LikeFriendsResponse, a, b);
  }
}

/**
 * /a2/ga/report
 * 해당 유저 또는 shot을 신고한다.
 *
 * @generated from message retrica.Gaia.ReportRequest
 */
export class Gaia_ReportRequest extends Message<Gaia_ReportRequest> {
  /**
   * 신고 타입
   *
   * @generated from field: retrica.ReportType report_type = 1;
   */
  reportType = ReportType.RET_NONE;

  /**
   * optional <유저 신고>
   *
   * @generated from field: string user_id = 2;
   */
  userId = "";

  /**
   * optional <shot 신고>
   *
   * @generated from field: string shot_id = 3;
   */
  shotId = "";

  constructor(data?: PartialMessage<Gaia_ReportRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.Gaia.ReportRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "report_type", kind: "enum", T: proto3.getEnumType(ReportType) },
    { no: 2, name: "user_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "shot_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Gaia_ReportRequest {
    return new Gaia_ReportRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Gaia_ReportRequest {
    return new Gaia_ReportRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Gaia_ReportRequest {
    return new Gaia_ReportRequest().fromJsonString(jsonString, options);
  }

  static equals(a: Gaia_ReportRequest | PlainMessage<Gaia_ReportRequest> | undefined, b: Gaia_ReportRequest | PlainMessage<Gaia_ReportRequest> | undefined): boolean {
    return proto3.util.equals(Gaia_ReportRequest, a, b);
  }
}

/**
 * @generated from message retrica.Gaia.ReportResponse
 */
export class Gaia_ReportResponse extends Message<Gaia_ReportResponse> {
  /**
   * @generated from field: retrica.ErrorCode error_code = 1;
   */
  errorCode = ErrorCode.UNKNOWN;

  constructor(data?: PartialMessage<Gaia_ReportResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.Gaia.ReportResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "error_code", kind: "enum", T: proto3.getEnumType(ErrorCode) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Gaia_ReportResponse {
    return new Gaia_ReportResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Gaia_ReportResponse {
    return new Gaia_ReportResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Gaia_ReportResponse {
    return new Gaia_ReportResponse().fromJsonString(jsonString, options);
  }

  static equals(a: Gaia_ReportResponse | PlainMessage<Gaia_ReportResponse> | undefined, b: Gaia_ReportResponse | PlainMessage<Gaia_ReportResponse> | undefined): boolean {
    return proto3.util.equals(Gaia_ReportResponse, a, b);
  }
}

/**
 * /a2/ga/resumableShotUrl
 * shot resumable url을 요청한다.
 *
 * @generated from message retrica.Gaia.ResumableShotUrlRequest
 */
export class Gaia_ResumableShotUrlRequest extends Message<Gaia_ResumableShotUrlRequest> {
  /**
   * 원본 사이즈
   *
   * @generated from field: int64 origin_size = 1;
   */
  originSize = protoInt64.zero;

  /**
   * thumb 사이즈
   *
   * @generated from field: int64 thumb_size = 2;
   */
  thumbSize = protoInt64.zero;

  /**
   * 원본 type
   *
   * @generated from field: retrica.ContentType origin_type = 3;
   */
  originType = ContentType.COT_NONE;

  /**
   * thumb type
   *
   * @generated from field: retrica.ContentType thumb_type = 4;
   */
  thumbType = ContentType.COT_NONE;

  constructor(data?: PartialMessage<Gaia_ResumableShotUrlRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.Gaia.ResumableShotUrlRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "origin_size", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "thumb_size", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "origin_type", kind: "enum", T: proto3.getEnumType(ContentType) },
    { no: 4, name: "thumb_type", kind: "enum", T: proto3.getEnumType(ContentType) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Gaia_ResumableShotUrlRequest {
    return new Gaia_ResumableShotUrlRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Gaia_ResumableShotUrlRequest {
    return new Gaia_ResumableShotUrlRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Gaia_ResumableShotUrlRequest {
    return new Gaia_ResumableShotUrlRequest().fromJsonString(jsonString, options);
  }

  static equals(a: Gaia_ResumableShotUrlRequest | PlainMessage<Gaia_ResumableShotUrlRequest> | undefined, b: Gaia_ResumableShotUrlRequest | PlainMessage<Gaia_ResumableShotUrlRequest> | undefined): boolean {
    return proto3.util.equals(Gaia_ResumableShotUrlRequest, a, b);
  }
}

/**
 * @generated from message retrica.Gaia.ResumableShotUrlResponse
 */
export class Gaia_ResumableShotUrlResponse extends Message<Gaia_ResumableShotUrlResponse> {
  /**
   * @generated from field: retrica.ErrorCode error_code = 1;
   */
  errorCode = ErrorCode.UNKNOWN;

  /**
   * origin resumble URL
   *
   * @generated from field: string origin_url = 2;
   */
  originUrl = "";

  /**
   * thumb resumble URL
   *
   * @generated from field: string thumb_url = 3;
   */
  thumbUrl = "";

  constructor(data?: PartialMessage<Gaia_ResumableShotUrlResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.Gaia.ResumableShotUrlResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "error_code", kind: "enum", T: proto3.getEnumType(ErrorCode) },
    { no: 2, name: "origin_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "thumb_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Gaia_ResumableShotUrlResponse {
    return new Gaia_ResumableShotUrlResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Gaia_ResumableShotUrlResponse {
    return new Gaia_ResumableShotUrlResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Gaia_ResumableShotUrlResponse {
    return new Gaia_ResumableShotUrlResponse().fromJsonString(jsonString, options);
  }

  static equals(a: Gaia_ResumableShotUrlResponse | PlainMessage<Gaia_ResumableShotUrlResponse> | undefined, b: Gaia_ResumableShotUrlResponse | PlainMessage<Gaia_ResumableShotUrlResponse> | undefined): boolean {
    return proto3.util.equals(Gaia_ResumableShotUrlResponse, a, b);
  }
}

/**
 * /a2/ga/resumableUploadShot
 * shot resumable upload를 완료한다.
 *
 * @generated from message retrica.Gaia.ResumableUploadShotRequest
 */
export class Gaia_ResumableUploadShotRequest extends Message<Gaia_ResumableUploadShotRequest> {
  /**
   * 기존 content 요청에서 origin, thumb 빼고, resumable origin url, resumable thumb url 넣음.
   *
   * @generated from field: retrica.Content content = 1;
   */
  content?: Content;

  /**
   * public or private
   *
   * @generated from field: bool is_public = 2;
   */
  isPublic = false;

  /**
   * 설명
   *
   * @generated from field: string description = 3;
   */
  description = "";

  constructor(data?: PartialMessage<Gaia_ResumableUploadShotRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.Gaia.ResumableUploadShotRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "content", kind: "message", T: Content },
    { no: 2, name: "is_public", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Gaia_ResumableUploadShotRequest {
    return new Gaia_ResumableUploadShotRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Gaia_ResumableUploadShotRequest {
    return new Gaia_ResumableUploadShotRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Gaia_ResumableUploadShotRequest {
    return new Gaia_ResumableUploadShotRequest().fromJsonString(jsonString, options);
  }

  static equals(a: Gaia_ResumableUploadShotRequest | PlainMessage<Gaia_ResumableUploadShotRequest> | undefined, b: Gaia_ResumableUploadShotRequest | PlainMessage<Gaia_ResumableUploadShotRequest> | undefined): boolean {
    return proto3.util.equals(Gaia_ResumableUploadShotRequest, a, b);
  }
}

/**
 * @generated from message retrica.Gaia.ResumableUploadShotResponse
 */
export class Gaia_ResumableUploadShotResponse extends Message<Gaia_ResumableUploadShotResponse> {
  /**
   * @generated from field: retrica.ErrorCode error_code = 1;
   */
  errorCode = ErrorCode.UNKNOWN;

  /**
   * shot 정보
   *
   * @generated from field: retrica.GaiaModel.Shot shot = 2;
   */
  shot?: GaiaModel_Shot;

  constructor(data?: PartialMessage<Gaia_ResumableUploadShotResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.Gaia.ResumableUploadShotResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "error_code", kind: "enum", T: proto3.getEnumType(ErrorCode) },
    { no: 2, name: "shot", kind: "message", T: GaiaModel_Shot },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Gaia_ResumableUploadShotResponse {
    return new Gaia_ResumableUploadShotResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Gaia_ResumableUploadShotResponse {
    return new Gaia_ResumableUploadShotResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Gaia_ResumableUploadShotResponse {
    return new Gaia_ResumableUploadShotResponse().fromJsonString(jsonString, options);
  }

  static equals(a: Gaia_ResumableUploadShotResponse | PlainMessage<Gaia_ResumableUploadShotResponse> | undefined, b: Gaia_ResumableUploadShotResponse | PlainMessage<Gaia_ResumableUploadShotResponse> | undefined): boolean {
    return proto3.util.equals(Gaia_ResumableUploadShotResponse, a, b);
  }
}

/**
 * /a2/ga/friendsShots
 * request following friends' recent shots
 *
 * @generated from message retrica.Gaia.FriendsShotsRequest
 */
export class Gaia_FriendsShotsRequest extends Message<Gaia_FriendsShotsRequest> {
  /**
   * @generated from field: string offset = 1;
   */
  offset = "";

  /**
   * @generated from field: int64 limit = 2;
   */
  limit = protoInt64.zero;

  constructor(data?: PartialMessage<Gaia_FriendsShotsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.Gaia.FriendsShotsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "offset", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "limit", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Gaia_FriendsShotsRequest {
    return new Gaia_FriendsShotsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Gaia_FriendsShotsRequest {
    return new Gaia_FriendsShotsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Gaia_FriendsShotsRequest {
    return new Gaia_FriendsShotsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: Gaia_FriendsShotsRequest | PlainMessage<Gaia_FriendsShotsRequest> | undefined, b: Gaia_FriendsShotsRequest | PlainMessage<Gaia_FriendsShotsRequest> | undefined): boolean {
    return proto3.util.equals(Gaia_FriendsShotsRequest, a, b);
  }
}

/**
 * @generated from message retrica.Gaia.FriendsShotsResponse
 */
export class Gaia_FriendsShotsResponse extends Message<Gaia_FriendsShotsResponse> {
  /**
   * @generated from field: retrica.ErrorCode error_code = 1;
   */
  errorCode = ErrorCode.UNKNOWN;

  /**
   * @generated from field: repeated retrica.GaiaModel.Shot shots = 2;
   */
  shots: GaiaModel_Shot[] = [];

  /**
   * @generated from field: string offset = 3;
   */
  offset = "";

  constructor(data?: PartialMessage<Gaia_FriendsShotsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.Gaia.FriendsShotsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "error_code", kind: "enum", T: proto3.getEnumType(ErrorCode) },
    { no: 2, name: "shots", kind: "message", T: GaiaModel_Shot, repeated: true },
    { no: 3, name: "offset", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Gaia_FriendsShotsResponse {
    return new Gaia_FriendsShotsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Gaia_FriendsShotsResponse {
    return new Gaia_FriendsShotsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Gaia_FriendsShotsResponse {
    return new Gaia_FriendsShotsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: Gaia_FriendsShotsResponse | PlainMessage<Gaia_FriendsShotsResponse> | undefined, b: Gaia_FriendsShotsResponse | PlainMessage<Gaia_FriendsShotsResponse> | undefined): boolean {
    return proto3.util.equals(Gaia_FriendsShotsResponse, a, b);
  }
}

