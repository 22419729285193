import { Button, Descriptions, Popconfirm, Tag, Drawer, Divider } from 'antd';
import React, { useRef, useState, useEffect } from 'react';
import { Pack } from 'types/pack';
import { MaterialList } from 'components/MaterialViews/MaterialList';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { useFetchMaterials } from 'hooks/useFetchMaterials';
import { Grapeseed } from 'api/grapeseed';
import { FallbackImage } from 'utils/constants';
import RawJson from 'utils/RawJson';
import { ThumbnailView } from 'components/Thumbnail/ThumbnailView';
import EditPack from '../EditPack';
import { MaterialDict } from 'components/MaterialViews/MaterialMap';
import { ThumbnailSizes } from 'components/Thumbnail/Constants';
import viewStyle from 'styles/view.module.css';
import { IdPopover } from 'components/IdPopover';
import { ItemsTagger } from './ItemsTagger';

interface PackViewProps {
  pack: Pack;
  onDelete?: (id: string) => void;
}

export function PackFullView(props: PackViewProps) {
  const lastMaterialRef = useRef(null);
  const [refreshMaterials, setRefreshMaterials] = useState<boolean>();
  const [pack, setPack] = useState<Pack>(props.pack);
  const [materials, , hasMore] = useFetchMaterials({
    tags: pack.byTags?.join(','),
    ids: pack.byMaterialIds
      ? pack.byMaterialIds
      : pack.byDictionary
      ? [...pack.byDictionary].map((elem) => elem[1])
      : undefined,
    type: pack.materialType ? [pack.materialType] : [],
    lastItemRef: lastMaterialRef,
    refreshTrigger: refreshMaterials,
  });
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  const [materialCount, setMaterialCount] = useState<number>(0);
  const [freeCount, setFreeCount] = useState<number>(0);
  const [premiumCount, setPremiumCount] = useState<number>(0);

  useEffect(() => {
    async function fetchCount() {
      const queries = new Map<string, string>();
      pack.byTags && queries.set('tags', pack.byTags.toString());
      pack.materialType && queries.set('type', pack.materialType);
      pack.byMaterialIds && queries.set('ids', pack.byMaterialIds.toString());
      pack.byDictionary &&
        queries.set(
          'ids',
          [...pack.byDictionary].map((elem) => elem[1]).toString()
        );
      if (queries.size > 0) {
        const result = await Grapeseed.GET(`/api/materials/count`, {
          queryParams: queries,
        });
        setMaterialCount(result.count);
      }
    }
    fetchCount();
  }, [pack.byDictionary, pack.byMaterialIds, pack.byTags, pack.materialType]);

  useEffect(() => {
    async function fetchCount() {
      const queries = new Map<string, string>();
      pack.byTags && queries.set('tags', pack.byTags.toString());
      pack.materialType && queries.set('type', pack.materialType);
      pack.byMaterialIds && queries.set('ids', pack.byMaterialIds.toString());
      pack.byDictionary &&
        queries.set(
          'ids',
          [...pack.byDictionary].map((elem) => elem[1]).toString()
        );
      if (queries.size > 0) {
        const result = await Grapeseed.GET(`/api/materials/count`, {
          queryParams: new Map<string, string>([
            ...queries,
            ['isFree', 'false'],
          ]),
        });
        setPremiumCount(result.count);
      }
    }
    fetchCount();
  }, [pack.byDictionary, pack.byMaterialIds, pack.byTags, pack.materialType]);

  useEffect(() => {
    async function fetchCount() {
      const queries = new Map<string, string>();
      pack.byTags && queries.set('tags', pack.byTags.toString());
      pack.materialType && queries.set('type', pack.materialType);
      pack.byMaterialIds && queries.set('ids', pack.byMaterialIds.toString());
      pack.byDictionary &&
        queries.set(
          'ids',
          [...pack.byDictionary].map((elem) => elem[1]).toString()
        );
      if (queries.size > 0) {
        const result = await Grapeseed.GET(`/api/materials/count`, {
          queryParams: new Map<string, string>([
            ...queries,
            ['isFree', 'true'],
          ]),
        });
        setFreeCount(result.count);
      }
    }
    fetchCount();
  }, [pack.byDictionary, pack.byMaterialIds, pack.byTags, pack.materialType]);

  const onDelete = async () => {
    const result = await Grapeseed.DELETE(`/api/packs/${pack.id}`);
    if (result) {
      props.onDelete?.(pack.id);
    }
  };

  return (
    <React.Fragment>
      <Drawer
        width="90%"
        onClose={() => setDrawerOpen(false)}
        open={drawerOpen}
      >
        <EditPack
          id={pack.id}
          pack={pack}
          materials={!pack.byDictionary ? materials : undefined}
          materialDict={
            pack.byDictionary
              ? [...pack.byDictionary].map((elem) => {
                  return {
                    key: elem[0],
                    id: elem[1],
                    material: materials.find((m) => m.id === elem[1]),
                  };
                })
              : undefined
          }
          onSubmit={(pack) => {
            setDrawerOpen(false);
            setPack(pack);
          }}
        />
      </Drawer>
      <div className={viewStyle.viewitem}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'stretch',
            alignItems: 'center',
            marginBottom: '0.6rem',
            width: '100%',
          }}
        >
          <div style={{ fontWeight: 'bold', fontSize: '1rem' }}>
            {pack.title}
          </div>
          <div
            style={{
              marginLeft: 'auto',
            }}
          >
            <Button type="primary" onClick={() => setDrawerOpen(true)}>
              Edit
            </Button>
            <Popconfirm
              title="Are you sure to DELETE？"
              icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
              onConfirm={onDelete}
            >
              <Button style={{ marginLeft: '1rem' }} type="primary" danger>
                Delete
              </Button>
            </Popconfirm>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignContent: 'center',
            position: 'relative',
          }}
        >
          <div
            style={{
              flex: '1 0 8rem',
              display: 'flex',
              alignContent: 'center',
              marginRight: '1rem',
            }}
          >
            <ThumbnailView
              size={ThumbnailSizes.full}
              src={pack.thumbnailUrl ?? FallbackImage}
              preview={false}
              isFree={pack.isFree}
            />
          </div>
          <Descriptions column={3}>
            <Descriptions.Item label="ID">
              <IdPopover
                id={pack.id}
                content={RawJson(pack)}
                showHref={{ path: 'packs' }}
              />
            </Descriptions.Item>
            <Descriptions.Item label="Type">{pack.type}</Descriptions.Item>
            <Descriptions.Item label="MaterialType">
              {pack.materialType ?? '-'}
            </Descriptions.Item>
            <Descriptions.Item label="Priority">
              {pack.priority}
            </Descriptions.Item>
            <Descriptions.Item label="Tags">
              {pack.byTags &&
                pack.byTags.map((tag) => {
                  return (
                    <Tag
                      key={tag}
                      closable={false}
                      style={{ margin: '0 0.2rem' }}
                    >
                      {tag}
                    </Tag>
                  );
                })}
            </Descriptions.Item>
            <Descriptions.Item label="Created At">
              {pack.createdAt?.toLocaleString()}
            </Descriptions.Item>
            <Descriptions.Item label="Material Count" span={3}>
              {`${materialCount} (${freeCount} free(s) and ${premiumCount} premium(s))`}
            </Descriptions.Item>
            <Descriptions.Item
              span={3}
              contentStyle={{
                fontStyle: 'italic',
                borderStyle: 'solid',
                borderWidth: '1.5px',
                borderColor: '#dedede',
                borderRadius: '10px',
                padding: '1rem 1rem',
                whiteSpace: 'pre-wrap',
              }}
            >
              {pack.description}
            </Descriptions.Item>
          </Descriptions>
          <div
            style={{
              position: 'absolute',
              right: '20px',
              bottom: '0px',
            }}
          >
            <ItemsTagger
              pack={pack}
              refreshCallback={() => setRefreshMaterials(!refreshMaterials)}
            />
          </div>
        </div>
        <Divider />
        {!pack.byDictionary && (
          <MaterialList
            itemSize="compact"
            className={`${viewStyle.viewbox} ${viewStyle.wrap} ${viewStyle.maxHeight600}`}
            list={materials}
            hasMore={hasMore}
            lastItemRef={lastMaterialRef}
          />
        )}
        {pack.byDictionary && (
          <>
            <MaterialDict
              dict={[...pack.byDictionary].map((elem) => {
                return {
                  key: elem[0],
                  id: elem[1],
                  material: materials.find((m) => m.id === elem[1]),
                };
              })}
              itemSize="compact"
              className={`${viewStyle.viewbox} ${viewStyle.wrap} ${viewStyle.maxHeight600}`}
              hasMore={hasMore}
              lastItemRef={lastMaterialRef}
            />
            <Divider orientation="left">Config</Divider>
            <Descriptions column={4}>
              <Descriptions.Item label="spacing">
                {pack.config?.spacing ?? 0}
              </Descriptions.Item>
              <Descriptions.Item label="oblique">
                {pack.config?.oblique ?? 0}
              </Descriptions.Item>
            </Descriptions>
          </>
        )}
      </div>
    </React.Fragment>
  );
}
