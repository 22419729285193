import {
  Button,
  Descriptions,
  Popover,
  Space,
  Typography,
  notification,
} from 'antd';
import { Grapeseed } from 'api/grapeseed';
import { SubTitle } from 'components/PageTitles';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  ContainerCreators,
  Creator,
  CreatorCoverAcceptTypes,
} from 'types/common';
import {
  UserOutlined,
  LinkOutlined,
  UploadOutlined,
  MailOutlined,
  CheckOutlined,
  BgColorsOutlined,
} from '@ant-design/icons';
import { FileUploader, acceptTypesInfo } from 'components/FileUploader';
import { ulid } from 'ulid';
import { UploadFile } from 'antd/lib/upload/interface';
import componentStyle from 'styles/common.module.css';
import { ParseColor, ParseColorText } from 'utils/utils';
import { HexAlphaColorPicker, HexColorInput } from 'react-colorful';
import { ApiError, OnError } from 'utils/errors';
import { EditableText } from 'components/EditableText';
import { CoverView } from './components/CoverView';

function SingleItem() {
  const navigate = useNavigate();
  const params = useParams();
  const code = params.code;

  const [email, setEmail] = useState<string>('');
  const [coverUrl, setCoverUrl] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [profileUrl, setProfileUrl] = useState<string>('');
  const [extraUrl, setExtraUrl] = useState<string>('');
  const [bgColor, setBgColor] = useState<string>('#F5F5F5FF');
  const [fgColor, setFgColor] = useState<string>('#5C5C5CFF');
  const [artworksRef, setArtworksRef] = useState<string>('');

  const colorDivRef = useRef<HTMLDivElement>(null);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const setAll = (item: Creator) => {
    setEmail(item.email ?? '');
    setCoverUrl(item.coverUrl ?? '');
    setName(item.name ?? '');
    setDescription(item.description ?? '');
    setProfileUrl(item.profileUrl ?? '');
    setExtraUrl(item.extraUrl ?? '');
    setBgColor(
      item.backgroundColor ? ParseColor(item.backgroundColor) : '#F5F5F5FF'
    );
    setFgColor(
      item.foregroundColor ? ParseColor(item.foregroundColor) : '#5C5C5CFF'
    );
    setArtworksRef(item.artworksRef ?? '');
  };

  useEffect(() => {
    if (colorDivRef.current == null) {
      return;
    }
    colorDivRef.current.style.backgroundColor = bgColor;
    colorDivRef.current.style.color = fgColor;
  }, [bgColor, fgColor]);

  const onSubmit = async () => {
    if (!code) return;
    setIsLoading(true);
    const result = await Grapeseed.PUT(`/api/creators/${code}`, {
      body: {
        code: code,
        email: email,
        coverUrl: coverUrl,
        name: name,
        description: description,
        profileUrl: profileUrl,
        extraUrl: extraUrl,
        backgroundColor: ParseColorText(bgColor.replace('#', '')),
        foregroundColor: ParseColorText(fgColor.replace('#', '')),
        artworksRef: artworksRef,
      },
    });
    if (result) {
      setAll(result);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    async function fetchItem() {
      const result = await Grapeseed.GET(`/api/creators/${code}`, {
        fallback: (e) => {
          if (e instanceof ApiError && e.statusCode === 404) {
            notification.info({
              placement: 'top',
              message: `Could not find creator with code: ${code}`,
              duration: 2,
              onClose: () => navigate(-1),
            });
          } else {
            OnError(e);
          }
        },
      });
      if (result) {
        setAll(result);
      }
    }
    fetchItem();
  }, [code, navigate]);

  return (
    <React.Fragment>
      <Descriptions title={<SubTitle>{code}</SubTitle>} />
      <div
        style={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}
      >
        <MailOutlined
          style={{
            fontSize: '22px',
            color: 'var(--highlight-color-blue)',
            marginRight: '15px',
          }}
        />
        <EditableText
          str={email}
          onChange={setEmail}
          triggerType="text"
          placeholder="Email"
        />
      </div>
      <div
        style={{
          width: '390px',
          height: '775px',
          display: 'flex',
          flexDirection: 'column',
          borderStyle: 'solid',
          borderWidth: '1px',
          borderColor: 'var(--border-color)',
          backgroundColor: 'var(--background-color)',
          padding: '80px 15px 56px 15px',
          marginBottom: '1.5rem',
        }}
      >
        <Popover
          overlayStyle={{ width: '600px' }}
          placement="right"
          trigger="click"
          title="Cover Image"
          content={
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <FileUploader
                container={ContainerCreators}
                accept={CreatorCoverAcceptTypes}
                filename={ulid()}
                onAdd={(file: UploadFile, id: string) =>
                  setCoverUrl(file.url ?? '')
                }
                onRemove={(id: string) => setCoverUrl('')}
              />
              {acceptTypesInfo(CreatorCoverAcceptTypes)}
            </div>
          }
        >
          <CoverView url={coverUrl} width={360} height={360} />
        </Popover>
        <div
          style={{
            display: 'flex',
            height: '38px',
            marginTop: '18px',
            alignItems: 'center',
            padding: '0 8px',
            justifyContent: 'space-between',
          }}
        >
          <div
            style={{
              fontSize: '32px',
              fontWeight: 'bold',
            }}
          >
            <EditableText
              str={name}
              placeholder="Name"
              onChange={setName}
              triggerType={'text'}
              prefix="@"
            />
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Popover
              overlayStyle={{ width: '600px' }}
              placement="rightTop"
              trigger="click"
              title="Profile Link"
              content={
                <EditableText
                  str={profileUrl}
                  placeholder="Profile URL"
                  onChange={setProfileUrl}
                  triggerType="icon"
                />
              }
            >
              <UserOutlined
                style={{
                  fontSize: '22px',
                  color: 'var(--highlight-color-blue)',
                  marginRight: '15px',
                }}
              />
            </Popover>
            <Popover
              overlayStyle={{ width: '600px' }}
              placement="rightTop"
              trigger="click"
              title="Extra Link"
              content={
                <EditableText
                  str={extraUrl}
                  placeholder="Extra URL"
                  onChange={setExtraUrl}
                  triggerType="icon"
                />
              }
            >
              <LinkOutlined
                style={{
                  fontSize: '22px',
                  color: 'var(--highlight-color-blue)',
                }}
              />
            </Popover>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            fontSize: '17px',
            width: '70%',
            marginTop: '8px',
            alignItems: 'center',
            padding: '0 8px',
            lineHeight: '1.2rem',
            overflowWrap: 'break-word',
          }}
        >
          <EditableText
            str={description}
            placeholder="Description"
            onChange={setDescription}
            triggerType="text"
          />
        </div>
        <div
          style={{
            display: 'flex',
            fontSize: '12px',
            color: 'var(--icontext-default-color)',
            marginTop: '8px',
            alignItems: 'center',
            padding: '0 8px',
            lineHeight: '1.2rem',
          }}
        >
          Donut Collage Creator
        </div>
        <div
          style={{
            display: 'flex',
            fontSize: '16px',
            marginTop: 'auto',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '0 8px',
            lineHeight: '1.2rem',
          }}
        >
          <div
            style={{
              width: '50px',
              height: '48px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              lineHeight: '1.2rem',
              backgroundColor: 'var(--secondary-background-color)',
              borderRadius: '8pt',
            }}
          >
            <UploadOutlined
              style={{ fontSize: '16px', color: 'var(--highlight-color-blue)' }}
            />
          </div>
          <div
            ref={colorDivRef}
            style={{
              height: '48px',
              flex: '1 0 auto',
              marginLeft: '8px',
              display: 'flex',
              justifyContent: 'space-evenly',
              alignItems: 'center',
              lineHeight: '1.2rem',
              fontSize: '16px',
              borderRadius: '8pt',
            }}
          >
            <Popover
              overlayStyle={{ width: '600px' }}
              placement="rightBottom"
              trigger="click"
              title="Artworks Ref"
              content={
                <EditableText
                  str={artworksRef}
                  placeholder="Artworks URL"
                  onChange={setArtworksRef}
                  triggerType="icon"
                />
              }
            >
              <div>See @{code}'s Artwork</div>
            </Popover>
            <Popover
              placement="rightBottom"
              trigger="click"
              title="Creator Colors"
              content={
                <Space size="large">
                  <div>
                    <Typography.Title level={5}>Background</Typography.Title>
                    <HexColorInput
                      alpha
                      prefixed
                      color={bgColor}
                      onChange={setBgColor}
                      style={{ marginBottom: '1rem' }}
                    />
                    <HexAlphaColorPicker
                      color={bgColor}
                      onChange={setBgColor}
                    />
                  </div>
                  <div>
                    <Typography.Title level={5}>Foreground</Typography.Title>
                    <HexColorInput
                      alpha
                      prefixed
                      color={fgColor}
                      onChange={setFgColor}
                      style={{ marginBottom: '1rem' }}
                    />
                    <HexAlphaColorPicker
                      color={fgColor}
                      onChange={setFgColor}
                    />
                  </div>
                </Space>
              }
            >
              <BgColorsOutlined
                style={{
                  fontSize: '16px',
                  color: 'var(--highlight-color-blue)',
                }}
              />
            </Popover>
          </div>
        </div>
      </div>
      <Button
        className={componentStyle.buttonOrange}
        type="primary"
        icon={<CheckOutlined />}
        loading={isLoading}
        onClick={onSubmit}
      >
        Submit
      </Button>
    </React.Fragment>
  );
}

const exports = SingleItem;
export default exports;
