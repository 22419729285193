import { Button, Checkbox, Form } from 'antd';
import { FileUploader, acceptTypesInfo } from 'components/FileUploader';
import { TagEntries } from 'components/TagEntries';
import { useState } from 'react';
import componentStyle from 'styles/common.module.css';
import { CheckOutlined } from '@ant-design/icons';
import {
  Background,
  BackgroundContentAcceptTypes,
  ContainerBackgrounds,
  ThumbnailAcceptTypes,
  ThumbnailFileSuffix,
} from 'types/material';
import { useForm } from 'antd/lib/form/Form';
import { Grapeseed } from 'api/grapeseed';
import { RadioPicker } from 'components/RadioPicker';
import { ParseColorText } from 'utils/utils';
import { ColorPicker } from 'components/ColorPicker';
import { getMediaResolution } from 'utils/media';

interface NewBackgroundFormProps {
  onAdd: (s: Background) => void;
}

function NewBackgroundForm(props: NewBackgroundFormProps) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [form] = useForm();
  const [id, setId] = useState<string>();

  const onSubmit = async (values: any) => {
    if (!id) {
      console.log('no id set!');
      return;
    }
    let body: Background = new Background({
      id: id,
      tags: values.tags ?? [],
      createdAt: new Date(),
      isFree: values.isFree ?? false,
      bytes: values.background[0].size,
      content: {
        type: values.background[0].type,
        size: await getMediaResolution(values.background[0]),
        url: values.background[0].url,
      },
      thumbnailUrl: values.thumbnail[0].url,
    });
    if (values.pattern && values.pattern !== 'No Pattern') {
      body.pattern = {
        style: values.pattern,
      };
    }
    const bgColor = ParseColorText(values.backgroundColor);
    if (bgColor) {
      body.backgroundColor = bgColor;
    }
    setIsLoading(true);
    const result = await Grapeseed.POST(`/api/backgrounds`, { body: body });
    setIsLoading(false);

    if (result) {
      props.onAdd(new Background(result));
      setId(undefined);
      form.resetFields();
    }
  };

  return (
    <Form
      form={form}
      onFinish={onSubmit}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          e.preventDefault();
        }
      }}
      labelCol={{ span: 3 }}
      labelAlign="left"
      colon={false}
      style={{ margin: '1rem 0' }}
    >
      <Form.Item
        label="Background"
        name="background"
        extra={acceptTypesInfo(BackgroundContentAcceptTypes)}
        rules={[{ required: true, message: 'Background content is required' }]}
      >
        <FileUploader
          container={ContainerBackgrounds}
          accept={BackgroundContentAcceptTypes}
          onAdd={(_, id) => setId(id)}
          onRemove={(_) => setId(undefined)}
        />
      </Form.Item>

      {id && (
        <Form.Item
          label="Thumbnail"
          name="thumbnail"
          extra={acceptTypesInfo(ThumbnailAcceptTypes)}
          rules={[{ required: true, message: 'Thumbnail is required' }]}
        >
          <FileUploader
            container={ContainerBackgrounds}
            accept={ThumbnailAcceptTypes}
            filename={id}
            suffix={ThumbnailFileSuffix}
          />
        </Form.Item>
      )}

      <Form.Item name="pattern" label="Pattern">
        <RadioPicker
          name="pattern"
          options={[
            'No Pattern',
            'tile',
            'verticalTile',
            'horizontalTile',
            'custom',
          ]}
        />
      </Form.Item>
      <Form.Item
        label="Background Color"
        name="backgroundColor"
        wrapperCol={{ span: 5 }}
        initialValue="#FFFFFFFF"
      >
        <ColorPicker />
      </Form.Item>
      <Form.Item
        name="tags"
        label="Tags"
        extra={
          <span className={componentStyle.hinttextOrange}>
            * Press enter to add tag.
          </span>
        }
      >
        <TagEntries layout="horizontal" />
      </Form.Item>
      <Form.Item
        label="Free"
        name="isFree"
        valuePropName="checked"
        wrapperCol={{ span: 5 }}
      >
        <Checkbox />
      </Form.Item>
      <Form.Item>
        <Button
          className={componentStyle.buttonOrange}
          type="primary"
          icon={<CheckOutlined />}
          htmlType="submit"
          loading={isLoading}
        >
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
}

const exports = NewBackgroundForm;
export default exports;
