import { Retriver } from 'api/retriver/retriver';
import {
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useIntersectionObserver } from './useIntersectionObserver';
import { Union } from 'utils/utils';
import { LookUpTable } from 'types/proto/retriver-struct_pb';
import { NewSearchLookUpTableRequest } from 'api/retriver/requestFactory/lookuptables';
import { ErrorCode } from 'types/proto/retriver-enum_pb';

interface useFetchLookUpTablesProps {
  size?: number;
  lastItemRef: MutableRefObject<null>;
}

export const useFetchLookUpTables = (
  props: useFetchLookUpTablesProps
): [LookUpTable[], Dispatch<SetStateAction<LookUpTable[]>>, boolean] => {
  const [list, setList] = useState<LookUpTable[]>([]);
  const [hasError, setHasError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const offsetRef = useRef<string>('');
  const hasMore = useRef<boolean>(true);

  const fetchLookUpTables = useCallback(
    async (offset: string, size: number) => {
      setIsLoading(true);
      const response = (
        await Retriver.do(NewSearchLookUpTableRequest(offset, size))
      )?.searchLookUpTablesResponse;
      setIsLoading(false);
      if (!response || response.errorCode !== ErrorCode.SUCCESS) {
        setHasError(true);
        return [];
      }
      hasMore.current = !(
        response.lookUpTables.length < size || response.offset === offset
      );
      offsetRef.current = response.offset;
      return response.lookUpTables;
    },
    []
  );

  const fetchNextPage = useCallback(
    async (size: number) => {
      const data = await fetchLookUpTables(offsetRef.current, size);
      setList((prev) => Union(prev, data) as LookUpTable[]);
    },
    [fetchLookUpTables]
  );

  const fetchInit = useCallback(
    async (size: number) => {
      const data = await fetchLookUpTables(offsetRef.current, size);
      setList((prev) => Union(prev, data) as LookUpTable[]);
    },
    [fetchLookUpTables]
  );

  useEffect(() => {
    hasMore.current = true;
    fetchInit(props.size ?? 30);
  }, [fetchInit, props.size]);

  useIntersectionObserver({
    root: null,
    target: props.lastItemRef?.current,
    onIntersect: ([{ isIntersecting }]) => {
      if (isIntersecting && !isLoading && !hasError) {
        fetchNextPage(props.size ?? 30);
      }
    },
  });

  return [list, setList, hasMore.current];
};
