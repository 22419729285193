import { Button, Form, Input } from 'antd';
import { SearchFormProps } from '../Search';
import { SearchOutlined } from '@ant-design/icons';
import componentStyle from 'styles/common.module.css';
import { RadioPicker } from 'components/RadioPicker';
import { useState } from 'react';
import { MaterialTypes } from 'types/material';

export function SearchPackForm(props: SearchFormProps) {
  const [hasIdQuery, setHasIdQuery] = useState<boolean>(false);

  return (
    <Form
      onFinish={(values: any) => {
        props.onSubmit(values);
      }}
      labelCol={props.labelCol}
      labelAlign="left"
      wrapperCol={props.wrapperCol}
      colon={false}
      style={{ margin: '1rem 0' }}
    >
      <Form.Item label="ID" name="id">
        <Input
          type="text"
          placeholder="Pack ID"
          onChange={(e) => {
            if (e.target.value) setHasIdQuery(true);
            else setHasIdQuery(false);
          }}
        />
      </Form.Item>
      <Form.Item label="Title" name="title">
        <Input type="text" placeholder="Title" disabled={hasIdQuery} />
      </Form.Item>
      <Form.Item label="Type" name="type">
        <Input type="text" placeholder="Type" disabled={hasIdQuery} />
      </Form.Item>
      <Form.Item label="Author ID" name="authorId">
        <Input type="text" placeholder="User ID" disabled={hasIdQuery} />
      </Form.Item>
      <Form.Item label="Material Type" name="materialType">
        <RadioPicker
          name="materialType"
          options={['All', ...Object.values(MaterialTypes)]}
          disabled={hasIdQuery}
        />
      </Form.Item>
      <Form.Item>
        <Button
          className={componentStyle.buttonOrange}
          type="primary"
          icon={<SearchOutlined />}
          htmlType="submit"
        >
          Search
        </Button>
      </Form.Item>
    </Form>
  );
}
