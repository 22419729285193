import { MouseEventHandler } from 'react';
import { FallbackImage } from 'utils/constants';

const CoverTypes = {
  Image: 'image',
  Video: 'video',
  None: 'none',
} as const;
type CoverType = (typeof CoverTypes)[keyof typeof CoverTypes];

export interface CoverViewProps {
  width: number;
  height: number;
  url: string | undefined;
  onClick?: MouseEventHandler | undefined;
}

export function CoverView(props: CoverViewProps) {
  const parseExtension = (url: string | undefined) => {
    if (!url) {
      return CoverTypes.None;
    }
    const ext = url.split('.').pop()?.toLowerCase();
    switch (ext) {
      case 'mp4':
        return CoverTypes.Video;
      case 'jpg':
      case 'jpeg':
      case 'png':
        return CoverTypes.Image;
      default:
        return CoverTypes.None;
    }
  };

  const type: CoverType = parseExtension(props.url);

  const render = () => {
    switch (type) {
      case CoverTypes.Image:
        return (
          <img
            src={props.url}
            alt=""
            width={props.width}
            height={props.height}
            onClick={props.onClick}
            draggable={false}
          />
        );
      case CoverTypes.Video:
        return (
          <video
            src={props.url}
            width={props.width}
            height={props.height}
            controls
            autoPlay
            loop
            onClick={props.onClick}
            draggable={false}
          />
        );
      default:
        return (
          <img
            src={FallbackImage}
            alt=""
            width={props.width}
            height={props.height}
            onClick={props.onClick}
            draggable={false}
          />
        );
    }
  };

  return render();
}
