import {
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useIntersectionObserver } from './useIntersectionObserver';
import { Union } from 'utils/utils';
import { User } from 'types/user';
import { Grapeseed } from 'api/grapeseed';
import { ApiError, OnError } from 'utils/errors';

interface useFetchUsersProps {
  page?: number;
  size?: number;
  lastItemRef?: MutableRefObject<null>;
}

export const useFetchUsers = (
  props: useFetchUsersProps
): [User[], Dispatch<SetStateAction<User[]>>, boolean] => {
  const [list, setList] = useState<User[]>([]);
  const [hasError, setHasError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const page = useRef(0);
  const hasMore = useRef<boolean>(true);
  const defaultPagingSize = 20;

  const fetchUsers = useCallback(async (page: number, size: number) => {
    const queryParams: Map<string, string> = new Map();
    queryParams.set('from', (page * size).toString());
    queryParams.set('size', size.toString());
    setIsLoading(true);
    const response = await Grapeseed.GET('/api/users', {
      queryParams: queryParams,
      fallback: (e) => {
        if (e instanceof ApiError && e.statusCode === 404) {
          hasMore.current = false;
        } else {
          OnError(e);
        }
      },
    });
    setIsLoading(false);
    if (!response) {
      setHasError(true);
      hasMore.current = false;
      return [];
    }
    hasMore.current = !(response.length < size);
    return response;
  }, []);

  const fetchNextPage = useCallback(
    async (size: number) => {
      page.current++;
      const data = await fetchUsers(page.current, size);
      setList((prev) => Union(prev, data) as User[]);
    },
    [fetchUsers]
  );

  const fetchInit = useCallback(
    async (size: number, startPage?: number) => {
      page.current = startPage ?? 0;
      const data = await fetchUsers(page.current, size);
      setList((prev) => Union(prev, data) as User[]);
    },
    [fetchUsers]
  );

  useEffect(() => {
    hasMore.current = true;
    fetchInit(props.size ?? defaultPagingSize, props.page);
  }, [fetchInit, props.page, props.size]);

  useIntersectionObserver({
    root: null,
    target: props.lastItemRef?.current,
    onIntersect: ([{ isIntersecting }]) => {
      if (isIntersecting && !isLoading && !hasError) {
        fetchNextPage(props.size ?? defaultPagingSize);
      }
    },
  });

  return [list, setList, hasMore.current];
};
