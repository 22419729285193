import React, { DragEvent } from 'react';
import { MaterialView } from 'components/MaterialViews/MaterialView';
import viewStyle from 'styles/view.module.css';
import { Material } from 'types/interfaces';
import { Button } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

interface ByIdsPreviewProps {
  materials: Material[];
  onRemove?: (id: string) => void;
  onItemMove?: (src: number, dest: number) => void;
}

export function PreviewByIds(props: ByIdsPreviewProps) {
  const onDragStart = (e: DragEvent<HTMLDivElement>, index: number) => {
    e.dataTransfer.setData('text/plain', index.toString());
  };
  const onDragOver = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };
  const onDrop = (e: DragEvent<HTMLDivElement>, index: number) => {
    const src = parseInt(e.dataTransfer.getData('text/plain'));
    if (!isNaN(src)) {
      props.onItemMove?.(src, index);
      e.stopPropagation();
    }
  };

  return (
    <React.Fragment>
      <div
        className={`${viewStyle.viewbox} ${viewStyle.wrap} ${viewStyle.maxHeight600}`}
      >
        {props.materials.map((m, index) => {
          return (
            <div
              key={m.id}
              style={{
                cursor: 'move',
                position: 'relative',
              }}
              draggable
              onDragStart={(e) => onDragStart(e, index)}
              onDragOver={onDragOver}
              onDrop={(e) => onDrop(e, index)}
            >
              <MaterialView
                size="compact"
                material={m}
                editable={false}
                draggable={false}
              />
              <div style={{ position: 'absolute', top: 12, right: 12 }}>
                <Button
                  size="small"
                  icon={<DeleteOutlined />}
                  danger
                  onClick={() => props.onRemove?.(m.id)}
                />
              </div>
            </div>
          );
        })}
      </div>
    </React.Fragment>
  );
}
