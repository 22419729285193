import React, { DragEvent, useState } from 'react';
import {
  Badge,
  Button,
  Checkbox,
  Collapse,
  Form,
  Input,
  Modal,
  Popconfirm,
  Space,
} from 'antd';
import {
  ContainerFonts,
  Font,
  FontThumbnailAcceptTypes,
  NewMaterial,
  ThumbnailFileSuffix,
} from 'types/material';
import { TagEntries } from 'components/TagEntries';
import viewStyle from 'styles/view.module.css';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Grapeseed } from 'api/grapeseed';
import { useForm } from 'antd/lib/form/Form';
import RawJson from '../../utils/RawJson';
import { FileUploader, acceptTypesInfo } from 'components/FileUploader';
import { FormatBytes, HaveSameContents } from 'utils/utils';
import { ThumbnailView } from 'components/Thumbnail/ThumbnailView';
import { Material } from 'types/interfaces';
import { ThumbnailSizes } from 'components/Thumbnail/Constants';
import { IdPopover } from 'components/IdPopover';

interface FontViewProps {
  font: Font;
  editable?: boolean;
  draggable?: boolean;
  onDragStart?: (e: DragEvent<HTMLDivElement>, m: Material) => void;
  onRemove?: (id: string) => void;
}

function FontView(props: FontViewProps) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isModified, setIsModified] = useState<boolean>(false);
  const [form] = useForm();
  const [modalForm] = useForm();
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [font, setFont] = useState<Font>(props.font);

  const onSubmit = async (values: any) => {
    setIsLoading(true);
    const result = await Grapeseed.PUT(`/api/fonts/${font.id}`, {
      body: {
        title: values.title,
        tags: values.tags ?? font.tags,
        thumbnailUrl: font.thumbnailUrl,
        isFree: values.isFree ?? false,
      },
      fallback: () => {
        form.setFieldsValue({ title: font.title, tags: font.tags });
      },
    });
    if (result) {
      setFont(NewMaterial(result) as Font);
    }
    setIsLoading(false);
    setIsModified(false);
  };

  const onRemove = async (event?: React.MouseEvent<HTMLElement>) => {
    const result = await Grapeseed.DELETE(`/api/fonts/${font.id}`);
    if (result) {
      props.onRemove?.(font.id);
    }
  };

  const handleModalFormSubmit = async (values: any) => {
    setModalOpen(false);
    modalForm.resetFields();
    const result = await Grapeseed.PUT(`/api/fonts/${font.id}`, {
      body: {
        title: font.title,
        tags: font.tags,
        thumbnailUrl: values.thumbnail[0].url,
        isFree: font.isFree,
      },
    });
    if (result) {
      setFont(result);
    }
  };

  const checkIsModified = (_: any, values: any) => {
    if (
      values.title !== font.title ||
      !HaveSameContents(values.tags, font.tags) ||
      values.isFree !== font.isFree
    ) {
      setIsModified(true);
    } else {
      setIsModified(false);
    }
  };

  let divClass = `${viewStyle.viewitem} ${viewStyle.full}`;
  if (props.draggable) {
    divClass = `${viewStyle.viewitem} ${viewStyle.draggable}`;
  }
  return (
    <React.Fragment>
      <Modal
        open={modalOpen}
        title="Change Thumbnail"
        footer={[
          <Button
            key="cancel"
            onClick={() => {
              modalForm.resetFields();
              setModalOpen(false);
            }}
          >
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={() => {
              modalForm.validateFields().then((values) => {
                handleModalFormSubmit(values);
              });
            }}
          >
            Submit
          </Button>,
        ]}
      >
        <Form form={modalForm} layout="horizontal">
          <Form.Item
            name="thumbnail"
            label="Thumbnail"
            extra={acceptTypesInfo(FontThumbnailAcceptTypes)}
            rules={[{ required: true, message: 'Thumbnail is required' }]}
          >
            <FileUploader
              container={ContainerFonts}
              accept={FontThumbnailAcceptTypes}
              filename={font.id}
              suffix={ThumbnailFileSuffix}
              direction="column"
            />
          </Form.Item>
        </Form>
      </Modal>
      <div
        className={divClass}
        draggable={props.draggable}
        onDragStart={(e) => props.onDragStart?.(e, font)}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'stretch',
            padding: '15px 15px 0 15px',
          }}
        >
          <Badge
            count={font.picked ?? 0}
            showZero
            color="var(--font-badge-color)"
          >
            {font.thumbnailUrl ? (
              <ThumbnailView
                size={ThumbnailSizes.half}
                src={font.thumbnailUrl}
                preview={false}
                isFree={font.isFree}
                style={{ marginBottom: '0.5rem' }}
              />
            ) : (
              <>
                <div style={{ fontWeight: 'bold' }}>Named Font</div>
                <div
                  style={{
                    fontWeight: 'bolder',
                    fontSize: '1.6rem',
                    margin: '3rem 0',
                  }}
                >
                  {font.fontName}
                </div>
              </>
            )}
          </Badge>
          <Space style={{ fontSize: '90%' }}>
            <Badge color="var(--font-badge-color)" />
            <IdPopover
              id={font.id}
              content={RawJson(font)}
              showHref={{ path: 'materials' }}
            />
          </Space>
          {font.bytes && (
            <div style={{ fontSize: '90%', marginBottom: '0.2rem' }}>
              {FormatBytes(font.bytes)}
            </div>
          )}
        </div>
        <div style={{ flex: '1 1 auto', padding: '20px', width: '100%' }}>
          <Form
            form={form}
            colon={false}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
              }
            }}
            onValuesChange={checkIsModified}
            onFinish={onSubmit}
          >
            <Form.Item
              style={{ marginBottom: '1rem' }}
              name="title"
              label="Title"
              hasFeedback={true}
              rules={[{ required: true, message: 'Title cannot be empty.' }]}
              initialValue={font.title}
            >
              <Input bordered={props.editable} disabled={!props.editable} />
            </Form.Item>
            <Collapse
              bordered={false}
              items={[
                {
                  key: 'tags',
                  label: 'Tags',
                  children: (
                    <Form.Item
                      name="tags"
                      label="Tags"
                      initialValue={font.tags}
                    >
                      <TagEntries
                        layout="vertical"
                        disabled={!props.editable}
                      />
                    </Form.Item>
                  ),
                },
              ]}
            />
            <Form.Item
              label="Free"
              name="isFree"
              valuePropName="checked"
              initialValue={font.isFree}
              wrapperCol={{ span: 5 }}
            >
              <Checkbox disabled={!props.editable} />
            </Form.Item>
            {props.editable && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                  }}
                >
                  <Button
                    type="primary"
                    style={{ marginBottom: '1em' }}
                    onClick={() => setModalOpen(true)}
                    disabled={font.thumbnailUrl === undefined}
                  >
                    Change Thumbnail
                  </Button>
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                  }}
                >
                  <Form.Item style={{ marginRight: '1em' }}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      disabled={!isModified}
                      loading={isLoading}
                    >
                      Save changes
                    </Button>
                  </Form.Item>
                  <Form.Item>
                    <Popconfirm
                      title="Are you sure to DELETE？"
                      icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                      onConfirm={onRemove}
                    >
                      <Button type="primary" danger>
                        Delete
                      </Button>
                    </Popconfirm>
                  </Form.Item>
                </div>
              </div>
            )}
          </Form>
        </div>
      </div>
    </React.Fragment>
  );
}

const exports = FontView;
export default exports;
