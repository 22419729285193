import { Checkbox, DatePicker, Result, Spin, Statistic } from 'antd';
import { SubTitle } from 'components/PageTitles';
import dayjs, { Dayjs } from 'dayjs';
import { useEffect, useRef, useState } from 'react';
import { dateStringFormat, range, rangePresets } from '../Main';
import { Grapeseed } from 'api/grapeseed';
import { UserCreatedList } from './UserCreatedList';
import { User } from 'types/user';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import componentStyle from 'styles/common.module.css';

export interface UserCreated {
  user?: User;
  created: number;
}
interface Summary {
  start: Date;
  end: Date;
  created: number;
  byUser: Map<string, UserCreated>;
}

export function CreatedPacks() {
  const lastday = dayjs().subtract(1, 'd');
  const [range, setRange] = useState<range>({
    start: lastday.subtract(6, 'd'),
    end: lastday,
  });
  const excludeKR = useRef<boolean>(true);
  const summary = useRef<Summary>();
  const [total, setTotal] = useState<number>(0);
  const [list, setList] = useState<[string, UserCreated][]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onRangeChange = (dates: null | (any | null)[]) => {
    if (dates && dates[0] && dates[1]) {
      setRange({
        start: dates[0] as Dayjs,
        end: dates[1] as Dayjs,
      });
    }
  };

  function renderLoading() {
    return (
      <Result
        icon={<Spin size="large" />}
        title="Working hard, please wait a moment..."
      />
    );
  }

  const excludingIds = ['4mB4yGObV4wj5', 'eeqJkzp9yg6np'];
  const setListAndTotal = (summary: Summary) => {
    let list = summary.byUser
      ? Array.from(summary.byUser).filter((value: [string, UserCreated]) => {
          if (value[1].user) {
            return !excludingIds.includes(value[1].user.id);
          }
          return true;
        })
      : [];
    if (excludeKR.current) {
      list = list.filter(
        (value: [string, UserCreated]) => value[1].user?.country !== 'KR'
      );
    }
    setTotal(list.reduce((sum, current) => sum + current[1].created, 0));
    setList(list);
  };

  const onChangeExcludeKR = (e: CheckboxChangeEvent) => {
    excludeKR.current = e.target.checked;
    if (summary.current) {
      setListAndTotal(summary.current);
    }
  };

  useEffect(() => {
    async function fetch() {
      const queryParams: Map<string, string> = new Map();
      queryParams.set('start', range.start.format(dateStringFormat));
      queryParams.set('end', range.end.format(dateStringFormat));
      setIsLoading(true);
      const result = await Grapeseed.GET('/api/stats/packs/created', {
        queryParams: queryParams,
      });
      if (result) {
        if (result.byUser) {
          result.byUser = new Map(Object.entries(result.byUser));
        } else {
          result.byUser = new Map();
        }
        summary.current = result;
        setListAndTotal(summary.current!);
      }
      setIsLoading(false);
    }
    fetch();
  }, [range.end, range.start]);

  const renderSummary = () => {
    if (summary) {
      return (
        <>
          <div
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Statistic
              title="Total Created"
              value={total}
              valueStyle={{ fontSize: '1.2 rem', fontWeight: 'bold' }}
            />
            <Checkbox checked={excludeKR.current} onChange={onChangeExcludeKR}>
              Exclude KR users
            </Checkbox>
          </div>
          <span
            className={componentStyle.hinttextOrange}
            style={{ marginTop: '2 rem' }}
          >
            * 임시로 아래 ID 들은 통계에서 제외하였습니다 *
            {excludingIds.map((id: string) => {
              let user = summary.current?.byUser.get(id)?.user;
              if (user) {
                return (
                  <pre>{`${id} (${user.username} / ${user.fullname})`}</pre>
                );
              }
              return '';
            })}
          </span>
          {<UserCreatedList list={list} />}
        </>
      );
    }
    return <></>;
  };

  return (
    <>
      <SubTitle>Created Packs</SubTitle>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <DatePicker.RangePicker
          style={{
            flex: '0 1 auto',
          }}
          value={[range.start, range.end]}
          presets={rangePresets}
          allowClear={false}
          onChange={onRangeChange}
          format={dateStringFormat}
        />
      </div>
      <div>{isLoading ? renderLoading() : renderSummary()}</div>
    </>
  );
}
