import { Button, Divider } from 'antd';
import viewStyle from 'styles/view.module.css';
import { ThumbnailView } from 'components/Thumbnail/ThumbnailView';
import { IdPopover } from 'components/IdPopover';
import RawJson from 'utils/RawJson';
import { TagEntries } from 'components/TagEntries';
import { StopOutlined } from '@ant-design/icons';
import { CSSProperties, useState } from 'react';
import { ThumbnailSizes } from 'components/Thumbnail/Constants';

export interface GiphyItem {
  tags: string[];
  id: string;
  url: string;
  thumbnailUrl: string;
}

export interface GiphyItemViewProps {
  background: GiphyItem;
  onChange: (s: GiphyItem) => void;
  onRemove: (id: string) => void;
  style?: CSSProperties;
}

export function GiphyItemView(props: GiphyItemViewProps) {
  const [background, setBackground] = useState<GiphyItem>(props.background);

  return (
    <div className={viewStyle.cardViewItem} style={{ ...props.style }}>
      {background.url && background.id && (
        <>
          <ThumbnailView
            preview={{ src: background.url }}
            src={background.thumbnailUrl}
            size={ThumbnailSizes.compact}
          />
          <Divider type="vertical" />
          <IdPopover
            id={background.id.slice(0, background.id.length - 4).trim()}
            content={RawJson(background)}
            ellipsis={{ suffix: `${background.id.slice(-4).trim()}` }}
            style={{ width: '120px' }}
          />
          <Divider type="vertical" />
        </>
      )}
      <div style={{ minWidth: 500 }}>{background.url}</div>
      {background.url && (
        <>
          <Divider type="vertical" />
          <TagEntries
            layout={'horizontal'}
            onChange={(t) => {
              let newOne = {
                tags: t,
                id: background.id,
                url: background.url,
                thumbnailUrl: background.thumbnailUrl,
              };
              setBackground(newOne);
              props.onChange(newOne);
            }}
          />
          <Divider type="vertical" />
          <Button
            size="small"
            danger
            onClick={() => props.onRemove(background.id)}
          >
            <StopOutlined />
          </Button>
        </>
      )}
    </div>
  );
}
