import { Identifiable } from './interfaces';
import { Content } from './material';

export interface Size {
  width: number;
  height: number;
}

export interface Color {
  red?: number;
  green?: number;
  blue?: number;
  alpha?: number;
}

export interface Transform {
  scaleX: number;
  scaleY: number;
  translateX: number;
  translateY: number;
  rotate: number;
  skewX: number;
  skewY: number;
  flipX: boolean;
  flipY: boolean;
}

export interface Canvas {
  version: number;
  type: string;
  size: Size;
  backgroundColor: Color;
  layers: Layer[];
  duration: number;
}

export interface Layer {
  content: Content;
  size: Size;
  transform: Transform;
  opacity: number;
  isTemplateLayer: boolean;
  isPlaceholder: boolean;
  isBackgroundLayer: boolean;
  maskSvg: string;
}

export interface Pattern {
  style: string;
  // TBD
}

export class Creator implements Identifiable {
  code: string;
  email: string;
  createdAt: Date;
  coverUrl?: string;
  name?: string;
  description?: string;
  profileUrl?: string;
  extraUrl?: string;
  updatedAt: Date;
  backgroundColor?: Color;
  foregroundColor?: Color;
  artworksRef?: string;

  constructor(props: {
    code: string;
    email: string;
    createdAt: Date;
    coverUrl?: string;
    name?: string;
    description?: string;
    profileUrl?: string;
    extraUrl?: string;
    updatedAt: Date;
    backgroundColor?: Color;
    foregroundColor?: Color;
    artworksRef?: string;
  }) {
    this.code = props.code;
    this.email = props.email;
    this.createdAt = props.createdAt;
    this.coverUrl = props.coverUrl;
    this.name = props.name;
    this.description = props.description;
    this.profileUrl = props.profileUrl;
    this.extraUrl = props.extraUrl;
    this.updatedAt = props.updatedAt;
    this.backgroundColor = props.backgroundColor;
    this.foregroundColor = props.foregroundColor;
    this.artworksRef = props.artworksRef;
  }

  get id() {
    return this.code;
  }
}

export const CreatorCoverAcceptTypes =
  'image/jpg,image/jpeg,image/png,video/mp4';
export const ContainerCreators = 'creators';
