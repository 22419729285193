import { useEffect } from 'react';

interface intersectionObserverProps {
  root: Element | Document | null;
  target: Element | null | undefined;
  onIntersect: IntersectionObserverCallback;
}

export function useIntersectionObserver(props: intersectionObserverProps) {
  useEffect(() => {
    const observer = new IntersectionObserver(props.onIntersect, {
      ...props,
      rootMargin: '0px',
      threshold: 0.1,
    });
    if (!props.target) {
      return;
    }
    observer.observe(props.target);

    return () => {
      observer.unobserve(props.target!);
    };
  }, [props]);
}

useIntersectionObserver.defaultProps = {
  rootMargin: '0px',
  thresholds: 1.0,
};
