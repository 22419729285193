import { Button, Descriptions, Popconfirm, Tag, Typography } from 'antd';
import { DragEvent, useState } from 'react';
import viewStyle from 'styles/view.module.css';
import { BundleItem, Bundle } from 'types/bundle';
import {
  EditOutlined,
  DeleteOutlined,
  QuestionCircleOutlined,
  ReloadOutlined,
} from '@ant-design/icons';
import { useNavigate } from 'react-router';
import { Grapeseed } from 'api/grapeseed';
import { ThumbnailView } from 'components/Thumbnail/ThumbnailView';
import RawJson from 'utils/RawJson';
import { getMediaResolution } from 'utils/media';
import { parseMDStyleLink } from 'utils/strings';
import { ThumbnailSizes } from 'components/Thumbnail/Constants';
import { IdPopover } from 'components/IdPopover';

interface BundleViewProps {
  bundle: Bundle;
  onRemove?: (id: string) => void;
  editable?: boolean;
  draggable?: boolean;
  onDragStart?: (e: DragEvent<HTMLDivElement>) => void;
  onDrop?: (e: DragEvent<HTMLDivElement>) => void;
}

export function BundleView(props: BundleViewProps) {
  const navigate = useNavigate();
  const [bundle, setBundle] = useState<Bundle>(props.bundle);

  const onRemove = async () => {
    const result = await Grapeseed.DELETE(`/api/bundles/${bundle.id}`);
    if (result) {
      props.onRemove?.(bundle.id);
    }
  };

  const updateItems = async (items: BundleItem[]) => {
    const newItems = items.map(async (item) => {
      const resource = await Grapeseed.GET(`/api${item.ref}`);
      if (resource) {
        if (resource.thumbnailUrl) {
          item.thumbnailUrl = resource.thumbnailUrl;
          try {
            item.thumbnailSize = await getMediaResolution(
              resource.thumbnailUrl
            );
          } catch {}
        }
        item.isFree = resource.isFree;
      }
      return item;
    });
    const promises = await Promise.allSettled(newItems);
    const fulfilled = promises.filter(
      (p) => p.status === 'fulfilled'
    ) as PromiseFulfilledResult<BundleItem>[];
    return fulfilled.map((f) => f.value);
  };

  const reload = async () => {
    const result = await Grapeseed.PUT(`/api/bundles/${bundle.id}`, {
      body: {
        id: bundle.id,
        title: bundle.title,
        description: bundle.description,
        createdAt: bundle.createdAt,
        items: await updateItems(bundle.items),
      },
    });
    if (result) {
      setBundle(result);
    }
  };

  let divClassName = viewStyle.viewitem;
  if (props.draggable) {
    divClassName += ` ${viewStyle.draggable}`;
  }

  return (
    <div
      className={divClassName}
      draggable={props.draggable}
      style={{ alignItems: 'flex-start', width: '100%' }}
      onDragStart={props.onDragStart}
      onDrop={props.onDrop}
      onDragOver={(e) => e.preventDefault()}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'stretch',
          alignContent: 'center',
          marginBottom: '0.6rem',
          width: '100%',
        }}
      >
        <div style={{ fontWeight: 'bold', fontSize: '1rem' }}>
          {bundle.isBanner && (
            <Tag color="var(--banner-badge-color)">Banner</Tag>
          )}
          {bundle.isRouteType && (
            <Tag color="var(--route-badge-color)">Route</Tag>
          )}
          {parseMDStyleLink(bundle.title)}
        </div>
        <div
          style={{
            marginLeft: 'auto',
          }}
        >
          {props.editable && !bundle.isRouteType && (
            <Popconfirm
              title="Thumbnail will be overwrited, continue?"
              icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
              onConfirm={reload}
            >
              <Button type="link" icon={<ReloadOutlined />} />
            </Popconfirm>
          )}
          {props.editable && (
            <>
              <Button
                type="link"
                icon={<EditOutlined />}
                onClick={() =>
                  navigate('/bundles/new', {
                    state: { bundle: bundle },
                  })
                }
              />
              <Popconfirm
                title="Are you sure to DELETE？"
                icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                onConfirm={onRemove}
              >
                <Button type="text" icon={<DeleteOutlined />} danger />
              </Popconfirm>
            </>
          )}
        </div>
      </div>
      <Descriptions column={1}>
        <Descriptions.Item label="ID">
          <IdPopover id={bundle.id} content={RawJson(bundle)} />
        </Descriptions.Item>
        <Descriptions.Item label="Description">
          {bundle.description}
        </Descriptions.Item>
        <Descriptions.Item>
          <Typography.Paragraph
            type="secondary"
            italic
            copyable={{ text: `/bundles/${bundle.id}` }}
            style={{ marginBottom: 0 }}
          >
            Copy ref to this Bundle
          </Typography.Paragraph>
        </Descriptions.Item>
        {bundle.isRouteType && (
          <Descriptions.Item label="Route link">
            {bundle.items[0].ref}
          </Descriptions.Item>
        )}
      </Descriptions>
      <div
        className={viewStyle.xscroll}
        style={{
          padding: '5px',
          columnGap: '15px',
          display: 'flex',
          alignItems: 'center',
          width: '100%',
        }}
      >
        {bundle.isRouteType ? (
          <>
            <ThumbnailView
              size={ThumbnailSizes.compact}
              src={bundle.items[0].thumbnailUrl ?? ''}
              preview={false}
              isFree={bundle.items[0].isFree}
              svgFillColor={
                bundle.items[0].ref.split('?type=').pop() === 'placeholder'
                  ? 'var(--placeholder-fill-color)'
                  : undefined
              }
            />
          </>
        ) : (
          bundle.items.map((item, index) => {
            return (
              <a
                key={item.ref}
                href={item.ref}
                target="_blank"
                rel="noreferrer"
                draggable={false}
              >
                <ThumbnailView
                  size={ThumbnailSizes.compact}
                  src={item.thumbnailUrl ?? ''}
                  preview={false}
                  isFree={item.isFree}
                  svgFillColor={
                    item.ref.split('?type=').pop() === 'placeholder'
                      ? 'var(--placeholder-fill-color)'
                      : undefined
                  }
                />
              </a>
            );
          })
        )}
      </div>
    </div>
  );
}
