import React, { DragEvent, useState } from 'react';
import { Material } from 'types/interfaces';
import { MaterialText, NewMaterial } from 'types/material';
import viewStyle from 'styles/view.module.css';
import { Badge, Button, Form, Input, Popconfirm, Space } from 'antd';
import RawJson from 'utils/RawJson';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { useForm } from 'antd/lib/form/Form';
import { HaveSameContents } from 'utils/utils';
import { TagEntries } from 'components/TagEntries';
import { Grapeseed } from 'api/grapeseed';
import { IdPopover } from 'components/IdPopover';

interface TextViewProps {
  text: MaterialText;
  editable?: boolean;
  draggable?: boolean;
  onDragStart?: (e: DragEvent<HTMLDivElement>, m: Material) => void;
  onRemove?: (id: string) => void;
}

export function TextView(props: TextViewProps) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isModified, setIsModified] = useState<boolean>(false);
  const [form] = useForm();
  const [text, setText] = useState<MaterialText>(props.text);

  const onSubmit = async (values: any) => {
    setIsLoading(true);
    const result = await Grapeseed.PUT(`/api/texts/${text.id}`, {
      body: {
        tags: values.tags ?? text.tags,
        text: values.text.trim(),
      },
      fallback: () => {
        form.setFieldsValue({ tags: text.tags });
      },
    });
    if (result) {
      setText(NewMaterial(result) as MaterialText);
    }
    setIsLoading(false);
    setIsModified(false);
  };

  const onRemove = async (event?: React.MouseEvent<HTMLElement>) => {
    const result = await Grapeseed.DELETE(`/api/texts/${text.id}`);
    if (result) {
      props.onRemove?.(text.id);
    }
  };

  const checkIsModified = (_: any, values: any) => {
    if (
      !HaveSameContents(values.tags, text.tags) ||
      values.text !== text.text
    ) {
      setIsModified(true);
    } else {
      setIsModified(false);
    }
  };

  let divClass = `${viewStyle.viewitem} ${viewStyle.full}`;
  if (props.draggable) {
    divClass = `${viewStyle.viewitem} ${viewStyle.draggable}`;
  }

  return (
    <React.Fragment>
      <div
        className={divClass}
        draggable={props.draggable}
        onDragStart={(e) => props.onDragStart?.(e, text)}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'stretch',
            padding: '15px 15px 0 15px',
          }}
        >
          <Badge
            count={text.picked ?? 0}
            showZero
            color="var(--text-badge-color)"
          >
            <Form
              form={form}
              colon={false}
              onValuesChange={checkIsModified}
              onFinish={onSubmit}
              style={{ width: '100%', marginBottom: '-1rem' }}
            >
              <Form.Item name="text" initialValue={text.text} required>
                <Input.TextArea rows={8} disabled={!props.editable} />
              </Form.Item>
            </Form>
          </Badge>
          <Space style={{ fontSize: '90%' }}>
            <Badge color="var(--text-badge-color)" />
            <IdPopover
              id={text.id}
              content={RawJson(text)}
              showHref={{ path: 'materials' }}
            />
          </Space>
        </div>
        <div style={{ flex: '1 1 auto', padding: '20px' }}>
          <Form
            form={form}
            colon={false}
            onValuesChange={checkIsModified}
            onFinish={onSubmit}
          >
            <Form.Item name="tags" label="Tags" initialValue={text.tags}>
              <TagEntries layout="vertical" disabled={!props.editable} />
            </Form.Item>
            {props.editable && (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}
              >
                <Form.Item style={{ marginRight: '1em' }}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    disabled={!isModified}
                    loading={isLoading}
                  >
                    Save changes
                  </Button>
                </Form.Item>
                <Form.Item>
                  <Popconfirm
                    title="Are you sure to DELETE？"
                    icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                    onConfirm={onRemove}
                  >
                    <Button type="primary" danger>
                      Delete
                    </Button>
                  </Popconfirm>
                </Form.Item>
              </div>
            )}
          </Form>
        </div>
      </div>
    </React.Fragment>
  );
}
