import viewStyle from 'styles/view.module.css';
import { MutableRefObject } from 'react';
import { Spin } from 'antd';
import { Material } from 'types/interfaces';
import { MaterialView } from './MaterialView';
import { DictElement } from 'pages/Packs/components/PreviewByDict';

interface MaterialDictProps {
  className?: string;
  dict: DictElement[];
  itemEditable: boolean;
  itemDraggable: boolean;
  itemSize: 'compact' | 'full';
  onRemove?: (id: string) => void;
  onItemDoubleClick?: (m: Material) => void;
  lastItemRef?: MutableRefObject<any>;
  hasMore?: boolean;
}

export const MaterialDict = (props: MaterialDictProps) => {
  let width = '240px';
  let height = '480px';
  switch (props.itemSize) {
    case 'compact':
      width = '80px';
      height = '80px';
      break;
  }

  return (
    <div className={props.className}>
      {props.dict.map((elem) => {
        return (
          elem.material && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <span style={{ fontWeight: 'bold' }}>{elem.key}</span>
              <MaterialView
                key={elem.material.id}
                editable={props.itemEditable}
                draggable={props.itemDraggable}
                size={props.itemSize}
                material={elem.material}
                onRemove={props.onRemove}
              />
            </div>
          )
        );
      })}
      {props.hasMore && props.lastItemRef && (
        <div
          key={'materialListLastItmeRef'}
          ref={props.lastItemRef}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: `${width}`,
            height: `${height}`,
            margin: '1rem',
          }}
        >
          <Spin />
        </div>
      )}
    </div>
  );
};

MaterialDict.defaultProps = {
  className: `${viewStyle.viewbox} ${viewStyle.wrap}`,
  itemSize: 'full',
  itemEditable: false,
  itemDraggable: false,
};
