import { Checkbox } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { CheckboxValueType } from 'antd/es/checkbox/Group';
import { useState } from 'react';

export interface CheckboxGroupProps {
  options: string[];
  onChange: (values: string[]) => void;
  disabled?: boolean;
}

export function CheckboxGroup(props: CheckboxGroupProps) {
  const [values, setValues] = useState<CheckboxValueType[]>([]);
  const [options] = useState<string[]>(props.options);
  const [indeterminate, setIndeterminate] = useState<boolean>(false);
  const [checkAll, setCheckAll] = useState<boolean>(false);

  const onChange = (list: CheckboxValueType[]) => {
    setValues(list);
    setIndeterminate(!!list.length && list.length < options.length);
    setCheckAll(list.length === options.length);
    props.onChange(list as string[]);
  };

  const onCheckAllChange = (e: CheckboxChangeEvent) => {
    const checked = e.target.checked ? options : [];
    setValues(checked);
    setIndeterminate(false);
    setCheckAll(e.target.checked);
    props.onChange(checked as string[]);
  };

  return (
    <>
      <Checkbox
        indeterminate={indeterminate}
        onChange={onCheckAllChange}
        checked={checkAll}
        disabled={props.disabled}
      >
        All
      </Checkbox>
      <Checkbox.Group
        options={options}
        value={values}
        onChange={onChange}
        disabled={props.disabled}
      ></Checkbox.Group>
    </>
  );
}
