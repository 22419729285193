import { DatePicker, Result, Spin } from 'antd';
import { SubTitle } from 'components/PageTitles';
import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import { Key, useEffect, useState } from 'react';
import { KeywordStat } from 'types/statistics';
import { Table } from 'antd';

import weekday from 'dayjs/plugin/weekday';
import localeData from 'dayjs/plugin/localeData';
import { ColumnsType } from 'antd/es/table';
import { Grapeseed } from 'api/grapeseed';
import { dateStringFormat, range, rangePresets } from './Main';
dayjs.extend(weekday);
dayjs.extend(localeData);

interface typeRowType {
  key: React.Key;
  name: string;
  count: number;
  userCount: number;
}

interface fromRowType {
  key: React.Key;
  source: string;
  count: number;
  type: typeRowType[];
}

interface statRowType {
  key: React.Key;
  keyword: string;
  count: number;
  from: fromRowType[];
}

export function Keywords() {
  const lastday = dayjs().subtract(1, 'd');
  const [range, setRange] = useState<range>({
    start: lastday.subtract(6, 'd'),
    end: lastday,
  });
  const [stats, setStats] = useState<KeywordStat[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    async function fetchStats() {
      const queryParams: Map<string, string> = new Map();
      queryParams.set('start', range.start.format(dateStringFormat));
      queryParams.set('end', range.end.format(dateStringFormat));
      setIsLoading(true);
      const result = await Grapeseed.GET('/api/stats/keywords', {
        queryParams: queryParams,
      });
      if (result) {
        setStats(result);
      }
      setIsLoading(false);
    }
    fetchStats();
  }, [range.start, range.end]);

  const onRangeChange = (dates: null | (any | null)[]) => {
    if (dates && dates[0] && dates[1]) {
      setRange({
        start: dates[0] as Dayjs,
        end: dates[1] as Dayjs,
      });
    }
  };

  const statColumns: ColumnsType<statRowType> = [
    {
      title: 'Keyword',
      dataIndex: 'keyword',
      width: 5,
      filters: stats.map((st: KeywordStat) => {
        return { text: st.keyword!, value: st.keyword };
      }),
      filterSearch: true,
      onFilter: (value: Key | boolean, record: statRowType) =>
        record.keyword.startsWith(value.toString()),
    },
    {
      title: 'Count',
      dataIndex: 'count',
      width: 3,
      sorter: {
        compare: (a: statRowType, b: statRowType) => a.count - b.count,
      },
    },
  ];

  const keywordFromRender = (record: statRowType) => {
    const keywordFromColumns: ColumnsType<fromRowType> = [
      { title: 'From', dataIndex: 'source' },
      { title: 'Count', dataIndex: 'count' },
    ];
    return (
      <Table
        columns={keywordFromColumns}
        dataSource={record.from}
        pagination={false}
        expandable={{ expandedRowRender: keywordTypeRender }}
      />
    );
  };

  const keywordTypeRender = (record: fromRowType) => {
    const usageColumns: ColumnsType<typeRowType> = [
      { title: 'Type', dataIndex: 'name' },
      { title: 'Count', dataIndex: 'count' },
      { title: 'UVs', dataIndex: 'userCount' },
    ];
    return (
      <Table
        columns={usageColumns}
        dataSource={record.type}
        pagination={false}
      />
    );
  };

  function renderStatTable() {
    return (
      <Table
        style={{ marginTop: '10px' }}
        size="small"
        pagination={{ defaultPageSize: 20 }}
        columns={statColumns}
        expandable={{ expandedRowRender: keywordFromRender }}
        dataSource={stats.map((stat) => {
          return {
            key: stat.keyword,
            keyword: stat.keyword,
            count: stat.count,
            from: stat.from.map((f) => {
              return {
                key: f.source,
                source: f.source,
                count: f.count,
                type: f.type.map((t) => {
                  return {
                    key: t.name ?? '(null)',
                    name: t.name ?? '(null)',
                    count: t.count,
                    userCount: t.users.length,
                  };
                }),
              };
            }),
          };
        })}
      />
    );
  }

  function renderLoading() {
    return (
      <Result
        icon={<Spin size="large" />}
        title="Working hard, please wait a moment..."
      />
    );
  }

  return (
    <>
      <SubTitle>Search Keywords</SubTitle>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <DatePicker.RangePicker
          style={{
            flex: '0 1 auto',
          }}
          value={[range.start, range.end]}
          presets={rangePresets}
          allowClear={false}
          onChange={onRangeChange}
          format={dateStringFormat}
        />
      </div>
      <div>{isLoading ? renderLoading() : renderStatTable()}</div>
    </>
  );
}
