import { Modal } from 'antd';
import { Grapeseed } from 'api/grapeseed';
import { Retriver } from 'api/retriver/retriver';
import React, {
  createContext,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useLocation, useNavigate } from 'react-router';

const tokenExpireSeconds = 30 * 60;

export type AuthState = {
  id?: string;
  roles?: string[];
};

export const AuthContext = createContext<{
  authState: AuthState | undefined;
  login: (credential: string | undefined) => void;
  logout: () => void;
}>({
  authState: {},
  login: (credential: string | undefined) => {},
  logout: () => {},
});

function urlBase64Decode(str: string) {
  let output = str.replace(/-/g, '+').replace(/_/g, '/');
  switch (output.length % 4) {
    case 0:
      break;
    case 2:
      output += '==';
      break;
    case 3:
      output += '=';
      break;
    default:
      // eslint-disable-next-line no-throw-literal
      throw new Error('illegal base64url string');
  }
  return decodeURIComponent(window.atob(output));
}

function decodeToken(token: string) {
  if (!token) {
    return undefined;
  }
  const parts = token.split('.');
  if (parts.length !== 3) {
    throw new Error('illegal token format');
  }
  const decoded = urlBase64Decode(parts[1]);
  if (!decoded) {
    throw new Error('could not decode the token');
  }
  return JSON.parse(decoded);
}

interface LocationState {
  from: {
    pathname: string;
  };
}

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [auth, setAuth] = useState<AuthState>();
  const timeoutId = useRef<NodeJS.Timeout>();
  const navigate = useNavigate();
  const location = useLocation();

  const login = async (credential: string | undefined) => {
    if (!credential) {
      return;
    }
    const result = await Grapeseed.POST('/api/users/login', {
      body: {
        social: {
          type: 'apple',
          apple: {
            idToken: credential,
          },
        },
      },
      fallback: (e) => {
        Modal.error({
          title: 'Auth Error',
          content: e.message,
          onOk: () => {
            navigate('/login');
          },
        });
      },
    });
    const decoded = decodeToken(result?.['token']);
    if (decoded) {
      setAuth({
        id: decoded['userid'],
        roles: decoded['roles'],
      });
      Grapeseed.setAuthToken(result['token']);
      Retriver.setAuthToken(result['token']);
      timeoutId.current = setTimeout(
        silentRefresh,
        (tokenExpireSeconds - 60) * 1000 // 1 minute before expiration
      );
      const loc = location.state as LocationState;
      if (loc) {
        navigate(loc.from.pathname);
      }
    }
  };

  const logout = () => {
    clearTimeout(timeoutId.current);
    Grapeseed.POST('/api/users/logout', {
      fallback: (e) => {
        console.log('error while log out, ', e);
      },
    });
    Grapeseed.setAuthToken('');
    Retriver.setAuthToken('');
    setAuth(undefined);
  };

  const silentRefresh = useCallback(async () => {
    clearTimeout(timeoutId.current);
    const result = await Grapeseed.GET('/api/users/refresh', {
      fallback: (e) => {
        console.log('failed to refresh token, ', e);
        navigate('/login');
      },
    });
    const decoded = decodeToken(result?.['token']);
    if (decoded) {
      setAuth({
        id: decoded['userid'],
        roles: decoded['roles'],
      });
      Grapeseed.setAuthToken(result['token']);
      Retriver.setAuthToken(result['token']);
      navigate(location.pathname);
      timeoutId.current = setTimeout(
        silentRefresh,
        (tokenExpireSeconds - 60 * 5) * 1000
      );
    } else {
      logout();
    }
  }, [location, navigate]);

  useEffect(() => {
    silentRefresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthContext.Provider
      value={{ authState: auth, login: login, logout: logout }}
    >
      {children}
    </AuthContext.Provider>
  );
}
