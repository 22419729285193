import {
  CaretRightOutlined,
  DeliveredProcedureOutlined,
} from '@ant-design/icons';
import { Card, Divider } from 'antd';
import { ImageProcessing } from 'types/proto/retriver-struct_pb';
import { proto3 } from '@bufbuild/protobuf';
import {
  ImageProcess,
  ImageProcess_ProcessType,
} from 'types/proto/retriver-image-process_pb';
import RawJson from 'utils/RawJson';

export interface ImageProcessingViewProps {
  processing: ImageProcessing | undefined;
}

export function ImageProcessingView(props: ImageProcessingViewProps) {
  const processes = props.processing?.imageProcesses;

  return (
    <div>
      <Divider orientation="left">
        <DeliveredProcedureOutlined style={{ marginRight: '0.4rem' }} />
        Image Processing
      </Divider>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {processes?.map((p: ImageProcess, i: number) => (
          <div
            key={p.type + i.toString()}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <ProcessView process={p} />
            {i !== processes.length - 1 ? (
              <CaretRightOutlined style={{ margin: '0 0.4rem' }} />
            ) : null}
          </div>
        ))}
      </div>
    </div>
  );
}

interface ProcessViewProps {
  process: ImageProcess;
}

function ProcessView(props: ProcessViewProps) {
  const filterTypeEnums = proto3.getEnumType(ImageProcess_ProcessType);

  return (
    <Card
      title={`${filterTypeEnums
        .findNumber(props.process.type)
        ?.name.replace('TYPE_', '')}`}
      size="small"
    >
      {RawJson(props.process.params?.value?.toJson())}
    </Card>
  );
}
