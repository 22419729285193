import React, { useContext } from 'react';
import { Navigate, useLocation } from 'react-router';
import { AuthContext } from './AuthProvider';

export function RequireAuth(props: {
  allowed?: string;
  children: React.ReactNode;
}) {
  const { authState } = useContext(AuthContext);
  const location = useLocation();

  if (
    authState?.id &&
    authState?.roles &&
    (authState?.roles?.includes('admin') ||
      (props.allowed && authState?.roles?.includes(props.allowed)))
  ) {
    return <React.Fragment>{props.children}</React.Fragment>;
  } else {
    return <Navigate to={'/login'} replace state={{ from: location }} />;
  }
}
