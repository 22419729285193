import React, { DragEvent, useState } from 'react';
import {
  Badge,
  Button,
  Checkbox,
  Collapse,
  Form,
  Modal,
  Popconfirm,
  Select,
  Space,
} from 'antd';
import {
  Background,
  ContainerBackgrounds,
  NewMaterial,
  ThumbnailAcceptTypes,
  ThumbnailFileSuffix,
} from 'types/material';
import { TagEntries } from 'components/TagEntries';
import viewStyle from 'styles/view.module.css';
import { FormatBytes, HaveSameContents } from 'utils/utils';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Grapeseed } from 'api/grapeseed';
import { useForm } from 'antd/lib/form/Form';
import RawJson from '../../utils/RawJson';
import { FileUploader, acceptTypesInfo } from 'components/FileUploader';
import { ThumbnailView } from 'components/Thumbnail/ThumbnailView';
import { Material } from 'types/interfaces';
import { ThumbnailSizes } from 'components/Thumbnail/Constants';
import { IdPopover } from 'components/IdPopover';

interface BackgroundViewProps {
  background: Background;
  editable?: boolean;
  draggable?: boolean;
  onDragStart?: (e: DragEvent<HTMLDivElement>, m: Material) => void;
  onRemove?: (id: string) => void;
}

function BackgroundView(props: BackgroundViewProps) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isModified, setIsModified] = useState<boolean>(false);
  const [form] = useForm();
  const [modalForm] = useForm();
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [background, setBackground] = useState<Background>(props.background);

  const onSubmit = async (values: any) => {
    normalizeInputs(values);
    setIsLoading(true);
    const result = await Grapeseed.PUT(`/api/backgrounds/${background.id}`, {
      body: {
        tags: values.tags ?? background.tags,
        pattern: values.pattern,
        thumbnailUrl: background.thumbnailUrl,
        isFree: values.isFree ?? false,
      },
      fallback: () => {
        form.setFieldsValue({
          tags: background.tags,
          patternStyle: background.pattern?.style,
        });
      },
    });
    if (result) {
      setBackground(NewMaterial(result) as Background);
    }
    setIsLoading(false);
    setIsModified(false);
  };

  const onRemove = async (event?: React.MouseEvent<HTMLElement>) => {
    const result = await Grapeseed.DELETE(`/api/backgrounds/${background.id}`);
    if (result) {
      props.onRemove?.(background.id);
    }
  };

  const handleModalFormSubmit = async (values: any) => {
    setModalOpen(false);
    modalForm.resetFields();
    const result = await Grapeseed.PUT(`/api/backgrounds/${background.id}`, {
      body: {
        tags: background.tags,
        pattern: background.pattern,
        thumbnailUrl: values.thumbnail[0].url,
        isFree: background.isFree,
      },
    });
    if (result) {
      setBackground(result);
    }
  };

  const normalizeInputs = (values: any) => {
    if (values.patternStyle) {
      values.pattern = { style: values.patternStyle };
    }
  };

  const checkIsModified = (_: any, values: any) => {
    normalizeInputs(values);
    if (
      values.pattern?.style !== background.pattern?.style ||
      !HaveSameContents(values.tags, background.tags) ||
      values.isFree !== background.isFree
    ) {
      setIsModified(true);
    } else {
      setIsModified(false);
    }
  };

  let divClass = `${viewStyle.viewitem} ${viewStyle.full}`;
  if (props.draggable) {
    divClass = `${viewStyle.viewitem} ${viewStyle.draggable}`;
  }
  return (
    <React.Fragment>
      <Modal
        open={modalOpen}
        title="Change Thumbnail"
        footer={[
          <Button
            key="cancel"
            onClick={() => {
              modalForm.resetFields();
              setModalOpen(false);
            }}
          >
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={() => {
              modalForm.validateFields().then((values) => {
                handleModalFormSubmit(values);
              });
            }}
          >
            Submit
          </Button>,
        ]}
      >
        <Form form={modalForm} layout="horizontal">
          <Form.Item
            name="thumbnail"
            label="Thumbnail"
            extra={acceptTypesInfo(ThumbnailAcceptTypes)}
            rules={[{ required: true, message: 'Thumbnail is required' }]}
          >
            <FileUploader
              container={ContainerBackgrounds}
              accept={ThumbnailAcceptTypes}
              filename={background.id}
              suffix={ThumbnailFileSuffix}
              direction="column"
            />
          </Form.Item>
        </Form>
      </Modal>
      <div
        className={divClass}
        draggable={props.draggable}
        onDragStart={(e) => props.onDragStart?.(e, background)}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'stretch',
            padding: '15px 15px 0 15px',
          }}
        >
          <Badge
            count={background.picked ?? 0}
            showZero
            color="var(--background-badge-color)"
          >
            <ThumbnailView
              size={ThumbnailSizes.full}
              src={background.thumbnailUrl}
              preview={
                background.content ? { src: background.content.url } : false
              }
              isFree={background.isFree}
              style={{ marginBottom: '0.5rem' }}
            />
          </Badge>
          <Space style={{ fontSize: '90%' }}>
            <Badge color="var(--background-badge-color)" />
            <IdPopover
              id={background.id}
              content={RawJson(background)}
              showHref={{ path: 'materials' }}
            />
          </Space>
          {background.content && (
            <React.Fragment>
              <div
                style={{
                  fontSize: '90%',
                  fontWeight: 'bold',
                  marginBottom: '0.2rem',
                }}
              >
                {background.content.type}
              </div>
              <div style={{ fontSize: '90%' }}>
                {background.content.size.width} x{' '}
                {background.content.size.height} (
                {FormatBytes(background.bytes)})
              </div>
            </React.Fragment>
          )}
        </div>
        <div style={{ flex: '1 1 auto', padding: '20px', width: '100%' }}>
          <Form
            form={form}
            colon={false}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
              }
            }}
            onValuesChange={checkIsModified}
            onFinish={onSubmit}
          >
            <Form.Item
              label="Pattern"
              name="patternStyle"
              initialValue={background.pattern?.style}
              style={{ marginBottom: '1rem' }}
            >
              <Select allowClear disabled={!props.editable}>
                <Select.Option value="tile">Tile</Select.Option>
                <Select.Option value="verticalTile">
                  Vertical Tile
                </Select.Option>
                <Select.Option value="horizontalTile">
                  Horizontal Tile
                </Select.Option>
                <Select.Option value="custom">Custom</Select.Option>
              </Select>
            </Form.Item>
            <Collapse
              bordered={false}
              items={[
                {
                  key: 'tags',
                  label: 'Tags',
                  children: (
                    <Form.Item
                      name="tags"
                      label="Tags"
                      initialValue={background.tags}
                    >
                      <TagEntries
                        layout="vertical"
                        disabled={!props.editable}
                      />
                    </Form.Item>
                  ),
                },
              ]}
            />
            <Form.Item
              label="Free"
              name="isFree"
              valuePropName="checked"
              initialValue={background.isFree}
              wrapperCol={{ span: 5 }}
            >
              <Checkbox disabled={!props.editable} />
            </Form.Item>
            {props.editable && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                  }}
                >
                  <Button
                    type="primary"
                    style={{ marginBottom: '1em' }}
                    onClick={() => setModalOpen(true)}
                  >
                    Change Thumbnail
                  </Button>
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                  }}
                >
                  <Form.Item style={{ marginRight: '1em' }}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      disabled={!isModified}
                      loading={isLoading}
                    >
                      Save changes
                    </Button>
                  </Form.Item>
                  <Form.Item>
                    <Popconfirm
                      title="Are you sure to DELETE？"
                      icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                      onConfirm={onRemove}
                    >
                      <Button type="primary" danger>
                        Delete
                      </Button>
                    </Popconfirm>
                  </Form.Item>
                </div>
              </div>
            )}
          </Form>
        </div>
      </div>
    </React.Fragment>
  );
}

const exports = BackgroundView;
export default exports;
