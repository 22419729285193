import { Grapeseed } from 'api/grapeseed';
import { SubTitle } from 'components/PageTitles';
import { useEffect, useRef, useState } from 'react';
import { InputForm } from './InputForm';
import { SuggestionPreview } from './SuggestionPreview';
import { useForm } from 'antd/es/form/Form';

export function TagsSuggested() {
  const localEditingTags = useRef<string[]>([]);
  const [tagsSuggested, setTagsSuggested] = useState<string[]>([]);
  const [syncing, setSyncing] = useState<boolean>(false);
  const [form] = useForm();

  useEffect(() => {
    async function fetch() {
      const result = await Grapeseed.GET('/api/configs/tags/suggestions');
      if (result && result.tags) {
        console.log('fetch from server: ', result.tags);
        setTagsSuggested(result.tags);
      }
    }
    fetch();
  }, []);

  const onAdd = async (tag: string) => {
    localEditingTags.current = [...new Set([...tagsSuggested, tag])];
    setSyncing(true);
    const result = await Grapeseed.PUT('/api/configs/tags/suggestions', {
      body: { tags: localEditingTags.current },
    });
    if (result && result.tags) {
      setTagsSuggested(result.tags);
      form.setFieldValue('tagSearch', '');
    }
    setSyncing(false);
  };

  const onDelete = async (tag: string) => {
    localEditingTags.current = tagsSuggested.filter((v) => v !== tag);
    setSyncing(true);
    const result = await Grapeseed.PUT('/api/configs/tags/suggestions', {
      body: { tags: localEditingTags.current },
    });
    if (result && result.tags) {
      setTagsSuggested(result.tags);
    }
    setSyncing(false);
  };

  return (
    <>
      <SubTitle>Suggested Tags</SubTitle>
      <hr style={{ borderTop: 'grey' }} />
      <InputForm form={form} loading={syncing} onAdd={onAdd} />
      <div style={{ marginTop: '2rem' }}>
        {tagsSuggested?.map((t: string) => (
          <SuggestionPreview key={t} tag={t} onDelete={onDelete} />
        ))}
      </div>
    </>
  );
}
