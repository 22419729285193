// @generated by protoc-gen-es v1.10.0 with parameter "target=ts,import_extension=.ts"
// @generated from file retriver-app-retrica.proto (package retrica, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";
import { ErrorCode, SnsType, UserRole } from "./retriver-enum_pb.ts";
import { Account, Friend, FriendsWrap } from "./retriver-struct_pb.ts";

/**
 * /1/re/signup
 * 가입할 때 사용한다.
 *
 * @generated from message retrica.SignupRequest
 */
export class SignupRequest extends Message<SignupRequest> {
  /**
   * @generated from field: string email = 4;
   */
  email = "";

  /**
   * @generated from field: string username = 5;
   */
  username = "";

  /**
   * @generated from field: string password = 6;
   */
  password = "";

  /**
   * @generated from field: string push_token = 7;
   */
  pushToken = "";

  /**
   * DEPRECATED
   *
   * @generated from field: string DEPRECATED_auth_id = 1;
   */
  DEPRECATEDAuthId = "";

  /**
   * @generated from field: string DEPRECATED_phone_number = 2;
   */
  DEPRECATEDPhoneNumber = "";

  /**
   * @generated from field: string DEPRECATED_country_code = 3;
   */
  DEPRECATEDCountryCode = "";

  /**
   * @generated from field: string DEPRECATED_device_model = 8;
   */
  DEPRECATEDDeviceModel = "";

  constructor(data?: PartialMessage<SignupRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.SignupRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 4, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "username", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "password", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "push_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 1, name: "DEPRECATED_auth_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "DEPRECATED_phone_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "DEPRECATED_country_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "DEPRECATED_device_model", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SignupRequest {
    return new SignupRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SignupRequest {
    return new SignupRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SignupRequest {
    return new SignupRequest().fromJsonString(jsonString, options);
  }

  static equals(a: SignupRequest | PlainMessage<SignupRequest> | undefined, b: SignupRequest | PlainMessage<SignupRequest> | undefined): boolean {
    return proto3.util.equals(SignupRequest, a, b);
  }
}

/**
 * @generated from message retrica.SignupResponse
 */
export class SignupResponse extends Message<SignupResponse> {
  /**
   * SUCCESS
   * INVALID_EMAIL, INVALID_PASSWORD, INVALID_USENAME, USERNAME_ALREADY_TAKEN
   *
   * @generated from field: retrica.ErrorCode error_code = 1;
   */
  errorCode = ErrorCode.UNKNOWN;

  /**
   * @generated from field: retrica.Account account = 2;
   */
  account?: Account;

  constructor(data?: PartialMessage<SignupResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.SignupResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "error_code", kind: "enum", T: proto3.getEnumType(ErrorCode) },
    { no: 2, name: "account", kind: "message", T: Account },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SignupResponse {
    return new SignupResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SignupResponse {
    return new SignupResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SignupResponse {
    return new SignupResponse().fromJsonString(jsonString, options);
  }

  static equals(a: SignupResponse | PlainMessage<SignupResponse> | undefined, b: SignupResponse | PlainMessage<SignupResponse> | undefined): boolean {
    return proto3.util.equals(SignupResponse, a, b);
  }
}

/**
 * /1/re/login
 *
 * @generated from message retrica.LoginRequest
 */
export class LoginRequest extends Message<LoginRequest> {
  /**
   * @generated from field: string email = 1;
   */
  email = "";

  /**
   * @generated from field: string username = 2;
   */
  username = "";

  /**
   * @generated from field: string password = 3;
   */
  password = "";

  /**
   * @generated from field: string push_token = 4;
   */
  pushToken = "";

  /**
   * DEPRECATED
   *
   * @generated from field: string DEPRECATED_device_model = 5;
   */
  DEPRECATEDDeviceModel = "";

  constructor(data?: PartialMessage<LoginRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.LoginRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "username", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "password", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "push_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "DEPRECATED_device_model", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LoginRequest {
    return new LoginRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LoginRequest {
    return new LoginRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LoginRequest {
    return new LoginRequest().fromJsonString(jsonString, options);
  }

  static equals(a: LoginRequest | PlainMessage<LoginRequest> | undefined, b: LoginRequest | PlainMessage<LoginRequest> | undefined): boolean {
    return proto3.util.equals(LoginRequest, a, b);
  }
}

/**
 * @generated from message retrica.LoginResponse
 */
export class LoginResponse extends Message<LoginResponse> {
  /**
   * SUCCESS, PASSWORD_MISMATCH
   * NOT_FOUND: email/username로 사용자를 못 찾았을 때.
   *
   * @generated from field: retrica.ErrorCode error_code = 1;
   */
  errorCode = ErrorCode.UNKNOWN;

  /**
   * @generated from field: retrica.Account account = 2;
   */
  account?: Account;

  constructor(data?: PartialMessage<LoginResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.LoginResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "error_code", kind: "enum", T: proto3.getEnumType(ErrorCode) },
    { no: 2, name: "account", kind: "message", T: Account },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LoginResponse {
    return new LoginResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LoginResponse {
    return new LoginResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LoginResponse {
    return new LoginResponse().fromJsonString(jsonString, options);
  }

  static equals(a: LoginResponse | PlainMessage<LoginResponse> | undefined, b: LoginResponse | PlainMessage<LoginResponse> | undefined): boolean {
    return proto3.util.equals(LoginResponse, a, b);
  }
}

/**
 * /a2/re/logout
 *
 * @generated from message retrica.LogoutRequest
 */
export class LogoutRequest extends Message<LogoutRequest> {
  constructor(data?: PartialMessage<LogoutRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.LogoutRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LogoutRequest {
    return new LogoutRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LogoutRequest {
    return new LogoutRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LogoutRequest {
    return new LogoutRequest().fromJsonString(jsonString, options);
  }

  static equals(a: LogoutRequest | PlainMessage<LogoutRequest> | undefined, b: LogoutRequest | PlainMessage<LogoutRequest> | undefined): boolean {
    return proto3.util.equals(LogoutRequest, a, b);
  }
}

/**
 * @generated from message retrica.LogoutResponse
 */
export class LogoutResponse extends Message<LogoutResponse> {
  /**
   * SUCCESS
   *
   * @generated from field: retrica.ErrorCode error_code = 1;
   */
  errorCode = ErrorCode.UNKNOWN;

  constructor(data?: PartialMessage<LogoutResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.LogoutResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "error_code", kind: "enum", T: proto3.getEnumType(ErrorCode) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LogoutResponse {
    return new LogoutResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LogoutResponse {
    return new LogoutResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LogoutResponse {
    return new LogoutResponse().fromJsonString(jsonString, options);
  }

  static equals(a: LogoutResponse | PlainMessage<LogoutResponse> | undefined, b: LogoutResponse | PlainMessage<LogoutResponse> | undefined): boolean {
    return proto3.util.equals(LogoutResponse, a, b);
  }
}

/**
 * /a2/re/deleteAccount
 *
 * @generated from message retrica.DeleteAccountRequest
 */
export class DeleteAccountRequest extends Message<DeleteAccountRequest> {
  constructor(data?: PartialMessage<DeleteAccountRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.DeleteAccountRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeleteAccountRequest {
    return new DeleteAccountRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteAccountRequest {
    return new DeleteAccountRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeleteAccountRequest {
    return new DeleteAccountRequest().fromJsonString(jsonString, options);
  }

  static equals(a: DeleteAccountRequest | PlainMessage<DeleteAccountRequest> | undefined, b: DeleteAccountRequest | PlainMessage<DeleteAccountRequest> | undefined): boolean {
    return proto3.util.equals(DeleteAccountRequest, a, b);
  }
}

/**
 * @generated from message retrica.DeleteAccountResponse
 */
export class DeleteAccountResponse extends Message<DeleteAccountResponse> {
  /**
   * @generated from field: retrica.ErrorCode error_code = 1;
   */
  errorCode = ErrorCode.UNKNOWN;

  constructor(data?: PartialMessage<DeleteAccountResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.DeleteAccountResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "error_code", kind: "enum", T: proto3.getEnumType(ErrorCode) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeleteAccountResponse {
    return new DeleteAccountResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteAccountResponse {
    return new DeleteAccountResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeleteAccountResponse {
    return new DeleteAccountResponse().fromJsonString(jsonString, options);
  }

  static equals(a: DeleteAccountResponse | PlainMessage<DeleteAccountResponse> | undefined, b: DeleteAccountResponse | PlainMessage<DeleteAccountResponse> | undefined): boolean {
    return proto3.util.equals(DeleteAccountResponse, a, b);
  }
}

/**
 * /a2/re/phoneNumberConnect
 * 계정과 전화번호를 연결한다.
 *
 * @generated from message retrica.PhoneNumberConnectRequest
 */
export class PhoneNumberConnectRequest extends Message<PhoneNumberConnectRequest> {
  /**
   * sms 이용시
   *
   * @generated from field: string phone_number = 1;
   */
  phoneNumber = "";

  /**
   * @generated from field: string country = 2;
   */
  country = "";

  /**
   * @generated from field: string passcode = 3;
   */
  passcode = "";

  /**
   * Facebook Account Kit 이용시
   *
   * @generated from field: string facebook_authorization_code = 4;
   */
  facebookAuthorizationCode = "";

  constructor(data?: PartialMessage<PhoneNumberConnectRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.PhoneNumberConnectRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "phone_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "country", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "passcode", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "facebook_authorization_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PhoneNumberConnectRequest {
    return new PhoneNumberConnectRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PhoneNumberConnectRequest {
    return new PhoneNumberConnectRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PhoneNumberConnectRequest {
    return new PhoneNumberConnectRequest().fromJsonString(jsonString, options);
  }

  static equals(a: PhoneNumberConnectRequest | PlainMessage<PhoneNumberConnectRequest> | undefined, b: PhoneNumberConnectRequest | PlainMessage<PhoneNumberConnectRequest> | undefined): boolean {
    return proto3.util.equals(PhoneNumberConnectRequest, a, b);
  }
}

/**
 * @generated from message retrica.PhoneNumberConnectResponse
 */
export class PhoneNumberConnectResponse extends Message<PhoneNumberConnectResponse> {
  /**
   * Facebook Account Kit을 사용시 SUCCESS
   * SMS 사용시 SUCCESS, INVALID_PHONENUMBER, VERIFY_SMS_MANY_RETRY, VERIFY_SMS_PASSCODE_MISMATCH
   *
   * @generated from field: retrica.ErrorCode error_code = 1;
   */
  errorCode = ErrorCode.UNKNOWN;

  /**
   * @generated from field: string phone_number = 2;
   */
  phoneNumber = "";

  constructor(data?: PartialMessage<PhoneNumberConnectResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.PhoneNumberConnectResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "error_code", kind: "enum", T: proto3.getEnumType(ErrorCode) },
    { no: 2, name: "phone_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PhoneNumberConnectResponse {
    return new PhoneNumberConnectResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PhoneNumberConnectResponse {
    return new PhoneNumberConnectResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PhoneNumberConnectResponse {
    return new PhoneNumberConnectResponse().fromJsonString(jsonString, options);
  }

  static equals(a: PhoneNumberConnectResponse | PlainMessage<PhoneNumberConnectResponse> | undefined, b: PhoneNumberConnectResponse | PlainMessage<PhoneNumberConnectResponse> | undefined): boolean {
    return proto3.util.equals(PhoneNumberConnectResponse, a, b);
  }
}

/**
 * /1/re/snsLogin
 * 한방에 SNS 로그인 또는 가입 하는 API
 *
 * @generated from message retrica.SnsLoginRequest
 */
export class SnsLoginRequest extends Message<SnsLoginRequest> {
  /**
   * @generated from field: retrica.SnsType sns_type = 1;
   */
  snsType = SnsType.SST_NONE;

  /**
   * @generated from field: string access_token = 2;
   */
  accessToken = "";

  /**
   * @generated from field: string push_token = 3;
   */
  pushToken = "";

  constructor(data?: PartialMessage<SnsLoginRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.SnsLoginRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "sns_type", kind: "enum", T: proto3.getEnumType(SnsType) },
    { no: 2, name: "access_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "push_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SnsLoginRequest {
    return new SnsLoginRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SnsLoginRequest {
    return new SnsLoginRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SnsLoginRequest {
    return new SnsLoginRequest().fromJsonString(jsonString, options);
  }

  static equals(a: SnsLoginRequest | PlainMessage<SnsLoginRequest> | undefined, b: SnsLoginRequest | PlainMessage<SnsLoginRequest> | undefined): boolean {
    return proto3.util.equals(SnsLoginRequest, a, b);
  }
}

/**
 * @generated from message retrica.SnsLoginResponse
 */
export class SnsLoginResponse extends Message<SnsLoginResponse> {
  /**
   * @generated from field: retrica.ErrorCode error_code = 1;
   */
  errorCode = ErrorCode.UNKNOWN;

  /**
   * @generated from field: retrica.Account account = 2;
   */
  account?: Account;

  constructor(data?: PartialMessage<SnsLoginResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.SnsLoginResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "error_code", kind: "enum", T: proto3.getEnumType(ErrorCode) },
    { no: 2, name: "account", kind: "message", T: Account },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SnsLoginResponse {
    return new SnsLoginResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SnsLoginResponse {
    return new SnsLoginResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SnsLoginResponse {
    return new SnsLoginResponse().fromJsonString(jsonString, options);
  }

  static equals(a: SnsLoginResponse | PlainMessage<SnsLoginResponse> | undefined, b: SnsLoginResponse | PlainMessage<SnsLoginResponse> | undefined): boolean {
    return proto3.util.equals(SnsLoginResponse, a, b);
  }
}

/**
 * /a2/re/firebaseCustomToken
 * firebase 용 custom token을 내려 준다.
 *
 * @generated from message retrica.FirebaseCustomTokenRequest
 */
export class FirebaseCustomTokenRequest extends Message<FirebaseCustomTokenRequest> {
  constructor(data?: PartialMessage<FirebaseCustomTokenRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.FirebaseCustomTokenRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FirebaseCustomTokenRequest {
    return new FirebaseCustomTokenRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FirebaseCustomTokenRequest {
    return new FirebaseCustomTokenRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FirebaseCustomTokenRequest {
    return new FirebaseCustomTokenRequest().fromJsonString(jsonString, options);
  }

  static equals(a: FirebaseCustomTokenRequest | PlainMessage<FirebaseCustomTokenRequest> | undefined, b: FirebaseCustomTokenRequest | PlainMessage<FirebaseCustomTokenRequest> | undefined): boolean {
    return proto3.util.equals(FirebaseCustomTokenRequest, a, b);
  }
}

/**
 * @generated from message retrica.FirebaseCustomTokenResponse
 */
export class FirebaseCustomTokenResponse extends Message<FirebaseCustomTokenResponse> {
  /**
   * @generated from field: retrica.ErrorCode error_code = 1;
   */
  errorCode = ErrorCode.UNKNOWN;

  /**
   * @generated from field: string custom_token = 2;
   */
  customToken = "";

  constructor(data?: PartialMessage<FirebaseCustomTokenResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.FirebaseCustomTokenResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "error_code", kind: "enum", T: proto3.getEnumType(ErrorCode) },
    { no: 2, name: "custom_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FirebaseCustomTokenResponse {
    return new FirebaseCustomTokenResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FirebaseCustomTokenResponse {
    return new FirebaseCustomTokenResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FirebaseCustomTokenResponse {
    return new FirebaseCustomTokenResponse().fromJsonString(jsonString, options);
  }

  static equals(a: FirebaseCustomTokenResponse | PlainMessage<FirebaseCustomTokenResponse> | undefined, b: FirebaseCustomTokenResponse | PlainMessage<FirebaseCustomTokenResponse> | undefined): boolean {
    return proto3.util.equals(FirebaseCustomTokenResponse, a, b);
  }
}

/**
 * /a2/re/firebaseCreateChatRoom
 * firebase 용 chat room 생성 API
 *
 * @generated from message retrica.FirebaseCreateChatRoomRequest
 */
export class FirebaseCreateChatRoomRequest extends Message<FirebaseCreateChatRoomRequest> {
  /**
   * 유저 ID(챗 할 대상)
   *
   * @generated from field: string user_id = 1;
   */
  userId = "";

  constructor(data?: PartialMessage<FirebaseCreateChatRoomRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.FirebaseCreateChatRoomRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FirebaseCreateChatRoomRequest {
    return new FirebaseCreateChatRoomRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FirebaseCreateChatRoomRequest {
    return new FirebaseCreateChatRoomRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FirebaseCreateChatRoomRequest {
    return new FirebaseCreateChatRoomRequest().fromJsonString(jsonString, options);
  }

  static equals(a: FirebaseCreateChatRoomRequest | PlainMessage<FirebaseCreateChatRoomRequest> | undefined, b: FirebaseCreateChatRoomRequest | PlainMessage<FirebaseCreateChatRoomRequest> | undefined): boolean {
    return proto3.util.equals(FirebaseCreateChatRoomRequest, a, b);
  }
}

/**
 * @generated from message retrica.FirebaseCreateChatRoomResponse
 */
export class FirebaseCreateChatRoomResponse extends Message<FirebaseCreateChatRoomResponse> {
  /**
   * @generated from field: retrica.ErrorCode error_code = 1;
   */
  errorCode = ErrorCode.UNKNOWN;

  /**
   * @generated from field: string chat_id = 2;
   */
  chatId = "";

  constructor(data?: PartialMessage<FirebaseCreateChatRoomResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.FirebaseCreateChatRoomResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "error_code", kind: "enum", T: proto3.getEnumType(ErrorCode) },
    { no: 2, name: "chat_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FirebaseCreateChatRoomResponse {
    return new FirebaseCreateChatRoomResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FirebaseCreateChatRoomResponse {
    return new FirebaseCreateChatRoomResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FirebaseCreateChatRoomResponse {
    return new FirebaseCreateChatRoomResponse().fromJsonString(jsonString, options);
  }

  static equals(a: FirebaseCreateChatRoomResponse | PlainMessage<FirebaseCreateChatRoomResponse> | undefined, b: FirebaseCreateChatRoomResponse | PlainMessage<FirebaseCreateChatRoomResponse> | undefined): boolean {
    return proto3.util.equals(FirebaseCreateChatRoomResponse, a, b);
  }
}

/**
 * /a2/re/firebaseChatPush
 * firebase chat 전용 push api
 *
 * @generated from message retrica.FirebaseChatPushRequest
 */
export class FirebaseChatPushRequest extends Message<FirebaseChatPushRequest> {
  /**
   * @generated from field: string chat_id = 1;
   */
  chatId = "";

  constructor(data?: PartialMessage<FirebaseChatPushRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.FirebaseChatPushRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "chat_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FirebaseChatPushRequest {
    return new FirebaseChatPushRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FirebaseChatPushRequest {
    return new FirebaseChatPushRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FirebaseChatPushRequest {
    return new FirebaseChatPushRequest().fromJsonString(jsonString, options);
  }

  static equals(a: FirebaseChatPushRequest | PlainMessage<FirebaseChatPushRequest> | undefined, b: FirebaseChatPushRequest | PlainMessage<FirebaseChatPushRequest> | undefined): boolean {
    return proto3.util.equals(FirebaseChatPushRequest, a, b);
  }
}

/**
 * @generated from message retrica.FirebaseChatPushResponse
 */
export class FirebaseChatPushResponse extends Message<FirebaseChatPushResponse> {
  /**
   * @generated from field: retrica.ErrorCode error_code = 1;
   */
  errorCode = ErrorCode.UNKNOWN;

  constructor(data?: PartialMessage<FirebaseChatPushResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.FirebaseChatPushResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "error_code", kind: "enum", T: proto3.getEnumType(ErrorCode) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FirebaseChatPushResponse {
    return new FirebaseChatPushResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FirebaseChatPushResponse {
    return new FirebaseChatPushResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FirebaseChatPushResponse {
    return new FirebaseChatPushResponse().fromJsonString(jsonString, options);
  }

  static equals(a: FirebaseChatPushResponse | PlainMessage<FirebaseChatPushResponse> | undefined, b: FirebaseChatPushResponse | PlainMessage<FirebaseChatPushResponse> | undefined): boolean {
    return proto3.util.equals(FirebaseChatPushResponse, a, b);
  }
}

/**
 * /a2/re/facebookConnect
 * 계정에 Facebook 연결
 *
 * @generated from message retrica.FacebookConnectRequest
 */
export class FacebookConnectRequest extends Message<FacebookConnectRequest> {
  /**
   * @generated from field: string facebook_access_token = 1;
   */
  facebookAccessToken = "";

  constructor(data?: PartialMessage<FacebookConnectRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.FacebookConnectRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "facebook_access_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FacebookConnectRequest {
    return new FacebookConnectRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FacebookConnectRequest {
    return new FacebookConnectRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FacebookConnectRequest {
    return new FacebookConnectRequest().fromJsonString(jsonString, options);
  }

  static equals(a: FacebookConnectRequest | PlainMessage<FacebookConnectRequest> | undefined, b: FacebookConnectRequest | PlainMessage<FacebookConnectRequest> | undefined): boolean {
    return proto3.util.equals(FacebookConnectRequest, a, b);
  }
}

/**
 * @generated from message retrica.FacebookConnectResponse
 */
export class FacebookConnectResponse extends Message<FacebookConnectResponse> {
  /**
   * FACEBOOK_TOKEN_INVALID, FACEBOOK_ACCOUNT_MISMATCH
   *
   * @generated from field: retrica.ErrorCode error_code = 1;
   */
  errorCode = ErrorCode.UNKNOWN;

  /**
   * @generated from field: string facebook_id = 2;
   */
  facebookId = "";

  /**
   * DEPRECATED
   *
   * @generated from field: retrica.FriendsWrap DEPRECATED_friends_wrap = 3;
   */
  DEPRECATEDFriendsWrap?: FriendsWrap;

  constructor(data?: PartialMessage<FacebookConnectResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.FacebookConnectResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "error_code", kind: "enum", T: proto3.getEnumType(ErrorCode) },
    { no: 2, name: "facebook_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "DEPRECATED_friends_wrap", kind: "message", T: FriendsWrap },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FacebookConnectResponse {
    return new FacebookConnectResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FacebookConnectResponse {
    return new FacebookConnectResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FacebookConnectResponse {
    return new FacebookConnectResponse().fromJsonString(jsonString, options);
  }

  static equals(a: FacebookConnectResponse | PlainMessage<FacebookConnectResponse> | undefined, b: FacebookConnectResponse | PlainMessage<FacebookConnectResponse> | undefined): boolean {
    return proto3.util.equals(FacebookConnectResponse, a, b);
  }
}

/**
 * /a2/re/facebookDisconnect
 * 계정에서 facebook 연결을 끊는다.
 *
 * @generated from message retrica.FacebookDisconnectRequest
 */
export class FacebookDisconnectRequest extends Message<FacebookDisconnectRequest> {
  constructor(data?: PartialMessage<FacebookDisconnectRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.FacebookDisconnectRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FacebookDisconnectRequest {
    return new FacebookDisconnectRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FacebookDisconnectRequest {
    return new FacebookDisconnectRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FacebookDisconnectRequest {
    return new FacebookDisconnectRequest().fromJsonString(jsonString, options);
  }

  static equals(a: FacebookDisconnectRequest | PlainMessage<FacebookDisconnectRequest> | undefined, b: FacebookDisconnectRequest | PlainMessage<FacebookDisconnectRequest> | undefined): boolean {
    return proto3.util.equals(FacebookDisconnectRequest, a, b);
  }
}

/**
 * @generated from message retrica.FacebookDisconnectResponse
 */
export class FacebookDisconnectResponse extends Message<FacebookDisconnectResponse> {
  /**
   * SUCCESS
   *
   * @generated from field: retrica.ErrorCode error_code = 1;
   */
  errorCode = ErrorCode.UNKNOWN;

  constructor(data?: PartialMessage<FacebookDisconnectResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.FacebookDisconnectResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "error_code", kind: "enum", T: proto3.getEnumType(ErrorCode) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FacebookDisconnectResponse {
    return new FacebookDisconnectResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FacebookDisconnectResponse {
    return new FacebookDisconnectResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FacebookDisconnectResponse {
    return new FacebookDisconnectResponse().fromJsonString(jsonString, options);
  }

  static equals(a: FacebookDisconnectResponse | PlainMessage<FacebookDisconnectResponse> | undefined, b: FacebookDisconnectResponse | PlainMessage<FacebookDisconnectResponse> | undefined): boolean {
    return proto3.util.equals(FacebookDisconnectResponse, a, b);
  }
}

/**
 * /a2/re/facebookFriends
 * Facebook 친구 중 친구가 아닌 레트리카 사용자를 반환.
 *
 * @generated from message retrica.FacebookFriendsRequest
 */
export class FacebookFriendsRequest extends Message<FacebookFriendsRequest> {
  /**
   * @generated from field: string facebook_access_token = 1;
   */
  facebookAccessToken = "";

  constructor(data?: PartialMessage<FacebookFriendsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.FacebookFriendsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "facebook_access_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FacebookFriendsRequest {
    return new FacebookFriendsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FacebookFriendsRequest {
    return new FacebookFriendsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FacebookFriendsRequest {
    return new FacebookFriendsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: FacebookFriendsRequest | PlainMessage<FacebookFriendsRequest> | undefined, b: FacebookFriendsRequest | PlainMessage<FacebookFriendsRequest> | undefined): boolean {
    return proto3.util.equals(FacebookFriendsRequest, a, b);
  }
}

/**
 * @generated from message retrica.FacebookFriendsResponse
 */
export class FacebookFriendsResponse extends Message<FacebookFriendsResponse> {
  /**
   * SUCCESS, FACEBOOK_TOKEN_INVALID
   *
   * @generated from field: retrica.ErrorCode error_code = 1;
   */
  errorCode = ErrorCode.UNKNOWN;

  /**
   * @generated from field: repeated retrica.Friend list = 2;
   */
  list: Friend[] = [];

  constructor(data?: PartialMessage<FacebookFriendsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.FacebookFriendsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "error_code", kind: "enum", T: proto3.getEnumType(ErrorCode) },
    { no: 2, name: "list", kind: "message", T: Friend, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FacebookFriendsResponse {
    return new FacebookFriendsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FacebookFriendsResponse {
    return new FacebookFriendsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FacebookFriendsResponse {
    return new FacebookFriendsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: FacebookFriendsResponse | PlainMessage<FacebookFriendsResponse> | undefined, b: FacebookFriendsResponse | PlainMessage<FacebookFriendsResponse> | undefined): boolean {
    return proto3.util.equals(FacebookFriendsResponse, a, b);
  }
}

/**
 * /a2/re/vkontakteConnect
 * 계정과 VK를 연결한다.
 *
 * @generated from message retrica.VkontakteConnectRequest
 */
export class VkontakteConnectRequest extends Message<VkontakteConnectRequest> {
  /**
   * @generated from field: string vkontakte_access_token = 1;
   */
  vkontakteAccessToken = "";

  constructor(data?: PartialMessage<VkontakteConnectRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.VkontakteConnectRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "vkontakte_access_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): VkontakteConnectRequest {
    return new VkontakteConnectRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): VkontakteConnectRequest {
    return new VkontakteConnectRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): VkontakteConnectRequest {
    return new VkontakteConnectRequest().fromJsonString(jsonString, options);
  }

  static equals(a: VkontakteConnectRequest | PlainMessage<VkontakteConnectRequest> | undefined, b: VkontakteConnectRequest | PlainMessage<VkontakteConnectRequest> | undefined): boolean {
    return proto3.util.equals(VkontakteConnectRequest, a, b);
  }
}

/**
 * @generated from message retrica.VkontakteConnectResponse
 */
export class VkontakteConnectResponse extends Message<VkontakteConnectResponse> {
  /**
   * SUCCESS, VKONTAKTE_TOKEN_INVALID, VKONTAKTE_ACCOUNT_MISMATCH
   *
   * @generated from field: retrica.ErrorCode error_code = 1;
   */
  errorCode = ErrorCode.UNKNOWN;

  /**
   * @generated from field: string vkontakte_id = 2;
   */
  vkontakteId = "";

  /**
   * DEPRECATED
   *
   * @generated from field: retrica.FriendsWrap DEPRECATED_friends_wrap = 3;
   */
  DEPRECATEDFriendsWrap?: FriendsWrap;

  constructor(data?: PartialMessage<VkontakteConnectResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.VkontakteConnectResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "error_code", kind: "enum", T: proto3.getEnumType(ErrorCode) },
    { no: 2, name: "vkontakte_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "DEPRECATED_friends_wrap", kind: "message", T: FriendsWrap },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): VkontakteConnectResponse {
    return new VkontakteConnectResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): VkontakteConnectResponse {
    return new VkontakteConnectResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): VkontakteConnectResponse {
    return new VkontakteConnectResponse().fromJsonString(jsonString, options);
  }

  static equals(a: VkontakteConnectResponse | PlainMessage<VkontakteConnectResponse> | undefined, b: VkontakteConnectResponse | PlainMessage<VkontakteConnectResponse> | undefined): boolean {
    return proto3.util.equals(VkontakteConnectResponse, a, b);
  }
}

/**
 * /a2/re/vkontakteDisconnect
 * 계정과 VK의 연결을 끊는다.
 *
 * @generated from message retrica.VkontakteDisconnectRequest
 */
export class VkontakteDisconnectRequest extends Message<VkontakteDisconnectRequest> {
  constructor(data?: PartialMessage<VkontakteDisconnectRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.VkontakteDisconnectRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): VkontakteDisconnectRequest {
    return new VkontakteDisconnectRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): VkontakteDisconnectRequest {
    return new VkontakteDisconnectRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): VkontakteDisconnectRequest {
    return new VkontakteDisconnectRequest().fromJsonString(jsonString, options);
  }

  static equals(a: VkontakteDisconnectRequest | PlainMessage<VkontakteDisconnectRequest> | undefined, b: VkontakteDisconnectRequest | PlainMessage<VkontakteDisconnectRequest> | undefined): boolean {
    return proto3.util.equals(VkontakteDisconnectRequest, a, b);
  }
}

/**
 * @generated from message retrica.VkontakteDisconnectResponse
 */
export class VkontakteDisconnectResponse extends Message<VkontakteDisconnectResponse> {
  /**
   * SUCCESS
   *
   * @generated from field: retrica.ErrorCode error_code = 1;
   */
  errorCode = ErrorCode.UNKNOWN;

  constructor(data?: PartialMessage<VkontakteDisconnectResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.VkontakteDisconnectResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "error_code", kind: "enum", T: proto3.getEnumType(ErrorCode) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): VkontakteDisconnectResponse {
    return new VkontakteDisconnectResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): VkontakteDisconnectResponse {
    return new VkontakteDisconnectResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): VkontakteDisconnectResponse {
    return new VkontakteDisconnectResponse().fromJsonString(jsonString, options);
  }

  static equals(a: VkontakteDisconnectResponse | PlainMessage<VkontakteDisconnectResponse> | undefined, b: VkontakteDisconnectResponse | PlainMessage<VkontakteDisconnectResponse> | undefined): boolean {
    return proto3.util.equals(VkontakteDisconnectResponse, a, b);
  }
}

/**
 * /a2/re/vkontakteFriends
 * VK친구 중 친구가 아닌 레트리카 사용자를 반환.
 *
 * @generated from message retrica.VkontakteFriendsRequest
 */
export class VkontakteFriendsRequest extends Message<VkontakteFriendsRequest> {
  /**
   * @generated from field: string vkontakte_access_token = 1;
   */
  vkontakteAccessToken = "";

  constructor(data?: PartialMessage<VkontakteFriendsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.VkontakteFriendsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "vkontakte_access_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): VkontakteFriendsRequest {
    return new VkontakteFriendsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): VkontakteFriendsRequest {
    return new VkontakteFriendsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): VkontakteFriendsRequest {
    return new VkontakteFriendsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: VkontakteFriendsRequest | PlainMessage<VkontakteFriendsRequest> | undefined, b: VkontakteFriendsRequest | PlainMessage<VkontakteFriendsRequest> | undefined): boolean {
    return proto3.util.equals(VkontakteFriendsRequest, a, b);
  }
}

/**
 * @generated from message retrica.VkontakteFriendsResponse
 */
export class VkontakteFriendsResponse extends Message<VkontakteFriendsResponse> {
  /**
   * SUCCESS
   *
   * @generated from field: retrica.ErrorCode error_code = 1;
   */
  errorCode = ErrorCode.UNKNOWN;

  /**
   * @generated from field: repeated retrica.Friend list = 2;
   */
  list: Friend[] = [];

  constructor(data?: PartialMessage<VkontakteFriendsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.VkontakteFriendsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "error_code", kind: "enum", T: proto3.getEnumType(ErrorCode) },
    { no: 2, name: "list", kind: "message", T: Friend, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): VkontakteFriendsResponse {
    return new VkontakteFriendsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): VkontakteFriendsResponse {
    return new VkontakteFriendsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): VkontakteFriendsResponse {
    return new VkontakteFriendsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: VkontakteFriendsResponse | PlainMessage<VkontakteFriendsResponse> | undefined, b: VkontakteFriendsResponse | PlainMessage<VkontakteFriendsResponse> | undefined): boolean {
    return proto3.util.equals(VkontakteFriendsResponse, a, b);
  }
}

/**
 * /a2/re/SetUserRoles
 *
 * @generated from message retrica.SetUserRolesRequest
 */
export class SetUserRolesRequest extends Message<SetUserRolesRequest> {
  /**
   * @generated from field: string user_id = 1;
   */
  userId = "";

  /**
   * @generated from field: repeated retrica.UserRole roles = 2;
   */
  roles: UserRole[] = [];

  constructor(data?: PartialMessage<SetUserRolesRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.SetUserRolesRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "roles", kind: "enum", T: proto3.getEnumType(UserRole), repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SetUserRolesRequest {
    return new SetUserRolesRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SetUserRolesRequest {
    return new SetUserRolesRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SetUserRolesRequest {
    return new SetUserRolesRequest().fromJsonString(jsonString, options);
  }

  static equals(a: SetUserRolesRequest | PlainMessage<SetUserRolesRequest> | undefined, b: SetUserRolesRequest | PlainMessage<SetUserRolesRequest> | undefined): boolean {
    return proto3.util.equals(SetUserRolesRequest, a, b);
  }
}

/**
 * @generated from message retrica.SetUserRolesResponse
 */
export class SetUserRolesResponse extends Message<SetUserRolesResponse> {
  /**
   * @generated from field: retrica.ErrorCode error_code = 1;
   */
  errorCode = ErrorCode.UNKNOWN;

  constructor(data?: PartialMessage<SetUserRolesResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.SetUserRolesResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "error_code", kind: "enum", T: proto3.getEnumType(ErrorCode) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SetUserRolesResponse {
    return new SetUserRolesResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SetUserRolesResponse {
    return new SetUserRolesResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SetUserRolesResponse {
    return new SetUserRolesResponse().fromJsonString(jsonString, options);
  }

  static equals(a: SetUserRolesResponse | PlainMessage<SetUserRolesResponse> | undefined, b: SetUserRolesResponse | PlainMessage<SetUserRolesResponse> | undefined): boolean {
    return proto3.util.equals(SetUserRolesResponse, a, b);
  }
}

