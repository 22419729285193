import { Typography } from 'antd';
import { ReactNode } from 'react';
import { isValidHttpUrl } from 'utils/utils';

export interface EditableTextProps {
  str: string | undefined;
  icon?: ReactNode;
  placeholder: string;
  onChange?: (value: string) => void;
  triggerType: 'text' | 'icon';
  prefix?: string;
}

export function EditableText(props: EditableTextProps) {
  const str = props.str;
  return (
    <>
      {props.prefix}
      <Typography.Paragraph
        style={{ marginBottom: '0px' }}
        editable={{
          text: str,
          icon: props.icon,
          onChange: props.onChange,
          triggerType: [props.triggerType],
          autoSize: { maxRows: 1 },
        }}
      >
        {!str || str === '' ? (
          <span style={{ color: '#5c5c5c88' }}>{props.placeholder}</span>
        ) : isValidHttpUrl(str) ? (
          <a href={str} target="_blank" rel="noreferrer">
            {str}
          </a>
        ) : (
          str
        )}
      </Typography.Paragraph>
    </>
  );
}
