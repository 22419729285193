import { DatePicker, Result, Spin } from 'antd';
import { SubTitle } from 'components/PageTitles';
import dayjs from 'dayjs';
import type { Dayjs } from 'dayjs';
import { Key, useEffect, useState } from 'react';
import { MaterialType, MaterialTypes } from 'types/material';
import { MaterialStat } from 'types/statistics';
import { Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { Grapeseed } from 'api/grapeseed';

import weekday from 'dayjs/plugin/weekday';
import localeData from 'dayjs/plugin/localeData';
import { dateStringFormat, lastday, range, rangePresets } from './Main';
dayjs.extend(weekday);
dayjs.extend(localeData);

interface statRowType {
  key: React.Key;
  id: string;
  type: MaterialType;
  count: number;
  subType: subTypeRowType[];
}
interface subTypeRowType {
  key: React.Key;
  name: string;
  count: number;
  usage: usageTypeRowType[];
}
interface usageTypeRowType {
  key: React.Key;
  as: string;
  count: number;
  userCount: number;
}

export function Materials() {
  const [range, setRange] = useState<range>({
    start: lastday.subtract(6, 'd'),
    end: lastday,
  });
  const [stats, setStats] = useState<MaterialStat[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    async function fetchStats() {
      const queryParams: Map<string, string> = new Map();
      queryParams.set('start', range.start.format(dateStringFormat));
      queryParams.set('end', range.end.format(dateStringFormat));
      setIsLoading(true);
      const result = await Grapeseed.GET('/api/stats/materials', {
        queryParams: queryParams,
      });
      if (result) {
        setStats(result);
      }
      setIsLoading(false);
    }
    fetchStats();
  }, [range.start, range.end]);

  const onRangeChange = (dates: null | (any | null)[]) => {
    if (dates && dates[0] && dates[1]) {
      setRange({
        start: dates[0] as Dayjs,
        end: dates[1] as Dayjs,
      });
    }
  };

  const statColumns: ColumnsType<statRowType> = [
    {
      title: 'ID',
      dataIndex: 'id',
      width: 5,
      filters: stats
        .filter((st) => st.id && st.id.length > 0)
        .map((st) => {
          return { text: st.id!, value: st.id! };
        }),
      filterSearch: true,
      onFilter: (value: Key | boolean, record: statRowType) =>
        record.id.startsWith(value.toString()),
      render: (text: any) => {
        return text === '(null)' ? (
          text
        ) : (
          <a href={`/materials/${text}`} target="_blank" rel="noreferrer">
            {text}
          </a>
        );
      },
    },
    {
      title: 'Type',
      dataIndex: 'type',
      width: 3,
      filters: Object.values(MaterialTypes).map((t) => {
        return { text: t, value: t };
      }),
      onFilter: (value: Key | boolean, record: statRowType) =>
        record.type === value,
      sorter: {
        compare: (a, b) => a.type.localeCompare(b.type),
        multiple: 3,
      },
    },
    {
      title: 'Count',
      dataIndex: 'count',
      width: 2,
      sorter: {
        compare: (a, b) => a.count - b.count,
        multiple: 2,
      },
    },
  ];

  const subTypeRender = (record: statRowType) => {
    const subTypeColumns: ColumnsType<subTypeRowType> = [
      { title: 'Sub Type', dataIndex: 'name' },
      { title: 'Count', dataIndex: 'count' },
    ];
    return (
      <Table
        columns={subTypeColumns}
        dataSource={record.subType}
        pagination={false}
        expandable={{ expandedRowRender: usageRender }}
      />
    );
  };

  const usageRender = (record: subTypeRowType) => {
    const usageColumns: ColumnsType<usageTypeRowType> = [
      { title: 'As', dataIndex: 'as' },
      { title: 'Count', dataIndex: 'count' },
      { title: 'UVs', dataIndex: 'userCount' },
    ];
    return (
      <Table
        columns={usageColumns}
        dataSource={record.usage}
        pagination={false}
      />
    );
  };

  function renderStatTable() {
    return (
      <Table
        style={{ marginTop: '10px' }}
        size="small"
        pagination={{ defaultPageSize: 20 }}
        columns={statColumns}
        expandable={{ expandedRowRender: subTypeRender }}
        dataSource={stats.map((stat) => {
          return {
            key: `${stat.id ?? '(null)'}${stat.type}`,
            id: `${stat.id ?? '(null)'}`,
            type: stat.type,
            count: stat.count,
            subType: stat.subType.map((st) => {
              return {
                key: st.name ?? '(null)',
                name: st.name ?? '(null)',
                count: st.count,
                usage: st.usage.map((us) => {
                  return {
                    key: us.as ?? '(null)',
                    as: us.as ?? '(null)',
                    count: us.count,
                    userCount: us.users.length,
                  };
                }),
              };
            }),
          };
        })}
      />
    );
  }

  function renderLoading() {
    return (
      <Result
        icon={<Spin size="large" />}
        title="Working hard, please wait a moment..."
      />
    );
  }

  return (
    <>
      <SubTitle>Material Statistics</SubTitle>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <DatePicker.RangePicker
          style={{
            flex: '0 1 auto',
          }}
          value={[range.start, range.end]}
          presets={rangePresets}
          allowClear={false}
          onChange={onRangeChange}
          format={dateStringFormat}
        />
      </div>
      <div>{isLoading ? renderLoading() : renderStatTable()}</div>
    </>
  );
}
