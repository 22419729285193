import { UploadFile } from 'antd/lib/upload/interface';
import { MaterialList } from 'components/MaterialViews/MaterialList';
import { SubTitle } from 'components/PageTitles';
import React, { useState } from 'react';
import {
  ContainerStickers,
  ContentType,
  Sticker,
  StickerContentAcceptTypes,
} from 'types/material';
import BulkUploadForm from './components/BulkUploadForm';
import { getMediaResolution } from 'utils/media';

function Bulk() {
  const [uploaded, setUploaded] = useState<Sticker[]>();

  const ctor = async (file: UploadFile, id: string): Promise<Sticker> => {
    if (!file.url || !file.type || !file.size) {
      return Promise.reject('one of mandatory attribute is undefined');
    }
    let size = await getMediaResolution(file);
    let s: Sticker = new Sticker({
      id: id,
      tags: [],
      createdAt: new Date(),
      isFree: false,
      content: {
        type: file.type as ContentType,
        size: size,
        url: file.url,
        duration: 0,
      },
      bytes: file.size,
    });
    return s;
  };

  return (
    <React.Fragment>
      <SubTitle>Add stickers</SubTitle>
      <BulkUploadForm
        endpoint={'/api/stickers'}
        accept={StickerContentAcceptTypes}
        container={ContainerStickers}
        elemConstructor={ctor}
        onAdd={setUploaded}
      ></BulkUploadForm>
      {uploaded && (
        <MaterialList
          list={uploaded}
          itemEditable
          onRemove={(id: string) =>
            setUploaded(uploaded.filter((s) => s.id !== id))
          }
        />
      )}
    </React.Fragment>
  );
}

const exports = Bulk;
export default exports;
