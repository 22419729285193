import {
  MaterialList,
  Selections,
} from 'components/MaterialViews/MaterialList';
import { useFetchMaterials } from 'hooks/useFetchMaterials';
import { SearchMaterialForm } from 'pages/Search/components/SearchMaterialsForm';
import { useRef, useState } from 'react';
import { MaterialType } from 'types/material';
import viewStyle from 'styles/view.module.css';

export interface SearchMaterialViewProps {
  selections?: Selections;
}

export function SearchMaterialView(props: SearchMaterialViewProps) {
  const [query, setQuery] = useState<{
    ids?: string[];
    tags?: string;
    type?: MaterialType[];
  }>({});
  const lastItemRef = useRef(null);

  const [list, , hasMore] = useFetchMaterials({ ...query, lastItemRef });

  const onSubmit = (values: {
    id?: string;
    tags?: string;
    type?: MaterialType[];
  }) => {
    if (values.id && values.id.length > 0) {
      setQuery({ ids: [values.id] });
    } else {
      setQuery({
        tags: values.tags,
        type: values.type,
      });
    }
  };

  return (
    <>
      <SearchMaterialForm onSubmit={onSubmit} />
      <MaterialList
        className={`${viewStyle.viewbox} ${viewStyle.wrap} ${viewStyle.maxHeight600}`}
        list={list}
        itemSize="compact"
        selections={props.selections}
        lastItemRef={lastItemRef}
        hasMore={hasMore}
      />
    </>
  );
}
