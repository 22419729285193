import { PageTitle, SubTitle } from 'components/PageTitles';
import React from 'react';
import { AccountView } from 'components/Auth/AccountView';

function Login() {
  return (
    <React.Fragment>
      <PageTitle>Login</PageTitle>
      <SubTitle>Not Allowed</SubTitle>
      <AccountView showRoles showButton />
    </React.Fragment>
  );
}

const exports = Login;
export default exports;
