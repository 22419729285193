import React, { useRef } from 'react';
import { SubTitle } from 'components/PageTitles';
import { MaterialList } from 'components/MaterialViews/MaterialList';
import { useFetchMaterials } from 'hooks/useFetchMaterials';
import { MaterialText, MaterialTypes } from 'types/material';
import { NewTextForm } from './components/NewTextForm';

function New() {
  const lastItemRef = useRef(null);
  const [list, setList, hasMore] = useFetchMaterials({
    type: [MaterialTypes.Text],
    lastItemRef: lastItemRef,
  });

  return (
    <React.Fragment>
      <SubTitle>Add a text</SubTitle>
      <NewTextForm
        onAdd={(t: MaterialText) => {
          setList((prev) => (prev ? [t, ...prev] : [t]));
        }}
      />
      <hr style={{ borderTop: 'grey' }} />
      <MaterialList
        list={list}
        itemEditable
        onRemove={(id: string) => setList(list.filter((c) => c.id !== id))}
        lastItemRef={lastItemRef}
        hasMore={hasMore}
      />
    </React.Fragment>
  );
}

const exports = New;
export default exports;
