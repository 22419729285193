import { CheckOutlined } from '@ant-design/icons';
import {
  Button,
  Checkbox,
  DatePicker,
  Divider,
  Form,
  Input,
  Modal,
  Select,
} from 'antd';
import { useForm } from 'antd/es/form/Form';
import { Grapeseed } from 'api/grapeseed';
import { SubTitle } from 'components/PageTitles';
import dayjs from 'dayjs';
import { useState } from 'react';
import componentStyle from 'styles/common.module.css';
import { Message, TopicDefault } from 'types/message';
import { NotificationCardView } from './components/NotificationCardView';
import { isValidUrl } from 'utils/strings';

export function Build() {
  const [form] = useForm();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [title, setTitle] = useState<string>('');
  const [body, setBody] = useState<string>('');
  const [image, setImage] = useState<string>();
  const deeplinkUrlPrefix = 'donut:///';

  const onSubmit = async (values: any) => {
    let sched: Date = values.scheduledAt?.toDate();
    if (sched) {
      sched = new Date(Math.floor(sched.valueOf() / 1000) * 1000);
    }
    if (sched <= new Date()) {
      Modal.error({
        title: `Invalid schedule`,
        content: 'Messaging schedule time cannot be in the past',
      });
    }
    const ref = values.ref?.trim();
    const requestBody: Message = {
      ref: ref ? `${deeplinkUrlPrefix}${ref}` : undefined,
      sound: form.getFieldValue('sound') ? 'default' : undefined,
      topic: form.getFieldValue('topic'),
      notification: {
        title: values.title,
        body: values.body,
        image: values.image,
      },
      status: {
        scheduledAt: sched,
      },
    };
    setIsLoading(true);
    const result = await Grapeseed.POST(`/api/messages`, { body: requestBody });
    if (result) {
      form.resetFields();
      setTitle('');
      setBody('');
      setImage(undefined);
    }
    setIsLoading(false);
  };

  return (
    <>
      <SubTitle>Send a message</SubTitle>
      <div style={{ display: 'flex' }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            padding: '1rem',
            width: '40%',
          }}
        >
          <Form
            form={form}
            onFinish={onSubmit}
            onValuesChange={async (changedValues, allValues) => {
              const url = changedValues.image;
              if (url && isValidUrl(url)) {
                setImage(url);
              }
            }}
          >
            <Divider orientation="left">Notification</Divider>
            <Form.Item
              name="title"
              label="Title"
              hasFeedback
              rules={[{ required: true, message: 'Title is requried' }]}
            >
              <Input
                type="text"
                placeholder="Notification Title"
                onChange={(e) => setTitle(e.target.value)}
              />
            </Form.Item>
            <Form.Item
              name="body"
              label="Text"
              hasFeedback
              rules={[{ required: true, message: 'Text is required' }]}
            >
              <Input.TextArea
                rows={4}
                placeholder="Notification Text"
                onChange={(e) => setBody(e.target.value)}
              />
            </Form.Item>
            <Form.Item
              name="image"
              label="Image"
              extra={
                <span className={componentStyle.hinttextOrange}>
                  * Only https, http protocol is not allowed.
                </span>
              }
            >
              <Input type="url" placeholder="Notification Image URL" />
            </Form.Item>
            <Form.Item name="topic" label="Topic" initialValue={TopicDefault}>
              <Select
                options={[{ value: TopicDefault, label: TopicDefault }]}
              />
            </Form.Item>
            <Divider orientation="left">Options</Divider>
            <Form.Item name="ref" label="Deeplink">
              <Input
                type="text"
                placeholder="Deeplink url"
                addonBefore={deeplinkUrlPrefix}
              />
            </Form.Item>
            <Form.Item
              name="sound"
              label="Sound"
              valuePropName="checked"
              initialValue={true}
            >
              <Checkbox defaultChecked />
            </Form.Item>
            <Form.Item
              name="scheduledAt"
              label="Scheduling"
              extra={
                <span className={componentStyle.hinttextOrange}>
                  * Scheduled 알림 발송 작업이 매 10분마다 실행되기 때문에, 너무
                  가까운 시각으로 설정하면 알림이 발송되지 않을 수 있습니다.
                  안정성을 위해서 최소 현재 시각으로부터 30분 이상으로 설정
                  부탁드립니다.
                </span>
              }
            >
              <DatePicker
                showTime
                disabledDate={(current) => {
                  return current <= dayjs().subtract(1, 'day').endOf('day');
                }}
              />
            </Form.Item>
            <Divider />
            <Form.Item>
              <Button
                className={componentStyle.buttonOrange}
                type="primary"
                icon={<CheckOutlined />}
                htmlType="submit"
                loading={isLoading}
              >
                Submit
              </Button>
            </Form.Item>
          </Form>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            padding: '1rem',
            width: '40%',
          }}
        >
          <Divider orientation="left">Preview</Divider>
          <NotificationCardView
            notification={{ title: title, body: body, image: image }}
            style={{ minHeight: '60px' }}
          />
        </div>
      </div>
    </>
  );
}
