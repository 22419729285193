import { DragEvent } from 'react';
import { Popconfirm, Popover, Space, Tag, Typography } from 'antd';
import viewStyle from 'styles/view.module.css';
import { DeleteOutlined, EditOutlined, LinkOutlined } from '@ant-design/icons';
import { Creator } from 'types/common';
import RawJson from 'utils/RawJson';
import { ThumbnailView } from 'components/Thumbnail/ThumbnailView';
import { ThumbnailSizes } from 'components/Thumbnail/Constants';

interface CreatorCardViewProps {
  creator: Creator;
  editable: boolean;
  onDelete?: (key: React.Key) => void;
  showTag: boolean;
  draggable: boolean;
  selected: boolean;
  onClick?: (c: Creator) => void;
}

export function CreatorCardView(props: CreatorCardViewProps) {
  const onDragStart = (e: DragEvent<HTMLDivElement>, c: Creator) => {
    e.dataTransfer.setData(
      'application/json',
      JSON.stringify({
        dataType: 'creator',
        data: c,
      })
    );
  };

  let mainClassName =
    viewStyle.viewitem +
    (props.draggable ? ` ${viewStyle.draggable}` : '') +
    (props.selected ? ` ${viewStyle.selected}` : '');

  return (
    <Space
      size={4}
      direction="vertical"
      align="center"
      className={mainClassName}
      draggable={props.draggable}
      onDragStart={(e) => onDragStart(e, props.creator)}
      onClick={() => props.onClick?.(props.creator)}
    >
      <ThumbnailView
        size={ThumbnailSizes.full}
        src={props.creator.coverUrl ?? ''}
        preview={false}
      />
      <Space size={2} onClick={(e) => e.stopPropagation()}>
        {props.showTag && <Tag color={`var(--creator-badge-color)`}>CR</Tag>}
        <Popover content={RawJson(props.creator)} trigger="click">
          {props.creator.name ?? (
            <Typography.Text code>{props.creator.code}</Typography.Text>
          )}
        </Popover>
        <a
          href={`/creators/${props.creator.code}`}
          target="_blank"
          rel="noreferrer"
          style={{ marginLeft: '0.5rem' }}
        >
          <LinkOutlined />
        </a>
      </Space>
      {props.editable && (
        <Space size={10} onClick={(e) => e.stopPropagation()}>
          <a
            href={`/creators/${props.creator.code}`}
            target="_blank"
            rel="noreferrer"
          >
            <EditOutlined />
          </a>
          <Popconfirm
            title="Sure to delete?"
            onConfirm={() => props.onDelete?.(props.creator.code)}
          >
            <Typography.Link>
              <DeleteOutlined style={{ color: 'red' }} />
            </Typography.Link>
          </Popconfirm>
        </Space>
      )}
    </Space>
  );
}

CreatorCardView.defaultProps = {
  editable: false,
  showTag: false,
  draggable: false,
  selected: false,
};
