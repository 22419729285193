import React, { DragEvent, useState } from 'react';
import { Badge, Button, Collapse, Form, Popconfirm, Space } from 'antd';
import { MaterialColor } from 'types/material';
import { TagEntries } from 'components/TagEntries';
import viewStyle from 'styles/view.module.css';
import { HaveSameContents } from 'utils/utils';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Grapeseed } from 'api/grapeseed';
import { useForm } from 'antd/lib/form/Form';
import RawJson from '../../utils/RawJson';
import { Material } from 'types/interfaces';
import { IdPopover } from 'components/IdPopover';

interface ColorViewProps {
  color: MaterialColor;
  editable?: boolean;
  draggable?: boolean;
  onDragStart?: (e: DragEvent<HTMLDivElement>, m: Material) => void;
  onRemove?: (id: string) => void;
}

function ColorView(props: ColorViewProps) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isModified, setIsModified] = useState<boolean>(false);
  const [form] = useForm();
  const [color, setColor] = useState<MaterialColor>(props.color);

  const onSubmit = async (values: any) => {
    setIsLoading(true);
    const result = await Grapeseed.PUT(`/api/colors/${color.id}`, {
      body: {
        tags: values.tags ?? color.tags,
        code: color.code,
      },
      fallback: () => {
        form.setFieldsValue({ tags: color.tags });
      },
    });
    if (result) {
      setColor(new MaterialColor(result));
    }
    setIsLoading(false);
    setIsModified(false);
  };

  const onRemove = async (event?: React.MouseEvent<HTMLElement>) => {
    const result = await Grapeseed.DELETE(`/api/colors/${color.id}`);
    if (result) {
      props.onRemove?.(color.id);
    }
  };

  const checkIsModified = (_: any, values: any) => {
    if (!HaveSameContents(values.tags, color.tags)) {
      setIsModified(true);
    } else {
      setIsModified(false);
    }
  };

  let divClass = `${viewStyle.viewitem} ${viewStyle.full}`;
  if (props.draggable) {
    divClass = `${viewStyle.viewitem} ${viewStyle.draggable}`;
  }

  const colorViewStyle = {
    width: '100px',
    height: '100px',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: 'var(--border-color)',
    borderRadius: '5px',
    backgroundColor: `#${color.code}`,
    marginBottom: '0.5rem',
  };
  return (
    <React.Fragment>
      <div
        className={divClass}
        draggable={props.draggable}
        onDragStart={(e) => props.onDragStart?.(e, color)}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'stretch',
            padding: '15px 15px 0 15px',
          }}
        >
          <Badge
            count={color.picked ?? 0}
            showZero
            color="var(--color-badge-color)"
          >
            <div style={colorViewStyle} />
          </Badge>
          <Space style={{ fontSize: '90%' }}>
            <Badge color="var(--color-badge-color)" />
            <IdPopover
              id={color.code}
              content={RawJson(color)}
              showHref={{ path: 'materials' }}
            />
          </Space>
        </div>
        <div style={{ flex: '1 1 auto', padding: '20px', width: '100%' }}>
          <Form
            form={form}
            colon={false}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
              }
            }}
            onValuesChange={checkIsModified}
            onFinish={onSubmit}
          >
            <Collapse
              bordered={false}
              items={[
                {
                  key: 'tags',
                  label: 'Tags',
                  children: (
                    <Form.Item
                      name="tags"
                      label="Tags"
                      initialValue={color.tags}
                    >
                      <TagEntries
                        layout="vertical"
                        disabled={!props.editable}
                      />
                    </Form.Item>
                  ),
                },
              ]}
            />
            {props.editable && (
              <div
                style={{
                  marginTop: '1rem',
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}
              >
                <Form.Item style={{ marginRight: '1em' }}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    disabled={!isModified}
                    loading={isLoading}
                  >
                    Save changes
                  </Button>
                </Form.Item>
                <Form.Item>
                  <Popconfirm
                    title="Are you sure to DELETE？"
                    icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                    onConfirm={onRemove}
                  >
                    <Button type="primary" danger>
                      Delete
                    </Button>
                  </Popconfirm>
                </Form.Item>
              </div>
            )}
          </Form>
        </div>
      </div>
    </React.Fragment>
  );
}

const exports = ColorView;
export default exports;
