import { Grapeseed } from 'api/grapeseed';
import {
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useCallback,
  useRef,
  useState,
} from 'react';
import { ApiPageSizeForPacks } from 'utils/constants';
import { useIntersectionObserver } from './useIntersectionObserver';
import useDeepCompareEffect from './useDeepCompareEffect';
import { ApiError, OnError } from 'utils/errors';
import { Bundle } from 'types/bundle';
import { Union } from 'utils/utils';

interface useFetchBundlesProps {
  lastItemRef?: MutableRefObject<null>;
}

export const useFetchBundles = (
  props: useFetchBundlesProps
): [Bundle[], Dispatch<SetStateAction<Bundle[]>>, boolean] => {
  const [list, setList] = useState<Bundle[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const page = useRef(0);
  const hasMore = useRef(true);

  const fetchBundles = useCallback(async (page: number, size: number) => {
    const queryParams: Map<string, string> = new Map();
    queryParams.set('from', (page * size).toString());
    queryParams.set('size', size.toString());

    setIsLoading(true);
    const result = await Grapeseed.GET(`/api/bundles`, {
      queryParams: queryParams,
      fallback: (e) => {
        if (e instanceof ApiError && e.statusCode === 404) {
          hasMore.current = false;
        } else {
          OnError(e);
        }
      },
    });
    setIsLoading(false);
    return result?.map((element: any) => new Bundle(element));
  }, []);

  const fetchNextPage = useCallback(
    async (size: number) => {
      page.current++;
      const data = (await fetchBundles(page.current, size)) as Bundle[];
      hasMore.current = data !== undefined && !(data.length < size);
      setList((prev) => Union(prev, data) as Bundle[]);
    },
    [fetchBundles]
  );

  const fetchInit = useCallback(
    async (size: number) => {
      page.current = 0;
      const data = (await fetchBundles(page.current, size)) as Bundle[];
      hasMore.current = data !== undefined && !(data.length < size);
      if (data) {
        setList(data);
      }
    },
    [fetchBundles]
  );

  useDeepCompareEffect(() => {
    hasMore.current = true;
    fetchInit(ApiPageSizeForPacks);
  }, [fetchInit]);

  useIntersectionObserver({
    root: null,
    target: props.lastItemRef?.current,
    onIntersect: ([{ isIntersecting }]) => {
      if (isIntersecting && !isLoading) {
        fetchNextPage(ApiPageSizeForPacks);
      }
    },
  });

  return [list, setList, hasMore.current];
};
