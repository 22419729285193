import { UploadFile } from 'antd/lib/upload/interface';
import { decompressFrames, parseGIF } from 'gifuct-js';
import { Size } from 'types/common';
import { ContentType, ContentTypes } from 'types/material';

const MediaTypes = {
  Image: 'image',
  Video: 'video',
  None: 'none',
} as const;
type MediaType = (typeof MediaTypes)[keyof typeof MediaTypes];

const ImageFormats = {
  Raster: 'raster',
  Vector: 'vector',
  None: 'none',
} as const;
type ImageFormat = (typeof ImageFormats)[keyof typeof ImageFormats];

function isUploadFile(object: any): object is UploadFile {
  return 'originFileObj' in object && 'type' in object;
}

interface MediaMeta {
  type: MediaType;
  format?: ImageFormat;
}

function parseMimeType(mime: string): MediaMeta {
  let meta: MediaMeta = {
    type: MediaTypes.None,
  };
  const splits = mime.split('/');
  if (!splits || splits.length !== 2) {
    return meta;
  }

  switch (splits[0]) {
    case 'image':
      meta.type = MediaTypes.Image;
      break;
    case 'video':
      meta.type = MediaTypes.Video;
      break;
    default:
      break;
  }

  switch (splits[1].toLowerCase()) {
    case 'jpg':
    case 'jpeg':
    case 'png':
    case 'webp':
    case 'gif':
      meta.format = ImageFormats.Raster;
      break;
    case 'svg+xml':
      meta.format = ImageFormats.Vector;
      break;
    default:
      break;
  }

  return meta;
}

function parseExtension(src: string): MediaMeta {
  const ext = src.split('.').pop()?.toLowerCase();
  switch (ext) {
    case 'jpg':
    case 'jpeg':
    case 'png':
    case 'webp':
    case 'gif':
      return {
        type: MediaTypes.Image,
        format: ImageFormats.Raster,
      };
    case 'svg':
      return {
        type: MediaTypes.Image,
        format: ImageFormats.Vector,
      };
    case 'mp4':
      return {
        type: MediaTypes.Video,
      };
    default:
      return {
        type: MediaTypes.None,
      };
  }
}

export async function getGifDuration(url: string) {
  const resp = await fetch(url);
  if (resp.ok) {
    const buf = await resp.arrayBuffer();
    const gif = parseGIF(buf);
    const frames = decompressFrames(gif, true);
    return frames.map((f) => f.delay).reduce((a, b) => a + b) / 1000;
  }
  return 0;
}

export async function getMediaMetadata(src: string) {
  const resp = await fetch(src, { method: 'HEAD' });
  if (resp) {
    const contentType = resp.headers.get('Content-Type') as ContentType;
    const contentLength = resp.headers.get('Content-Length');
    let bytes = 0;
    if (contentLength) {
      bytes = parseInt(contentLength);
    }
    return { contentType: contentType, bytes: bytes };
  }
  return { contentType: ContentTypes.None, bytes: 0 };
}

export function getMediaResolution(src: UploadFile | string): Promise<Size> {
  let meta: MediaMeta;
  if (typeof src === 'string') {
    meta = parseExtension(src as string);
  } else if (isUploadFile(src)) {
    meta = parseMimeType(src.type ?? '');
  } else {
    return Promise.reject('src type error');
  }

  return new Promise((resolve, reject) => {
    switch (meta.type) {
      case MediaTypes.Image:
        return getImageResolution(src, meta.format, resolve, reject);
      case MediaTypes.Video:
        return getVideoResolution(src, resolve, reject);
      default:
        reject(`unsupported media type`);
    }
  });
}

function getImageResolution(
  src: UploadFile | string,
  format: ImageFormat | undefined,
  resolve: (value: Size | PromiseLike<Size>) => void,
  reject: (reason?: any) => void
) {
  switch (format) {
    case ImageFormats.Raster:
      getRasterImageResolution(src, resolve, reject);
      break;
    case ImageFormats.Vector:
      getVectorImageResolution(src, resolve, reject);
      break;
    default:
      reject(`unsupported format ${format}`);
  }
}

function getRasterImageResolution(
  src: UploadFile | string,
  resolve: (value: Size | PromiseLike<Size>) => void,
  reject: (reason?: any) => void
) {
  const $image = document.createElement('img');
  if (typeof src === 'string') {
    $image.src = src as string;
  } else if (isUploadFile(src) && src.originFileObj) {
    const imageUrl = URL.createObjectURL(src.originFileObj);
    $image.src = imageUrl;
  } else {
    reject('src type error');
  }
  $image.addEventListener('load', function () {
    resolve({ width: this.width, height: this.height });
  });
  $image.addEventListener('error', function (e) {
    reject(e);
  });
}

async function getVectorImageResolution(
  src: UploadFile | string,
  resolve: (value: Size | PromiseLike<Size>) => void,
  reject: (reason?: any) => void
) {
  if (typeof src === 'string') {
    const svgString = await fetch(src as string);
    const doc = new DOMParser().parseFromString(
      await svgString.text(),
      'image/svg+xml'
    );
    const svg = doc.getElementsByTagName('svg')[0];
    const viewbox = svg.getAttribute('viewBox')?.split(' ');
    if (!viewbox || viewbox.length !== 4) {
      return getRasterImageResolution(src, resolve, reject);
    } else {
      resolve({
        width: parseInt(viewbox[2]),
        height: parseInt(viewbox[3]),
      });
    }
  } else if (isUploadFile(src) && src.originFileObj) {
    const reader = new FileReader();
    reader.readAsText(src.originFileObj);
    reader.addEventListener('load', (event) => {
      const doc = new DOMParser().parseFromString(
        event.target?.result as string,
        src.type as DOMParserSupportedType
      );
      const svg = doc.getElementsByTagName('svg')[0];
      const viewbox = svg.getAttribute('viewBox')?.split(' ');
      if (!viewbox || viewbox.length !== 4) {
        return getRasterImageResolution(src, resolve, reject);
      } else {
        resolve({
          width: parseInt(viewbox[2]),
          height: parseInt(viewbox[3]),
        });
      }
    });
  } else {
    reject('src type error');
  }
}

function getVideoResolution(
  src: UploadFile | string,
  resolve: (value: Size | PromiseLike<Size>) => void,
  reject: (reason?: any) => void
) {
  const $video = document.createElement('video');
  if (typeof src === 'string') {
    $video.src = src;
  } else if (isUploadFile(src) && src.originFileObj) {
    const videoUrl = URL.createObjectURL(src.originFileObj);
    $video.src = videoUrl;
  } else {
    reject('src type error');
  }
  $video.addEventListener('loadedmetadata', function () {
    resolve({ width: this.videoWidth, height: this.videoHeight });
  });
  $video.addEventListener('error', function (e) {
    reject(e);
  });
}
