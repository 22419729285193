import { MaterialType, NewMaterial } from 'types/material';
import {
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useCallback,
  useRef,
  useState,
} from 'react';
import { Union } from 'utils/utils';
import { useIntersectionObserver } from './useIntersectionObserver';
import { ApiPageSizeForMaterials } from 'utils/constants';
import { Grapeseed } from 'api/grapeseed';
import useDeepCompareEffect from './useDeepCompareEffect';
import { ApiError, OnError } from 'utils/errors';
import { Material } from 'types/interfaces';

interface useFetchMaterialsProps {
  ids?: string[];
  tags?: string;
  type?: MaterialType[];
  lastItemRef?: MutableRefObject<null>;
  page?: number;
  size?: number;
  randomSeed?: number;
  includeUnsafe?: boolean;
  refreshTrigger?: boolean; // to force re-running this hook, flip this value
}

export const useFetchMaterials = (
  props: useFetchMaterialsProps
): [Material[], Dispatch<SetStateAction<Material[]>>, boolean] => {
  const [list, setList] = useState<Material[]>([]);
  const [hasError, setHasError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const page = useRef(0);
  const hasMore = useRef(true);

  const fetchMaterials = useCallback(
    async (
      page: number,
      size: number,
      ids?: string[],
      tags?: string,
      type?: MaterialType[],
      randomSeed?: number,
      includeUnsafe?: boolean
    ) => {
      const queryParams: Map<string, string> = new Map();
      if (ids && ids.length > 0) {
        queryParams.set('ids', ids.toString());
      } else {
        if (tags && tags.length > 0) {
          queryParams.set('tags', tags);
        }
        if (type) {
          queryParams.set('type', type.join('+')); // type query only support OR operator
        }
        if (randomSeed) {
          queryParams.set('randomSeed', randomSeed.toString());
        }
        if (includeUnsafe) {
          queryParams.set('includeUnsafe', 'true');
        }
        if (queryParams.size === 0 && (!type || type.length === 0)) {
          hasMore.current = false;
          return [];
        }
        queryParams.set('from', (page * size).toString());
        queryParams.set('size', size.toString());
      }

      setIsLoading(true);
      const result = await Grapeseed.GET('/api/materials', {
        queryParams: queryParams,
        fallback: (e) => {
          if (e instanceof ApiError && e.statusCode === 404) {
            hasMore.current = false;
          } else {
            OnError(e);
          }
        },
      });
      setIsLoading(false);
      if (!result) {
        setHasError(true);
        return [];
      }
      const materials = result
        ?.map((element: any) => NewMaterial(element))
        .filter((m: any) => m != null);
      hasMore.current =
        !(ids && ids.length > 0) &&
        materials !== undefined &&
        !(materials.length < size);
      return materials;
    },
    []
  );

  const fetchNextPage = useCallback(
    async (
      size: number,
      ids?: string[],
      tags?: string,
      type?: MaterialType[],
      randomSeed?: number,
      includeUnsafe?: boolean
    ) => {
      page.current++;
      const data = (await fetchMaterials(
        page.current,
        size,
        ids,
        tags,
        type,
        randomSeed,
        includeUnsafe
      )) as Material[];
      setList((prev) => Union(prev, data) as Material[]);
    },
    [fetchMaterials]
  );

  const fetchInit = useCallback(
    async (
      size: number,
      startPage?: number,
      ids?: string[],
      tags?: string,
      type?: MaterialType[],
      randomSeed?: number,
      includeUnsafe?: boolean
    ) => {
      page.current = startPage ?? 0;
      const data = (await fetchMaterials(
        page.current,
        size,
        ids,
        tags,
        type,
        randomSeed,
        includeUnsafe
      )) as Material[];
      setList(data);
    },
    [fetchMaterials]
  );

  useDeepCompareEffect(() => {
    hasMore.current = true;
    fetchInit(
      props.size ?? ApiPageSizeForMaterials,
      props.page,
      props.ids,
      props.tags,
      props.type,
      props.randomSeed,
      props.includeUnsafe
    );
  }, [
    fetchInit,
    props.size,
    props.page,
    props.ids,
    props.tags,
    props.type,
    props.randomSeed,
    props.includeUnsafe,
    props.refreshTrigger,
  ]);

  useIntersectionObserver({
    root: null,
    target: props.lastItemRef?.current,
    onIntersect: ([{ isIntersecting }]) => {
      if (isIntersecting && !isLoading && !hasError) {
        fetchNextPage(
          props.size ?? ApiPageSizeForMaterials,
          props.ids,
          props.tags,
          props.type,
          props.randomSeed,
          props.includeUnsafe
        );
      }
    },
  });

  return [list, setList, hasMore.current];
};
