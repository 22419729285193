import React, { useRef } from 'react';
import { MaterialColor, MaterialTypes } from 'types/material';
import { SubTitle } from 'components/PageTitles';
import { MaterialList } from 'components/MaterialViews/MaterialList';
import { useFetchMaterials } from 'hooks/useFetchMaterials';
import NewColorForm from './components/NewColorForm';

function New() {
  const lastItemRef = useRef(null);
  const [list, setList, hasMore] = useFetchMaterials({
    type: [MaterialTypes.Color],
    lastItemRef: lastItemRef,
  });

  return (
    <React.Fragment>
      <SubTitle>Add a color</SubTitle>
      <NewColorForm
        onAdd={(c: MaterialColor[]) => setList((prev) => [...c, ...prev])}
      />
      <hr style={{ borderTop: 'grey' }} />
      <MaterialList
        list={list}
        itemEditable
        onRemove={(id: string) => setList(list.filter((c) => c.id !== id))}
        lastItemRef={lastItemRef}
        hasMore={hasMore}
      />
    </React.Fragment>
  );
}

const exports = New;
export default exports;
