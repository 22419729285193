import { Descriptions } from 'antd';
import { SubTitle } from 'components/PageTitles';
import { useFetchMaterials } from 'hooks/useFetchMaterials';
import React, { useEffect, useRef, useState } from 'react';
import { MaterialType } from 'types/material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Grapeseed } from 'api/grapeseed';
import MaterialLegend from 'components/MaterialViews/MaterialLegend';
import { MaterialList } from 'components/MaterialViews/MaterialList';
import { SearchMaterialForm } from '../Search/components/SearchMaterialsForm';
import { encodedQueryString } from 'utils/strings';

export function Materials() {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const tags = params.get('tags') ?? undefined;
  const type = params.get('type') ?? undefined;
  const lastItemRef = useRef(null);
  const [list, setList, hasMore] = useFetchMaterials({
    tags: tags,
    type: type?.split('+') as MaterialType[],
    lastItemRef: lastItemRef,
  });
  const [total, setTotal] = useState<number>(0);
  const [freeCount, setFreeCount] = useState<number>(0);
  const [premiumCount, setPremiumCount] = useState<number>(0);

  const onSubmit = (value: { id?: string; tags: string; type: string[] }) => {
    if (value.id) {
      navigate(`/materials/${value.id}`);
    } else {
      const searchUrl = new URL('/search/materials', window.origin);
      if (value.tags && value.tags.length > 0) {
        searchUrl.searchParams.set('tags', value.tags);
      }
      if (value.type && value.type.length > 0) {
        searchUrl.searchParams.set('type', value.type.join('+'));
      }
      navigate(
        `${searchUrl.pathname}?${encodedQueryString(searchUrl.searchParams)}`
      );
    }
  };

  useEffect(() => {
    if (list?.length > 0) {
      async function fetchCount() {
        const queries = new Map<string, string>();
        tags && queries.set('tags', tags.toString());
        type && queries.set('type', type);
        const total = await Grapeseed.GET(`/api/materials/count`, {
          queryParams: queries,
        });
        setTotal(total.count);
      }
      fetchCount();
    }
  }, [list?.length, tags, type]);

  useEffect(() => {
    if (list?.length > 0) {
      async function fetchCount() {
        const queries = new Map<string, string>();
        tags && queries.set('tags', tags.toString());
        type && queries.set('type', type);
        const frees = await Grapeseed.GET(`/api/materials/count`, {
          queryParams: new Map<string, string>([
            ...queries,
            ['isFree', 'true'],
          ]),
        });
        setFreeCount(frees.count);
      }
      fetchCount();
    }
  }, [list?.length, tags, type]);

  useEffect(() => {
    if (list?.length > 0) {
      async function fetchCount() {
        const queries = new Map<string, string>();
        tags && queries.set('tags', tags.toString());
        type && queries.set('type', type);
        const premiums = await Grapeseed.GET(`/api/materials/count`, {
          queryParams: new Map<string, string>([
            ...queries,
            ['isFree', 'false'],
          ]),
        });
        setPremiumCount(premiums.count);
      }
      fetchCount();
    }
  }, [list?.length, tags, type]);

  return (
    <React.Fragment>
      <Descriptions
        title={<SubTitle>Search materials</SubTitle>}
        extra={<MaterialLegend />}
      />
      <SearchMaterialForm
        labelCol={{ span: 3 }}
        wrapperCol={{ span: 15 }}
        onSubmit={onSubmit}
      />
      <hr style={{ borderTop: 'grey' }} />
      {`Searched: ${total} results (${freeCount} free(s) and ${premiumCount} premium(s))`}
      <div style={{ height: '1rem' }} />
      <MaterialList
        list={list}
        itemEditable
        onRemove={(id: string) => setList(list.filter((m) => m.id !== id))}
        lastItemRef={lastItemRef}
        hasMore={hasMore}
      />
    </React.Fragment>
  );
}
