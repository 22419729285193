import { CSSProperties } from 'react';

export enum GradientType {
  ToBottom = 'toBottom',
  ToBottomLeading = 'toBottomLeading',
  ToLeading = 'toLeading',
  ToTopLeading = 'toTopLeading',
  ToTop = 'toTop',
  ToTopTrailing = 'toTopTrailing',
  ToTrailing = 'toTrailing',
  ToBottomTrailing = 'toBottomTrailing',
  Circle = 'circle',
  Diamond = 'diamond',
}

export const normalizeColorCode = (code: string | undefined) => {
  if (!code || !code.startsWith('#')) {
    return undefined;
  }
  if (code.length === 7) {
    code += 'FF';
  }
  if (code.length !== 9) {
    return undefined;
  }
  return code.replace('#', '').toUpperCase();
};

export function gradientStyle(
  width: string | number,
  height: string | number,
  direction: GradientType,
  colors: string[]
): CSSProperties {
  let style: CSSProperties = {
    width: width,
    height: height,
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: 'var(--border-color)',
  };
  let side: string = '';
  switch (direction) {
    case GradientType.ToTopLeading:
      side = 'to left top';
      break;
    case GradientType.ToBottomLeading:
      side = 'to left bottom';
      break;
    case GradientType.ToTopTrailing:
      side = 'to right top';
      break;
    case GradientType.ToBottomTrailing:
      side = 'to right bottom';
      break;
    case GradientType.ToTop:
      side = 'to top';
      break;
    case GradientType.ToBottom:
      side = 'to bottom';
      break;
    case GradientType.ToLeading:
      side = 'to left';
      break;
    case GradientType.ToTrailing:
      side = 'to right';
      break;
    case GradientType.Circle:
      style.background = `radial-gradient(closest-side, ${colors.map(
        (c) => `#${c}`
      )})`;
      return style;
    case GradientType.Diamond:
      const stepRegion = 50 / (colors.length - 1);
      const colorSteps = colors.map((c, i) => `#${c} ${i * stepRegion}%`);
      return {
        ...style,
        background: `linear-gradient(to bottom right, ${colorSteps}) right bottom,
              linear-gradient(to bottom left, ${colorSteps}) bottom left,
              linear-gradient(to top right, ${colorSteps}) top right,
              linear-gradient(to top left, ${colorSteps}) top left`,
        backgroundSize: '50% 50%',
        backgroundRepeat: 'no-repeat',
      };
    default:
      return style;
  }
  style.background = `linear-gradient(${[
    side,
    ...colors.map((c) => `#${c}`),
  ]})`;
  return style;
}
