import { AutoComplete, Form, Input } from 'antd';
import type { SelectProps } from 'antd/es/select';
import { MaterialTypes } from 'types/material';
import { SearchFormProps } from 'pages/Search/Search';
import { useState } from 'react';
import { Grapeseed } from 'api/grapeseed';
import { useForm } from 'antd/lib/form/Form';
import { useDebounce } from 'hooks/useDebounce';
import { CheckboxGroup } from './CheckboxGroup';

interface suggest {
  value: string;
  count: number;
}

interface option {
  text: string;
}

export function SearchMaterialForm(props: SearchFormProps) {
  const [form] = useForm();
  const [hasIdQuery, setHasIdQuery] = useState<boolean>(false);
  const [suggestions, setSuggesions] = useState<SelectProps<object>['options']>(
    []
  );

  const onSearch = async (value: string) => {
    value = value.trim();
    if (value.length < 1) {
      setSuggesions([]);
      return;
    }
    // get suggestion from server
    const queryParams: Map<string, string> = new Map();
    queryParams.set('prefix', value);
    queryParams.set('size', '20');
    const type = form.getFieldValue('type');
    if (type && type.length > 0) {
      queryParams.set('types', type.join('+'));
    }

    const result = await Grapeseed.GET('/api/materials/suggest', {
      queryParams: queryParams,
    });
    if (result) {
      const options: option[] = result.options;
      const texts = options.map((o) => {
        return { value: o.text };
      });
      const countPromises = texts.map((t) => {
        return new Promise<suggest>(async (resolve, reject) => {
          const queries = new Map<string, string>();
          queries.set('tags', t.value);
          if (type && type.length > 0) {
            queries.set('type', type.join('+'));
          }
          const count = await Grapeseed.GET(`/api/materials/count`, {
            queryParams: queries,
          });
          resolve({ value: t.value, count: count.count });
        });
      });
      const countResults = await Promise.allSettled(countPromises);
      const fulfilled = countResults.filter(
        (r) => r.status === 'fulfilled'
      ) as PromiseFulfilledResult<suggest>[];
      setSuggesions(fulfilled.map((f) => renderSuggest(f.value)));
    }
  };

  const debouncedOnSearch = useDebounce(onSearch, 200);

  const doSearch = () => {
    const id = form.getFieldValue('id')?.trim();
    const keywords = form.getFieldValue('tags')?.trim();
    const types = form.getFieldValue('type');
    props.onSubmit({
      id: id,
      tags: keywords,
      type: types,
    });
  };

  const onTypeChange = (values: string[]) => {
    if (values.length === 1) {
      doSearch();
    }
  };

  const renderSuggest = (suggest: suggest) => {
    return {
      value: suggest.value,
      label: (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          {suggest.value}
          <span># {suggest.count}</span>
        </div>
      ),
    };
  };

  return (
    <Form
      form={form}
      onFinish={(values: any) => {
        doSearch();
      }}
      labelCol={props.labelCol}
      labelAlign="left"
      wrapperCol={props.wrapperCol}
      colon={false}
      style={{ margin: '1rem 0' }}
    >
      <Form.Item label="ID" name="id">
        <Input.Search
          style={{ width: `80%` }}
          enterButton
          onChange={(e) => {
            if (e.target.value) setHasIdQuery(true);
            else setHasIdQuery(false);
          }}
          onSearch={doSearch}
        />
      </Form.Item>
      <Form.Item label="Anything you want" name="tags">
        <AutoComplete
          options={suggestions}
          style={{ width: `80%` }}
          onSelect={doSearch}
          onSearch={debouncedOnSearch}
          disabled={hasIdQuery}
        >
          <Input.Search enterButton onSearch={doSearch} />
        </AutoComplete>
      </Form.Item>
      <Form.Item label="Type" name="type">
        <CheckboxGroup
          options={Object.values(MaterialTypes)}
          onChange={onTypeChange}
          disabled={hasIdQuery}
        />
      </Form.Item>
    </Form>
  );
}
