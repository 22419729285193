import React, { useRef } from 'react';
import { Font, MaterialTypes } from 'types/material';
import { SubTitle } from 'components/PageTitles';
import { MaterialList } from 'components/MaterialViews/MaterialList';
import NewFontForm from './components/NewFontForm';
import { useFetchMaterials } from 'hooks/useFetchMaterials';

function New() {
  const lastItemRef = useRef(null);
  const [list, setList, hasMore] = useFetchMaterials({
    type: [MaterialTypes.Font],
    lastItemRef: lastItemRef,
  });

  return (
    <React.Fragment>
      <SubTitle>Add a font</SubTitle>
      <NewFontForm onAdd={(f: Font) => setList([f, ...list])} />
      <hr style={{ borderTop: 'grey' }} />
      <MaterialList
        list={list}
        itemEditable
        onRemove={(id: string) => setList(list.filter((f) => f.id !== id))}
        lastItemRef={lastItemRef}
        hasMore={hasMore}
      />
    </React.Fragment>
  );
}

const exports = New;
export default exports;
