import { useFetchCreators } from 'hooks/useFetchCreators';
import {
  CreatorCardList,
  Selections,
} from 'pages/Creators/components/CreatorCardList';
import { SearchCreatorsForm } from 'pages/Search/components/SearchCreatorsForm';
import { useRef, useState } from 'react';
import viewStyle from 'styles/view.module.css';

export interface SearchCreatorViewProps {
  selections?: Selections;
}

export function SearchCreatorView(props: SearchCreatorViewProps) {
  const [query, setQuery] = useState<{
    code?: string;
  }>({});
  const lastItemRef = useRef(null);

  const [list, , hasMore] = useFetchCreators({ ...query, lastItemRef });

  const onSubmit = (values: { code?: string }) => {
    if (values.code && values.code.length > 0) {
      setQuery({ code: values.code });
    } else {
      setQuery({});
    }
  };

  return (
    <>
      <SearchCreatorsForm onSubmit={onSubmit} />
      <CreatorCardList
        className={`${viewStyle.viewbox} ${viewStyle.wrap} ${viewStyle.maxHeight600}`}
        list={list}
        itemDraggable
        showTag
        selections={props.selections}
        lastItemRef={lastItemRef}
        hasMore={hasMore}
      />
    </>
  );
}
