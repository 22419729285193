import React, { DragEvent, useCallback, useEffect, useState } from 'react';
import {
  Badge,
  Button,
  Checkbox,
  Collapse,
  Form,
  Modal,
  Popconfirm,
  Space,
} from 'antd';
import {
  ContainerStickers,
  NewMaterial,
  Sticker,
  ThumbnailAcceptTypes,
  ThumbnailFileSuffix,
} from 'types/material';
import { TagEntries } from 'components/TagEntries';
import viewStyle from 'styles/view.module.css';
import { FormatBytes, HaveSameContents, OnResponse } from 'utils/utils';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { FallbackImage } from 'utils/constants';
import { Grapeseed } from 'api/grapeseed';
import { useForm } from 'antd/lib/form/Form';
import RawJson from '../../utils/RawJson';
import { FileUploader, acceptTypesInfo } from 'components/FileUploader';
import Lottie from 'lottie-react';
import { ThumbnailView } from 'components/Thumbnail/ThumbnailView';
import { Material } from 'types/interfaces';
import { ThumbnailSizes } from 'components/Thumbnail/Constants';
import { IdPopover } from 'components/IdPopover';

interface StickerViewProps {
  sticker: Sticker;
  editable?: boolean;
  draggable?: boolean;
  onDragStart?: (e: DragEvent<HTMLDivElement>, m: Material) => void;
  onRemove?: (id: string) => void;
}

function StickerView(props: StickerViewProps) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isModified, setIsModified] = useState<boolean>(false);
  const [form] = useForm();
  const [modalForm] = useForm();
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [sticker, setSticker] = useState<Sticker>(props.sticker);
  const [lottieAnim, setLottieAnim] = useState();

  const onSubmit = async (values: any) => {
    setIsLoading(true);
    const result = await Grapeseed.PUT(`/api/stickers/${sticker.id}`, {
      body: {
        thumbnailUrl: sticker.thumbnailUrl,
        tags: values.tags ?? sticker.tags,
        isFree: values.isFree ?? false,
      },
      fallback: () => {
        form.setFieldsValue({ tags: sticker.tags });
      },
    });
    if (result) {
      setSticker(NewMaterial(result) as Sticker);
    }
    setIsLoading(false);
    setIsModified(false);
  };

  const onRemove = async (event?: React.MouseEvent<HTMLElement>) => {
    const result = await Grapeseed.DELETE(`/api/stickers/${sticker.id}`);
    if (result) {
      props.onRemove?.(sticker.id);
    }
  };

  const handleModalFormSubmit = async (values: any) => {
    setModalOpen(false);
    modalForm.resetFields();
    const result = await Grapeseed.PUT(`/api/stickers/${sticker.id}`, {
      body: {
        thumbnailUrl: values.thumbnail[0].url,
        tags: sticker.tags,
        isFree: sticker.isFree,
      },
    });
    if (result) {
      setSticker(result);
    }
  };

  const checkIsModified = (_: any, values: any) => {
    if (
      !HaveSameContents(values.tags, sticker.tags) ||
      values.isFree !== sticker.isFree
    ) {
      setIsModified(true);
    } else {
      setIsModified(false);
    }
  };

  const renderThumbnail = (sticker: Sticker) => {
    if (sticker.content.type === 'lottie/json') {
      return <Lottie animationData={lottieAnim} />;
    } else {
      return (
        <ThumbnailView
          size={ThumbnailSizes.full}
          src={sticker.thumbnailUrl ?? FallbackImage}
          preview={{ src: sticker.content.url }}
          isFree={sticker.isFree}
          style={{ marginBottom: '0.5rem' }}
        />
      );
    }
  };

  const loadLottie = useCallback(async () => {
    if (sticker.content.type === 'lottie/json' && sticker.content.url) {
      const res = await fetch(sticker.content.url);
      const result = await OnResponse(res);
      setLottieAnim(JSON.parse(result));
    }
  }, [sticker.content.type, sticker.content.url]);

  useEffect(() => {
    loadLottie();
  }, [loadLottie]);

  let divClass = `${viewStyle.viewitem} ${viewStyle.full}`;
  if (props.draggable) {
    divClass = `${viewStyle.viewitem} ${viewStyle.draggable}`;
  }
  return (
    <React.Fragment>
      <Modal
        open={modalOpen}
        title="Change Thumbnail"
        footer={[
          <Button
            key="cancel"
            onClick={() => {
              modalForm.resetFields();
              setModalOpen(false);
            }}
          >
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={() => {
              modalForm.validateFields().then((values) => {
                handleModalFormSubmit(values);
              });
            }}
          >
            Submit
          </Button>,
        ]}
      >
        <Form form={modalForm} layout="horizontal">
          <Form.Item
            name="thumbnail"
            label="Thumbnail"
            extra={acceptTypesInfo(ThumbnailAcceptTypes)}
          >
            <FileUploader
              container={ContainerStickers}
              accept={ThumbnailAcceptTypes}
              filename={sticker.id}
              suffix={ThumbnailFileSuffix}
              direction="column"
            />
          </Form.Item>
        </Form>
      </Modal>
      <div
        className={divClass}
        draggable={props.draggable}
        onDragStart={(e) => props.onDragStart?.(e, sticker)}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'stretch',
            padding: '15px 15px 0 15px',
          }}
        >
          <Badge
            count={sticker.picked ?? 0}
            showZero
            color="var(--sticker-badge-color)"
          >
            {renderThumbnail(sticker)}
          </Badge>
          <Space style={{ fontSize: '90%' }}>
            <Badge color="var(--sticker-badge-color)" />
            <IdPopover
              id={sticker.id}
              content={RawJson(sticker)}
              showHref={{ path: 'materials' }}
            />{' '}
          </Space>
          <div
            style={{
              fontSize: '90%',
              fontWeight: 'bold',
              marginBottom: '0.2rem',
            }}
          >
            {sticker.content.type}
          </div>
          <div style={{ fontSize: '90%' }}>
            {sticker.content.size.width} x {sticker.content.size.height} (
            {FormatBytes(sticker.bytes)})
          </div>
        </div>
        <div style={{ flex: '1 1 auto', padding: '20px', width: '100%' }}>
          <Form
            form={form}
            colon={false}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
              }
            }}
            onValuesChange={checkIsModified}
            onFinish={onSubmit}
          >
            <Collapse
              bordered={false}
              items={[
                {
                  key: 'tags',
                  label: 'Tags',
                  children: (
                    <Form.Item
                      name="tags"
                      label="Tags"
                      initialValue={sticker.tags}
                    >
                      <TagEntries
                        layout="vertical"
                        disabled={!props.editable}
                      />
                    </Form.Item>
                  ),
                },
              ]}
            />
            <Form.Item
              label="Free"
              name="isFree"
              valuePropName="checked"
              initialValue={sticker.isFree}
              wrapperCol={{ span: 5 }}
            >
              <Checkbox disabled={!props.editable} />
            </Form.Item>
            {props.editable && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                {sticker.content.type !== 'lottie/json' && (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <Button
                      type="primary"
                      style={{ marginBottom: '1em' }}
                      onClick={() => setModalOpen(true)}
                    >
                      Change Thumbnail
                    </Button>
                  </div>
                )}
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                  }}
                >
                  <Form.Item style={{ marginRight: '1em' }}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      disabled={!isModified}
                      loading={isLoading}
                    >
                      Save changes
                    </Button>
                  </Form.Item>
                  <Form.Item>
                    <Popconfirm
                      title="Are you sure to DELETE？"
                      icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                      onConfirm={onRemove}
                    >
                      <Button type="primary" danger>
                        Delete
                      </Button>
                    </Popconfirm>
                  </Form.Item>
                </div>
              </div>
            )}
          </Form>
        </div>
      </div>
    </React.Fragment>
  );
}

const exports = StickerView;
export default exports;
