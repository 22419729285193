import { MaterialList } from 'components/MaterialViews/MaterialList';
import { SubTitle } from 'components/PageTitles';
import { useFetchMaterials } from 'hooks/useFetchMaterials';
import { useRef } from 'react';
import viewStyle from 'styles/view.module.css';

export function SafeSearch() {
  const lastItemRef = useRef(null);
  const [materials, , hasMore] = useFetchMaterials({
    tags: '_adult+_spoof+_medical+_violence+_racy',
    lastItemRef: lastItemRef,
    includeUnsafe: true,
  });
  return (
    <>
      <SubTitle>Safe Search</SubTitle>
      <hr style={{ borderTop: 'grey' }} />
      <div style={{ marginTop: '2rem' }}>
        <MaterialList
          className={`${viewStyle.viewbox} ${viewStyle.wrap}`}
          list={materials}
          itemEditable
          itemSize="full"
          lastItemRef={lastItemRef}
          hasMore={hasMore}
        />
      </div>
    </>
  );
}
