import { UserProperties } from 'types/proto/retriver-struct_pb';
import { RequestProto, ResponseProto } from 'types/proto/retriver_pb';
import { OnError } from 'utils/errors';

let authToken: string;

export interface ApiRequest {
  path: string;
  protoBody: RequestProto;
}

export const Retriver = {
  setAuthToken(token: string) {
    authToken = token;
  },

  headers(authToken: string) {
    const requestHeader: HeadersInit = {
      Accept: 'application/x-protobuf',
    };
    if (authToken) {
      requestHeader.Authorization = `Bearer ${authToken}`;
    }
    return requestHeader;
  },

  async do(req: ApiRequest) {
    req.protoBody.properties = new UserProperties({
      deviceId: 'grapefront',
    });
    const url = new URL(req.path, process.env.REACT_APP_RETRIVER_URL);
    const res = await fetch(url, {
      method: 'POST',
      headers: this.headers(authToken),
      body: req.protoBody.toBinary(),
    });
    if (!res || !res.ok) {
      OnError(res.statusText);
      return undefined;
    }
    const blob = await res.blob().catch((r: any) => {
      OnError(r);
      return undefined;
    });
    if (!blob) {
      return undefined;
    }
    const binary = await blob.arrayBuffer().catch((r: any) => {
      OnError(r);
      return undefined;
    });
    if (!binary) {
      return undefined;
    }
    return ResponseProto.fromBinary(new Uint8Array(binary));
  },
};
