// @generated by protoc-gen-es v1.10.0 with parameter "target=ts,import_extension=.ts"
// @generated from file retriver-app-common.proto (package retrica, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64 } from "@bufbuild/protobuf";
import { ContentType, ErrorCode, ThemeType, Toggle } from "./retriver-enum_pb.ts";
import { Account, Friend, FriendsWrap, LookUpTable, Preset, Resource_Category, Resource_Stamp, Resource_Sticker, SelfieNetwork_SelfieWrap, Store_Product, TagContent } from "./retriver-struct_pb.ts";

/**
 * /h1/ac/superInit
 *
 * @generated from message retrica.SuperInitRequest
 */
export class SuperInitRequest extends Message<SuperInitRequest> {
  constructor(data?: PartialMessage<SuperInitRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.SuperInitRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SuperInitRequest {
    return new SuperInitRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SuperInitRequest {
    return new SuperInitRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SuperInitRequest {
    return new SuperInitRequest().fromJsonString(jsonString, options);
  }

  static equals(a: SuperInitRequest | PlainMessage<SuperInitRequest> | undefined, b: SuperInitRequest | PlainMessage<SuperInitRequest> | undefined): boolean {
    return proto3.util.equals(SuperInitRequest, a, b);
  }
}

/**
 * @generated from message retrica.SuperInitResponse
 */
export class SuperInitResponse extends Message<SuperInitResponse> {
  /**
   * @generated from field: retrica.ErrorCode error_code = 1;
   */
  errorCode = ErrorCode.UNKNOWN;

  /**
   * @generated from field: int64 pack_list_revision = 2;
   */
  packListRevision = protoInt64.zero;

  /**
   * @generated from field: int64 product_list_revision = 3;
   */
  productListRevision = protoInt64.zero;

  constructor(data?: PartialMessage<SuperInitResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.SuperInitResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "error_code", kind: "enum", T: proto3.getEnumType(ErrorCode) },
    { no: 2, name: "pack_list_revision", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "product_list_revision", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SuperInitResponse {
    return new SuperInitResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SuperInitResponse {
    return new SuperInitResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SuperInitResponse {
    return new SuperInitResponse().fromJsonString(jsonString, options);
  }

  static equals(a: SuperInitResponse | PlainMessage<SuperInitResponse> | undefined, b: SuperInitResponse | PlainMessage<SuperInitResponse> | undefined): boolean {
    return proto3.util.equals(SuperInitResponse, a, b);
  }
}

/**
 * /h1/ac/addSubscription
 * 제품 구독 정보 추가
 *
 * @generated from message retrica.AddSubscriptionRequest
 */
export class AddSubscriptionRequest extends Message<AddSubscriptionRequest> {
  /**
   * @generated from field: string unique_id = 1;
   */
  uniqueId = "";

  /**
   * @generated from field: string user_id = 2;
   */
  userId = "";

  /**
   * @generated from field: string receipt_data = 3;
   */
  receiptData = "";

  /**
   * @generated from field: string subscriptionId = 4;
   */
  subscriptionId = "";

  /**
   * @generated from field: string token = 5;
   */
  token = "";

  /**
   * @generated from field: string os = 6;
   */
  os = "";

  /**
   * @generated from field: string os_version = 7;
   */
  osVersion = "";

  /**
   * @generated from field: string app_version = 8;
   */
  appVersion = "";

  /**
   * @generated from field: string country = 9;
   */
  country = "";

  /**
   * @generated from field: string language = 10;
   */
  language = "";

  constructor(data?: PartialMessage<AddSubscriptionRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.AddSubscriptionRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "unique_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "user_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "receipt_data", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "subscriptionId", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "os", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "os_version", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "app_version", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "country", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "language", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AddSubscriptionRequest {
    return new AddSubscriptionRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AddSubscriptionRequest {
    return new AddSubscriptionRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AddSubscriptionRequest {
    return new AddSubscriptionRequest().fromJsonString(jsonString, options);
  }

  static equals(a: AddSubscriptionRequest | PlainMessage<AddSubscriptionRequest> | undefined, b: AddSubscriptionRequest | PlainMessage<AddSubscriptionRequest> | undefined): boolean {
    return proto3.util.equals(AddSubscriptionRequest, a, b);
  }
}

/**
 * @generated from message retrica.AddSubscriptionResponse
 */
export class AddSubscriptionResponse extends Message<AddSubscriptionResponse> {
  /**
   * @generated from field: retrica.ErrorCode error_code = 1;
   */
  errorCode = ErrorCode.UNKNOWN;

  constructor(data?: PartialMessage<AddSubscriptionResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.AddSubscriptionResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "error_code", kind: "enum", T: proto3.getEnumType(ErrorCode) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AddSubscriptionResponse {
    return new AddSubscriptionResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AddSubscriptionResponse {
    return new AddSubscriptionResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AddSubscriptionResponse {
    return new AddSubscriptionResponse().fromJsonString(jsonString, options);
  }

  static equals(a: AddSubscriptionResponse | PlainMessage<AddSubscriptionResponse> | undefined, b: AddSubscriptionResponse | PlainMessage<AddSubscriptionResponse> | undefined): boolean {
    return proto3.util.equals(AddSubscriptionResponse, a, b);
  }
}

/**
 * /h1/ac/verifyReceipt
 * 구독 결제 영수증 증명
 *
 * @generated from message retrica.VerifyReceiptRequest
 */
export class VerifyReceiptRequest extends Message<VerifyReceiptRequest> {
  /**
   * @generated from field: string unique_id = 1;
   */
  uniqueId = "";

  /**
   * @generated from field: string user_id = 2;
   */
  userId = "";

  /**
   * @generated from field: string receipt_data = 3;
   */
  receiptData = "";

  /**
   * @generated from field: string subscriptionId = 4;
   */
  subscriptionId = "";

  /**
   * @generated from field: string token = 5;
   */
  token = "";

  /**
   * @generated from field: string os = 6;
   */
  os = "";

  /**
   * @generated from field: string os_version = 7;
   */
  osVersion = "";

  /**
   * @generated from field: string app_version = 8;
   */
  appVersion = "";

  /**
   * @generated from field: string country = 9;
   */
  country = "";

  /**
   * @generated from field: string language = 10;
   */
  language = "";

  constructor(data?: PartialMessage<VerifyReceiptRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.VerifyReceiptRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "unique_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "user_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "receipt_data", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "subscriptionId", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "os", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "os_version", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "app_version", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "country", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "language", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): VerifyReceiptRequest {
    return new VerifyReceiptRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): VerifyReceiptRequest {
    return new VerifyReceiptRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): VerifyReceiptRequest {
    return new VerifyReceiptRequest().fromJsonString(jsonString, options);
  }

  static equals(a: VerifyReceiptRequest | PlainMessage<VerifyReceiptRequest> | undefined, b: VerifyReceiptRequest | PlainMessage<VerifyReceiptRequest> | undefined): boolean {
    return proto3.util.equals(VerifyReceiptRequest, a, b);
  }
}

/**
 * @generated from message retrica.VerifyReceiptResponse
 */
export class VerifyReceiptResponse extends Message<VerifyReceiptResponse> {
  /**
   * @generated from field: retrica.ErrorCode error_code = 1;
   */
  errorCode = ErrorCode.UNKNOWN;

  /**
   * @generated from field: string error_description = 2;
   */
  errorDescription = "";

  /**
   * @generated from field: string receipt_data = 3;
   */
  receiptData = "";

  /**
   * @generated from field: string quantity = 4;
   */
  quantity = "";

  /**
   * @generated from field: string product_id = 5;
   */
  productId = "";

  /**
   * @generated from field: string transaction_id = 6;
   */
  transactionId = "";

  /**
   * @generated from field: string original_transaction_id = 7;
   */
  originalTransactionId = "";

  /**
   * @generated from field: string web_order_line_item_id = 8;
   */
  webOrderLineItemId = "";

  /**
   * @generated from field: string is_trial_period = 9;
   */
  isTrialPeriod = "";

  /**
   * @generated from field: string is_in_intro_offer_period = 10;
   */
  isInIntroOfferPeriod = "";

  /**
   * @generated from field: int64 expire_at = 11;
   */
  expireAt = protoInt64.zero;

  /**
   * @generated from field: int64 purchase_date = 12;
   */
  purchaseDate = protoInt64.zero;

  /**
   * @generated from field: int64 original_purchase_date = 13;
   */
  originalPurchaseDate = protoInt64.zero;

  /**
   * @generated from field: int64 cancellation_date = 14;
   */
  cancellationDate = protoInt64.zero;

  /**
   * @generated from field: int64 cancellation_reason = 15;
   */
  cancellationReason = protoInt64.zero;

  constructor(data?: PartialMessage<VerifyReceiptResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.VerifyReceiptResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "error_code", kind: "enum", T: proto3.getEnumType(ErrorCode) },
    { no: 2, name: "error_description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "receipt_data", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "quantity", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "product_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "transaction_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "original_transaction_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "web_order_line_item_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "is_trial_period", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "is_in_intro_offer_period", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "expire_at", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 12, name: "purchase_date", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 13, name: "original_purchase_date", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 14, name: "cancellation_date", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 15, name: "cancellation_reason", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): VerifyReceiptResponse {
    return new VerifyReceiptResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): VerifyReceiptResponse {
    return new VerifyReceiptResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): VerifyReceiptResponse {
    return new VerifyReceiptResponse().fromJsonString(jsonString, options);
  }

  static equals(a: VerifyReceiptResponse | PlainMessage<VerifyReceiptResponse> | undefined, b: VerifyReceiptResponse | PlainMessage<VerifyReceiptResponse> | undefined): boolean {
    return proto3.util.equals(VerifyReceiptResponse, a, b);
  }
}

/**
 * /a2/ac/updateAccount
 * 개인 정보 업데이트
 *
 * @generated from message retrica.UpdateAccountRequest
 */
export class UpdateAccountRequest extends Message<UpdateAccountRequest> {
  /**
   * @generated from field: retrica.Toggle push_toggle = 1;
   */
  pushToggle = Toggle.TG_NONE;

  /**
   * @generated from field: string push_token = 2;
   */
  pushToken = "";

  /**
   * @generated from field: retrica.Toggle location_toggle = 3;
   */
  locationToggle = Toggle.TG_NONE;

  /**
   * @generated from field: string username = 4;
   */
  username = "";

  /**
   * @generated from field: string fullname = 5;
   */
  fullname = "";

  /**
   * format: YYYY-MM-DD
   *
   * @generated from field: string birth = 6;
   */
  birth = "";

  /**
   * M or F
   *
   * @generated from field: string gender = 7;
   */
  gender = "";

  /**
   * 색상
   *
   * @generated from field: retrica.ThemeType theme_type = 8;
   */
  themeType = ThemeType.TT_NONE;

  /**
   * 상태
   *
   * @generated from field: string bio = 9;
   */
  bio = "";

  /**
   * @generated from field: bool is_up_bio = 10;
   */
  isUpBio = false;

  /**
   * snapchat
   *
   * @generated from field: string snapchat_name = 11;
   */
  snapchatName = "";

  /**
   * @generated from field: bool is_up_snapchat_name = 12;
   */
  isUpSnapchatName = false;

  /**
   * instagram
   *
   * @generated from field: string instagram_name = 13;
   */
  instagramName = "";

  /**
   * @generated from field: bool is_up_instagram_name = 14;
   */
  isUpInstagramName = false;

  /**
   * musically
   *
   * @generated from field: string musically_name = 15;
   */
  musicallyName = "";

  /**
   * @generated from field: bool is_up_musically_name = 16;
   */
  isUpMusicallyName = false;

  /**
   * kik
   *
   * @generated from field: string kik_name = 17;
   */
  kikName = "";

  /**
   * @generated from field: bool is_up_kik_name = 18;
   */
  isUpKikName = false;

  /**
   * profile
   *
   * @generated from field: bytes profile = 19;
   */
  profile = new Uint8Array(0);

  /**
   * @generated from field: retrica.ContentType profile_type = 20;
   */
  profileType = ContentType.COT_NONE;

  constructor(data?: PartialMessage<UpdateAccountRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.UpdateAccountRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "push_toggle", kind: "enum", T: proto3.getEnumType(Toggle) },
    { no: 2, name: "push_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "location_toggle", kind: "enum", T: proto3.getEnumType(Toggle) },
    { no: 4, name: "username", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "fullname", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "birth", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "gender", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "theme_type", kind: "enum", T: proto3.getEnumType(ThemeType) },
    { no: 9, name: "bio", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "is_up_bio", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 11, name: "snapchat_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 12, name: "is_up_snapchat_name", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 13, name: "instagram_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 14, name: "is_up_instagram_name", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 15, name: "musically_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 16, name: "is_up_musically_name", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 17, name: "kik_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 18, name: "is_up_kik_name", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 19, name: "profile", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
    { no: 20, name: "profile_type", kind: "enum", T: proto3.getEnumType(ContentType) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateAccountRequest {
    return new UpdateAccountRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateAccountRequest {
    return new UpdateAccountRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateAccountRequest {
    return new UpdateAccountRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateAccountRequest | PlainMessage<UpdateAccountRequest> | undefined, b: UpdateAccountRequest | PlainMessage<UpdateAccountRequest> | undefined): boolean {
    return proto3.util.equals(UpdateAccountRequest, a, b);
  }
}

/**
 * @generated from message retrica.UpdateAccountResponse
 */
export class UpdateAccountResponse extends Message<UpdateAccountResponse> {
  /**
   * SUCCESS
   *
   * @generated from field: retrica.ErrorCode error_code = 1;
   */
  errorCode = ErrorCode.UNKNOWN;

  /**
   * @generated from field: retrica.Account account = 2;
   */
  account?: Account;

  constructor(data?: PartialMessage<UpdateAccountResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.UpdateAccountResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "error_code", kind: "enum", T: proto3.getEnumType(ErrorCode) },
    { no: 2, name: "account", kind: "message", T: Account },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateAccountResponse {
    return new UpdateAccountResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateAccountResponse {
    return new UpdateAccountResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateAccountResponse {
    return new UpdateAccountResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateAccountResponse | PlainMessage<UpdateAccountResponse> | undefined, b: UpdateAccountResponse | PlainMessage<UpdateAccountResponse> | undefined): boolean {
    return proto3.util.equals(UpdateAccountResponse, a, b);
  }
}

/**
 * /1/ac/sendEmailResetPassword
 * Password 찾기 위한 이메일을 전송한다.
 *
 * @generated from message retrica.SendEmailResetPasswordRequest
 */
export class SendEmailResetPasswordRequest extends Message<SendEmailResetPasswordRequest> {
  /**
   * @generated from field: string email = 1;
   */
  email = "";

  constructor(data?: PartialMessage<SendEmailResetPasswordRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.SendEmailResetPasswordRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SendEmailResetPasswordRequest {
    return new SendEmailResetPasswordRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SendEmailResetPasswordRequest {
    return new SendEmailResetPasswordRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SendEmailResetPasswordRequest {
    return new SendEmailResetPasswordRequest().fromJsonString(jsonString, options);
  }

  static equals(a: SendEmailResetPasswordRequest | PlainMessage<SendEmailResetPasswordRequest> | undefined, b: SendEmailResetPasswordRequest | PlainMessage<SendEmailResetPasswordRequest> | undefined): boolean {
    return proto3.util.equals(SendEmailResetPasswordRequest, a, b);
  }
}

/**
 * @generated from message retrica.SendEmailResetPasswordResponse
 */
export class SendEmailResetPasswordResponse extends Message<SendEmailResetPasswordResponse> {
  /**
   * SUCCESS
   *
   * @generated from field: retrica.ErrorCode error_code = 1;
   */
  errorCode = ErrorCode.UNKNOWN;

  constructor(data?: PartialMessage<SendEmailResetPasswordResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.SendEmailResetPasswordResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "error_code", kind: "enum", T: proto3.getEnumType(ErrorCode) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SendEmailResetPasswordResponse {
    return new SendEmailResetPasswordResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SendEmailResetPasswordResponse {
    return new SendEmailResetPasswordResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SendEmailResetPasswordResponse {
    return new SendEmailResetPasswordResponse().fromJsonString(jsonString, options);
  }

  static equals(a: SendEmailResetPasswordResponse | PlainMessage<SendEmailResetPasswordResponse> | undefined, b: SendEmailResetPasswordResponse | PlainMessage<SendEmailResetPasswordResponse> | undefined): boolean {
    return proto3.util.equals(SendEmailResetPasswordResponse, a, b);
  }
}

/**
 * /1/ac/resources
 * sticker, stamp 리소스 가져온다.
 *
 * @generated from message retrica.ResourcesRequest
 */
export class ResourcesRequest extends Message<ResourcesRequest> {
  /**
   * @generated from field: int64 sticker_revision = 1;
   */
  stickerRevision = protoInt64.zero;

  /**
   * @generated from field: int64 stamp_revision = 2;
   */
  stampRevision = protoInt64.zero;

  /**
   * @generated from field: string filter_revision = 3;
   */
  filterRevision = "";

  constructor(data?: PartialMessage<ResourcesRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.ResourcesRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "sticker_revision", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "stamp_revision", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "filter_revision", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ResourcesRequest {
    return new ResourcesRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ResourcesRequest {
    return new ResourcesRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ResourcesRequest {
    return new ResourcesRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ResourcesRequest | PlainMessage<ResourcesRequest> | undefined, b: ResourcesRequest | PlainMessage<ResourcesRequest> | undefined): boolean {
    return proto3.util.equals(ResourcesRequest, a, b);
  }
}

/**
 * @generated from message retrica.ResourcesResponse
 */
export class ResourcesResponse extends Message<ResourcesResponse> {
  /**
   * @generated from field: retrica.ErrorCode error_code = 1;
   */
  errorCode = ErrorCode.UNKNOWN;

  /**
   * @generated from field: repeated retrica.Resource.Sticker stickers = 2;
   */
  stickers: Resource_Sticker[] = [];

  /**
   * @generated from field: repeated retrica.Resource.Stamp stamps = 3;
   */
  stamps: Resource_Stamp[] = [];

  /**
   * @generated from field: repeated retrica.Resource.Category categories = 4;
   */
  categories: Resource_Category[] = [];

  /**
   * @generated from field: int64 sticker_revision = 5;
   */
  stickerRevision = protoInt64.zero;

  /**
   * @generated from field: int64 stamp_revision = 6;
   */
  stampRevision = protoInt64.zero;

  /**
   * @generated from field: string filter_revision = 7;
   */
  filterRevision = "";

  /**
   * @generated from field: string filter_json = 8;
   */
  filterJson = "";

  constructor(data?: PartialMessage<ResourcesResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.ResourcesResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "error_code", kind: "enum", T: proto3.getEnumType(ErrorCode) },
    { no: 2, name: "stickers", kind: "message", T: Resource_Sticker, repeated: true },
    { no: 3, name: "stamps", kind: "message", T: Resource_Stamp, repeated: true },
    { no: 4, name: "categories", kind: "message", T: Resource_Category, repeated: true },
    { no: 5, name: "sticker_revision", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 6, name: "stamp_revision", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 7, name: "filter_revision", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "filter_json", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ResourcesResponse {
    return new ResourcesResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ResourcesResponse {
    return new ResourcesResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ResourcesResponse {
    return new ResourcesResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ResourcesResponse | PlainMessage<ResourcesResponse> | undefined, b: ResourcesResponse | PlainMessage<ResourcesResponse> | undefined): boolean {
    return proto3.util.equals(ResourcesResponse, a, b);
  }
}

/**
 * /1/ac/products
 * filter등 store의 최신 제품 목록을 가져온다.
 *
 * @generated from message retrica.ProductsRequest
 */
export class ProductsRequest extends Message<ProductsRequest> {
  /**
   * offset 정보
   *
   * @generated from field: string offset = 1;
   */
  offset = "";

  constructor(data?: PartialMessage<ProductsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.ProductsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "offset", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ProductsRequest {
    return new ProductsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ProductsRequest {
    return new ProductsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ProductsRequest {
    return new ProductsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ProductsRequest | PlainMessage<ProductsRequest> | undefined, b: ProductsRequest | PlainMessage<ProductsRequest> | undefined): boolean {
    return proto3.util.equals(ProductsRequest, a, b);
  }
}

/**
 * @generated from message retrica.ProductsResponse
 */
export class ProductsResponse extends Message<ProductsResponse> {
  /**
   * @generated from field: retrica.ErrorCode error_code = 1;
   */
  errorCode = ErrorCode.UNKNOWN;

  /**
   * product 리스트
   *
   * @generated from field: repeated retrica.Store.Product products = 2;
   */
  products: Store_Product[] = [];

  /**
   * offset 정보
   *
   * @generated from field: string offset = 3;
   */
  offset = "";

  constructor(data?: PartialMessage<ProductsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.ProductsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "error_code", kind: "enum", T: proto3.getEnumType(ErrorCode) },
    { no: 2, name: "products", kind: "message", T: Store_Product, repeated: true },
    { no: 3, name: "offset", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ProductsResponse {
    return new ProductsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ProductsResponse {
    return new ProductsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ProductsResponse {
    return new ProductsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ProductsResponse | PlainMessage<ProductsResponse> | undefined, b: ProductsResponse | PlainMessage<ProductsResponse> | undefined): boolean {
    return proto3.util.equals(ProductsResponse, a, b);
  }
}

/**
 * /1/ac/tag/pack/contents
 * packId의 pack 기준으로 추천된 컨텐츠를 가져온다.
 *
 * @generated from message retrica.PackContentsRequest
 */
export class PackContentsRequest extends Message<PackContentsRequest> {
  /**
   * packId
   *
   * @generated from field: string packId = 1;
   */
  packId = "";

  constructor(data?: PartialMessage<PackContentsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.PackContentsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "packId", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PackContentsRequest {
    return new PackContentsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PackContentsRequest {
    return new PackContentsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PackContentsRequest {
    return new PackContentsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: PackContentsRequest | PlainMessage<PackContentsRequest> | undefined, b: PackContentsRequest | PlainMessage<PackContentsRequest> | undefined): boolean {
    return proto3.util.equals(PackContentsRequest, a, b);
  }
}

/**
 * @generated from message retrica.PackContentsResponse
 */
export class PackContentsResponse extends Message<PackContentsResponse> {
  /**
   * @generated from field: retrica.ErrorCode error_code = 1;
   */
  errorCode = ErrorCode.UNKNOWN;

  /**
   * tagContents 리스트
   *
   * @generated from field: repeated retrica.TagContent tagContents = 2;
   */
  tagContents: TagContent[] = [];

  constructor(data?: PartialMessage<PackContentsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.PackContentsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "error_code", kind: "enum", T: proto3.getEnumType(ErrorCode) },
    { no: 2, name: "tagContents", kind: "message", T: TagContent, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PackContentsResponse {
    return new PackContentsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PackContentsResponse {
    return new PackContentsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PackContentsResponse {
    return new PackContentsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: PackContentsResponse | PlainMessage<PackContentsResponse> | undefined, b: PackContentsResponse | PlainMessage<PackContentsResponse> | undefined): boolean {
    return proto3.util.equals(PackContentsResponse, a, b);
  }
}

/**
 * /1/ac/tag/filter/contents
 * filterId의 filter 기준으로 추천된 컨텐츠를 가져온다.
 *
 * @generated from message retrica.FilterContentsRequest
 */
export class FilterContentsRequest extends Message<FilterContentsRequest> {
  /**
   * filterId
   *
   * @generated from field: string filterId = 1;
   */
  filterId = "";

  constructor(data?: PartialMessage<FilterContentsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.FilterContentsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "filterId", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FilterContentsRequest {
    return new FilterContentsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FilterContentsRequest {
    return new FilterContentsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FilterContentsRequest {
    return new FilterContentsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: FilterContentsRequest | PlainMessage<FilterContentsRequest> | undefined, b: FilterContentsRequest | PlainMessage<FilterContentsRequest> | undefined): boolean {
    return proto3.util.equals(FilterContentsRequest, a, b);
  }
}

/**
 * @generated from message retrica.FilterContentsResponse
 */
export class FilterContentsResponse extends Message<FilterContentsResponse> {
  /**
   * @generated from field: retrica.ErrorCode error_code = 1;
   */
  errorCode = ErrorCode.UNKNOWN;

  /**
   * tagContents 리스트
   *
   * @generated from field: repeated retrica.TagContent tagContents = 2;
   */
  tagContents: TagContent[] = [];

  constructor(data?: PartialMessage<FilterContentsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.FilterContentsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "error_code", kind: "enum", T: proto3.getEnumType(ErrorCode) },
    { no: 2, name: "tagContents", kind: "message", T: TagContent, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FilterContentsResponse {
    return new FilterContentsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FilterContentsResponse {
    return new FilterContentsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FilterContentsResponse {
    return new FilterContentsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: FilterContentsResponse | PlainMessage<FilterContentsResponse> | undefined, b: FilterContentsResponse | PlainMessage<FilterContentsResponse> | undefined): boolean {
    return proto3.util.equals(FilterContentsResponse, a, b);
  }
}

/**
 * /a2/ac/sendSms
 * 계정과 전화번호를 연결하기 위해 전화번호 verification sms 전송한다.
 *
 * @generated from message retrica.SendSmsRequest
 */
export class SendSmsRequest extends Message<SendSmsRequest> {
  /**
   * @generated from field: string phone_number = 1;
   */
  phoneNumber = "";

  /**
   * @generated from field: string country_code = 2;
   */
  countryCode = "";

  constructor(data?: PartialMessage<SendSmsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.SendSmsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "phone_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "country_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SendSmsRequest {
    return new SendSmsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SendSmsRequest {
    return new SendSmsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SendSmsRequest {
    return new SendSmsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: SendSmsRequest | PlainMessage<SendSmsRequest> | undefined, b: SendSmsRequest | PlainMessage<SendSmsRequest> | undefined): boolean {
    return proto3.util.equals(SendSmsRequest, a, b);
  }
}

/**
 * @generated from message retrica.SendSmsResponse
 */
export class SendSmsResponse extends Message<SendSmsResponse> {
  /**
   * @generated from field: retrica.ErrorCode error_code = 1;
   */
  errorCode = ErrorCode.UNKNOWN;

  /**
   * @generated from field: string phone_number = 2;
   */
  phoneNumber = "";

  /**
   * @generated from field: string country_code = 3;
   */
  countryCode = "";

  constructor(data?: PartialMessage<SendSmsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.SendSmsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "error_code", kind: "enum", T: proto3.getEnumType(ErrorCode) },
    { no: 2, name: "phone_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "country_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SendSmsResponse {
    return new SendSmsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SendSmsResponse {
    return new SendSmsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SendSmsResponse {
    return new SendSmsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: SendSmsResponse | PlainMessage<SendSmsResponse> | undefined, b: SendSmsResponse | PlainMessage<SendSmsResponse> | undefined): boolean {
    return proto3.util.equals(SendSmsResponse, a, b);
  }
}

/**
 * /a2/ac/friendRangeSearch
 * Username으로 Range 검색
 *
 * @generated from message retrica.FriendRangeSearchRequest
 */
export class FriendRangeSearchRequest extends Message<FriendRangeSearchRequest> {
  /**
   * @generated from field: string username = 1;
   */
  username = "";

  constructor(data?: PartialMessage<FriendRangeSearchRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.FriendRangeSearchRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "username", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FriendRangeSearchRequest {
    return new FriendRangeSearchRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FriendRangeSearchRequest {
    return new FriendRangeSearchRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FriendRangeSearchRequest {
    return new FriendRangeSearchRequest().fromJsonString(jsonString, options);
  }

  static equals(a: FriendRangeSearchRequest | PlainMessage<FriendRangeSearchRequest> | undefined, b: FriendRangeSearchRequest | PlainMessage<FriendRangeSearchRequest> | undefined): boolean {
    return proto3.util.equals(FriendRangeSearchRequest, a, b);
  }
}

/**
 * @generated from message retrica.FriendRangeSearchResponse
 */
export class FriendRangeSearchResponse extends Message<FriendRangeSearchResponse> {
  /**
   * @generated from field: retrica.ErrorCode error_code = 1;
   */
  errorCode = ErrorCode.UNKNOWN;

  /**
   * @generated from field: repeated retrica.Friend friends = 3;
   */
  friends: Friend[] = [];

  constructor(data?: PartialMessage<FriendRangeSearchResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.FriendRangeSearchResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "error_code", kind: "enum", T: proto3.getEnumType(ErrorCode) },
    { no: 3, name: "friends", kind: "message", T: Friend, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FriendRangeSearchResponse {
    return new FriendRangeSearchResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FriendRangeSearchResponse {
    return new FriendRangeSearchResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FriendRangeSearchResponse {
    return new FriendRangeSearchResponse().fromJsonString(jsonString, options);
  }

  static equals(a: FriendRangeSearchResponse | PlainMessage<FriendRangeSearchResponse> | undefined, b: FriendRangeSearchResponse | PlainMessage<FriendRangeSearchResponse> | undefined): boolean {
    return proto3.util.equals(FriendRangeSearchResponse, a, b);
  }
}

/**
 * /a2/ac/friendSearch
 * userID 로 친구를 찾는다. <내부용>
 *
 * @generated from message retrica.FriendSearchRequest
 */
export class FriendSearchRequest extends Message<FriendSearchRequest> {
  /**
   * optional user_id 리스트
   *
   * @generated from field: repeated string user_ids = 1;
   */
  userIds: string[] = [];

  /**
   * optional username 리스트
   *
   * @generated from field: repeated string usernames = 2;
   */
  usernames: string[] = [];

  constructor(data?: PartialMessage<FriendSearchRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.FriendSearchRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 2, name: "usernames", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FriendSearchRequest {
    return new FriendSearchRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FriendSearchRequest {
    return new FriendSearchRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FriendSearchRequest {
    return new FriendSearchRequest().fromJsonString(jsonString, options);
  }

  static equals(a: FriendSearchRequest | PlainMessage<FriendSearchRequest> | undefined, b: FriendSearchRequest | PlainMessage<FriendSearchRequest> | undefined): boolean {
    return proto3.util.equals(FriendSearchRequest, a, b);
  }
}

/**
 * @generated from message retrica.FriendSearchResponse
 */
export class FriendSearchResponse extends Message<FriendSearchResponse> {
  /**
   * @generated from field: retrica.ErrorCode error_code = 1;
   */
  errorCode = ErrorCode.UNKNOWN;

  /**
   * @generated from field: repeated retrica.Friend friends = 2;
   */
  friends: Friend[] = [];

  constructor(data?: PartialMessage<FriendSearchResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.FriendSearchResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "error_code", kind: "enum", T: proto3.getEnumType(ErrorCode) },
    { no: 2, name: "friends", kind: "message", T: Friend, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FriendSearchResponse {
    return new FriendSearchResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FriendSearchResponse {
    return new FriendSearchResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FriendSearchResponse {
    return new FriendSearchResponse().fromJsonString(jsonString, options);
  }

  static equals(a: FriendSearchResponse | PlainMessage<FriendSearchResponse> | undefined, b: FriendSearchResponse | PlainMessage<FriendSearchResponse> | undefined): boolean {
    return proto3.util.equals(FriendSearchResponse, a, b);
  }
}

/**
 * /h1/ac/followingFriends
 * 요청하는 following 리스트를 가져온다.
 *
 * @generated from message retrica.FollowingFriendsRequest
 */
export class FollowingFriendsRequest extends Message<FollowingFriendsRequest> {
  /**
   * 해당 user_id
   *
   * @generated from field: string target_id = 1;
   */
  targetId = "";

  /**
   * offset 위치
   *
   * @generated from field: int64 offset = 2;
   */
  offset = protoInt64.zero;

  constructor(data?: PartialMessage<FollowingFriendsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.FollowingFriendsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "target_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "offset", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FollowingFriendsRequest {
    return new FollowingFriendsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FollowingFriendsRequest {
    return new FollowingFriendsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FollowingFriendsRequest {
    return new FollowingFriendsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: FollowingFriendsRequest | PlainMessage<FollowingFriendsRequest> | undefined, b: FollowingFriendsRequest | PlainMessage<FollowingFriendsRequest> | undefined): boolean {
    return proto3.util.equals(FollowingFriendsRequest, a, b);
  }
}

/**
 * @generated from message retrica.FollowingFriendsResponse
 */
export class FollowingFriendsResponse extends Message<FollowingFriendsResponse> {
  /**
   * @generated from field: retrica.ErrorCode error_code = 1;
   */
  errorCode = ErrorCode.UNKNOWN;

  /**
   * @generated from field: repeated retrica.Friend list = 2;
   */
  list: Friend[] = [];

  /**
   * 다음 offset 위치
   *
   * @generated from field: int64 offset = 3;
   */
  offset = protoInt64.zero;

  constructor(data?: PartialMessage<FollowingFriendsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.FollowingFriendsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "error_code", kind: "enum", T: proto3.getEnumType(ErrorCode) },
    { no: 2, name: "list", kind: "message", T: Friend, repeated: true },
    { no: 3, name: "offset", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FollowingFriendsResponse {
    return new FollowingFriendsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FollowingFriendsResponse {
    return new FollowingFriendsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FollowingFriendsResponse {
    return new FollowingFriendsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: FollowingFriendsResponse | PlainMessage<FollowingFriendsResponse> | undefined, b: FollowingFriendsResponse | PlainMessage<FollowingFriendsResponse> | undefined): boolean {
    return proto3.util.equals(FollowingFriendsResponse, a, b);
  }
}

/**
 * /h1/ac/followerFriends
 * 요청하는 follower 리스트를 가져온다.
 *
 * @generated from message retrica.FollowerFriendsRequest
 */
export class FollowerFriendsRequest extends Message<FollowerFriendsRequest> {
  /**
   * 해당 user_id
   *
   * @generated from field: string target_id = 1;
   */
  targetId = "";

  /**
   * offset 위치
   *
   * @generated from field: int64 offset = 2;
   */
  offset = protoInt64.zero;

  constructor(data?: PartialMessage<FollowerFriendsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.FollowerFriendsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "target_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "offset", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FollowerFriendsRequest {
    return new FollowerFriendsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FollowerFriendsRequest {
    return new FollowerFriendsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FollowerFriendsRequest {
    return new FollowerFriendsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: FollowerFriendsRequest | PlainMessage<FollowerFriendsRequest> | undefined, b: FollowerFriendsRequest | PlainMessage<FollowerFriendsRequest> | undefined): boolean {
    return proto3.util.equals(FollowerFriendsRequest, a, b);
  }
}

/**
 * @generated from message retrica.FollowerFriendsResponse
 */
export class FollowerFriendsResponse extends Message<FollowerFriendsResponse> {
  /**
   * @generated from field: retrica.ErrorCode error_code = 1;
   */
  errorCode = ErrorCode.UNKNOWN;

  /**
   * @generated from field: repeated retrica.Friend list = 2;
   */
  list: Friend[] = [];

  /**
   * 다음 offset 위치
   *
   * @generated from field: int64 offset = 3;
   */
  offset = protoInt64.zero;

  constructor(data?: PartialMessage<FollowerFriendsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.FollowerFriendsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "error_code", kind: "enum", T: proto3.getEnumType(ErrorCode) },
    { no: 2, name: "list", kind: "message", T: Friend, repeated: true },
    { no: 3, name: "offset", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FollowerFriendsResponse {
    return new FollowerFriendsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FollowerFriendsResponse {
    return new FollowerFriendsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FollowerFriendsResponse {
    return new FollowerFriendsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: FollowerFriendsResponse | PlainMessage<FollowerFriendsResponse> | undefined, b: FollowerFriendsResponse | PlainMessage<FollowerFriendsResponse> | undefined): boolean {
    return proto3.util.equals(FollowerFriendsResponse, a, b);
  }
}

/**
 * /a2/ac/contactFriends
 * 연락처 기반 친추 추천 가능 리스트
 *
 * @generated from message retrica.ContactFriendsRequest
 */
export class ContactFriendsRequest extends Message<ContactFriendsRequest> {
  constructor(data?: PartialMessage<ContactFriendsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.ContactFriendsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ContactFriendsRequest {
    return new ContactFriendsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ContactFriendsRequest {
    return new ContactFriendsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ContactFriendsRequest {
    return new ContactFriendsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ContactFriendsRequest | PlainMessage<ContactFriendsRequest> | undefined, b: ContactFriendsRequest | PlainMessage<ContactFriendsRequest> | undefined): boolean {
    return proto3.util.equals(ContactFriendsRequest, a, b);
  }
}

/**
 * @generated from message retrica.ContactFriendsResponse
 */
export class ContactFriendsResponse extends Message<ContactFriendsResponse> {
  /**
   * SUCCESS
   *
   * @generated from field: retrica.ErrorCode error_code = 1;
   */
  errorCode = ErrorCode.UNKNOWN;

  /**
   * @generated from field: repeated retrica.Friend list = 2;
   */
  list: Friend[] = [];

  constructor(data?: PartialMessage<ContactFriendsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.ContactFriendsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "error_code", kind: "enum", T: proto3.getEnumType(ErrorCode) },
    { no: 2, name: "list", kind: "message", T: Friend, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ContactFriendsResponse {
    return new ContactFriendsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ContactFriendsResponse {
    return new ContactFriendsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ContactFriendsResponse {
    return new ContactFriendsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ContactFriendsResponse | PlainMessage<ContactFriendsResponse> | undefined, b: ContactFriendsResponse | PlainMessage<ContactFriendsResponse> | undefined): boolean {
    return proto3.util.equals(ContactFriendsResponse, a, b);
  }
}

/**
 * /a2/ac/suggestFriends
 * 추천 기반 친구 리스트
 *
 * @generated from message retrica.SuggestFriendsRequest
 */
export class SuggestFriendsRequest extends Message<SuggestFriendsRequest> {
  constructor(data?: PartialMessage<SuggestFriendsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.SuggestFriendsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SuggestFriendsRequest {
    return new SuggestFriendsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SuggestFriendsRequest {
    return new SuggestFriendsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SuggestFriendsRequest {
    return new SuggestFriendsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: SuggestFriendsRequest | PlainMessage<SuggestFriendsRequest> | undefined, b: SuggestFriendsRequest | PlainMessage<SuggestFriendsRequest> | undefined): boolean {
    return proto3.util.equals(SuggestFriendsRequest, a, b);
  }
}

/**
 * @generated from message retrica.SuggestFriendsResponse
 */
export class SuggestFriendsResponse extends Message<SuggestFriendsResponse> {
  /**
   * SUCCESS
   *
   * @generated from field: retrica.ErrorCode error_code = 1;
   */
  errorCode = ErrorCode.UNKNOWN;

  /**
   * @generated from field: repeated retrica.Friend list = 2;
   */
  list: Friend[] = [];

  constructor(data?: PartialMessage<SuggestFriendsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.SuggestFriendsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "error_code", kind: "enum", T: proto3.getEnumType(ErrorCode) },
    { no: 2, name: "list", kind: "message", T: Friend, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SuggestFriendsResponse {
    return new SuggestFriendsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SuggestFriendsResponse {
    return new SuggestFriendsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SuggestFriendsResponse {
    return new SuggestFriendsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: SuggestFriendsResponse | PlainMessage<SuggestFriendsResponse> | undefined, b: SuggestFriendsResponse | PlainMessage<SuggestFriendsResponse> | undefined): boolean {
    return proto3.util.equals(SuggestFriendsResponse, a, b);
  }
}

/**
 * /a2/ac/blockedFriends
 * 블럭된 친구를 반환 한다.
 *
 * @generated from message retrica.BlockedFriendsRequest
 */
export class BlockedFriendsRequest extends Message<BlockedFriendsRequest> {
  /**
   * DEPRECATED
   *
   * @generated from field: int64 DEPRECATED_block_revision = 1;
   */
  DEPRECATEDBlockRevision = protoInt64.zero;

  constructor(data?: PartialMessage<BlockedFriendsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.BlockedFriendsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "DEPRECATED_block_revision", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): BlockedFriendsRequest {
    return new BlockedFriendsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): BlockedFriendsRequest {
    return new BlockedFriendsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): BlockedFriendsRequest {
    return new BlockedFriendsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: BlockedFriendsRequest | PlainMessage<BlockedFriendsRequest> | undefined, b: BlockedFriendsRequest | PlainMessage<BlockedFriendsRequest> | undefined): boolean {
    return proto3.util.equals(BlockedFriendsRequest, a, b);
  }
}

/**
 * @generated from message retrica.BlockedFriendsResponse
 */
export class BlockedFriendsResponse extends Message<BlockedFriendsResponse> {
  /**
   * SUCCESS
   *
   * @generated from field: retrica.ErrorCode error_code = 1;
   */
  errorCode = ErrorCode.UNKNOWN;

  /**
   * @generated from field: repeated retrica.Friend list = 3;
   */
  list: Friend[] = [];

  /**
   * DEPRECATED
   *
   * @generated from field: int64 DEPRECATED_block_revision = 2;
   */
  DEPRECATEDBlockRevision = protoInt64.zero;

  constructor(data?: PartialMessage<BlockedFriendsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.BlockedFriendsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "error_code", kind: "enum", T: proto3.getEnumType(ErrorCode) },
    { no: 3, name: "list", kind: "message", T: Friend, repeated: true },
    { no: 2, name: "DEPRECATED_block_revision", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): BlockedFriendsResponse {
    return new BlockedFriendsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): BlockedFriendsResponse {
    return new BlockedFriendsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): BlockedFriendsResponse {
    return new BlockedFriendsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: BlockedFriendsResponse | PlainMessage<BlockedFriendsResponse> | undefined, b: BlockedFriendsResponse | PlainMessage<BlockedFriendsResponse> | undefined): boolean {
    return proto3.util.equals(BlockedFriendsResponse, a, b);
  }
}

/**
 * /a2/ac/addFriend
 * 친구를 추가 한다.
 *
 * @generated from message retrica.AddFriendRequest
 */
export class AddFriendRequest extends Message<AddFriendRequest> {
  /**
   * optional 둘중 하나만 있어야함.
   *
   * @generated from field: string user_id = 1;
   */
  userId = "";

  /**
   * optional 둘중 하나만 있어야함.
   *
   * @generated from field: string username = 4;
   */
  username = "";

  /**
   * @generated from field: string contact_name = 2;
   */
  contactName = "";

  /**
   * DEPRECATED
   *
   * @generated from field: string DEPRECATED_source = 3;
   */
  DEPRECATEDSource = "";

  constructor(data?: PartialMessage<AddFriendRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.AddFriendRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "username", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "contact_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "DEPRECATED_source", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AddFriendRequest {
    return new AddFriendRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AddFriendRequest {
    return new AddFriendRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AddFriendRequest {
    return new AddFriendRequest().fromJsonString(jsonString, options);
  }

  static equals(a: AddFriendRequest | PlainMessage<AddFriendRequest> | undefined, b: AddFriendRequest | PlainMessage<AddFriendRequest> | undefined): boolean {
    return proto3.util.equals(AddFriendRequest, a, b);
  }
}

/**
 * @generated from message retrica.AddFriendResponse
 */
export class AddFriendResponse extends Message<AddFriendResponse> {
  /**
   * SUCCESS
   *
   * @generated from field: retrica.ErrorCode error_code = 1;
   */
  errorCode = ErrorCode.UNKNOWN;

  /**
   * @generated from field: retrica.Friend friend = 6;
   */
  friend?: Friend;

  /**
   * DEPRECATED
   *
   * @generated from field: int64 DEPRECATED_friend_revision = 2;
   */
  DEPRECATEDFriendRevision = protoInt64.zero;

  /**
   * @generated from field: int64 DEPRECATED_block_revision = 3;
   */
  DEPRECATEDBlockRevision = protoInt64.zero;

  /**
   * @generated from field: int64 DEPRECATED_recommend_revision = 4;
   */
  DEPRECATEDRecommendRevision = protoInt64.zero;

  /**
   * @generated from field: int64 DEPRECATED_added_me_revision = 5;
   */
  DEPRECATEDAddedMeRevision = protoInt64.zero;

  /**
   * @generated from field: retrica.SelfieNetwork.SelfieWrap DEPRECATED_selfie_wrap = 7;
   */
  DEPRECATEDSelfieWrap?: SelfieNetwork_SelfieWrap;

  constructor(data?: PartialMessage<AddFriendResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.AddFriendResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "error_code", kind: "enum", T: proto3.getEnumType(ErrorCode) },
    { no: 6, name: "friend", kind: "message", T: Friend },
    { no: 2, name: "DEPRECATED_friend_revision", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "DEPRECATED_block_revision", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "DEPRECATED_recommend_revision", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 5, name: "DEPRECATED_added_me_revision", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 7, name: "DEPRECATED_selfie_wrap", kind: "message", T: SelfieNetwork_SelfieWrap },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AddFriendResponse {
    return new AddFriendResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AddFriendResponse {
    return new AddFriendResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AddFriendResponse {
    return new AddFriendResponse().fromJsonString(jsonString, options);
  }

  static equals(a: AddFriendResponse | PlainMessage<AddFriendResponse> | undefined, b: AddFriendResponse | PlainMessage<AddFriendResponse> | undefined): boolean {
    return proto3.util.equals(AddFriendResponse, a, b);
  }
}

/**
 * /a2/ac/blockFriend
 * 친구를 차단한다.
 *
 * @generated from message retrica.BlockFriendRequest
 */
export class BlockFriendRequest extends Message<BlockFriendRequest> {
  /**
   * @generated from field: string user_id = 1;
   */
  userId = "";

  /**
   * @generated from field: string contact_name = 2;
   */
  contactName = "";

  constructor(data?: PartialMessage<BlockFriendRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.BlockFriendRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "contact_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): BlockFriendRequest {
    return new BlockFriendRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): BlockFriendRequest {
    return new BlockFriendRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): BlockFriendRequest {
    return new BlockFriendRequest().fromJsonString(jsonString, options);
  }

  static equals(a: BlockFriendRequest | PlainMessage<BlockFriendRequest> | undefined, b: BlockFriendRequest | PlainMessage<BlockFriendRequest> | undefined): boolean {
    return proto3.util.equals(BlockFriendRequest, a, b);
  }
}

/**
 * @generated from message retrica.BlockFriendResponse
 */
export class BlockFriendResponse extends Message<BlockFriendResponse> {
  /**
   * SUCCESS
   *
   * @generated from field: retrica.ErrorCode error_code = 1;
   */
  errorCode = ErrorCode.UNKNOWN;

  /**
   * @generated from field: retrica.Friend friend = 6;
   */
  friend?: Friend;

  /**
   * DEPRECATED
   *
   * @generated from field: int64 DEPRECATED_friend_revision = 2;
   */
  DEPRECATEDFriendRevision = protoInt64.zero;

  /**
   * @generated from field: int64 DEPRECATED_block_revision = 3;
   */
  DEPRECATEDBlockRevision = protoInt64.zero;

  /**
   * @generated from field: int64 DEPRECATED_recommend_revision = 4;
   */
  DEPRECATEDRecommendRevision = protoInt64.zero;

  /**
   * @generated from field: int64 DEPRECATED_added_me_revision = 5;
   */
  DEPRECATEDAddedMeRevision = protoInt64.zero;

  constructor(data?: PartialMessage<BlockFriendResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.BlockFriendResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "error_code", kind: "enum", T: proto3.getEnumType(ErrorCode) },
    { no: 6, name: "friend", kind: "message", T: Friend },
    { no: 2, name: "DEPRECATED_friend_revision", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "DEPRECATED_block_revision", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "DEPRECATED_recommend_revision", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 5, name: "DEPRECATED_added_me_revision", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): BlockFriendResponse {
    return new BlockFriendResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): BlockFriendResponse {
    return new BlockFriendResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): BlockFriendResponse {
    return new BlockFriendResponse().fromJsonString(jsonString, options);
  }

  static equals(a: BlockFriendResponse | PlainMessage<BlockFriendResponse> | undefined, b: BlockFriendResponse | PlainMessage<BlockFriendResponse> | undefined): boolean {
    return proto3.util.equals(BlockFriendResponse, a, b);
  }
}

/**
 * /a2/ac/unFriend
 * 친구 관계를 해제 한다.
 *
 * @generated from message retrica.UnFriendRequest
 */
export class UnFriendRequest extends Message<UnFriendRequest> {
  /**
   * optional 둘중 하나만 있어야함.
   *
   * @generated from field: string user_id = 1;
   */
  userId = "";

  /**
   * optional 둘중 하나만 있어야함.
   *
   * @generated from field: string username = 2;
   */
  username = "";

  constructor(data?: PartialMessage<UnFriendRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.UnFriendRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "username", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UnFriendRequest {
    return new UnFriendRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UnFriendRequest {
    return new UnFriendRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UnFriendRequest {
    return new UnFriendRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UnFriendRequest | PlainMessage<UnFriendRequest> | undefined, b: UnFriendRequest | PlainMessage<UnFriendRequest> | undefined): boolean {
    return proto3.util.equals(UnFriendRequest, a, b);
  }
}

/**
 * @generated from message retrica.UnFriendResponse
 */
export class UnFriendResponse extends Message<UnFriendResponse> {
  /**
   * @generated from field: retrica.ErrorCode error_code = 1;
   */
  errorCode = ErrorCode.UNKNOWN;

  /**
   * @generated from field: retrica.Friend friend = 2;
   */
  friend?: Friend;

  constructor(data?: PartialMessage<UnFriendResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.UnFriendResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "error_code", kind: "enum", T: proto3.getEnumType(ErrorCode) },
    { no: 2, name: "friend", kind: "message", T: Friend },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UnFriendResponse {
    return new UnFriendResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UnFriendResponse {
    return new UnFriendResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UnFriendResponse {
    return new UnFriendResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UnFriendResponse | PlainMessage<UnFriendResponse> | undefined, b: UnFriendResponse | PlainMessage<UnFriendResponse> | undefined): boolean {
    return proto3.util.equals(UnFriendResponse, a, b);
  }
}

/**
 * /a2/ac/suggestedContacts
 * 연락처에 있는 사람중 레트리카에 초대를 할 사람을 추천해 준다.
 *
 * @generated from message retrica.SuggestedContactsRequest
 */
export class SuggestedContactsRequest extends Message<SuggestedContactsRequest> {
  constructor(data?: PartialMessage<SuggestedContactsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.SuggestedContactsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SuggestedContactsRequest {
    return new SuggestedContactsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SuggestedContactsRequest {
    return new SuggestedContactsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SuggestedContactsRequest {
    return new SuggestedContactsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: SuggestedContactsRequest | PlainMessage<SuggestedContactsRequest> | undefined, b: SuggestedContactsRequest | PlainMessage<SuggestedContactsRequest> | undefined): boolean {
    return proto3.util.equals(SuggestedContactsRequest, a, b);
  }
}

/**
 * @generated from message retrica.SuggestedContactsResponse
 */
export class SuggestedContactsResponse extends Message<SuggestedContactsResponse> {
  /**
   * SUCCESS
   *
   * @generated from field: retrica.ErrorCode error_code = 1;
   */
  errorCode = ErrorCode.UNKNOWN;

  /**
   * @generated from field: repeated retrica.SuggestedContactsResponse.Contact contacts = 2;
   */
  contacts: SuggestedContactsResponse_Contact[] = [];

  constructor(data?: PartialMessage<SuggestedContactsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.SuggestedContactsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "error_code", kind: "enum", T: proto3.getEnumType(ErrorCode) },
    { no: 2, name: "contacts", kind: "message", T: SuggestedContactsResponse_Contact, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SuggestedContactsResponse {
    return new SuggestedContactsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SuggestedContactsResponse {
    return new SuggestedContactsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SuggestedContactsResponse {
    return new SuggestedContactsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: SuggestedContactsResponse | PlainMessage<SuggestedContactsResponse> | undefined, b: SuggestedContactsResponse | PlainMessage<SuggestedContactsResponse> | undefined): boolean {
    return proto3.util.equals(SuggestedContactsResponse, a, b);
  }
}

/**
 * @generated from message retrica.SuggestedContactsResponse.Contact
 */
export class SuggestedContactsResponse_Contact extends Message<SuggestedContactsResponse_Contact> {
  /**
   * @generated from field: string contact_name = 1;
   */
  contactName = "";

  /**
   * @generated from field: string phone_number = 2;
   */
  phoneNumber = "";

  /**
   * Retrica에 이 연락처를 가지고 있는 사람 수.
   *
   * @generated from field: int64 count = 3;
   */
  count = protoInt64.zero;

  constructor(data?: PartialMessage<SuggestedContactsResponse_Contact>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.SuggestedContactsResponse.Contact";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "contact_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "phone_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SuggestedContactsResponse_Contact {
    return new SuggestedContactsResponse_Contact().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SuggestedContactsResponse_Contact {
    return new SuggestedContactsResponse_Contact().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SuggestedContactsResponse_Contact {
    return new SuggestedContactsResponse_Contact().fromJsonString(jsonString, options);
  }

  static equals(a: SuggestedContactsResponse_Contact | PlainMessage<SuggestedContactsResponse_Contact> | undefined, b: SuggestedContactsResponse_Contact | PlainMessage<SuggestedContactsResponse_Contact> | undefined): boolean {
    return proto3.util.equals(SuggestedContactsResponse_Contact, a, b);
  }
}

/**
 * /a2/ac/uploadContacts
 * 연락처를 업로드 한다.
 *
 * @generated from message retrica.UploadContactsRequest
 */
export class UploadContactsRequest extends Message<UploadContactsRequest> {
  /**
   * @generated from field: repeated retrica.UploadContactsRequest.Contact contacts = 1;
   */
  contacts: UploadContactsRequest_Contact[] = [];

  constructor(data?: PartialMessage<UploadContactsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.UploadContactsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "contacts", kind: "message", T: UploadContactsRequest_Contact, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UploadContactsRequest {
    return new UploadContactsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UploadContactsRequest {
    return new UploadContactsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UploadContactsRequest {
    return new UploadContactsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UploadContactsRequest | PlainMessage<UploadContactsRequest> | undefined, b: UploadContactsRequest | PlainMessage<UploadContactsRequest> | undefined): boolean {
    return proto3.util.equals(UploadContactsRequest, a, b);
  }
}

/**
 * @generated from message retrica.UploadContactsRequest.Contact
 */
export class UploadContactsRequest_Contact extends Message<UploadContactsRequest_Contact> {
  /**
   * @generated from field: string contact_name = 1;
   */
  contactName = "";

  /**
   * @generated from field: string phone_number = 2;
   */
  phoneNumber = "";

  constructor(data?: PartialMessage<UploadContactsRequest_Contact>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.UploadContactsRequest.Contact";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "contact_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "phone_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UploadContactsRequest_Contact {
    return new UploadContactsRequest_Contact().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UploadContactsRequest_Contact {
    return new UploadContactsRequest_Contact().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UploadContactsRequest_Contact {
    return new UploadContactsRequest_Contact().fromJsonString(jsonString, options);
  }

  static equals(a: UploadContactsRequest_Contact | PlainMessage<UploadContactsRequest_Contact> | undefined, b: UploadContactsRequest_Contact | PlainMessage<UploadContactsRequest_Contact> | undefined): boolean {
    return proto3.util.equals(UploadContactsRequest_Contact, a, b);
  }
}

/**
 * @generated from message retrica.UploadContactsResponse
 */
export class UploadContactsResponse extends Message<UploadContactsResponse> {
  /**
   * SUCCESS
   *
   * @generated from field: retrica.ErrorCode error_code = 1;
   */
  errorCode = ErrorCode.UNKNOWN;

  /**
   * DEPRECATED
   *
   * @generated from field: int64 DEPRECATED_friend_revision = 2;
   */
  DEPRECATEDFriendRevision = protoInt64.zero;

  /**
   * @generated from field: int64 DEPRECATED_block_revision = 3;
   */
  DEPRECATEDBlockRevision = protoInt64.zero;

  /**
   * @generated from field: int64 DEPRECATED_recommend_revision = 4;
   */
  DEPRECATEDRecommendRevision = protoInt64.zero;

  /**
   * @generated from field: int64 DEPRECATED_added_me_revision = 5;
   */
  DEPRECATEDAddedMeRevision = protoInt64.zero;

  /**
   * @generated from field: retrica.FriendsWrap DEPRECATED_friends_wrap = 6;
   */
  DEPRECATEDFriendsWrap?: FriendsWrap;

  constructor(data?: PartialMessage<UploadContactsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.UploadContactsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "error_code", kind: "enum", T: proto3.getEnumType(ErrorCode) },
    { no: 2, name: "DEPRECATED_friend_revision", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "DEPRECATED_block_revision", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "DEPRECATED_recommend_revision", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 5, name: "DEPRECATED_added_me_revision", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 6, name: "DEPRECATED_friends_wrap", kind: "message", T: FriendsWrap },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UploadContactsResponse {
    return new UploadContactsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UploadContactsResponse {
    return new UploadContactsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UploadContactsResponse {
    return new UploadContactsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UploadContactsResponse | PlainMessage<UploadContactsResponse> | undefined, b: UploadContactsResponse | PlainMessage<UploadContactsResponse> | undefined): boolean {
    return proto3.util.equals(UploadContactsResponse, a, b);
  }
}

/**
 * /a2/ac/subscribeFriend
 * 해당 유저를 subscribe 한다.
 *
 * @generated from message retrica.SubscribeFriendRequest
 */
export class SubscribeFriendRequest extends Message<SubscribeFriendRequest> {
  /**
   * @generated from field: string user_id = 1;
   */
  userId = "";

  constructor(data?: PartialMessage<SubscribeFriendRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.SubscribeFriendRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SubscribeFriendRequest {
    return new SubscribeFriendRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SubscribeFriendRequest {
    return new SubscribeFriendRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SubscribeFriendRequest {
    return new SubscribeFriendRequest().fromJsonString(jsonString, options);
  }

  static equals(a: SubscribeFriendRequest | PlainMessage<SubscribeFriendRequest> | undefined, b: SubscribeFriendRequest | PlainMessage<SubscribeFriendRequest> | undefined): boolean {
    return proto3.util.equals(SubscribeFriendRequest, a, b);
  }
}

/**
 * @generated from message retrica.SubscribeFriendResponse
 */
export class SubscribeFriendResponse extends Message<SubscribeFriendResponse> {
  /**
   * @generated from field: retrica.ErrorCode error_code = 1;
   */
  errorCode = ErrorCode.UNKNOWN;

  /**
   * @generated from field: retrica.Friend friend = 2;
   */
  friend?: Friend;

  constructor(data?: PartialMessage<SubscribeFriendResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.SubscribeFriendResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "error_code", kind: "enum", T: proto3.getEnumType(ErrorCode) },
    { no: 2, name: "friend", kind: "message", T: Friend },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SubscribeFriendResponse {
    return new SubscribeFriendResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SubscribeFriendResponse {
    return new SubscribeFriendResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SubscribeFriendResponse {
    return new SubscribeFriendResponse().fromJsonString(jsonString, options);
  }

  static equals(a: SubscribeFriendResponse | PlainMessage<SubscribeFriendResponse> | undefined, b: SubscribeFriendResponse | PlainMessage<SubscribeFriendResponse> | undefined): boolean {
    return proto3.util.equals(SubscribeFriendResponse, a, b);
  }
}

/**
 * /a2/ac/unSubscribeFriend
 * 해당 유저를 un subscribe 한다.
 *
 * @generated from message retrica.UnSubscribeFriendRequest
 */
export class UnSubscribeFriendRequest extends Message<UnSubscribeFriendRequest> {
  /**
   * @generated from field: string user_id = 1;
   */
  userId = "";

  constructor(data?: PartialMessage<UnSubscribeFriendRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.UnSubscribeFriendRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UnSubscribeFriendRequest {
    return new UnSubscribeFriendRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UnSubscribeFriendRequest {
    return new UnSubscribeFriendRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UnSubscribeFriendRequest {
    return new UnSubscribeFriendRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UnSubscribeFriendRequest | PlainMessage<UnSubscribeFriendRequest> | undefined, b: UnSubscribeFriendRequest | PlainMessage<UnSubscribeFriendRequest> | undefined): boolean {
    return proto3.util.equals(UnSubscribeFriendRequest, a, b);
  }
}

/**
 * @generated from message retrica.UnSubscribeFriendResponse
 */
export class UnSubscribeFriendResponse extends Message<UnSubscribeFriendResponse> {
  /**
   * @generated from field: retrica.ErrorCode error_code = 1;
   */
  errorCode = ErrorCode.UNKNOWN;

  /**
   * @generated from field: retrica.Friend friend = 2;
   */
  friend?: Friend;

  constructor(data?: PartialMessage<UnSubscribeFriendResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.UnSubscribeFriendResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "error_code", kind: "enum", T: proto3.getEnumType(ErrorCode) },
    { no: 2, name: "friend", kind: "message", T: Friend },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UnSubscribeFriendResponse {
    return new UnSubscribeFriendResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UnSubscribeFriendResponse {
    return new UnSubscribeFriendResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UnSubscribeFriendResponse {
    return new UnSubscribeFriendResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UnSubscribeFriendResponse | PlainMessage<UnSubscribeFriendResponse> | undefined, b: UnSubscribeFriendResponse | PlainMessage<UnSubscribeFriendResponse> | undefined): boolean {
    return proto3.util.equals(UnSubscribeFriendResponse, a, b);
  }
}

/**
 * /a2/ac/addPreset
 * preset 을 추가한다.
 *
 * @generated from message retrica.AddPresetRequest
 */
export class AddPresetRequest extends Message<AddPresetRequest> {
  /**
   * @generated from field: retrica.Preset preset = 1;
   */
  preset?: Preset;

  constructor(data?: PartialMessage<AddPresetRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.AddPresetRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "preset", kind: "message", T: Preset },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AddPresetRequest {
    return new AddPresetRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AddPresetRequest {
    return new AddPresetRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AddPresetRequest {
    return new AddPresetRequest().fromJsonString(jsonString, options);
  }

  static equals(a: AddPresetRequest | PlainMessage<AddPresetRequest> | undefined, b: AddPresetRequest | PlainMessage<AddPresetRequest> | undefined): boolean {
    return proto3.util.equals(AddPresetRequest, a, b);
  }
}

/**
 * @generated from message retrica.AddPresetResponse
 */
export class AddPresetResponse extends Message<AddPresetResponse> {
  /**
   * @generated from field: retrica.ErrorCode error_code = 1;
   */
  errorCode = ErrorCode.UNKNOWN;

  /**
   * @generated from field: retrica.Preset preset = 2;
   */
  preset?: Preset;

  constructor(data?: PartialMessage<AddPresetResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.AddPresetResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "error_code", kind: "enum", T: proto3.getEnumType(ErrorCode) },
    { no: 2, name: "preset", kind: "message", T: Preset },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AddPresetResponse {
    return new AddPresetResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AddPresetResponse {
    return new AddPresetResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AddPresetResponse {
    return new AddPresetResponse().fromJsonString(jsonString, options);
  }

  static equals(a: AddPresetResponse | PlainMessage<AddPresetResponse> | undefined, b: AddPresetResponse | PlainMessage<AddPresetResponse> | undefined): boolean {
    return proto3.util.equals(AddPresetResponse, a, b);
  }
}

/**
 * /h1/ac/getPreset
 * preset 을 가져온다.
 *
 * @generated from message retrica.GetPresetsRequest
 */
export class GetPresetsRequest extends Message<GetPresetsRequest> {
  /**
   * @generated from field: repeated string ids = 1;
   */
  ids: string[] = [];

  constructor(data?: PartialMessage<GetPresetsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.GetPresetsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetPresetsRequest {
    return new GetPresetsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetPresetsRequest {
    return new GetPresetsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetPresetsRequest {
    return new GetPresetsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetPresetsRequest | PlainMessage<GetPresetsRequest> | undefined, b: GetPresetsRequest | PlainMessage<GetPresetsRequest> | undefined): boolean {
    return proto3.util.equals(GetPresetsRequest, a, b);
  }
}

/**
 * @generated from message retrica.GetPresetsResponse
 */
export class GetPresetsResponse extends Message<GetPresetsResponse> {
  /**
   * @generated from field: retrica.ErrorCode error_code = 1;
   */
  errorCode = ErrorCode.UNKNOWN;

  /**
   * @generated from field: repeated retrica.Preset presets = 2;
   */
  presets: Preset[] = [];

  constructor(data?: PartialMessage<GetPresetsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.GetPresetsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "error_code", kind: "enum", T: proto3.getEnumType(ErrorCode) },
    { no: 2, name: "presets", kind: "message", T: Preset, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetPresetsResponse {
    return new GetPresetsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetPresetsResponse {
    return new GetPresetsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetPresetsResponse {
    return new GetPresetsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetPresetsResponse | PlainMessage<GetPresetsResponse> | undefined, b: GetPresetsResponse | PlainMessage<GetPresetsResponse> | undefined): boolean {
    return proto3.util.equals(GetPresetsResponse, a, b);
  }
}

/**
 * /h1/ac/searchPreset
 *
 * @generated from message retrica.SearchPresetsRequest
 */
export class SearchPresetsRequest extends Message<SearchPresetsRequest> {
  /**
   * 이전 요청의 응답으로 받은 offset 을 넣습니다. 첫 요청일 경우 비워두면 됩니다.
   *
   * @generated from field: string offset = 1;
   */
  offset = "";

  /**
   * hide 처리된 preset 을 포함할지 설정하는 flag. 주의) Admin 에서만 사용합니다.
   *
   * @generated from field: bool showHidden = 2;
   */
  showHidden = false;

  /**
   * default 0, 이 값이 0 일 경우 elasticsearch 의 default paging size 인 10 개의 결과를 내려줍니다.
   *
   * @generated from field: int32 size = 3;
   */
  size = 0;

  /**
   *
   * 아래 각 field 값이 있을 경우, field 끼리는 AND 검색이 적용됩니다.
   * ex)
   * title="A", description="", tags="" 일 경우 title 이 "A" 인 것만 검색
   * title="A", description="B", tags="" 일 경우 title 이 "A" 이고, description 이 "B" 인 것들을 검색
   * title="A", description="B", tags="tag1" 일 경우 title 이 "A" 이고, description 이 "B", 그리고 tags 에 "tag1" 을 포함한
   * 것들을 검색
   *
   * @generated from field: string title = 4;
   */
  title = "";

  /**
   * @generated from field: string description = 5;
   */
  description = "";

  /**
   *
   * tags string 에는 ','(comma), '+'(plus) 를 이용하여 여러 tag 의 AND/OR 검색을 지정할 수 있습니다.
   * ex)
   * tags="tag1,tag2" 일 경우 tag1, tag2 가 모두 포함된 것들을 검색 (AND)
   * tags="tag1+tag2" 일 경우 tag1, tag2 둘중 하나라도 포함된 것들을 검색 (OR)
   *
   * @generated from field: string tags = 6;
   */
  tags = "";

  constructor(data?: PartialMessage<SearchPresetsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.SearchPresetsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "offset", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "showHidden", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "size", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "tags", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SearchPresetsRequest {
    return new SearchPresetsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SearchPresetsRequest {
    return new SearchPresetsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SearchPresetsRequest {
    return new SearchPresetsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: SearchPresetsRequest | PlainMessage<SearchPresetsRequest> | undefined, b: SearchPresetsRequest | PlainMessage<SearchPresetsRequest> | undefined): boolean {
    return proto3.util.equals(SearchPresetsRequest, a, b);
  }
}

/**
 * @generated from message retrica.SearchPresetsResponse
 */
export class SearchPresetsResponse extends Message<SearchPresetsResponse> {
  /**
   * @generated from field: retrica.ErrorCode error_code = 1;
   */
  errorCode = ErrorCode.UNKNOWN;

  /**
   * 이 필드가 비어있고, offset 필드 값이 request 의 offset 값과 같으면 더이상 검색결과가 없는 것!
   *
   * @generated from field: repeated retrica.Preset presets = 2;
   */
  presets: Preset[] = [];

  /**
   * 다음 page 요청시 사용될 offset
   *
   * @generated from field: string offset = 3;
   */
  offset = "";

  constructor(data?: PartialMessage<SearchPresetsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.SearchPresetsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "error_code", kind: "enum", T: proto3.getEnumType(ErrorCode) },
    { no: 2, name: "presets", kind: "message", T: Preset, repeated: true },
    { no: 3, name: "offset", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SearchPresetsResponse {
    return new SearchPresetsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SearchPresetsResponse {
    return new SearchPresetsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SearchPresetsResponse {
    return new SearchPresetsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: SearchPresetsResponse | PlainMessage<SearchPresetsResponse> | undefined, b: SearchPresetsResponse | PlainMessage<SearchPresetsResponse> | undefined): boolean {
    return proto3.util.equals(SearchPresetsResponse, a, b);
  }
}

/**
 * /a2/ac/deletePreset
 * preset 을 삭제한다.
 *
 * @generated from message retrica.DeletePresetRequest
 */
export class DeletePresetRequest extends Message<DeletePresetRequest> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  constructor(data?: PartialMessage<DeletePresetRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.DeletePresetRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeletePresetRequest {
    return new DeletePresetRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeletePresetRequest {
    return new DeletePresetRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeletePresetRequest {
    return new DeletePresetRequest().fromJsonString(jsonString, options);
  }

  static equals(a: DeletePresetRequest | PlainMessage<DeletePresetRequest> | undefined, b: DeletePresetRequest | PlainMessage<DeletePresetRequest> | undefined): boolean {
    return proto3.util.equals(DeletePresetRequest, a, b);
  }
}

/**
 * @generated from message retrica.DeletePresetResponse
 */
export class DeletePresetResponse extends Message<DeletePresetResponse> {
  /**
   * @generated from field: retrica.ErrorCode error_code = 1;
   */
  errorCode = ErrorCode.UNKNOWN;

  constructor(data?: PartialMessage<DeletePresetResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.DeletePresetResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "error_code", kind: "enum", T: proto3.getEnumType(ErrorCode) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeletePresetResponse {
    return new DeletePresetResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeletePresetResponse {
    return new DeletePresetResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeletePresetResponse {
    return new DeletePresetResponse().fromJsonString(jsonString, options);
  }

  static equals(a: DeletePresetResponse | PlainMessage<DeletePresetResponse> | undefined, b: DeletePresetResponse | PlainMessage<DeletePresetResponse> | undefined): boolean {
    return proto3.util.equals(DeletePresetResponse, a, b);
  }
}

/**
 * /a2/ac/likePreset
 * preset 을 like(북마크) 또는 unlike(북마크 해제) 한다.
 *
 * @generated from message retrica.LikePresetRequest
 */
export class LikePresetRequest extends Message<LikePresetRequest> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * message 종류가 너무 많아지는 것을 방지하고자 하나의 message 로 like/unlike 둘다 처리할 수 있도록 구성합니다.
   *
   * @generated from field: bool like = 2;
   */
  like = false;

  constructor(data?: PartialMessage<LikePresetRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.LikePresetRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "like", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LikePresetRequest {
    return new LikePresetRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LikePresetRequest {
    return new LikePresetRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LikePresetRequest {
    return new LikePresetRequest().fromJsonString(jsonString, options);
  }

  static equals(a: LikePresetRequest | PlainMessage<LikePresetRequest> | undefined, b: LikePresetRequest | PlainMessage<LikePresetRequest> | undefined): boolean {
    return proto3.util.equals(LikePresetRequest, a, b);
  }
}

/**
 * @generated from message retrica.LikePresetResponse
 */
export class LikePresetResponse extends Message<LikePresetResponse> {
  /**
   * @generated from field: retrica.ErrorCode error_code = 1;
   */
  errorCode = ErrorCode.UNKNOWN;

  /**
   * @generated from field: retrica.Preset preset = 2;
   */
  preset?: Preset;

  constructor(data?: PartialMessage<LikePresetResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.LikePresetResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "error_code", kind: "enum", T: proto3.getEnumType(ErrorCode) },
    { no: 2, name: "preset", kind: "message", T: Preset },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LikePresetResponse {
    return new LikePresetResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LikePresetResponse {
    return new LikePresetResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LikePresetResponse {
    return new LikePresetResponse().fromJsonString(jsonString, options);
  }

  static equals(a: LikePresetResponse | PlainMessage<LikePresetResponse> | undefined, b: LikePresetResponse | PlainMessage<LikePresetResponse> | undefined): boolean {
    return proto3.util.equals(LikePresetResponse, a, b);
  }
}

/**
 * /a2/ac/favoritePresets
 * favorite (bookmark 한) preset 들을 가져온다
 *
 * @generated from message retrica.FavoritePresetsRequest
 */
export class FavoritePresetsRequest extends Message<FavoritePresetsRequest> {
  /**
   * @generated from field: string offset = 1;
   */
  offset = "";

  constructor(data?: PartialMessage<FavoritePresetsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.FavoritePresetsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "offset", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FavoritePresetsRequest {
    return new FavoritePresetsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FavoritePresetsRequest {
    return new FavoritePresetsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FavoritePresetsRequest {
    return new FavoritePresetsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: FavoritePresetsRequest | PlainMessage<FavoritePresetsRequest> | undefined, b: FavoritePresetsRequest | PlainMessage<FavoritePresetsRequest> | undefined): boolean {
    return proto3.util.equals(FavoritePresetsRequest, a, b);
  }
}

/**
 * @generated from message retrica.FavoritePresetsResponse
 */
export class FavoritePresetsResponse extends Message<FavoritePresetsResponse> {
  /**
   * @generated from field: retrica.ErrorCode error_code = 1;
   */
  errorCode = ErrorCode.UNKNOWN;

  /**
   * @generated from field: repeated retrica.Preset presets = 2;
   */
  presets: Preset[] = [];

  /**
   * @generated from field: string offset = 3;
   */
  offset = "";

  constructor(data?: PartialMessage<FavoritePresetsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.FavoritePresetsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "error_code", kind: "enum", T: proto3.getEnumType(ErrorCode) },
    { no: 2, name: "presets", kind: "message", T: Preset, repeated: true },
    { no: 3, name: "offset", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FavoritePresetsResponse {
    return new FavoritePresetsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FavoritePresetsResponse {
    return new FavoritePresetsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FavoritePresetsResponse {
    return new FavoritePresetsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: FavoritePresetsResponse | PlainMessage<FavoritePresetsResponse> | undefined, b: FavoritePresetsResponse | PlainMessage<FavoritePresetsResponse> | undefined): boolean {
    return proto3.util.equals(FavoritePresetsResponse, a, b);
  }
}

/**
 * /a2/ac/hidePreset
 * 관리자가 discover 에서 보여주지 않을 preset 을 설정할 수 있는 기능.
 *
 * @generated from message retrica.HidePresetRequest
 */
export class HidePresetRequest extends Message<HidePresetRequest> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: bool hide = 2;
   */
  hide = false;

  constructor(data?: PartialMessage<HidePresetRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.HidePresetRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "hide", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): HidePresetRequest {
    return new HidePresetRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): HidePresetRequest {
    return new HidePresetRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): HidePresetRequest {
    return new HidePresetRequest().fromJsonString(jsonString, options);
  }

  static equals(a: HidePresetRequest | PlainMessage<HidePresetRequest> | undefined, b: HidePresetRequest | PlainMessage<HidePresetRequest> | undefined): boolean {
    return proto3.util.equals(HidePresetRequest, a, b);
  }
}

/**
 * @generated from message retrica.HidePresetResponse
 */
export class HidePresetResponse extends Message<HidePresetResponse> {
  /**
   * @generated from field: retrica.ErrorCode error_code = 1;
   */
  errorCode = ErrorCode.UNKNOWN;

  /**
   * @generated from field: retrica.Preset preset = 2;
   */
  preset?: Preset;

  constructor(data?: PartialMessage<HidePresetResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.HidePresetResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "error_code", kind: "enum", T: proto3.getEnumType(ErrorCode) },
    { no: 2, name: "preset", kind: "message", T: Preset },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): HidePresetResponse {
    return new HidePresetResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): HidePresetResponse {
    return new HidePresetResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): HidePresetResponse {
    return new HidePresetResponse().fromJsonString(jsonString, options);
  }

  static equals(a: HidePresetResponse | PlainMessage<HidePresetResponse> | undefined, b: HidePresetResponse | PlainMessage<HidePresetResponse> | undefined): boolean {
    return proto3.util.equals(HidePresetResponse, a, b);
  }
}

/**
 * /a2/ac/updatePreset
 * Preset 을 update 한다.
 *
 * @generated from message retrica.UpdatePresetRequest
 */
export class UpdatePresetRequest extends Message<UpdatePresetRequest> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * 우선은 필요한 것만, 추후 update 필요한 field 가 있을 때마다 추가
   *
   * 비어있을 경우 ("") 업데이트하지 않습니다.
   *
   * @generated from field: string preview_url = 2;
   */
  previewUrl = "";

  /**
   * 비어있을 경우 ("") 업데이트하지 않습니다.
   *
   * @generated from field: string origin_url = 3;
   */
  originUrl = "";

  /**
   * 비어있을 경우 (nil) 기존의 모든 태그가 삭제됩니다.
   *
   * @generated from field: repeated string tags = 4;
   */
  tags: string[] = [];

  constructor(data?: PartialMessage<UpdatePresetRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.UpdatePresetRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "preview_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "origin_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "tags", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdatePresetRequest {
    return new UpdatePresetRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdatePresetRequest {
    return new UpdatePresetRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdatePresetRequest {
    return new UpdatePresetRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdatePresetRequest | PlainMessage<UpdatePresetRequest> | undefined, b: UpdatePresetRequest | PlainMessage<UpdatePresetRequest> | undefined): boolean {
    return proto3.util.equals(UpdatePresetRequest, a, b);
  }
}

/**
 * @generated from message retrica.UpdatePresetResponse
 */
export class UpdatePresetResponse extends Message<UpdatePresetResponse> {
  /**
   * @generated from field: retrica.ErrorCode error_code = 1;
   */
  errorCode = ErrorCode.UNKNOWN;

  /**
   * @generated from field: retrica.Preset preset = 2;
   */
  preset?: Preset;

  constructor(data?: PartialMessage<UpdatePresetResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.UpdatePresetResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "error_code", kind: "enum", T: proto3.getEnumType(ErrorCode) },
    { no: 2, name: "preset", kind: "message", T: Preset },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdatePresetResponse {
    return new UpdatePresetResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdatePresetResponse {
    return new UpdatePresetResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdatePresetResponse {
    return new UpdatePresetResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UpdatePresetResponse | PlainMessage<UpdatePresetResponse> | undefined, b: UpdatePresetResponse | PlainMessage<UpdatePresetResponse> | undefined): boolean {
    return proto3.util.equals(UpdatePresetResponse, a, b);
  }
}

/**
 * /a2/ac/fileUploadUrl
 * file upload 를 위한 signed URL 을 요청한다.
 *
 * retrica 의 azure blob storage 는 region 별로 3개의 container 를 갖고 있습니다. (retrica-us/eu/asia)
 * retriver 에서 request 보낸 client/user 의 country 를 파악하여
 * 적절한 container 하위에 /directory/user-id/file_name 로 업로드할 수 있는 SAS URL 을 생성해줍니다.
 *
 * @generated from message retrica.FileUploadUrlRequest
 */
export class FileUploadUrlRequest extends Message<FileUploadUrlRequest> {
  /**
   * preset 에 사용될 file 경로는 아래와 같이 사용하도록 합니다.
   * directory: "presets"
   * file_path: "PRESET_ID/FILE_NAME.EXT"
   *
   * e.g. 미국에서 user 가 위와 같이 요청했을 경우 아래 경로에 업로드할 수 있는 URL 이 생성됩니다.
   *    /retrica-us/presets/USER_ID/PRESET_ID/FILE_NAME.EXT
   *
   * @generated from field: string directory = 1;
   */
  directory = "";

  /**
   * @generated from field: string file_path = 2;
   */
  filePath = "";

  constructor(data?: PartialMessage<FileUploadUrlRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.FileUploadUrlRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "directory", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "file_path", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FileUploadUrlRequest {
    return new FileUploadUrlRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FileUploadUrlRequest {
    return new FileUploadUrlRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FileUploadUrlRequest {
    return new FileUploadUrlRequest().fromJsonString(jsonString, options);
  }

  static equals(a: FileUploadUrlRequest | PlainMessage<FileUploadUrlRequest> | undefined, b: FileUploadUrlRequest | PlainMessage<FileUploadUrlRequest> | undefined): boolean {
    return proto3.util.equals(FileUploadUrlRequest, a, b);
  }
}

/**
 * @generated from message retrica.FileUploadUrlResponse
 */
export class FileUploadUrlResponse extends Message<FileUploadUrlResponse> {
  /**
   * @generated from field: retrica.ErrorCode error_code = 1;
   */
  errorCode = ErrorCode.UNKNOWN;

  /**
   * @generated from field: string token = 2;
   */
  token = "";

  /**
   * @generated from field: string url = 3;
   */
  url = "";

  constructor(data?: PartialMessage<FileUploadUrlResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.FileUploadUrlResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "error_code", kind: "enum", T: proto3.getEnumType(ErrorCode) },
    { no: 2, name: "token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FileUploadUrlResponse {
    return new FileUploadUrlResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FileUploadUrlResponse {
    return new FileUploadUrlResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FileUploadUrlResponse {
    return new FileUploadUrlResponse().fromJsonString(jsonString, options);
  }

  static equals(a: FileUploadUrlResponse | PlainMessage<FileUploadUrlResponse> | undefined, b: FileUploadUrlResponse | PlainMessage<FileUploadUrlResponse> | undefined): boolean {
    return proto3.util.equals(FileUploadUrlResponse, a, b);
  }
}

/**
 * /a2/ac/fileDelete
 *
 * @generated from message retrica.FileDeleteRequest
 */
export class FileDeleteRequest extends Message<FileDeleteRequest> {
  /**
   * @generated from field: string url = 1;
   */
  url = "";

  constructor(data?: PartialMessage<FileDeleteRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.FileDeleteRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FileDeleteRequest {
    return new FileDeleteRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FileDeleteRequest {
    return new FileDeleteRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FileDeleteRequest {
    return new FileDeleteRequest().fromJsonString(jsonString, options);
  }

  static equals(a: FileDeleteRequest | PlainMessage<FileDeleteRequest> | undefined, b: FileDeleteRequest | PlainMessage<FileDeleteRequest> | undefined): boolean {
    return proto3.util.equals(FileDeleteRequest, a, b);
  }
}

/**
 * @generated from message retrica.FileDeleteResponse
 */
export class FileDeleteResponse extends Message<FileDeleteResponse> {
  /**
   * @generated from field: retrica.ErrorCode error_code = 1;
   */
  errorCode = ErrorCode.UNKNOWN;

  constructor(data?: PartialMessage<FileDeleteResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.FileDeleteResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "error_code", kind: "enum", T: proto3.getEnumType(ErrorCode) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FileDeleteResponse {
    return new FileDeleteResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FileDeleteResponse {
    return new FileDeleteResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FileDeleteResponse {
    return new FileDeleteResponse().fromJsonString(jsonString, options);
  }

  static equals(a: FileDeleteResponse | PlainMessage<FileDeleteResponse> | undefined, b: FileDeleteResponse | PlainMessage<FileDeleteResponse> | undefined): boolean {
    return proto3.util.equals(FileDeleteResponse, a, b);
  }
}

/**
 * /a2/ac/addLookUpTable
 *
 * @generated from message retrica.AddLookUpTableRequest
 */
export class AddLookUpTableRequest extends Message<AddLookUpTableRequest> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * @generated from field: string preview_url = 4;
   */
  previewUrl = "";

  /**
   * @generated from field: string origin_url = 5;
   */
  originUrl = "";

  /**
   * Lookup table data
   *
   * @generated from oneof retrica.AddLookUpTableRequest.lut
   */
  lut: {
    /**
     * file 을 읽어 bytes array 를 업로드 -> retriver 에서 encrypt 하여 저장한다.
     *
     * @generated from field: bytes lut_bytes = 6;
     */
    value: Uint8Array;
    case: "lutBytes";
  } | {
    /**
     * file 을 미리 storage 에 업로드하고 그 url 로 LUT 생성, 업로드한 파일은 encrypt 되어 있어야 한다.
     *
     * @generated from field: string lut_url = 7;
     */
    value: string;
    case: "lutUrl";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<AddLookUpTableRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.AddLookUpTableRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "preview_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "origin_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "lut_bytes", kind: "scalar", T: 12 /* ScalarType.BYTES */, oneof: "lut" },
    { no: 7, name: "lut_url", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "lut" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AddLookUpTableRequest {
    return new AddLookUpTableRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AddLookUpTableRequest {
    return new AddLookUpTableRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AddLookUpTableRequest {
    return new AddLookUpTableRequest().fromJsonString(jsonString, options);
  }

  static equals(a: AddLookUpTableRequest | PlainMessage<AddLookUpTableRequest> | undefined, b: AddLookUpTableRequest | PlainMessage<AddLookUpTableRequest> | undefined): boolean {
    return proto3.util.equals(AddLookUpTableRequest, a, b);
  }
}

/**
 * @generated from message retrica.AddLookUpTableResponse
 */
export class AddLookUpTableResponse extends Message<AddLookUpTableResponse> {
  /**
   * @generated from field: retrica.ErrorCode error_code = 1;
   */
  errorCode = ErrorCode.UNKNOWN;

  /**
   * @generated from field: retrica.LookUpTable look_up_table = 2;
   */
  lookUpTable?: LookUpTable;

  constructor(data?: PartialMessage<AddLookUpTableResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.AddLookUpTableResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "error_code", kind: "enum", T: proto3.getEnumType(ErrorCode) },
    { no: 2, name: "look_up_table", kind: "message", T: LookUpTable },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AddLookUpTableResponse {
    return new AddLookUpTableResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AddLookUpTableResponse {
    return new AddLookUpTableResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AddLookUpTableResponse {
    return new AddLookUpTableResponse().fromJsonString(jsonString, options);
  }

  static equals(a: AddLookUpTableResponse | PlainMessage<AddLookUpTableResponse> | undefined, b: AddLookUpTableResponse | PlainMessage<AddLookUpTableResponse> | undefined): boolean {
    return proto3.util.equals(AddLookUpTableResponse, a, b);
  }
}

/**
 * /a2/ac/getLookUpTable
 * LookUpTable 로의 직접 접근은 Preset 을 생성할 경우에만 해당하므로, login 한 user 만 접근 가능하도록 합니다.
 *
 * @generated from message retrica.GetLookUpTablesRequest
 */
export class GetLookUpTablesRequest extends Message<GetLookUpTablesRequest> {
  /**
   * @generated from field: repeated string ids = 1;
   */
  ids: string[] = [];

  constructor(data?: PartialMessage<GetLookUpTablesRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.GetLookUpTablesRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetLookUpTablesRequest {
    return new GetLookUpTablesRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetLookUpTablesRequest {
    return new GetLookUpTablesRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetLookUpTablesRequest {
    return new GetLookUpTablesRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetLookUpTablesRequest | PlainMessage<GetLookUpTablesRequest> | undefined, b: GetLookUpTablesRequest | PlainMessage<GetLookUpTablesRequest> | undefined): boolean {
    return proto3.util.equals(GetLookUpTablesRequest, a, b);
  }
}

/**
 * @generated from message retrica.GetLookUpTablesResponse
 */
export class GetLookUpTablesResponse extends Message<GetLookUpTablesResponse> {
  /**
   * @generated from field: retrica.ErrorCode error_code = 1;
   */
  errorCode = ErrorCode.UNKNOWN;

  /**
   * @generated from field: repeated retrica.LookUpTable look_up_tables = 2;
   */
  lookUpTables: LookUpTable[] = [];

  constructor(data?: PartialMessage<GetLookUpTablesResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.GetLookUpTablesResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "error_code", kind: "enum", T: proto3.getEnumType(ErrorCode) },
    { no: 2, name: "look_up_tables", kind: "message", T: LookUpTable, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetLookUpTablesResponse {
    return new GetLookUpTablesResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetLookUpTablesResponse {
    return new GetLookUpTablesResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetLookUpTablesResponse {
    return new GetLookUpTablesResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetLookUpTablesResponse | PlainMessage<GetLookUpTablesResponse> | undefined, b: GetLookUpTablesResponse | PlainMessage<GetLookUpTablesResponse> | undefined): boolean {
    return proto3.util.equals(GetLookUpTablesResponse, a, b);
  }
}

/**
 * /a2/ac/searchLookUpTable
 * LookUpTable 로의 직접 접근은 Preset 을 생성할 경우에만 해당하므로, login 한 user 만 접근 가능하도록 합니다.
 *
 * @generated from message retrica.SearchLookUpTablesRequest
 */
export class SearchLookUpTablesRequest extends Message<SearchLookUpTablesRequest> {
  /**
   * @generated from field: string offset = 1;
   */
  offset = "";

  /**
   * showhidden??
   *
   * @generated from field: uint32 size = 2;
   */
  size = 0;

  constructor(data?: PartialMessage<SearchLookUpTablesRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.SearchLookUpTablesRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "offset", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "size", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SearchLookUpTablesRequest {
    return new SearchLookUpTablesRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SearchLookUpTablesRequest {
    return new SearchLookUpTablesRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SearchLookUpTablesRequest {
    return new SearchLookUpTablesRequest().fromJsonString(jsonString, options);
  }

  static equals(a: SearchLookUpTablesRequest | PlainMessage<SearchLookUpTablesRequest> | undefined, b: SearchLookUpTablesRequest | PlainMessage<SearchLookUpTablesRequest> | undefined): boolean {
    return proto3.util.equals(SearchLookUpTablesRequest, a, b);
  }
}

/**
 * @generated from message retrica.SearchLookUpTablesResponse
 */
export class SearchLookUpTablesResponse extends Message<SearchLookUpTablesResponse> {
  /**
   * @generated from field: retrica.ErrorCode error_code = 1;
   */
  errorCode = ErrorCode.UNKNOWN;

  /**
   * @generated from field: repeated retrica.LookUpTable look_up_tables = 2;
   */
  lookUpTables: LookUpTable[] = [];

  /**
   * @generated from field: string offset = 3;
   */
  offset = "";

  constructor(data?: PartialMessage<SearchLookUpTablesResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.SearchLookUpTablesResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "error_code", kind: "enum", T: proto3.getEnumType(ErrorCode) },
    { no: 2, name: "look_up_tables", kind: "message", T: LookUpTable, repeated: true },
    { no: 3, name: "offset", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SearchLookUpTablesResponse {
    return new SearchLookUpTablesResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SearchLookUpTablesResponse {
    return new SearchLookUpTablesResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SearchLookUpTablesResponse {
    return new SearchLookUpTablesResponse().fromJsonString(jsonString, options);
  }

  static equals(a: SearchLookUpTablesResponse | PlainMessage<SearchLookUpTablesResponse> | undefined, b: SearchLookUpTablesResponse | PlainMessage<SearchLookUpTablesResponse> | undefined): boolean {
    return proto3.util.equals(SearchLookUpTablesResponse, a, b);
  }
}

/**
 * /a2/ac/deleteLookUpTable
 *
 * @generated from message retrica.DeleteLookUpTableRequest
 */
export class DeleteLookUpTableRequest extends Message<DeleteLookUpTableRequest> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  constructor(data?: PartialMessage<DeleteLookUpTableRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.DeleteLookUpTableRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeleteLookUpTableRequest {
    return new DeleteLookUpTableRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteLookUpTableRequest {
    return new DeleteLookUpTableRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeleteLookUpTableRequest {
    return new DeleteLookUpTableRequest().fromJsonString(jsonString, options);
  }

  static equals(a: DeleteLookUpTableRequest | PlainMessage<DeleteLookUpTableRequest> | undefined, b: DeleteLookUpTableRequest | PlainMessage<DeleteLookUpTableRequest> | undefined): boolean {
    return proto3.util.equals(DeleteLookUpTableRequest, a, b);
  }
}

/**
 * @generated from message retrica.DeleteLookUpTableResponse
 */
export class DeleteLookUpTableResponse extends Message<DeleteLookUpTableResponse> {
  /**
   * @generated from field: retrica.ErrorCode error_code = 1;
   */
  errorCode = ErrorCode.UNKNOWN;

  constructor(data?: PartialMessage<DeleteLookUpTableResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.DeleteLookUpTableResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "error_code", kind: "enum", T: proto3.getEnumType(ErrorCode) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeleteLookUpTableResponse {
    return new DeleteLookUpTableResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteLookUpTableResponse {
    return new DeleteLookUpTableResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeleteLookUpTableResponse {
    return new DeleteLookUpTableResponse().fromJsonString(jsonString, options);
  }

  static equals(a: DeleteLookUpTableResponse | PlainMessage<DeleteLookUpTableResponse> | undefined, b: DeleteLookUpTableResponse | PlainMessage<DeleteLookUpTableResponse> | undefined): boolean {
    return proto3.util.equals(DeleteLookUpTableResponse, a, b);
  }
}

/**
 * /h1/ac/getPresetRecommendation
 *
 * @generated from message retrica.GetPresetRecommendationRequest
 */
export class GetPresetRecommendationRequest extends Message<GetPresetRecommendationRequest> {
  constructor(data?: PartialMessage<GetPresetRecommendationRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.GetPresetRecommendationRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetPresetRecommendationRequest {
    return new GetPresetRecommendationRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetPresetRecommendationRequest {
    return new GetPresetRecommendationRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetPresetRecommendationRequest {
    return new GetPresetRecommendationRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetPresetRecommendationRequest | PlainMessage<GetPresetRecommendationRequest> | undefined, b: GetPresetRecommendationRequest | PlainMessage<GetPresetRecommendationRequest> | undefined): boolean {
    return proto3.util.equals(GetPresetRecommendationRequest, a, b);
  }
}

/**
 * @generated from message retrica.GetPresetRecommendationResponse
 */
export class GetPresetRecommendationResponse extends Message<GetPresetRecommendationResponse> {
  /**
   * @generated from field: retrica.ErrorCode error_code = 1;
   */
  errorCode = ErrorCode.UNKNOWN;

  /**
   * @generated from field: repeated string tags = 2;
   */
  tags: string[] = [];

  constructor(data?: PartialMessage<GetPresetRecommendationResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.GetPresetRecommendationResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "error_code", kind: "enum", T: proto3.getEnumType(ErrorCode) },
    { no: 2, name: "tags", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetPresetRecommendationResponse {
    return new GetPresetRecommendationResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetPresetRecommendationResponse {
    return new GetPresetRecommendationResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetPresetRecommendationResponse {
    return new GetPresetRecommendationResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetPresetRecommendationResponse | PlainMessage<GetPresetRecommendationResponse> | undefined, b: GetPresetRecommendationResponse | PlainMessage<GetPresetRecommendationResponse> | undefined): boolean {
    return proto3.util.equals(GetPresetRecommendationResponse, a, b);
  }
}

/**
 * /a2/ac/updatePresetRecommendation
 *
 * @generated from message retrica.UpdatePresetRecommendationRequest
 */
export class UpdatePresetRecommendationRequest extends Message<UpdatePresetRecommendationRequest> {
  /**
   * @generated from field: repeated string tags = 1;
   */
  tags: string[] = [];

  constructor(data?: PartialMessage<UpdatePresetRecommendationRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.UpdatePresetRecommendationRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "tags", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdatePresetRecommendationRequest {
    return new UpdatePresetRecommendationRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdatePresetRecommendationRequest {
    return new UpdatePresetRecommendationRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdatePresetRecommendationRequest {
    return new UpdatePresetRecommendationRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdatePresetRecommendationRequest | PlainMessage<UpdatePresetRecommendationRequest> | undefined, b: UpdatePresetRecommendationRequest | PlainMessage<UpdatePresetRecommendationRequest> | undefined): boolean {
    return proto3.util.equals(UpdatePresetRecommendationRequest, a, b);
  }
}

/**
 * @generated from message retrica.UpdatePresetRecommendationResponse
 */
export class UpdatePresetRecommendationResponse extends Message<UpdatePresetRecommendationResponse> {
  /**
   * @generated from field: retrica.ErrorCode error_code = 1;
   */
  errorCode = ErrorCode.UNKNOWN;

  /**
   * @generated from field: repeated string tags = 2;
   */
  tags: string[] = [];

  constructor(data?: PartialMessage<UpdatePresetRecommendationResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.UpdatePresetRecommendationResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "error_code", kind: "enum", T: proto3.getEnumType(ErrorCode) },
    { no: 2, name: "tags", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdatePresetRecommendationResponse {
    return new UpdatePresetRecommendationResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdatePresetRecommendationResponse {
    return new UpdatePresetRecommendationResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdatePresetRecommendationResponse {
    return new UpdatePresetRecommendationResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UpdatePresetRecommendationResponse | PlainMessage<UpdatePresetRecommendationResponse> | undefined, b: UpdatePresetRecommendationResponse | PlainMessage<UpdatePresetRecommendationResponse> | undefined): boolean {
    return proto3.util.equals(UpdatePresetRecommendationResponse, a, b);
  }
}

