import { Pack } from 'types/pack';
import { PackFullView } from './PackFullView';
import { PackCompactView } from './PackCompactView';

export interface PackViewProps {
  pack: Pack;
  size: 'full' | 'compact';
  selected: boolean;
  onDelete?: (id: string) => void;
  onClick?: (p: Pack) => void;
}

export function PackView(props: PackViewProps) {
  switch (props.size) {
    case 'full':
      return <PackFullView pack={props.pack} onDelete={props.onDelete} />;
    case 'compact':
      return (
        <PackCompactView
          pack={props.pack}
          size="compact"
          selected={props.selected}
          onDelete={props.onDelete}
          onClick={props.onClick}
        />
      );
  }
}

PackView.defaultProps = {
  size: 'full',
  selected: false,
};
