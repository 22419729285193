import { NotificationInstance } from 'antd/es/notification/interface';
import { Content, MaterialType } from './material';

export interface Identifiable {
  id: string;
}

export const copyId = (i: Identifiable, noti?: NotificationInstance) => {
  navigator.clipboard.writeText(i.id);
  noti?.success({
    message: 'Material ID copied to clipboard.',
    description: `${i.id}`,
    placement: 'top',
    duration: 2,
  });
};

export interface HasThumbnail {
  thumbnailUrl: string;
}

export function hasThumbnail(object: any): object is HasThumbnail {
  return 'thumbnailUrl' in object;
}

export interface HasContent {
  content: Content;
}

export function hasContent(object: any): object is HasContent {
  return 'content' in object;
}

export interface Vendable {
  isFree: boolean;
}

export function isVendable(object: any): object is Vendable {
  return 'isFree' in object;
}

export interface Material extends Identifiable {
  type: MaterialType;
  tags: string[];
  picked?: number;
}

export function isMaterial(object: any): object is Material {
  if (!object) return false;
  return 'type' in object && 'tags' in object;
}
