import React from 'react';
import { SubTitle } from 'components/PageTitles';
import { BundleListEditView } from './components/BundleListEditView';

function BundleLists() {
  return (
    <React.Fragment>
      <SubTitle>Manage Bundle list</SubTitle>
      <BundleListEditView />
    </React.Fragment>
  );
}

const exports = BundleLists;
export default exports;
