import { Button, Form } from 'antd';
import { TagEntries } from 'components/TagEntries';
import { useState } from 'react';
import componentStyle from 'styles/common.module.css';
import { CheckOutlined } from '@ant-design/icons';
import { MaterialColor, NewMaterial } from 'types/material';
import { useForm } from 'antd/lib/form/Form';
import { ColorPicker } from 'components/ColorPicker';
import TextArea from 'antd/lib/input/TextArea';
import { Grapeseed } from 'api/grapeseed';
import { OnError } from 'utils/errors';
import { normalizeColorCode } from 'utils/colors';

interface NewColorFormProps {
  onAdd: (c: MaterialColor[]) => void;
}

function NewColorForm(props: NewColorFormProps) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hideColorField, setHideColorField] = useState<boolean>(false);
  const [form] = useForm();

  const submitBulk = async (tags: string[], codes: string[]) => {
    const submitPromises = codes.map((c: string) => {
      return new Promise<MaterialColor>(async (resolve, reject) => {
        const code = normalizeColorCode(c);
        if (!code) {
          return reject(`invalid code string: ${c}`);
        }
        const body: MaterialColor = new MaterialColor({
          tags: tags,
          code: code,
        });
        const result = await Grapeseed.POST('/api/colors', {
          body: body,
          fallback: (e) => reject(e),
        });
        if (result) {
          resolve(new MaterialColor(result));
        }
      });
    });
    const results = await Promise.allSettled(submitPromises);
    const fulfilled = results.filter(
      (r) => r.status === 'fulfilled'
    ) as PromiseFulfilledResult<MaterialColor>[];
    const rejected = results.filter(
      (r) => r.status === 'rejected'
    ) as PromiseRejectedResult[];
    props.onAdd(
      fulfilled
        .map((f) => f.value)
        .sort((a, b) => {
          return a.id > b.id ? -1 : 1;
        })
    );

    if (rejected.length > 0) {
      OnError(
        rejected
          .map((r) => r.reason as string)
          .reduce((acc, cur) => acc + ', ' + cur)
      );
    }
  };

  const submit = async (tags: string[], code: string) => {
    const normalized = normalizeColorCode(code);
    if (!normalized) {
      OnError(`invalid color code: ${code}`);
      return;
    }
    const body: MaterialColor = new MaterialColor({
      tags: tags,
      code: normalized,
    });
    const result = await Grapeseed.POST(`/api/colors`, { body: body });
    if (result) {
      props.onAdd([NewMaterial(result) as MaterialColor]);
    }
  };

  const onSubmit = async (values: any) => {
    setIsLoading(true);
    if (values.codeStrings) {
      submitBulk(values.tags ?? [], values.codeStrings.split(','));
    } else {
      submit(values.tags ?? [], values.colorcode);
    }
    setIsLoading(false);
    setHideColorField(false);
    form.resetFields();
  };

  const onValuesChange = (values: any) => {
    if (values.codeStrings?.length > 0) {
      setHideColorField(true);
    } else {
      setHideColorField(false);
    }
  };

  return (
    <Form
      form={form}
      onFinish={onSubmit}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          e.preventDefault();
        }
      }}
      onValuesChange={onValuesChange}
      labelCol={{ span: 3 }}
      labelAlign="left"
      colon={false}
      style={{ margin: '1rem 0' }}
    >
      <Form.Item
        label="Color"
        name="colorcode"
        initialValue="#FFFFFFFF"
        hidden={hideColorField}
      >
        <ColorPicker />
      </Form.Item>
      <Form.Item
        label="Colors"
        name="codeStrings"
        extra={
          <span className={componentStyle.hinttextOrange}>
            * Enter multiple color codes separated with ',' e.g.
            "#FFFFFFFF,#9AB1E974"
          </span>
        }
      >
        <TextArea />
      </Form.Item>
      <Form.Item
        name="tags"
        label="Tags"
        extra={
          <span className={componentStyle.hinttextOrange}>
            * Press enter to add tag.
          </span>
        }
      >
        <TagEntries layout="horizontal" />
      </Form.Item>
      <Form.Item>
        <Button
          className={componentStyle.buttonOrange}
          type="primary"
          icon={<CheckOutlined />}
          htmlType="submit"
          loading={isLoading}
        >
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
}

const exports = NewColorForm;
export default exports;
