import { ReloadOutlined } from '@ant-design/icons';
import { Button, Popover, Tag } from 'antd';
import { ThumbnailSizes } from 'components/Thumbnail/Constants';
import { ThumbnailView } from 'components/Thumbnail/ThumbnailView';
import { useFetchMaterials } from 'hooks/useFetchMaterials';
import { useState } from 'react';
import { hasThumbnail } from 'types/interfaces';
import { MaterialTypes } from 'types/material';
import { FallbackImage } from 'utils/constants';

export interface SuggestionPreviewProps {
  tag: string;
  onDelete: (s: string) => void;
}

const randomInt = () => {
  return Math.floor(Math.random() * 100);
};

export function SuggestionPreview(props: SuggestionPreviewProps) {
  const [seed, setSeed] = useState<number>(randomInt());
  const [items] = useFetchMaterials({
    tags: props.tag,
    type: [MaterialTypes.Sticker],
    page: 0,
    size: 12,
    randomSeed: seed,
  });

  return (
    <>
      <Popover
        trigger="hover"
        content={
          <div style={{ display: 'flex', width: 210, alignItems: 'flex-end' }}>
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              {items.map((elem) => {
                return hasThumbnail(elem) ? (
                  <ThumbnailView
                    size={ThumbnailSizes.mini}
                    preview={false}
                    src={elem.thumbnailUrl}
                  />
                ) : (
                  <img src={FallbackImage} width="40px" height="40px" alt="" />
                );
              })}
            </div>
            <Button
              type="text"
              shape="circle"
              onClick={() => setSeed(randomInt())}
            >
              <ReloadOutlined style={{ color: 'var(--highlight-color' }} />
            </Button>
          </div>
        }
      >
        <Tag color="blue" closable onClose={() => props.onDelete(props.tag)}>
          {props.tag}
        </Tag>
      </Popover>
    </>
  );
}
