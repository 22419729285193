import { Descriptions } from 'antd';
import MaterialLegend from 'components/MaterialViews/MaterialLegend';
import { SubTitle } from 'components/PageTitles';
import { useFetchPacks } from 'hooks/useFetchPacks';
import React, { useRef } from 'react';
import { PackList } from './components/PackList';

function Recent() {
  const lastItemRef = useRef(null);
  const [list, setList, hasMore] = useFetchPacks({
    sort: ['createdAt:desc', 'priority:desc'],
    lastItemRef: lastItemRef,
  });

  return (
    <React.Fragment>
      <Descriptions
        title={<SubTitle>Recent packs</SubTitle>}
        extra={<MaterialLegend />}
      ></Descriptions>
      <PackList
        list={list}
        lastItemRef={lastItemRef}
        hasMore={hasMore}
        onDelete={(id: string) =>
          setList(list.filter((pack) => pack.id !== id))
        }
        itemSize={'full'}
      />
    </React.Fragment>
  );
}

const exports = Recent;
export default exports;
