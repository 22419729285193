import { Select } from 'antd';
import { useState } from 'react';
import { SearchMaterialView } from '../../../components/SearchViews/SearchMaterialView';
import { SearchPackView } from './SearchPackView';
import { SearchCreatorView } from './SearchCreatorView';
import { Material } from 'types/interfaces';
import { Pack } from 'types/pack';
import { Creator } from 'types/common';

export const SearchTypes = {
  Materials: 'materials',
  Packs: 'packs',
  Creators: 'creators',
} as const;
export type SearchType = (typeof SearchTypes)[keyof typeof SearchTypes];

export interface Selections {
  materials: Material[];
  packs: Pack[];
  creators: Creator[];
  onItemSelect: (item: Material | Pack | Creator) => void;
}

export interface SearchItemViewProps {
  onSearchTypeChange?: (t: SearchType) => void;
  selections?: Selections;
}

export function SearchItemView(props: SearchItemViewProps) {
  const [searchType, setSearchType] = useState<SearchType>(
    SearchTypes.Materials
  );

  const itemTypes = () => {
    let menuItems = [];
    for (const [k, v] of Object.entries(SearchTypes)) {
      menuItems.push({ value: v, label: k });
    }
    return menuItems;
  };

  const onChangeItemType = (value: string, options: any) => {
    const st = value as SearchType;
    setSearchType(st);
    props.onSearchTypeChange?.(st);
  };

  const renderSearchView = (type: SearchType) => {
    switch (type) {
      case SearchTypes.Materials:
        return <SearchMaterialView selections={props.selections} />;
      case SearchTypes.Packs:
        return <SearchPackView selections={props.selections} />;
      case SearchTypes.Creators:
        return <SearchCreatorView selections={props.selections} />;
    }
  };

  return (
    <div
      style={{
        overflow: 'scroll',
        flex: '1 0 0',
        display: 'flex',
        flexDirection: 'column',
        borderStyle: 'solid',
        borderColor: '#E0E0E0',
        borderRadius: '20px',
        padding: '1rem',
      }}
    >
      <div>
        <Select
          style={{ width: 140 }}
          defaultValue={SearchTypes.Materials}
          onChange={onChangeItemType}
          options={itemTypes()}
        />
      </div>
      {renderSearchView(searchType)}
    </div>
  );
}
