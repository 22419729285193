import { Radio } from 'antd';
import { DisabledType } from 'antd/lib/config-provider/DisabledContext';
import { useState } from 'react';
import componentStyle from 'styles/common.module.css';

export interface RadioPickerProps {
  name: string;
  options: (string | number)[];
  suffix?: string;
  disabled?: DisabledType;
  onChange?: (value: string) => void;
}

export function RadioPicker(props: RadioPickerProps) {
  const [val, setVal] = useState<string>('');

  const radioButtons = props.options.map((button) => (
    <Radio key={button} value={button}>
      <span
        className={val === button ? componentStyle.selectedRadioButtonText : ''}
      >
        {button + (props.suffix ?? '')}
      </span>
    </Radio>
  ));

  return (
    <Radio.Group
      name={props.name}
      value={val}
      onChange={(e) => {
        setVal(e.target.value);
        if (props.onChange != null) {
          props.onChange(e.target.value);
        }
      }}
      disabled={props.disabled}
    >
      {radioButtons}
    </Radio.Group>
  );
}
