import { Descriptions, notification } from 'antd';
import { Grapeseed } from 'api/grapeseed';
import MaterialLegend from 'components/MaterialViews/MaterialLegend';
import { MaterialView } from 'components/MaterialViews/MaterialView';
import { SubTitle } from 'components/PageTitles';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Material } from 'types/interfaces';
import { NewMaterial } from 'types/material';
import { ApiError, OnError } from 'utils/errors';
import viewStyle from 'styles/view.module.css';

function SingleItem() {
  const navigate = useNavigate();
  const params = useParams();
  const id = params.id;

  const [item, setItem] = useState<Material>();

  useEffect(() => {
    async function fetchItem() {
      const result = await Grapeseed.GET(`/api/materials/${id}`, {
        fallback: (e) => {
          if (e instanceof ApiError && e.statusCode === 404) {
            notification.info({
              placement: 'top',
              message: `Could not find material with ID: ${id}`,
              duration: 2,
              onClose: () => navigate(-1),
            });
          } else {
            OnError(e);
          }
        },
      });

      if (result) {
        const mat = NewMaterial(result);
        if (mat) {
          setItem(mat);
        }
      }
    }
    fetchItem();
  }, [id, navigate]);

  return item ? (
    <React.Fragment>
      <Descriptions
        title={<SubTitle>{id}</SubTitle>}
        extra={<MaterialLegend />}
      />
      <div className={`${viewStyle.viewbox} ${viewStyle.wrap}`}>
        <MaterialView editable draggable={false} material={item!} />
      </div>
    </React.Fragment>
  ) : (
    <React.Fragment></React.Fragment>
  );
}

const exports = SingleItem;
export default exports;
