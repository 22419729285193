import { useEffect } from 'react';
import style from './imageComparison.module.css';
import { getMediaResolution } from 'utils/media';

export interface ImageComparisonViewProps {
  id: string;
  width: number;
  rightImageUrl: string;
  rightImageLabel: string;
  leftImageUrl: string;
  leftImageLabel: string;
}

export function ImageComparisonView(props: ImageComparisonViewProps) {
  useEffect(() => {
    initComparisons(props.id, props.width);
  }, [props.id, props.rightImageUrl, props.width]);
  return (
    <div className={`${style.imgCompBox}`}>
      <div className={`${style.imgCompImage}`}>
        <img src={props.rightImageUrl} alt="left" width={props.width} />
      </div>
      <div
        className={style.imgLabel}
        style={{
          width: `${props.width}px`,
          paddingRight: '6px',
          justifyContent: 'flex-end',
        }}
      >
        {props.rightImageLabel}
      </div>
      <div className={`${style.imgCompImage}`} id={`overlayImage-${props.id}`}>
        <img src={props.leftImageUrl} alt="right" width={props.width} />
      </div>
      <div
        className={style.imgLabel}
        id={`overlayLabel-${props.id}`}
        style={{
          width: `${props.width}px`,
          paddingLeft: '6px',
          justifyContent: 'flex-start',
        }}
      >
        {props.leftImageLabel}
      </div>
    </div>
  );
}

function initComparisons(id: string, width: number) {
  /* Find all elements with an "overlay" class: */
  let x = document.getElementById(`overlayImage-${id}`);
  let l = document.getElementById(`overlayLabel-${id}`);
  if (x && l) {
    compareImages(x as HTMLElement, l as HTMLElement, width);
  }
  async function compareImages(
    imgDiv: HTMLElement,
    lableDiv: HTMLElement,
    width: number
  ) {
    if (width === 0) return;
    var slider: HTMLElement;
    var clicked = 0;
    var w: number;
    var h: number;
    /* Check if slider already exists */
    w = imgDiv.offsetWidth;
    if (w !== width) {
      return;
    }
    /* Get the width and height of the img element */
    const img = imgDiv.children[0];
    const src = img.getAttribute('src');
    if (!src) return;
    const imgRes = await getMediaResolution(src);
    const height = imgRes.height / (imgRes.width / width);
    h = height;
    /* Set the width of the img element to 50%: */
    imgDiv.style.width = w / 2 + 'px';
    /* Create slider: */
    slider = document.createElement('DIV');
    slider.setAttribute('class', `${style.imgCompSlider}`);
    slider.setAttribute('id', `slider-${id}`);
    slider.style.height = h + 'px';
    /* Insert slider */
    if (document.getElementById(`slider-${id}`)) {
      return;
    }
    imgDiv.parentElement?.insertBefore(slider, imgDiv);
    /* Position the slider in the middle: */
    slider.style.top = '0px';
    slider.style.left = w / 2 - slider.offsetWidth / 2 + 'px';
    /* Execute a function when the mouse button is pressed: */
    slider.addEventListener('mousedown', slideReady);
    /* And another function when the mouse button is released: */
    window.addEventListener('mouseup', slideFinish);
    function slideReady(e: MouseEvent) {
      /* Prevent any other actions that may occur when moving over the image: */
      e.preventDefault();
      /* The slider is now clicked and ready to move: */
      clicked = 1;
      /* Execute a function when the slider is moved: */
      window.addEventListener('mousemove', slideMove);
    }
    function slideFinish() {
      /* The slider is no longer clicked: */
      clicked = 0;
    }
    function slideMove(e: MouseEvent) {
      var pos: number;
      /* If the slider is no longer clicked, exit this function: */
      if (clicked === 0) return false;
      /* Get the cursor's x position: */
      pos = getCursorPos(e);
      /* Prevent the slider from being positioned outside the image: */
      if (pos < 0) pos = 0;
      if (pos > w) pos = w;
      /* Execute a function that will resize the overlay image according to the cursor: */
      slide(pos);
    }
    function getCursorPos(e: MouseEvent) {
      var a: DOMRect;
      var x = 0;
      /* Get the x positions of the image: */
      a = imgDiv.getBoundingClientRect();
      /* Calculate the cursor's x coordinate, relative to the image: */
      x = e.pageX - a.left;
      /* Consider any page scrolling: */
      x = x - window.scrollX;
      return x;
    }
    function slide(x: number) {
      /* Resize the image: */
      imgDiv.style.width = x + 'px';
      lableDiv.style.width = x + 'px';

      /* Position the slider: */
      slider.style.left = imgDiv.offsetWidth - slider.offsetWidth / 2 + 'px';
    }
  }
}
