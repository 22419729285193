import { useFetchBundles } from 'hooks/useFetchBundles';
import { useRef, DragEvent } from 'react';
import viewStyle from 'styles/view.module.css';
import { Bundle } from 'types/bundle';
import { BundlesVerticalList } from './BundlesVerticalList';

export function BundlePool() {
  const lastItemRef = useRef(null);
  const [bundles, setBundles, hasMore] = useFetchBundles({ lastItemRef });

  const onItemDragStart = (
    e: DragEvent<HTMLDivElement>,
    b: Bundle,
    i: number
  ) => {
    e.dataTransfer.setData(
      'application/json',
      JSON.stringify({ dataType: 'bundle', data: b })
    );
  };

  return (
    <div
      className={`${viewStyle.viewbox}`}
      style={{
        overflow: 'scroll',
        flex: '1 1 0',
      }}
    >
      <BundlesVerticalList
        list={bundles}
        onRemove={(id: string) =>
          setBundles((prev) => prev.filter((b) => b.id !== id))
        }
        lastItemRef={lastItemRef}
        hasMore={hasMore}
        itemEditable
        itemDraggable
        onItemDragStart={onItemDragStart}
      />
    </div>
  );
}
