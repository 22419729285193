import { notification } from 'antd';
import { Grapeseed } from 'api/grapeseed';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { User } from 'types/user';
import { ApiError, OnError } from 'utils/errors';
import { UserView } from './components/UserView';

export function SingleItem() {
  const navigate = useNavigate();
  const params = useParams();
  const id = params.id;

  const [user, setUser] = useState<User>();

  useEffect(() => {
    async function fetchUser() {
      const result = await Grapeseed.GET(`/api/users/${id}`, {
        fallback: (e) => {
          if (e instanceof ApiError && e.statusCode === 404) {
            notification.info({
              placement: 'top',
              message: `Could not find material with ID: ${id}`,
              duration: 2,
              onClose: () => navigate(-1),
            });
          } else {
            OnError(e);
          }
        },
      });

      if (result) {
        setUser(result);
      }
    }
    fetchUser();
  }, [id, navigate, setUser]);

  return user ? <UserView user={user} size="full" /> : null;
}
