import { User } from 'types/user';
import { UserView } from './UserView';
import { MutableRefObject } from 'react';
import { Popover, Spin } from 'antd';

export interface UserListViewProps {
  list: User[];
  hasMore?: boolean;
  lastItemRef?: MutableRefObject<null>;
  onItemClick?: 'popover' | ((u: User) => void);
}

export function UserListView(props: UserListViewProps) {
  return (
    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
      {props.list.map((user: User) => {
        return props.onItemClick === 'popover' ? (
          <Popover
            trigger="click"
            content={
              <div style={{ minWidth: '80vh' }}>
                <UserView size="full" user={user} />
              </div>
            }
          >
            <UserView size="compact" user={user} />
          </Popover>
        ) : (
          <UserView size="compact" user={user} onClick={props.onItemClick} />
        );
      })}
      {props.hasMore && props.lastItemRef && (
        <div
          ref={props.lastItemRef}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: 200,
            margin: '0.5rem 1rem',
          }}
        >
          <Spin />
        </div>
      )}
    </div>
  );
}
