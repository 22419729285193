import { CheckOutlined } from '@ant-design/icons';
import { Button, Checkbox, Divider, Form, Input, Modal, Switch } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import {
  Bundle,
  BundleItem,
  NewBundleItem,
  UnionBundleItems,
} from 'types/bundle';
import componentStyle from 'styles/common.module.css';
import { DragEvent, useRef, useState } from 'react';
import { ulid } from 'ulid';
import { Grapeseed } from 'api/grapeseed';
import { useNavigate } from 'react-router';
import { BundleItemsConstructView } from './BundleItemsConstructView';
import { SearchItemView } from './SearchItemView';
import { Creator, Size } from 'types/common';
import {
  ContainerBundles,
  ThumbnailAcceptTypes,
  ThumbnailFileSuffix,
} from 'types/material';
import { Pack } from 'types/pack';
import { FileUploader, acceptTypesInfo } from 'components/FileUploader';
import { getMediaResolution } from 'utils/media';
import { ThumbnailView } from 'components/Thumbnail/ThumbnailView';
import { ThumbnailSizes } from 'components/Thumbnail/Constants';
import { Material, isMaterial } from 'types/interfaces';

export interface BundleEditViewProps {
  bundle?: Bundle;
}

export function BundleEditView(props: BundleEditViewProps) {
  const [form] = useForm();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const bundle: Bundle | undefined = props.bundle;
  const isNew: boolean = bundle === undefined;
  const id = useRef<string>(bundle?.id ?? ulid());
  const [list, setList] = useState<BundleItem[]>(bundle?.items ?? []);
  const isBanner = useRef<boolean>(
    bundle ? (bundle.isBanner ? true : false) : false
  );
  const [isRoute, setIsRoute] = useState<boolean>(
    bundle ? (bundle.items[0]?.ref.startsWith('/route/') ? true : false) : false
  );
  const moveAfterSubmit = useRef<boolean>(true);
  const [convertToBannerModalOpen, setConvertToBannerModalOpen] =
    useState<boolean>(false);
  const [convertToRouteModalOpen, setConvertToRouteModalOpen] =
    useState<boolean>(false);

  const onSubmit = async (values: any) => {
    const createdAt = new Date();
    const items: BundleItem[] = isRoute
      ? [
          {
            thumbnailUrl: values.thumbnail[0].url,
            thumbnailSize: await getMediaResolution(values.thumbnail[0]),
            ref: `/route/${values.routeRef}`,
          },
        ]
      : list;
    const body: Bundle = new Bundle({
      id: id.current,
      title: values.title,
      description: values.description,
      createdAt: isNew ? createdAt : bundle!.createdAt,
      isBanner: isRoute ? true : isBanner.current,
      items: items,
    });
    setIsLoading(true);
    let result;
    if (isNew) {
      result = await Grapeseed.POST('/api/bundles', { body: body });
    } else {
      result = await Grapeseed.PUT(`/api/bundles/${bundle!.id}`, {
        body: body,
      });
    }
    setIsLoading(false);
    if (result) {
      if (moveAfterSubmit.current) {
        navigate('/bundles/bundleList');
      } else {
        form.resetFields();
        setList([]);
      }
    }
  };

  const onDragOver = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const onDrop = async (e: DragEvent<HTMLDivElement>) => {
    if (isBanner.current && list.length > 0) {
      return;
    }
    const transfer = e.dataTransfer.getData('application/json');
    const obj = JSON.parse(transfer);
    try {
      const newItem = await NewBundleItem(obj.data);
      if (newItem) {
        setList((prev) => UnionBundleItems(prev, [newItem]));
      }
    } catch (e) {
      console.log(e);
    }
  };

  const swapItems = (src: number, dest: number) => {
    const old = list;
    const movingItem = old.splice(src, 1)[0];
    setList([...old.slice(0, dest), movingItem, ...old.slice(dest)]);
  };

  const changeThumbnail = (url: string, size: Size, i: number) => {
    const old = list;
    const changed = old.splice(i, 1)[0];
    changed.thumbnailUrl = url;
    changed.thumbnailSize = size;
    setList([...old.slice(0, i), changed, ...old.slice(i)]);
  };

  const [selectedMaterials, setSelectedMaterials] = useState<Material[]>([]);
  const [selectedPacks, setSelectedPacks] = useState<Pack[]>([]);
  const [selectedCreators, setSelectedCreators] = useState<Creator[]>([]);

  const onItemSelected = async (item: Material | Pack | Creator) => {
    if (isMaterial(item)) {
      if (selectedMaterials.find((m) => m.id === item.id)) {
        setSelectedMaterials((prev) => prev.filter((m) => m.id !== item.id));
      } else {
        setSelectedMaterials((prev) => {
          const cur = prev.slice();
          cur.push(item);
          return cur;
        });
      }
    } else if (item instanceof Pack) {
      if (selectedPacks.find((p) => p.id === item.id)) {
        setSelectedPacks((prev) => prev.filter((p) => p.id !== item.id));
      } else {
        setSelectedPacks((prev) => {
          const cur = prev.slice();
          cur.push(item);
          return cur;
        });
      }
    } else if (item instanceof Creator) {
      if (selectedCreators.find((c) => c.id === item.id)) {
        setSelectedCreators((prev) => prev.filter((c) => c.id !== item.id));
      } else {
        setSelectedCreators((prev) => {
          const cur = prev.slice();
          cur.push(item);
          return cur;
        });
      }
    }
  };

  const resetSelected = () => {
    setSelectedPacks([]);
    setSelectedCreators([]);
    setSelectedMaterials([]);
  };

  const addBundleItem = async () => {
    const promises: Promise<BundleItem>[] = [];
    const results = await Promise.allSettled(
      promises
        .concat(selectedMaterials.map((m) => NewBundleItem(m)))
        .concat(selectedPacks.map((p) => NewBundleItem(p)))
        .concat(selectedCreators.map((c) => NewBundleItem(c)))
    );
    const fulfilled = results.filter(
      (r) => r.status === 'fulfilled'
    ) as PromiseFulfilledResult<BundleItem>[];
    setList((prev) =>
      UnionBundleItems(
        prev,
        fulfilled.map((f) => f.value)
      )
    );
    resetSelected();
  };

  return (
    <>
      <Modal
        title="Convert to Banner Type?"
        open={convertToBannerModalOpen}
        onOk={() => {
          setList((prev) => [prev[0]]);
          form.setFieldValue('isBanner', true);
          isBanner.current = true;
          setConvertToBannerModalOpen(false);
        }}
        onCancel={() => {
          form.setFieldValue('isBanner', false);
          isBanner.current = false;
          setConvertToBannerModalOpen(false);
        }}
      >
        Items except the first one will be removed from list
      </Modal>
      <Modal
        title="Convert to Route Type?"
        open={convertToRouteModalOpen}
        onOk={() => {
          setList([]);
          form.setFieldValue('isRoute', true);
          form.setFieldValue('isBanner', true);
          setIsRoute(true);
          setConvertToRouteModalOpen(false);
        }}
        onCancel={() => {
          form.setFieldValue('isRoute', false);
          setIsRoute(false);
          setConvertToRouteModalOpen(false);
        }}
      >
        All items will be removed from list
      </Modal>
      <Form
        form={form}
        onFinish={onSubmit}
        labelWrap={true}
        labelCol={{ span: 3 }}
        labelAlign="left"
        colon={false}
        style={{ margin: '1rem 0' }}
      >
        <Form.Item
          label="Title"
          name="title"
          wrapperCol={{ span: 5 }}
          hasFeedback={true}
          initialValue={bundle?.title}
          rules={[{ required: true, message: 'Title is required.' }]}
        >
          <Input type="text" placeholder="Bundle title" />
        </Form.Item>
        <Form.Item
          label="Description"
          name="description"
          initialValue={bundle?.description}
          wrapperCol={{ span: 12 }}
        >
          <Input.TextArea rows={4} />
        </Form.Item>
        <Form.Item
          label="Banner Type"
          name="isBanner"
          valuePropName="checked"
          initialValue={isRoute ? true : isBanner.current}
          wrapperCol={{ span: 7 }}
          extra={
            <span className={componentStyle.hinttextOrange}>
              * Bundle with banner type can have only 1 item in it.
            </span>
          }
        >
          <Checkbox
            disabled={isRoute}
            onChange={(e) => {
              if (e.target.checked && list.length > 1) {
                setConvertToBannerModalOpen(true);
              } else {
                isBanner.current = e.target.checked;
              }
            }}
          />
        </Form.Item>
        <Form.Item
          label="Route Type"
          name="isRoute"
          valuePropName="checked"
          initialValue={isRoute}
          wrapperCol={{ span: 7 }}
        >
          <Checkbox
            onChange={(e) => {
              if (e.target.checked && list.length > 0) {
                setConvertToRouteModalOpen(true);
              } else {
                if (e.target.checked) {
                  form.setFieldValue('isBanner', true);
                }
                setIsRoute(e.target.checked);
              }
            }}
          />
        </Form.Item>
        {isRoute && (
          <>
            <Form.Item
              name="thumbnail"
              label="Thumbnail"
              extra={
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <ThumbnailView
                    size={ThumbnailSizes.compact}
                    style={{ marginTop: '0.4rem' }}
                    src={bundle?.items[0].thumbnailUrl ?? ''}
                    preview={false}
                  />
                  {acceptTypesInfo(ThumbnailAcceptTypes)}
                </div>
              }
              rules={[{ required: true, message: 'thumbnail is required.' }]}
            >
              <FileUploader
                container={ContainerBundles}
                accept={ThumbnailAcceptTypes}
                filename={id.current}
                suffix={ThumbnailFileSuffix}
              />
            </Form.Item>
            <Form.Item
              label="Route link"
              name="routeRef"
              wrapperCol={{ span: 7 }}
              hasFeedback={true}
              initialValue={bundle?.items[0]?.ref.replaceAll('/route/', '')}
              rules={[{ required: true, message: 'link is required.' }]}
            >
              <Input
                type="text"
                placeholder="link where to route"
                addonBefore="/route/"
              />
            </Form.Item>
          </>
        )}
        <Form.Item>
          <Button
            className={componentStyle.buttonOrange}
            type="primary"
            icon={<CheckOutlined />}
            htmlType="submit"
            loading={isLoading}
          >
            Submit
          </Button>
          {isNew && (
            <span
              style={{
                marginLeft: '1rem',
                color: 'var(--icontext-default-color)',
              }}
            >
              Create more{' '}
              <Switch
                size="small"
                onChange={(checked) => {
                  moveAfterSubmit.current = !checked;
                }}
              />
            </span>
          )}
        </Form.Item>
      </Form>
      {!isRoute && (
        <div style={{ marginBottom: '1rem' }}>
          <BundleItemsConstructView
            list={list}
            onDragOver={onDragOver}
            onDrop={onDrop}
            onRemove={(ref) =>
              setList((prev) => prev.filter((b) => b.ref !== ref))
            }
            onItemMove={swapItems}
            onChangeThumbnail={changeThumbnail}
          />
          <Divider orientation="center">
            <Button type="primary" onClick={addBundleItem}>
              ⬆
            </Button>
          </Divider>
          <SearchItemView
            onSearchTypeChange={resetSelected}
            selections={{
              materials: selectedMaterials,
              packs: selectedPacks,
              creators: selectedCreators,
              onItemSelect: onItemSelected,
            }}
          />
        </div>
      )}
    </>
  );
}
