import React, { DragEvent } from 'react';
import {
  Background,
  Combo,
  Font,
  Gradient,
  Layout,
  MaterialColor,
  MaterialText,
  MaterialTypes,
  Placeholder,
  Shape,
  Sticker,
  Template,
} from 'types/material';
import StickerView from 'components/MaterialViews/StickerView';
import FontView from 'components/MaterialViews/FontView';
import LayoutView from './LayoutView';
import BackgroundView from './BackgroundView';
import PlaceholderView from './PlaceholderView';
import { Material } from 'types/interfaces';
import ColorView from './ColorView';
import { ComboView } from './ComboView';
import { TextView } from './TextView';
import { ShapeView } from './ShapeView';
import { GradientView } from './GradientView';
import { TemplateView } from './TemplateView';
import { MaterialCompactView } from './MaterialCompactView';

export interface MaterialViewProps {
  material: Material;
  size?: 'compact' | 'full';
  editable: boolean;
  draggable: boolean;
  selected: boolean;
  onRemove?: (id: string) => void;
  onClick?: (m: Material) => void;
}

export const onMaterialDragStart = (
  e: DragEvent<HTMLDivElement>,
  m: Material
) => {
  e.dataTransfer.setData(
    'application/json',
    JSON.stringify({
      dataType: 'material',
      data: m,
    })
  );
};

export function MaterialView(props: MaterialViewProps) {
  const render = () => {
    switch (props.size) {
      case 'compact':
        return (
          <MaterialCompactView
            material={props.material}
            editable={props.editable}
            draggable={props.draggable}
            selected={props.selected}
            onClick={props.onClick}
          />
        );
      case 'full':
        switch (props.material.type) {
          case 'template':
            return (
              <TemplateView
                template={props.material as Template}
                editable={props.editable}
                draggable={props.draggable}
                onDragStart={onMaterialDragStart}
                onRemove={props.onRemove}
              />
            );
          case 'layout':
            return (
              <LayoutView
                layout={props.material as Layout}
                editable={props.editable}
                draggable={props.draggable}
                onDragStart={onMaterialDragStart}
                onRemove={props.onRemove}
              />
            );
          case 'sticker':
            return (
              <StickerView
                sticker={props.material as Sticker}
                editable={props.editable}
                draggable={props.draggable}
                onDragStart={onMaterialDragStart}
                onRemove={props.onRemove}
              />
            );
          case 'font':
            return (
              <FontView
                font={props.material as Font}
                editable={props.editable}
                draggable={props.draggable}
                onDragStart={onMaterialDragStart}
                onRemove={props.onRemove}
              />
            );
          case 'background':
            return (
              <BackgroundView
                background={props.material as Background}
                editable={props.editable}
                draggable={props.draggable}
                onDragStart={onMaterialDragStart}
                onRemove={props.onRemove}
              />
            );
          case 'placeholder':
            return (
              <PlaceholderView
                placeholder={props.material as Placeholder}
                editable={props.editable}
                draggable={props.draggable}
                onDragStart={onMaterialDragStart}
                onRemove={props.onRemove}
              />
            );
          case 'color':
            return (
              <ColorView
                color={props.material as MaterialColor}
                editable={props.editable}
                draggable={props.draggable}
                onDragStart={onMaterialDragStart}
                onRemove={props.onRemove}
              />
            );
          case 'combo':
            return (
              <ComboView
                combo={props.material as Combo}
                editable={props.editable}
                draggable={props.draggable}
                onDragStart={onMaterialDragStart}
                onRemove={props.onRemove}
              />
            );
          case 'text':
            return (
              <TextView
                text={props.material as MaterialText}
                editable={props.editable}
                draggable={props.draggable}
                onDragStart={onMaterialDragStart}
                onRemove={props.onRemove}
              />
            );
          case 'shape':
            return (
              <ShapeView
                shape={props.material as Shape}
                editable={props.editable}
                draggable={props.draggable}
                onDragStart={onMaterialDragStart}
                onRemove={props.onRemove}
              />
            );
          case MaterialTypes.Gradient:
            return (
              <GradientView
                gradient={props.material as Gradient}
                editable={props.editable}
                draggable={props.draggable}
                onDragStart={onMaterialDragStart}
                onRemove={props.onRemove}
              />
            );
          default:
            return <React.Fragment />;
        }
      default:
        return <React.Fragment />;
    }
  };

  return render();
}

MaterialView.defaultProps = {
  size: 'full',
  editable: false,
  draggable: false,
  selected: false,
};
