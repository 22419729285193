import React, { useRef } from 'react';
import { SubTitle } from 'components/PageTitles';
import { BundlesVerticalList } from './components/BundlesVerticalList';
import { useFetchBundles } from 'hooks/useFetchBundles';

function BundleList() {
  const lastItemRef = useRef(null);
  const [bundles, setBundles, hasMore] = useFetchBundles({
    lastItemRef: lastItemRef,
  });

  return (
    <React.Fragment>
      <SubTitle>Manage Bundles</SubTitle>
      <BundlesVerticalList
        list={bundles}
        onRemove={(id: string) =>
          setBundles((prev) => prev.filter((b) => b.id !== id))
        }
        lastItemRef={lastItemRef}
        hasMore={hasMore}
        itemEditable
      />
    </React.Fragment>
  );
}

const exports = BundleList;
export default exports;
