import {
  Button,
  Checkbox,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  Select,
  Tabs,
  TabsProps,
} from 'antd';
import React, { useState, useRef } from 'react';
import { useForm } from 'antd/lib/form/Form';
import { CheckOutlined, WarningOutlined } from '@ant-design/icons';
import { Pack } from 'types/pack';
import {
  ContainerPacks,
  MaterialType,
  MaterialTypes,
  ThumbnailAcceptTypes,
  ThumbnailFileSuffix,
} from 'types/material';
import { Union } from 'utils/utils';
import { TagEntries } from 'components/TagEntries';
import { SubTitle } from 'components/PageTitles';
import { PreviewByIds } from './components/PreviewByIds';
import { MaterialList } from 'components/MaterialViews/MaterialList';
import componentStyle from 'styles/common.module.css';
import viewStyle from 'styles/view.module.css';
import { useFetchMaterials } from 'hooks/useFetchMaterials';
import { Grapeseed } from 'api/grapeseed';
import { Material } from 'types/interfaces';
import { ThumbnailView } from 'components/Thumbnail/ThumbnailView';
import { FileUploader, acceptTypesInfo } from 'components/FileUploader';
import { DictElement, PreviewByDict } from './components/PreviewByDict';
import useNotification from 'antd/es/notification/useNotification';
import { ThumbnailSizes } from 'components/Thumbnail/Constants';
import { SearchMaterialView } from 'components/SearchViews/SearchMaterialView';

interface EditPackProps {
  id: string;
  pack?: Pack;
  materials?: Material[];
  materialDict?: DictElement[];
  onSubmit?: (pack: Pack) => void;
}

function EditPack(props: EditPackProps) {
  const id = props.id;
  const pack = props.pack;
  const materials = props.materials;
  const materialDict = props.materialDict;

  const [form] = useForm();
  const [configForm] = useForm();
  const [noti, ctxHolder] = useNotification();
  const [method, setMethod] = useState<'bytags' | 'byids' | 'bydict'>(
    pack
      ? pack.byTags
        ? 'bytags'
        : pack.byMaterialIds
        ? 'byids'
        : 'bydict'
      : 'bytags'
  );

  const [materialType, setMaterialType] = useState<MaterialType | undefined>(
    pack?.materialType
  );
  const [tags, setTags] = useState<string[]>(pack?.byTags ?? []);
  const byTagsLastRef = useRef(null);
  const [byTagsList, , hasMoreByTags] = useFetchMaterials({
    tags: tags?.join(','),
    type: materialType ? [materialType] : [],
    lastItemRef: byTagsLastRef,
  });
  const [byIdsList, setByIdsList] = useState<Material[]>(
    method === 'byids' && materials ? materials : []
  );
  const [byDictElems, setByDictElems] = useState<DictElement[]>(
    method === 'bydict' && materialDict ? materialDict : []
  );
  const [selectedMaterials, setSelectedMaterials] = useState<Material[]>([]);
  const [submitting, setSubmitting] = useState<boolean>(false);

  // const onDragOver = (e: DragEvent<HTMLDivElement>) => {
  //   e.preventDefault();
  // };

  // const onDrop = (e: DragEvent<HTMLDivElement>) => {
  //   const transfer = e.dataTransfer.getData('application/json');
  //   const obj = JSON.parse(transfer);
  //   if (obj.dataType === 'material') {
  //     const target = NewMaterial(obj.data);
  //     if (target) {
  //       if (materialType === undefined || target.type === materialType) {
  //         setByIdsList((prev) => Union(prev, [target]) as Material[]);
  //       } else {
  //         notification.info({
  //           placement: 'top',
  //           message: `Material type does not match`,
  //           duration: 2,
  //         });
  //       }
  //     }
  //   }
  // };

  const reorderIdsList = (src: number, dest: number) => {
    const old = byIdsList;
    const movingItem = old.splice(src, 1)[0];
    setByIdsList([...old.slice(0, dest), movingItem, ...old.slice(dest)]);
  };

  const onSelected = (m: Material) => {
    if (selectedMaterials.find((s) => s.id === m.id)) {
      setSelectedMaterials((prev) => prev.filter((s) => s.id !== m.id));
    } else {
      setSelectedMaterials((prev) => {
        const cur = prev.slice();
        cur.push(m);
        return cur;
      });
    }
  };

  const addMaterials = () => {
    const showNotification = (count: number) => {
      if (count > 0) {
        noti.info({
          message: `Filtered out ${count} items`,
          description: `Material Type is set to ${materialType}, un-matching items were filtered out.`,
          placement: 'top',
          duration: 2,
        });
      }
    };
    switch (method) {
      case 'byids':
        setByIdsList((prev) => {
          const cur = Union(prev, selectedMaterials) as Material[];
          if (materialType) {
            const filtered = cur.filter((m) => m.type === materialType);
            showNotification(cur.length - filtered.length);
            return filtered;
          }
          return cur;
        });
        break;
      case 'bydict':
        setByDictElems((prev) => {
          const cur = Union(
            prev,
            selectedMaterials.map((m) => {
              return {
                key: m.id,
                id: m.id,
                material: m,
              };
            })
          ) as DictElement[];
          if (materialType) {
            const filtered = cur.filter(
              (e) => e.material?.type === materialType
            );
            showNotification(cur.length - filtered.length);
            return filtered;
          }
          return cur;
        });
        break;
    }
    setSelectedMaterials([]);
  };

  const onSubmit = async () => {
    let values: any;
    try {
      values = await form.validateFields();
    } catch (e) {
      return;
    }
    const body: Pack = new Pack({
      id: id,
      type: values.type,
      title: values.title,
      thumbnailUrl: values.thumbnail
        ? values.thumbnail[0].url
        : pack?.thumbnailUrl,
      description: values.description,
      priority: parseInt(values.priority) ?? 0,
      isFree: values.isFree ?? false,
      materialType: materialType,
    });

    switch (method) {
      case 'byids':
        const ids = byIdsList.map((m) => {
          return m.id;
        });
        body.byMaterialIds = ids;
        break;
      case 'bytags':
        body.byTags = tags;
        break;
      case 'bydict':
        body.byDictionary = new Map(
          byDictElems.map((elem) => [elem.key, elem.id])
        );
        const spacing = configForm.getFieldValue('spacing');
        const oblique = configForm.getFieldValue('oblique');
        if (spacing !== 0 || oblique !== 0) {
          body.config = {
            spacing: spacing !== 0 ? spacing : undefined,
            oblique: oblique !== 0 ? oblique : undefined,
          };
        }

        break;
      default:
        console.log('method not defined');
        return false;
    }

    setSubmitting(true);
    const queryParams = pack?.md5Sum
      ? new Map<string, string>([['baseMD5', pack.md5Sum]])
      : undefined;
    const result = await Grapeseed.PUT(`/api/packs`, {
      queryParams: queryParams,
      body: body,
    });
    console.log(body);
    setSubmitting(false);
    if (result) {
      props.onSubmit?.(result);
    }
  };

  const tabItems: TabsProps['items'] = [
    {
      key: 'bytags',
      label: 'By Tags',
      children: (
        <div style={{ display: 'flex' }}>
          <Form
            form={form}
            style={{ width: '80%' }}
            labelCol={{ span: 3 }}
            labelAlign="left"
            colon={false}
          >
            <Form.Item
              label="Tags"
              name="tags"
              extra={
                <span className={componentStyle.hinttextOrange}>
                  * Press enter to add tag.
                </span>
              }
              initialValue={pack?.byTags}
            >
              <TagEntries layout={'horizontal'} onChange={setTags} />
            </Form.Item>
          </Form>
        </div>
      ),
    },
    {
      key: 'byids',
      label: 'By Material IDs',
      children: (
        <SearchMaterialView
          selections={{
            materials: selectedMaterials,
            onItemSelect: onSelected,
          }}
        />
      ),
    },
    {
      key: 'bydict',
      label: 'By Dictionary',
      children: (
        <SearchMaterialView
          selections={{
            materials: selectedMaterials,
            onItemSelect: onSelected,
          }}
        />
      ),
    },
  ];

  return (
    <React.Fragment>
      {ctxHolder}
      <SubTitle>{pack ? 'Edit a pack' : 'Add a pack'}</SubTitle>
      {/* Pack edit form */}
      <Form
        form={form}
        labelCol={{ span: 3 }}
        labelAlign="left"
        wrapperCol={{ span: 8 }}
        colon={false}
        style={{ margin: '1rem 0' }}
      >
        <Form.Item
          label="Title"
          name="title"
          hasFeedback={true}
          rules={[{ required: true, message: 'Title cannot be empty.' }]}
          extra={
            <span className={componentStyle.hinttextOrange}>
              * This is the title that will be shown to users, and can be
              changed later
            </span>
          }
          initialValue={props.pack?.title}
        >
          <Input type="text" placeholder="Title" />
        </Form.Item>
        <Form.Item
          label="Type"
          name="type"
          hasFeedback={true}
          rules={[{ required: true, message: 'Type cannot be empty.' }]}
          initialValue={props.pack?.type}
        >
          <Input type="text" placeholder="Type" />
        </Form.Item>
        <Form.Item
          label="Material Type"
          name="materialType"
          wrapperCol={{ span: 5 }}
          initialValue={props.pack?.materialType}
        >
          <Select
            allowClear
            onChange={(value) => {
              switch (method) {
                case 'bytags':
                  setMaterialType(value as MaterialType);
                  break;
                case 'byids':
                  if (value && byIdsList.find((m) => m.type !== value)) {
                    Modal.confirm({
                      title: "Are you sure to change pack's material type?",
                      content: `current pack have some items whose type is not ${value} and they'll be removed from the list`,
                      icon: <WarningOutlined />,
                      okType: 'danger',
                      onOk() {
                        setByIdsList((prev) =>
                          prev.filter((m) => m.type === value)
                        );
                        setMaterialType(value as MaterialType);
                      },
                    });
                  } else {
                    setMaterialType(value as MaterialType);
                  }
                  break;
                case 'bydict':
                  if (
                    value &&
                    byDictElems.find(
                      (elem) => elem.material && elem.material.type !== value
                    )
                  ) {
                    Modal.confirm({
                      title: "Are you sure to change pack's material type?",
                      content: `current pack have some items whose type is not ${value} and they'll be removed from the list`,
                      icon: <WarningOutlined />,
                      okType: 'danger',
                      onOk() {
                        setByDictElems((prev) =>
                          prev.filter(
                            (elem) =>
                              elem.material && elem.material.type === value
                          )
                        );
                        setMaterialType(value as MaterialType);
                      },
                    });
                  } else {
                    setMaterialType(value as MaterialType);
                  }
                  break;
              }
            }}
          >
            {Object.values(MaterialTypes).map((v) => (
              <Select.Option key={`${v}`} value={v}>
                {v}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Thumbnail"
          name="thumbnail"
          extra={
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              {props.pack?.thumbnailUrl && (
                <ThumbnailView
                  size={ThumbnailSizes.full}
                  src={props.pack.thumbnailUrl}
                  preview={false}
                />
              )}
              {acceptTypesInfo(ThumbnailAcceptTypes)}
            </div>
          }
        >
          <FileUploader
            accept={ThumbnailAcceptTypes}
            container={ContainerPacks}
            filename={props.id}
            suffix={ThumbnailFileSuffix}
          />
        </Form.Item>
        <Form.Item
          label="Priority"
          name="priority"
          extra={
            <span className={componentStyle.hinttextOrange}>
              * Number, 0 is the default, the higher it is, the more exposed at
              the top. If they are same, the recently registered tag is
              displayed at the top.
            </span>
          }
          initialValue={props.pack?.priority}
        >
          <Input type="number" placeholder="0" />
        </Form.Item>
        <Form.Item
          label="Description"
          name="description"
          wrapperCol={{ span: 12 }}
          initialValue={props.pack?.description}
        >
          <Input.TextArea rows={4} />
        </Form.Item>
        <Form.Item
          label="Free"
          name="isFree"
          valuePropName="checked"
          wrapperCol={{ span: 5 }}
          initialValue={props.pack?.isFree ?? false}
        >
          <Checkbox />
        </Form.Item>
      </Form>
      {/* Pack edit form end */}

      {/* Preview */}
      <div style={{ marginBottom: '1rem' }}>
        {method === 'bytags' && (
          <MaterialList
            className={`${viewStyle.viewbox} ${viewStyle.wrap} ${viewStyle.maxHeight600}`}
            list={byTagsList}
            lastItemRef={byTagsLastRef}
            hasMore={hasMoreByTags}
            itemSize="compact"
          />
        )}
        {method === 'byids' && (
          <PreviewByIds
            materials={byIdsList}
            onRemove={(id) => {
              setByIdsList((prev) => prev.filter((m) => m.id !== id));
            }}
            onItemMove={reorderIdsList}
          />
        )}
        {method === 'bydict' && (
          <>
            <PreviewByDict
              dict={byDictElems}
              onRemove={(id) => {
                setByDictElems((prev) => prev.filter((elem) => elem.id !== id));
              }}
              onItemMove={reorderIdsList}
              onChangeKey={(index, newKey) => {
                setByDictElems((prev) => {
                  const dict = prev.slice();
                  dict[index].key = newKey;
                  return dict;
                });
              }}
            />
            <Divider orientation="left">Config</Divider>
            <Form
              form={configForm}
              labelAlign="left"
              colon={false}
              style={{ margin: '1rem 0' }}
              layout="inline"
            >
              <Form.Item
                label="Spacing"
                name="spacing"
                initialValue={pack?.config?.spacing ?? 0}
              >
                <InputNumber />
              </Form.Item>
              <Form.Item
                label="Oblique"
                name="oblique"
                initialValue={pack?.config?.oblique ?? 0}
              >
                <InputNumber />
              </Form.Item>
            </Form>
          </>
        )}
      </div>
      {/* Preview end */}
      <Button
        className={componentStyle.buttonOrange}
        type="primary"
        icon={<CheckOutlined />}
        loading={submitting}
        onClick={onSubmit}
      >
        Submit
      </Button>

      <Divider orientation="center">
        <Button type="primary" onClick={addMaterials}>
          ⬆
        </Button>
      </Divider>

      <Tabs
        defaultActiveKey="bytags"
        items={tabItems}
        onChange={(key: any) => {
          setSelectedMaterials([]);
          setMethod(key);
        }}
        activeKey={method}
      />
    </React.Fragment>
  );
}

const exports = EditPack;
export default exports;
