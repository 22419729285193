import {
  Button,
  Col,
  Descriptions,
  Form,
  Input,
  message,
  Popconfirm,
  Row,
  Table,
  Tag,
} from 'antd';
import { Grapeseed } from 'api/grapeseed';
import { PageTitle, SubTitle } from 'components/PageTitles';
import { TagEntries } from 'components/TagEntries';
import React, { useEffect, useState } from 'react';

interface ACLUser {
  email: string;
  updatedAt: string;
  roles: string[];
}

interface EditableCellProps {
  editing: boolean;
  dataIndex: string;
  title: any;
  inputType: 'tag' | 'text';
  record: ACLUser;
  index: number;
  children: React.ReactNode;
}

function EditableCell(
  props: EditableCellProps,
  rest?: React.HTMLAttributes<HTMLElement>
) {
  const inputNode =
    props.inputType === 'tag' ? <TagEntries layout="horizontal" /> : <Input />;

  return (
    <td {...rest}>
      {props.editing ? (
        <Form.Item
          name={props.dataIndex}
          style={{ margin: 0 }}
          rules={[
            {
              required: true,
              message: `Please Input ${props.title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        props.children
      )}
    </td>
  );
}

function ACL() {
  const [form] = Form.useForm();
  const [data, setData] = useState<ACLUser[]>([]);
  const [editingKey, setEditingKey] = useState('');
  const [newRowAdded, setNewRowAdded] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const result = await Grapeseed.GET(`/api/admins`);
    setData(result);
  };

  const addUser = async (record: ACLUser) => {
    await Grapeseed.POST(`/api/admins`, {
      body: {
        email: record.email,
        roles: record.roles,
      },
    });
    getData();
  };

  const updateUser = async (record: ACLUser) => {
    await Grapeseed.PUT(`/api/admins/${record.email}`, {
      body: {
        email: record.email,
        roles: record.roles,
      },
    });
    getData();
  };

  const deleteUser = async (record: ACLUser) => {
    await Grapeseed.DELETE(`/api/admins/${record.email}`);
    getData();
  };

  const handleAdd = () => {
    const newData = [...data];

    const row: ACLUser = {
      email: '',
      updatedAt: '',
      roles: [],
    };
    const index = newData.findIndex((item) => '' === item.email);
    if (index === -1) {
      newData.push(row);
      setData(newData);
    }
    setNewRowAdded(true);
    edit(row);
  };

  const save = async (email: React.Key) => {
    try {
      const row = (await form.validateFields()) as ACLUser;
      if (email !== row.email && !newRowAdded) {
        message.error(
          'Cannot edit Email. Delete and add new email if you need to change it'
        );
      }
      newRowAdded ? addUser(row) : updateUser(row);
      setNewRowAdded(false);
      setEditingKey('');
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };

  const isEditing = (record: ACLUser) => record.email === editingKey;

  const edit = (record: Partial<ACLUser> & { email: React.Key }) => {
    form.setFieldsValue({ roles: '', ...record });
    setEditingKey(record.email);
  };

  const cancel = () => {
    setEditingKey('');
    setNewRowAdded(false);
    getData();
  };

  const deleteRow = async (email: React.Key) => {
    const index = data.findIndex((item) => email === item.email);
    deleteUser(data[index]);
    setNewRowAdded(false);
    setEditingKey('');
  };

  const columns = [
    {
      title: 'Email',
      dataIndex: 'email',
      width: '20%',
      editable: true,
    },
    {
      title: 'Roles',
      dataIndex: 'roles',
      width: '40%',
      editable: true,
      render: (_: any, record: ACLUser) => {
        return (
          <>
            {record.roles.map((role) => {
              let color = role === 'admin' ? 'red' : 'default';
              return (
                <Tag color={color} key={role}>
                  {role}
                </Tag>
              );
            })}
          </>
        );
      },
    },
    {
      title: 'Updated At',
      dataIndex: 'updatedAt',
      width: '25%',
      editable: false,
    },
    {
      title: 'Action',
      dataIndex: 'action',
      render: (_: any, record: ACLUser) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Button onClick={() => save(record.email)} type="link" size="small">
              Save
            </Button>
            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
              <Button danger type="link" size="small">
                Cancel
              </Button>
            </Popconfirm>
          </span>
        ) : (
          <span>
            <Button
              disabled={editingKey !== '' || newRowAdded}
              onClick={() => edit(record)}
              type="link"
              size="small"
            >
              Edit
            </Button>
            <Popconfirm
              disabled={editingKey !== '' || newRowAdded}
              title="Sure to delete?"
              onConfirm={() => deleteRow(record.email)}
            >
              <Button
                danger
                disabled={editingKey !== '' || newRowAdded}
                type="link"
                size="small"
              >
                Delete
              </Button>
            </Popconfirm>
          </span>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: ACLUser) => ({
        record,
        inputType: col.dataIndex === 'roles' ? 'tag' : 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  return (
    <React.Fragment>
      <PageTitle>ACL (Access Control List)</PageTitle>
      <SubTitle>Users</SubTitle>
      <br />
      <Descriptions
        extra={
          <Button
            onClick={handleAdd}
            type="primary"
            style={{ marginBottom: 16 }}
          >
            Add a user
          </Button>
        }
      />
      <Form form={form} component={false}>
        <Table
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          bordered
          dataSource={data}
          columns={mergedColumns}
          rowClassName="editable-row"
          pagination={false}
        />
      </Form>
      <br />
      <hr style={{ borderTop: 'grey' }} />
      <SubTitle>Roles</SubTitle>
      <br />
      <Row gutter={[0, 16]} justify="start">
        <Col span={2}>
          <Tag>search</Tag>
        </Col>
        <Col span={10}>Access to Search Menu</Col>
        <Col span={2}>
          <Tag>filter</Tag>
        </Col>
        <Col span={10}>Access to Recommend Filters, Packs, Product Menu</Col>
        <Col span={2}>
          <Tag>curation</Tag>
        </Col>
        <Col span={10}>
          Access to Curation Menu. Select items for feed, pool
        </Col>
        <Col span={2}>
          <Tag>build</Tag>
        </Col>
        <Col span={10}>Access to Build Menu</Col>
        <Col span={2}>
          <Tag>shots</Tag>
        </Col>
        <Col span={10}>Access to Shots Menu</Col>
        <Col span={2}>
          <Tag>deleteuser</Tag>
        </Col>
        <Col span={10}>Allow to delete a user</Col>
        <Col span={2}>
          <Tag>feed</Tag>
        </Col>
        <Col span={10}>Read and Write Feed Menu</Col>
        <Col span={2}>
          <Tag color={'red'}>admin</Tag>
        </Col>
        <Col span={10}>All Access, Super power</Col>
        <Col span={2}>
          <Tag>studio</Tag>
        </Col>
        <Col span={10}>Studio</Col>
        <Col span={2}>
          <Tag>editor</Tag>
        </Col>
        <Col span={10}>Editor</Col>
        <Col span={2}>
          <Tag>cs</Tag>
        </Col>
        <Col span={10}>Customer Support</Col>
      </Row>
    </React.Fragment>
  );
}

const exports = ACL;
export default exports;
