import React, { useRef } from 'react';
import { MaterialTypes, Shape } from 'types/material';
import { SubTitle } from 'components/PageTitles';
import { MaterialList } from 'components/MaterialViews/MaterialList';
import { useFetchMaterials } from 'hooks/useFetchMaterials';
import { NewShapeForm } from './components/NewShapeForm';

function New() {
  const lastItemRef = useRef(null);
  const [list, setList, hasMore] = useFetchMaterials({
    type: [MaterialTypes.Shape],
    lastItemRef: lastItemRef,
  });

  return (
    <React.Fragment>
      <SubTitle>Add a shape</SubTitle>
      <NewShapeForm onAdd={(s: Shape) => setList([s, ...list])} />
      <hr style={{ borderTop: 'grey' }} />
      <MaterialList
        list={list}
        itemEditable
        onRemove={(id: string) => setList(list.filter((s) => s.id !== id))}
        lastItemRef={lastItemRef}
        hasMore={hasMore}
      />
    </React.Fragment>
  );
}

const exports = New;
export default exports;
