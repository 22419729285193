import { LinkOutlined } from '@ant-design/icons';
import { Popover, Typography } from 'antd';
import { EllipsisConfig } from 'antd/es/typography/Base';
import { CSSProperties, ReactElement } from 'react';

export interface IdPopoverProps {
  id: string;
  content: ReactElement;
  ellipsis?: boolean | EllipsisConfig;
  showHref?: { path: 'materials' | 'packs' | 'creators' | 'users' };
  style?: CSSProperties | undefined;
}

export function IdPopover(props: IdPopoverProps) {
  if (!props.id) {
    return <></>;
  }
  return (
    <div style={{ display: 'inline-flex', alignItems: 'center' }}>
      <Popover content={props.content} trigger="click">
        <Typography.Paragraph
          code
          copyable
          ellipsis={props.ellipsis}
          style={{ marginBottom: 0, ...props.style }}
        >
          {props.id}
        </Typography.Paragraph>
      </Popover>
      {props.showHref && (
        <a
          href={`/${props.showHref.path}/${props.id}`}
          target="_blank"
          rel="noreferrer"
          style={{ marginLeft: '0.5rem' }}
        >
          <LinkOutlined />
        </a>
      )}
    </div>
  );
}
