import { Button, Checkbox, Form, Input, Switch } from 'antd';
import { FileUploader, acceptTypesInfo } from 'components/FileUploader';
import { TagEntries } from 'components/TagEntries';
import React, { useState } from 'react';
import componentStyle from 'styles/common.module.css';
import { CheckOutlined } from '@ant-design/icons';
import { useForm } from 'antd/lib/form/Form';
import {
  ContainerFonts,
  Font,
  FontFileAcceptTypes,
  FontThumbnailAcceptTypes,
  ThumbnailFileSuffix,
} from 'types/material';
import { Grapeseed } from 'api/grapeseed';
import { ulid } from 'ulid';

interface NewFontFormProps {
  onAdd: (s: Font) => void;
}

const FontTypes = {
  NamedFont: 'named font',
  CustomFont: 'custom font',
} as const;
type FontType = (typeof FontTypes)[keyof typeof FontTypes];

function NewFontForm(props: NewFontFormProps) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [form] = useForm();
  const [id, setId] = useState<string>();
  const [fontType, setFontType] = useState<FontType>(FontTypes.CustomFont);

  const onSubmit = async (values: any) => {
    let fid = id;
    let v = undefined;
    if (fontType === FontTypes.CustomFont && !fid) {
      console.log('no id set!');
      return;
    }
    if (fontType === FontTypes.NamedFont) {
      fid = ulid();
      v = 2;
    }
    setIsLoading(true);
    const body: Font = new Font({
      id: fid!,
      v: v,
      tags: values.tags ?? [],
      createdAt: new Date(),
      isFree: values.isFree ?? false,
      title: values.title,
      fontName: fontType === FontTypes.NamedFont ? values.fontName : undefined,
      originUrl:
        fontType === FontTypes.CustomFont ? values.origin[0].url : undefined,
      thumbnailUrl:
        fontType === FontTypes.CustomFont ? values.thumbnail[0].url : undefined,
      bytes:
        fontType === FontTypes.CustomFont ? values.origin[0].size : undefined,
    });
    const result = await Grapeseed.POST(`/api/fonts`, { body: body });
    if (result) {
      props.onAdd(new Font(result));
      setId(undefined);
      form.resetFields();
    }
    setIsLoading(false);
  };

  const toggleFontType = (checked: boolean) => {
    if (checked) {
      setFontType(FontTypes.CustomFont);
    } else {
      setFontType(FontTypes.NamedFont);
    }
    setId(undefined);
  };

  return (
    <React.Fragment>
      <div>
        <Switch
          defaultChecked
          checkedChildren="Custom Font"
          unCheckedChildren="Named Font"
          onChange={toggleFontType}
        />
      </div>
      <Form
        form={form}
        onFinish={onSubmit}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
          }
        }}
        labelCol={{ span: 3 }}
        labelAlign="left"
        colon={false}
        style={{ margin: '1rem 0' }}
      >
        {fontType === FontTypes.CustomFont && (
          <React.Fragment>
            <Form.Item
              label="Font"
              name="origin"
              extra={acceptTypesInfo(FontFileAcceptTypes)}
              rules={[{ required: true, message: 'Font file is required' }]}
            >
              <FileUploader
                container={ContainerFonts}
                accept={FontFileAcceptTypes}
                onAdd={(_, id) => setId(id)}
                onRemove={(_) => setId(undefined)}
              />
            </Form.Item>
            {id && (
              <Form.Item
                label="Thumbnail"
                name="thumbnail"
                extra={acceptTypesInfo(FontThumbnailAcceptTypes)}
                rules={[{ required: true }]}
              >
                <FileUploader
                  container={ContainerFonts}
                  accept={FontThumbnailAcceptTypes}
                  filename={id}
                  suffix={ThumbnailFileSuffix}
                />
              </Form.Item>
            )}
          </React.Fragment>
        )}
        {fontType === FontTypes.NamedFont && (
          <Form.Item
            label="Font Name"
            name="fontName"
            wrapperCol={{ span: 5 }}
            hasFeedback={true}
            rules={[{ required: true, message: 'Font name is required' }]}
          >
            <Input type="text" placeholder="Font name" />
          </Form.Item>
        )}

        <Form.Item
          label="Title"
          name="title"
          wrapperCol={{ span: 5 }}
          hasFeedback={true}
          rules={[{ required: true, message: 'Title cannot be empty.' }]}
        >
          <Input type="text" placeholder="Font title" />
        </Form.Item>
        <Form.Item
          name="tags"
          label="Tags"
          extra={
            <span className={componentStyle.hinttextOrange}>
              * Press enter to add tag.
            </span>
          }
        >
          <TagEntries layout="horizontal" />
        </Form.Item>
        <Form.Item
          label="Free"
          name="isFree"
          valuePropName="checked"
          wrapperCol={{ span: 5 }}
        >
          <Checkbox />
        </Form.Item>
        <Form.Item>
          <Button
            className={componentStyle.buttonOrange}
            type="primary"
            icon={<CheckOutlined />}
            htmlType="submit"
            loading={isLoading}
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    </React.Fragment>
  );
}

const exports = NewFontForm;
export default exports;
