import { Button, Popover, Typography } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { AuthContext } from './AuthProvider';
import { SignInWithAppleButton } from './SingInWithAppleButton';
import { UserView } from 'pages/Users/components/UserView';
import { Grapeseed } from 'api/grapeseed';
import { User } from 'types/user';
import { RoleTag } from 'pages/Users/components/RoleTag';

const { Text } = Typography;

export function AccountView(props: {
  showRoles?: boolean;
  showButton: boolean;
}) {
  const { authState, login, logout } = useContext(AuthContext);
  const [user, setUser] = useState<User>();

  useEffect(() => {
    async function getUser(id: string | undefined) {
      if (!id) {
        setUser(undefined);
        return;
      }
      const result = await Grapeseed.GET(`/api/users/${id}`);
      if (result) {
        setUser(result);
      }
    }
    getUser(authState?.id);
  }, [authState?.id]);

  if (authState?.id && authState?.roles) {
    return (
      <div>
        {user && (
          <>
            <Popover
              trigger="click"
              content={<UserView user={user} size={'full'} />}
            >
              <Text type="secondary" style={{ marginRight: '1rem' }}>
                {authState.id}
              </Text>
            </Popover>
            {props.showRoles &&
              authState.roles.map((r: string) => <RoleTag role={r} />)}
          </>
        )}
        <Button
          type="default"
          onClick={logout}
          style={props.showRoles ? { marginLeft: '2rem' } : {}}
        >
          Logout
        </Button>
      </div>
    );
  } else {
    return props.showButton ? (
      <>
        <SignInWithAppleButton callback={login} />
      </>
    ) : null;
  }
}
