import { Divider, Input } from 'antd';
import { SearchProps } from 'antd/es/input';
import { Grapeseed } from 'api/grapeseed';
import { PageTitle, SubTitle } from 'components/PageTitles';
import { useState } from 'react';

export function Search() {
  const [order, setOrder] = useState<string>();
  const [transaction, setTransaction] = useState<string>();

  const onSearchOrderId: SearchProps['onSearch'] = async (value) => {
    const resp = await Grapeseed.GET(`/api/iap/retrica/apple/orders/${value}`);
    if (resp) {
      setOrder(resp);
    }
  };

  const onSearchTransactionId: SearchProps['onSearch'] = async (value) => {
    const resp = await Grapeseed.GET(
      `/api/iap/retrica/apple/transactions/${value}`
    );
    if (resp) {
      setTransaction(resp);
    }
  };

  const appleTransactionSerializer = (k: string, v: any) => {
    if (k.endsWith('Date')) {
      return `${v} [${new Date(v).toISOString()}]`;
    }
    return v;
  };

  return (
    <>
      <PageTitle>In App Purchase</PageTitle>
      <SubTitle>Search</SubTitle>
      <Divider orientation="left">Order Info</Divider>
      <Input.Search
        placeholder="Order ID"
        onSearch={onSearchOrderId}
        enterButton
      />
      {order && (
        <div>
          <pre>{JSON.stringify(order, appleTransactionSerializer, 2)}</pre>
        </div>
      )}
      <Divider orientation="left">Transaction Info</Divider>
      <Input.Search
        placeholder="Transaction ID"
        onSearch={onSearchTransactionId}
        enterButton
      />
      {transaction && (
        <div>
          <pre>
            {JSON.stringify(transaction, appleTransactionSerializer, 2)}
          </pre>
        </div>
      )}
    </>
  );
}
