import { Button, Form, Input } from 'antd';
import { TagEntries } from 'components/TagEntries';
import { useState } from 'react';
import componentStyle from 'styles/common.module.css';
import { CheckOutlined } from '@ant-design/icons';
import { MaterialText } from 'types/material';
import { useForm } from 'antd/lib/form/Form';
import { Grapeseed } from 'api/grapeseed';
import { ulid } from 'ulid';

interface NewTextFormProps {
  onAdd: (t: MaterialText) => void;
}

export function NewTextForm(props: NewTextFormProps) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [form] = useForm();

  const onSubmit = async (values: any) => {
    setIsLoading(true);
    const body: MaterialText = new MaterialText({
      tags: values.tags ?? [],
      id: ulid(),
      text: values.text.trim(),
    });
    const result = await Grapeseed.POST('/api/texts', { body: body });
    setIsLoading(false);

    if (result) {
      props.onAdd(new MaterialText(result));
      form.resetFields();
    }
  };

  return (
    <Form
      form={form}
      onFinish={onSubmit}
      labelCol={{ span: 3 }}
      labelAlign="left"
      colon={false}
      style={{ margin: '1rem 0' }}
    >
      <Form.Item
        label="Text"
        name="text"
        wrapperCol={{ span: 17 }}
        required
        extra={
          <span className={componentStyle.hinttextOrange}>
            * Whitespaces at both sides of the text will be removed
          </span>
        }
      >
        <Input.TextArea rows={6} />
      </Form.Item>
      <Form.Item
        name="tags"
        label="Tags"
        extra={
          <span className={componentStyle.hinttextOrange}>
            * Press enter to add tag.
          </span>
        }
      >
        <TagEntries layout="horizontal" />
      </Form.Item>
      <Form.Item>
        <Button
          className={componentStyle.buttonOrange}
          type="primary"
          icon={<CheckOutlined />}
          htmlType="submit"
          loading={isLoading}
        >
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
}
