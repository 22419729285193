import React, { useRef } from 'react';
import { SubTitle } from 'components/PageTitles';
import { MaterialList } from 'components/MaterialViews/MaterialList';
import { useFetchMaterials } from 'hooks/useFetchMaterials';
import { MaterialTypes } from 'types/material';

export function List() {
  const lastItemRef = useRef(null);
  const [list, setList, hasMore] = useFetchMaterials({
    type: [MaterialTypes.Combo],
    lastItemRef: lastItemRef,
  });

  return (
    <React.Fragment>
      <SubTitle>Combos</SubTitle>
      <hr style={{ borderTop: 'grey' }} />
      <MaterialList
        list={list}
        itemEditable
        onRemove={(id: string) => {
          setList(list.filter((t) => t.id !== id));
        }}
        lastItemRef={lastItemRef}
        hasMore={hasMore}
      />
    </React.Fragment>
  );
}
