// @generated by protoc-gen-es v1.10.0 with parameter "target=ts,import_extension=.ts"
// @generated from file retriver-deprecated.proto (package retrica, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64 } from "@bufbuild/protobuf";
import { ChannelContentType, ErrorCode, HashTagType, ReportType, SettingType, UnreadType } from "./retriver-enum_pb.ts";
import { Account, Channel, ChannelContent, ChannelContentV2, CloudContent, Content, Friend, FriendsWrap, Notification, ProfileWrap, SelfieNetwork_Activity, SelfieNetwork_Decorator, SelfieNetwork_HashTag, SelfieNetwork_HashTagWrap, SelfieNetwork_Selfie, SelfieNetwork_SelfiePage, SelfieNetwork_SelfieWrap, SelfieNetwork_SquadContainer_Crew, SelfieNetwork_SquadContainer_Squad, Unread } from "./retriver-struct_pb.ts";

/**
 * /a1/updateProfile
 * 프로필 사진이나 full name을 업데이트 할 때 쓴다.
 *
 * @generated from message retrica.UpdateProfileRequest
 */
export class UpdateProfileRequest extends Message<UpdateProfileRequest> {
  /**
   * @generated from field: string DEPRECATED_source = 2;
   */
  DEPRECATEDSource = "";

  /**
   * @generated from field: bytes profile = 1;
   */
  profile = new Uint8Array(0);

  /**
   * @generated from field: string fullname = 3;
   */
  fullname = "";

  /**
   * @generated from field: string username = 4;
   */
  username = "";

  constructor(data?: PartialMessage<UpdateProfileRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.UpdateProfileRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 2, name: "DEPRECATED_source", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 1, name: "profile", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
    { no: 3, name: "fullname", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "username", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateProfileRequest {
    return new UpdateProfileRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateProfileRequest {
    return new UpdateProfileRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateProfileRequest {
    return new UpdateProfileRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateProfileRequest | PlainMessage<UpdateProfileRequest> | undefined, b: UpdateProfileRequest | PlainMessage<UpdateProfileRequest> | undefined): boolean {
    return proto3.util.equals(UpdateProfileRequest, a, b);
  }
}

/**
 * @generated from message retrica.UpdateProfileResponse
 */
export class UpdateProfileResponse extends Message<UpdateProfileResponse> {
  /**
   * SUCCESS
   *
   * @generated from field: retrica.ErrorCode error_code = 1;
   */
  errorCode = ErrorCode.UNKNOWN;

  /**
   * @generated from field: string profile_url = 2;
   */
  profileUrl = "";

  /**
   * @generated from field: string fullname = 3;
   */
  fullname = "";

  /**
   * @generated from field: string username = 4;
   */
  username = "";

  constructor(data?: PartialMessage<UpdateProfileResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.UpdateProfileResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "error_code", kind: "enum", T: proto3.getEnumType(ErrorCode) },
    { no: 2, name: "profile_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "fullname", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "username", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateProfileResponse {
    return new UpdateProfileResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateProfileResponse {
    return new UpdateProfileResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateProfileResponse {
    return new UpdateProfileResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateProfileResponse | PlainMessage<UpdateProfileResponse> | undefined, b: UpdateProfileResponse | PlainMessage<UpdateProfileResponse> | undefined): boolean {
    return proto3.util.equals(UpdateProfileResponse, a, b);
  }
}

/**
 * /a1/updateAccountSettings
 * 전체 push notification을 on/off한다.
 *
 * @generated from message retrica.UpdateAccountSettingsRequest
 */
export class UpdateAccountSettingsRequest extends Message<UpdateAccountSettingsRequest> {
  /**
   * @generated from field: retrica.SettingType push = 1;
   */
  push = SettingType.ST_NONE;

  /**
   * @generated from field: string push_token = 2;
   */
  pushToken = "";

  constructor(data?: PartialMessage<UpdateAccountSettingsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.UpdateAccountSettingsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "push", kind: "enum", T: proto3.getEnumType(SettingType) },
    { no: 2, name: "push_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateAccountSettingsRequest {
    return new UpdateAccountSettingsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateAccountSettingsRequest {
    return new UpdateAccountSettingsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateAccountSettingsRequest {
    return new UpdateAccountSettingsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateAccountSettingsRequest | PlainMessage<UpdateAccountSettingsRequest> | undefined, b: UpdateAccountSettingsRequest | PlainMessage<UpdateAccountSettingsRequest> | undefined): boolean {
    return proto3.util.equals(UpdateAccountSettingsRequest, a, b);
  }
}

/**
 * @generated from message retrica.UpdateAccountSettingsResponse
 */
export class UpdateAccountSettingsResponse extends Message<UpdateAccountSettingsResponse> {
  /**
   * SUCCESS
   *
   * @generated from field: retrica.ErrorCode error_code = 1;
   */
  errorCode = ErrorCode.UNKNOWN;

  /**
   * @generated from field: retrica.SettingType push = 2;
   */
  push = SettingType.ST_NONE;

  /**
   * @generated from field: string push_token = 3;
   */
  pushToken = "";

  constructor(data?: PartialMessage<UpdateAccountSettingsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.UpdateAccountSettingsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "error_code", kind: "enum", T: proto3.getEnumType(ErrorCode) },
    { no: 2, name: "push", kind: "enum", T: proto3.getEnumType(SettingType) },
    { no: 3, name: "push_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateAccountSettingsResponse {
    return new UpdateAccountSettingsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateAccountSettingsResponse {
    return new UpdateAccountSettingsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateAccountSettingsResponse {
    return new UpdateAccountSettingsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateAccountSettingsResponse | PlainMessage<UpdateAccountSettingsResponse> | undefined, b: UpdateAccountSettingsResponse | PlainMessage<UpdateAccountSettingsResponse> | undefined): boolean {
    return proto3.util.equals(UpdateAccountSettingsResponse, a, b);
  }
}

/**
 * /a1/changeUsername
 * Username을 변경할 수 있다.
 *
 * @generated from message retrica.ChangeUsernameRequest
 */
export class ChangeUsernameRequest extends Message<ChangeUsernameRequest> {
  /**
   * 변경하려는 username
   *
   * @generated from field: string username = 1;
   */
  username = "";

  constructor(data?: PartialMessage<ChangeUsernameRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.ChangeUsernameRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "username", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ChangeUsernameRequest {
    return new ChangeUsernameRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ChangeUsernameRequest {
    return new ChangeUsernameRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ChangeUsernameRequest {
    return new ChangeUsernameRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ChangeUsernameRequest | PlainMessage<ChangeUsernameRequest> | undefined, b: ChangeUsernameRequest | PlainMessage<ChangeUsernameRequest> | undefined): boolean {
    return proto3.util.equals(ChangeUsernameRequest, a, b);
  }
}

/**
 * @generated from message retrica.ChangeUsernameResponse
 */
export class ChangeUsernameResponse extends Message<ChangeUsernameResponse> {
  /**
   * INVALID_USERNAME: 잘못된 username 일 때.
   * USERNAME_ALREADY_TAKEN: 이미 존재하는 username 일 때.
   *
   * @generated from field: retrica.ErrorCode error_code = 1;
   */
  errorCode = ErrorCode.UNKNOWN;

  /**
   * @generated from field: string username = 2;
   */
  username = "";

  constructor(data?: PartialMessage<ChangeUsernameResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.ChangeUsernameResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "error_code", kind: "enum", T: proto3.getEnumType(ErrorCode) },
    { no: 2, name: "username", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ChangeUsernameResponse {
    return new ChangeUsernameResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ChangeUsernameResponse {
    return new ChangeUsernameResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ChangeUsernameResponse {
    return new ChangeUsernameResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ChangeUsernameResponse | PlainMessage<ChangeUsernameResponse> | undefined, b: ChangeUsernameResponse | PlainMessage<ChangeUsernameResponse> | undefined): boolean {
    return proto3.util.equals(ChangeUsernameResponse, a, b);
  }
}

/**
 * /1/verifySms
 *
 * @generated from message retrica.VerifySmsRequest
 */
export class VerifySmsRequest extends Message<VerifySmsRequest> {
  /**
   * @generated from field: string phone_number = 1;
   */
  phoneNumber = "";

  /**
   * @generated from field: string country_code = 2;
   */
  countryCode = "";

  /**
   * @generated from field: string pass_code = 3;
   */
  passCode = "";

  constructor(data?: PartialMessage<VerifySmsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.VerifySmsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "phone_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "country_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "pass_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): VerifySmsRequest {
    return new VerifySmsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): VerifySmsRequest {
    return new VerifySmsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): VerifySmsRequest {
    return new VerifySmsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: VerifySmsRequest | PlainMessage<VerifySmsRequest> | undefined, b: VerifySmsRequest | PlainMessage<VerifySmsRequest> | undefined): boolean {
    return proto3.util.equals(VerifySmsRequest, a, b);
  }
}

/**
 * @generated from message retrica.VerifySmsResponse
 */
export class VerifySmsResponse extends Message<VerifySmsResponse> {
  /**
   * @generated from field: retrica.ErrorCode error_code = 1;
   */
  errorCode = ErrorCode.UNKNOWN;

  /**
   * @generated from field: string auth_id = 2;
   */
  authId = "";

  constructor(data?: PartialMessage<VerifySmsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.VerifySmsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "error_code", kind: "enum", T: proto3.getEnumType(ErrorCode) },
    { no: 2, name: "auth_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): VerifySmsResponse {
    return new VerifySmsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): VerifySmsResponse {
    return new VerifySmsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): VerifySmsResponse {
    return new VerifySmsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: VerifySmsResponse | PlainMessage<VerifySmsResponse> | undefined, b: VerifySmsResponse | PlainMessage<VerifySmsResponse> | undefined): boolean {
    return proto3.util.equals(VerifySmsResponse, a, b);
  }
}

/**
 * /1/checkEmail
 *
 * @generated from message retrica.CheckEmailRequest
 */
export class CheckEmailRequest extends Message<CheckEmailRequest> {
  /**
   * @generated from field: string email = 1;
   */
  email = "";

  constructor(data?: PartialMessage<CheckEmailRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.CheckEmailRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CheckEmailRequest {
    return new CheckEmailRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CheckEmailRequest {
    return new CheckEmailRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CheckEmailRequest {
    return new CheckEmailRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CheckEmailRequest | PlainMessage<CheckEmailRequest> | undefined, b: CheckEmailRequest | PlainMessage<CheckEmailRequest> | undefined): boolean {
    return proto3.util.equals(CheckEmailRequest, a, b);
  }
}

/**
 * @generated from message retrica.CheckEmailResponse
 */
export class CheckEmailResponse extends Message<CheckEmailResponse> {
  /**
   * @generated from field: retrica.ErrorCode error_code = 1;
   */
  errorCode = ErrorCode.UNKNOWN;

  /**
   * @generated from field: bool exist = 2;
   */
  exist = false;

  constructor(data?: PartialMessage<CheckEmailResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.CheckEmailResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "error_code", kind: "enum", T: proto3.getEnumType(ErrorCode) },
    { no: 2, name: "exist", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CheckEmailResponse {
    return new CheckEmailResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CheckEmailResponse {
    return new CheckEmailResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CheckEmailResponse {
    return new CheckEmailResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CheckEmailResponse | PlainMessage<CheckEmailResponse> | undefined, b: CheckEmailResponse | PlainMessage<CheckEmailResponse> | undefined): boolean {
    return proto3.util.equals(CheckEmailResponse, a, b);
  }
}

/**
 * /1/checkUsername
 *
 * @generated from message retrica.CheckUsernameRequest
 */
export class CheckUsernameRequest extends Message<CheckUsernameRequest> {
  /**
   * @generated from field: string username = 1;
   */
  username = "";

  constructor(data?: PartialMessage<CheckUsernameRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.CheckUsernameRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "username", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CheckUsernameRequest {
    return new CheckUsernameRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CheckUsernameRequest {
    return new CheckUsernameRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CheckUsernameRequest {
    return new CheckUsernameRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CheckUsernameRequest | PlainMessage<CheckUsernameRequest> | undefined, b: CheckUsernameRequest | PlainMessage<CheckUsernameRequest> | undefined): boolean {
    return proto3.util.equals(CheckUsernameRequest, a, b);
  }
}

/**
 * @generated from message retrica.CheckUsernameResponse
 */
export class CheckUsernameResponse extends Message<CheckUsernameResponse> {
  /**
   * @generated from field: retrica.ErrorCode error_code = 1;
   */
  errorCode = ErrorCode.UNKNOWN;

  /**
   * @generated from field: bool exist = 2;
   */
  exist = false;

  constructor(data?: PartialMessage<CheckUsernameResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.CheckUsernameResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "error_code", kind: "enum", T: proto3.getEnumType(ErrorCode) },
    { no: 2, name: "exist", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CheckUsernameResponse {
    return new CheckUsernameResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CheckUsernameResponse {
    return new CheckUsernameResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CheckUsernameResponse {
    return new CheckUsernameResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CheckUsernameResponse | PlainMessage<CheckUsernameResponse> | undefined, b: CheckUsernameResponse | PlainMessage<CheckUsernameResponse> | undefined): boolean {
    return proto3.util.equals(CheckUsernameResponse, a, b);
  }
}

/**
 * /1/recommendUsername
 *
 * @generated from message retrica.RecommendUsernameRequest
 */
export class RecommendUsernameRequest extends Message<RecommendUsernameRequest> {
  /**
   * @generated from field: string email = 1;
   */
  email = "";

  /**
   * @generated from field: string facebook_access_token = 2;
   */
  facebookAccessToken = "";

  constructor(data?: PartialMessage<RecommendUsernameRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.RecommendUsernameRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "facebook_access_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RecommendUsernameRequest {
    return new RecommendUsernameRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RecommendUsernameRequest {
    return new RecommendUsernameRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RecommendUsernameRequest {
    return new RecommendUsernameRequest().fromJsonString(jsonString, options);
  }

  static equals(a: RecommendUsernameRequest | PlainMessage<RecommendUsernameRequest> | undefined, b: RecommendUsernameRequest | PlainMessage<RecommendUsernameRequest> | undefined): boolean {
    return proto3.util.equals(RecommendUsernameRequest, a, b);
  }
}

/**
 * @generated from message retrica.RecommendUsernameResponse
 */
export class RecommendUsernameResponse extends Message<RecommendUsernameResponse> {
  /**
   * @generated from field: retrica.ErrorCode error_code = 1;
   */
  errorCode = ErrorCode.UNKNOWN;

  /**
   * @generated from field: repeated string usernames = 2;
   */
  usernames: string[] = [];

  constructor(data?: PartialMessage<RecommendUsernameResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.RecommendUsernameResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "error_code", kind: "enum", T: proto3.getEnumType(ErrorCode) },
    { no: 2, name: "usernames", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RecommendUsernameResponse {
    return new RecommendUsernameResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RecommendUsernameResponse {
    return new RecommendUsernameResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RecommendUsernameResponse {
    return new RecommendUsernameResponse().fromJsonString(jsonString, options);
  }

  static equals(a: RecommendUsernameResponse | PlainMessage<RecommendUsernameResponse> | undefined, b: RecommendUsernameResponse | PlainMessage<RecommendUsernameResponse> | undefined): boolean {
    return proto3.util.equals(RecommendUsernameResponse, a, b);
  }
}

/**
 * DEPRECATED: use /a1/sendChannelContent instead
 * /a1/uploadChannelContent
 * Photo/Video를 전송한다.
 * 각 Content별 제한
 * Photo: Max width or height: 1080px, Quality: 50%
 * Video: Max duration: 10초, Max width or height: 720px
 *
 * @generated from message retrica.UploadChannelContentRequest
 */
export class UploadChannelContentRequest extends Message<UploadChannelContentRequest> {
  /**
   * channel_id 와 friend_id중 하나만 assign하면 된다.
   *
   * @generated from field: string channel_id = 1;
   */
  channelId = "";

  /**
   * channel_id가 없으면 friend_id를 가지고 channel_id를 찾거나 생성한다.
   *
   * @generated from field: string friend_id = 2;
   */
  friendId = "";

  /**
   * image or video
   *
   * @generated from field: retrica.ChannelContentType type = 3;
   */
  type = ChannelContentType.CCT_NONE;

  /**
   * Photo/Video data
   *
   * @generated from field: bytes origin = 4;
   */
  origin = new Uint8Array(0);

  /**
   * Video or Photo Thumbnail을 보낸다.
   *
   * @generated from field: bytes thumb = 5;
   */
  thumb = new Uint8Array(0);

  /**
   * 원본 가로 사이즈
   *
   * @generated from field: int32 width = 6;
   */
  width = 0;

  /**
   * 원본 세로 사이즈
   *
   * @generated from field: int32 height = 7;
   */
  height = 0;

  /**
   * @generated from field: string DEPRECATED_thru = 8;
   */
  DEPRECATEDThru = "";

  /**
   * @generated from field: string DEPRECATED_source = 9;
   */
  DEPRECATEDSource = "";

  /**
   * @generated from field: string DEPRECATED_toss_content_type = 10;
   */
  DEPRECATEDTossContentType = "";

  constructor(data?: PartialMessage<UploadChannelContentRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.UploadChannelContentRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "channel_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "friend_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "type", kind: "enum", T: proto3.getEnumType(ChannelContentType) },
    { no: 4, name: "origin", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
    { no: 5, name: "thumb", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
    { no: 6, name: "width", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 7, name: "height", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 8, name: "DEPRECATED_thru", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "DEPRECATED_source", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "DEPRECATED_toss_content_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UploadChannelContentRequest {
    return new UploadChannelContentRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UploadChannelContentRequest {
    return new UploadChannelContentRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UploadChannelContentRequest {
    return new UploadChannelContentRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UploadChannelContentRequest | PlainMessage<UploadChannelContentRequest> | undefined, b: UploadChannelContentRequest | PlainMessage<UploadChannelContentRequest> | undefined): boolean {
    return proto3.util.equals(UploadChannelContentRequest, a, b);
  }
}

/**
 * @generated from message retrica.UploadChannelContentResponse
 */
export class UploadChannelContentResponse extends Message<UploadChannelContentResponse> {
  /**
   * SUCCESS
   * PERMISSION_DENIED: 차단친구/채널에 보낼 경우.
   *
   * @generated from field: retrica.ErrorCode error_code = 1;
   */
  errorCode = ErrorCode.UNKNOWN;

  /**
   * @generated from field: retrica.Channel channel = 2;
   */
  channel?: Channel;

  /**
   * @generated from field: retrica.ChannelContent channel_content = 3;
   */
  channelContent?: ChannelContent;

  constructor(data?: PartialMessage<UploadChannelContentResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.UploadChannelContentResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "error_code", kind: "enum", T: proto3.getEnumType(ErrorCode) },
    { no: 2, name: "channel", kind: "message", T: Channel },
    { no: 3, name: "channel_content", kind: "message", T: ChannelContent },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UploadChannelContentResponse {
    return new UploadChannelContentResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UploadChannelContentResponse {
    return new UploadChannelContentResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UploadChannelContentResponse {
    return new UploadChannelContentResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UploadChannelContentResponse | PlainMessage<UploadChannelContentResponse> | undefined, b: UploadChannelContentResponse | PlainMessage<UploadChannelContentResponse> | undefined): boolean {
    return proto3.util.equals(UploadChannelContentResponse, a, b);
  }
}

/**
 * DEPRECATED. use /a1/channelContents instead.
 * /a1/channelContent
 * ContentView에서 1개의 Content만 가져올 때 사용.
 *
 * @generated from message retrica.ChannelContentRequest
 */
export class ChannelContentRequest extends Message<ChannelContentRequest> {
  /**
   * @generated from field: string content_id = 1;
   */
  contentId = "";

  constructor(data?: PartialMessage<ChannelContentRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.ChannelContentRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "content_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ChannelContentRequest {
    return new ChannelContentRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ChannelContentRequest {
    return new ChannelContentRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ChannelContentRequest {
    return new ChannelContentRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ChannelContentRequest | PlainMessage<ChannelContentRequest> | undefined, b: ChannelContentRequest | PlainMessage<ChannelContentRequest> | undefined): boolean {
    return proto3.util.equals(ChannelContentRequest, a, b);
  }
}

/**
 * @generated from message retrica.ChannelContentResponse
 */
export class ChannelContentResponse extends Message<ChannelContentResponse> {
  /**
   * SUCCESS
   * PERMISSION_DENIED: 차단친구/채널에 보낼 경우.
   *
   * @generated from field: retrica.ErrorCode error_code = 1;
   */
  errorCode = ErrorCode.UNKNOWN;

  /**
   * @generated from field: retrica.ChannelContent channel_content = 2;
   */
  channelContent?: ChannelContent;

  constructor(data?: PartialMessage<ChannelContentResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.ChannelContentResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "error_code", kind: "enum", T: proto3.getEnumType(ErrorCode) },
    { no: 2, name: "channel_content", kind: "message", T: ChannelContent },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ChannelContentResponse {
    return new ChannelContentResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ChannelContentResponse {
    return new ChannelContentResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ChannelContentResponse {
    return new ChannelContentResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ChannelContentResponse | PlainMessage<ChannelContentResponse> | undefined, b: ChannelContentResponse | PlainMessage<ChannelContentResponse> | undefined): boolean {
    return proto3.util.equals(ChannelContentResponse, a, b);
  }
}

/**
 * /a1/channelContentsV2
 * channel에서 content_id를 시작으로 count만큼 content를 가져온다.
 *
 * @generated from message retrica.ChannelContentsV2Request
 */
export class ChannelContentsV2Request extends Message<ChannelContentsV2Request> {
  /**
   * @generated from field: string channel_id = 1;
   */
  channelId = "";

  /**
   * start channel id
   *
   * @generated from field: string content_id = 2;
   */
  contentId = "";

  /**
   * channel_id부터 가져올content의 count
   *
   * @generated from field: int64 count = 3;
   */
  count = protoInt64.zero;

  /**
   * ChannelContentV2 response에 comment가필요하면 true
   *
   * @generated from field: bool need_comment = 4;
   */
  needComment = false;

  constructor(data?: PartialMessage<ChannelContentsV2Request>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.ChannelContentsV2Request";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "channel_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "content_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "need_comment", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ChannelContentsV2Request {
    return new ChannelContentsV2Request().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ChannelContentsV2Request {
    return new ChannelContentsV2Request().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ChannelContentsV2Request {
    return new ChannelContentsV2Request().fromJsonString(jsonString, options);
  }

  static equals(a: ChannelContentsV2Request | PlainMessage<ChannelContentsV2Request> | undefined, b: ChannelContentsV2Request | PlainMessage<ChannelContentsV2Request> | undefined): boolean {
    return proto3.util.equals(ChannelContentsV2Request, a, b);
  }
}

/**
 * @generated from message retrica.ChannelContentsV2Response
 */
export class ChannelContentsV2Response extends Message<ChannelContentsV2Response> {
  /**
   * SUCCESS
   *
   * @generated from field: retrica.ErrorCode error_code = 1;
   */
  errorCode = ErrorCode.UNKNOWN;

  /**
   * @generated from field: retrica.Channel channel = 2;
   */
  channel?: Channel;

  /**
   * 주의: ChannelContentV2에 channel 값은 nil이다.
   *
   * @generated from field: repeated retrica.ChannelContentV2 contents = 3;
   */
  contents: ChannelContentV2[] = [];

  constructor(data?: PartialMessage<ChannelContentsV2Response>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.ChannelContentsV2Response";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "error_code", kind: "enum", T: proto3.getEnumType(ErrorCode) },
    { no: 2, name: "channel", kind: "message", T: Channel },
    { no: 3, name: "contents", kind: "message", T: ChannelContentV2, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ChannelContentsV2Response {
    return new ChannelContentsV2Response().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ChannelContentsV2Response {
    return new ChannelContentsV2Response().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ChannelContentsV2Response {
    return new ChannelContentsV2Response().fromJsonString(jsonString, options);
  }

  static equals(a: ChannelContentsV2Response | PlainMessage<ChannelContentsV2Response> | undefined, b: ChannelContentsV2Response | PlainMessage<ChannelContentsV2Response> | undefined): boolean {
    return proto3.util.equals(ChannelContentsV2Response, a, b);
  }
}

/**
 * /a1/notifications
 * 알림(notifications) 리스트를 반환한다.
 * Server revision과 client revision이 같으면 빈리스트가 반환된다.
 * 최대 리스트는 20개.
 *
 * @generated from message retrica.NotificationsRequest
 */
export class NotificationsRequest extends Message<NotificationsRequest> {
  /**
   * @generated from field: int64 notifications_revision = 1;
   */
  notificationsRevision = protoInt64.zero;

  /**
   * notificationId 올린경우는 notificationId 기준으로 내려줌
   *
   * @generated from field: string notification_id = 2;
   */
  notificationId = "";

  constructor(data?: PartialMessage<NotificationsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.NotificationsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "notifications_revision", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "notification_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): NotificationsRequest {
    return new NotificationsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): NotificationsRequest {
    return new NotificationsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): NotificationsRequest {
    return new NotificationsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: NotificationsRequest | PlainMessage<NotificationsRequest> | undefined, b: NotificationsRequest | PlainMessage<NotificationsRequest> | undefined): boolean {
    return proto3.util.equals(NotificationsRequest, a, b);
  }
}

/**
 * @generated from message retrica.NotificationsResponse
 */
export class NotificationsResponse extends Message<NotificationsResponse> {
  /**
   * SUCCESS, PERMISSION_DENIED
   *
   * @generated from field: retrica.ErrorCode error_code = 1;
   */
  errorCode = ErrorCode.UNKNOWN;

  /**
   * @generated from field: int64 notifications_revision = 2;
   */
  notificationsRevision = protoInt64.zero;

  /**
   * @generated from field: repeated retrica.Notification notifications = 3;
   */
  notifications: Notification[] = [];

  constructor(data?: PartialMessage<NotificationsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.NotificationsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "error_code", kind: "enum", T: proto3.getEnumType(ErrorCode) },
    { no: 2, name: "notifications_revision", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "notifications", kind: "message", T: Notification, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): NotificationsResponse {
    return new NotificationsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): NotificationsResponse {
    return new NotificationsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): NotificationsResponse {
    return new NotificationsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: NotificationsResponse | PlainMessage<NotificationsResponse> | undefined, b: NotificationsResponse | PlainMessage<NotificationsResponse> | undefined): boolean {
    return proto3.util.equals(NotificationsResponse, a, b);
  }
}

/**
 * /a1/notificationsSeen
 * Notification의 읽음 처리.
 *
 * @generated from message retrica.NotificationsSeenRequest
 */
export class NotificationsSeenRequest extends Message<NotificationsSeenRequest> {
  /**
   * @generated from field: int64 notifications_revision = 1;
   */
  notificationsRevision = protoInt64.zero;

  constructor(data?: PartialMessage<NotificationsSeenRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.NotificationsSeenRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "notifications_revision", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): NotificationsSeenRequest {
    return new NotificationsSeenRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): NotificationsSeenRequest {
    return new NotificationsSeenRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): NotificationsSeenRequest {
    return new NotificationsSeenRequest().fromJsonString(jsonString, options);
  }

  static equals(a: NotificationsSeenRequest | PlainMessage<NotificationsSeenRequest> | undefined, b: NotificationsSeenRequest | PlainMessage<NotificationsSeenRequest> | undefined): boolean {
    return proto3.util.equals(NotificationsSeenRequest, a, b);
  }
}

/**
 * @generated from message retrica.NotificationsSeenResponse
 */
export class NotificationsSeenResponse extends Message<NotificationsSeenResponse> {
  /**
   * SUCCESS
   *
   * @generated from field: retrica.ErrorCode error_code = 1;
   */
  errorCode = ErrorCode.UNKNOWN;

  constructor(data?: PartialMessage<NotificationsSeenResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.NotificationsSeenResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "error_code", kind: "enum", T: proto3.getEnumType(ErrorCode) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): NotificationsSeenResponse {
    return new NotificationsSeenResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): NotificationsSeenResponse {
    return new NotificationsSeenResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): NotificationsSeenResponse {
    return new NotificationsSeenResponse().fromJsonString(jsonString, options);
  }

  static equals(a: NotificationsSeenResponse | PlainMessage<NotificationsSeenResponse> | undefined, b: NotificationsSeenResponse | PlainMessage<NotificationsSeenResponse> | undefined): boolean {
    return proto3.util.equals(NotificationsSeenResponse, a, b);
  }
}

/**
 * /a1/sendContentComment
 * Content에 comment을 보낸다.
 *
 * @generated from message retrica.SendContentCommentRequest
 */
export class SendContentCommentRequest extends Message<SendContentCommentRequest> {
  /**
   * @generated from field: string content_id = 1;
   */
  contentId = "";

  /**
   * 200자 이상 보내면 앞에 200자만 write된다.
   *
   * @generated from field: string msg = 2;
   */
  msg = "";

  constructor(data?: PartialMessage<SendContentCommentRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.SendContentCommentRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "content_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "msg", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SendContentCommentRequest {
    return new SendContentCommentRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SendContentCommentRequest {
    return new SendContentCommentRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SendContentCommentRequest {
    return new SendContentCommentRequest().fromJsonString(jsonString, options);
  }

  static equals(a: SendContentCommentRequest | PlainMessage<SendContentCommentRequest> | undefined, b: SendContentCommentRequest | PlainMessage<SendContentCommentRequest> | undefined): boolean {
    return proto3.util.equals(SendContentCommentRequest, a, b);
  }
}

/**
 * @generated from message retrica.SendContentCommentResponse
 */
export class SendContentCommentResponse extends Message<SendContentCommentResponse> {
  /**
   * SUCCESS
   * PERMISSION_DENIED: 컨텐츠에 write권한이 없을 때.(차단등)
   *
   * @generated from field: retrica.ErrorCode error_code = 1;
   */
  errorCode = ErrorCode.UNKNOWN;

  /**
   * @generated from field: retrica.ChannelContentV2 content = 2;
   */
  content?: ChannelContentV2;

  constructor(data?: PartialMessage<SendContentCommentResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.SendContentCommentResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "error_code", kind: "enum", T: proto3.getEnumType(ErrorCode) },
    { no: 2, name: "content", kind: "message", T: ChannelContentV2 },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SendContentCommentResponse {
    return new SendContentCommentResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SendContentCommentResponse {
    return new SendContentCommentResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SendContentCommentResponse {
    return new SendContentCommentResponse().fromJsonString(jsonString, options);
  }

  static equals(a: SendContentCommentResponse | PlainMessage<SendContentCommentResponse> | undefined, b: SendContentCommentResponse | PlainMessage<SendContentCommentResponse> | undefined): boolean {
    return proto3.util.equals(SendContentCommentResponse, a, b);
  }
}

/**
 * /a1/channelFriendList
 * channel 정보와 친구 정보를 함께 내려준다.
 *
 * @generated from message retrica.ChannelFriendListRequest
 */
export class ChannelFriendListRequest extends Message<ChannelFriendListRequest> {
  /**
   * @generated from field: int64 channels_revision = 1;
   */
  channelsRevision = protoInt64.zero;

  /**
   * @generated from field: int64 friend_revision = 2;
   */
  friendRevision = protoInt64.zero;

  /**
   * @generated from field: int64 added_me_revision = 3;
   */
  addedMeRevision = protoInt64.zero;

  /**
   * @generated from field: int64 recommend_revision = 4;
   */
  recommendRevision = protoInt64.zero;

  constructor(data?: PartialMessage<ChannelFriendListRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.ChannelFriendListRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "channels_revision", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "friend_revision", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "added_me_revision", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "recommend_revision", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ChannelFriendListRequest {
    return new ChannelFriendListRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ChannelFriendListRequest {
    return new ChannelFriendListRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ChannelFriendListRequest {
    return new ChannelFriendListRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ChannelFriendListRequest | PlainMessage<ChannelFriendListRequest> | undefined, b: ChannelFriendListRequest | PlainMessage<ChannelFriendListRequest> | undefined): boolean {
    return proto3.util.equals(ChannelFriendListRequest, a, b);
  }
}

/**
 * @generated from message retrica.ChannelFriendListResponse
 */
export class ChannelFriendListResponse extends Message<ChannelFriendListResponse> {
  /**
   * SUCCESS
   *
   * @generated from field: retrica.ErrorCode error_code = 1;
   */
  errorCode = ErrorCode.UNKNOWN;

  /**
   * @generated from field: int64 channels_revision = 2;
   */
  channelsRevision = protoInt64.zero;

  /**
   * @generated from field: int64 friend_revision = 3;
   */
  friendRevision = protoInt64.zero;

  /**
   * @generated from field: int64 added_me_revision = 4;
   */
  addedMeRevision = protoInt64.zero;

  /**
   * @generated from field: int64 recommend_revision = 5;
   */
  recommendRevision = protoInt64.zero;

  /**
   * @generated from field: repeated retrica.Channel channels = 6;
   */
  channels: Channel[] = [];

  /**
   * @generated from field: repeated retrica.Friend friends = 7;
   */
  friends: Friend[] = [];

  /**
   * @generated from field: repeated retrica.Friend added_mes = 8;
   */
  addedMes: Friend[] = [];

  /**
   * @generated from field: repeated retrica.Friend recommends = 9;
   */
  recommends: Friend[] = [];

  /**
   * @generated from field: retrica.Unread added_me_unread = 10;
   */
  addedMeUnread?: Unread;

  constructor(data?: PartialMessage<ChannelFriendListResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.ChannelFriendListResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "error_code", kind: "enum", T: proto3.getEnumType(ErrorCode) },
    { no: 2, name: "channels_revision", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "friend_revision", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "added_me_revision", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 5, name: "recommend_revision", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 6, name: "channels", kind: "message", T: Channel, repeated: true },
    { no: 7, name: "friends", kind: "message", T: Friend, repeated: true },
    { no: 8, name: "added_mes", kind: "message", T: Friend, repeated: true },
    { no: 9, name: "recommends", kind: "message", T: Friend, repeated: true },
    { no: 10, name: "added_me_unread", kind: "message", T: Unread },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ChannelFriendListResponse {
    return new ChannelFriendListResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ChannelFriendListResponse {
    return new ChannelFriendListResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ChannelFriendListResponse {
    return new ChannelFriendListResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ChannelFriendListResponse | PlainMessage<ChannelFriendListResponse> | undefined, b: ChannelFriendListResponse | PlainMessage<ChannelFriendListResponse> | undefined): boolean {
    return proto3.util.equals(ChannelFriendListResponse, a, b);
  }
}

/**
 * /a1/uploadProfiles
 * 프로필 이미지 또는 동영상 등록할때 사용한다.
 *
 * @generated from message retrica.UploadProfilesRequest
 */
export class UploadProfilesRequest extends Message<UploadProfilesRequest> {
  /**
   * @generated from field: repeated retrica.Content contents = 1;
   */
  contents: Content[] = [];

  constructor(data?: PartialMessage<UploadProfilesRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.UploadProfilesRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "contents", kind: "message", T: Content, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UploadProfilesRequest {
    return new UploadProfilesRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UploadProfilesRequest {
    return new UploadProfilesRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UploadProfilesRequest {
    return new UploadProfilesRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UploadProfilesRequest | PlainMessage<UploadProfilesRequest> | undefined, b: UploadProfilesRequest | PlainMessage<UploadProfilesRequest> | undefined): boolean {
    return proto3.util.equals(UploadProfilesRequest, a, b);
  }
}

/**
 * @generated from message retrica.UploadProfilesResponse
 */
export class UploadProfilesResponse extends Message<UploadProfilesResponse> {
  /**
   * @generated from field: retrica.ErrorCode error_code = 1;
   */
  errorCode = ErrorCode.UNKNOWN;

  /**
   * @generated from field: retrica.Account account = 2;
   */
  account?: Account;

  constructor(data?: PartialMessage<UploadProfilesResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.UploadProfilesResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "error_code", kind: "enum", T: proto3.getEnumType(ErrorCode) },
    { no: 2, name: "account", kind: "message", T: Account },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UploadProfilesResponse {
    return new UploadProfilesResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UploadProfilesResponse {
    return new UploadProfilesResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UploadProfilesResponse {
    return new UploadProfilesResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UploadProfilesResponse | PlainMessage<UploadProfilesResponse> | undefined, b: UploadProfilesResponse | PlainMessage<UploadProfilesResponse> | undefined): boolean {
    return proto3.util.equals(UploadProfilesResponse, a, b);
  }
}

/**
 * /a1/deleteProfiles
 * 프로필 이미지 또는 동영상 삭제할때 사용한다.
 *
 * @generated from message retrica.DeleteProfilesRequest
 */
export class DeleteProfilesRequest extends Message<DeleteProfilesRequest> {
  /**
   * @generated from field: repeated string ids = 1;
   */
  ids: string[] = [];

  constructor(data?: PartialMessage<DeleteProfilesRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.DeleteProfilesRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeleteProfilesRequest {
    return new DeleteProfilesRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteProfilesRequest {
    return new DeleteProfilesRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeleteProfilesRequest {
    return new DeleteProfilesRequest().fromJsonString(jsonString, options);
  }

  static equals(a: DeleteProfilesRequest | PlainMessage<DeleteProfilesRequest> | undefined, b: DeleteProfilesRequest | PlainMessage<DeleteProfilesRequest> | undefined): boolean {
    return proto3.util.equals(DeleteProfilesRequest, a, b);
  }
}

/**
 * @generated from message retrica.DeleteProfilesResponse
 */
export class DeleteProfilesResponse extends Message<DeleteProfilesResponse> {
  /**
   * @generated from field: retrica.ErrorCode error_code = 1;
   */
  errorCode = ErrorCode.UNKNOWN;

  /**
   * @generated from field: retrica.Account account = 2;
   */
  account?: Account;

  constructor(data?: PartialMessage<DeleteProfilesResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.DeleteProfilesResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "error_code", kind: "enum", T: proto3.getEnumType(ErrorCode) },
    { no: 2, name: "account", kind: "message", T: Account },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeleteProfilesResponse {
    return new DeleteProfilesResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteProfilesResponse {
    return new DeleteProfilesResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeleteProfilesResponse {
    return new DeleteProfilesResponse().fromJsonString(jsonString, options);
  }

  static equals(a: DeleteProfilesResponse | PlainMessage<DeleteProfilesResponse> | undefined, b: DeleteProfilesResponse | PlainMessage<DeleteProfilesResponse> | undefined): boolean {
    return proto3.util.equals(DeleteProfilesResponse, a, b);
  }
}

/**
 * /a1/profiles
 * 해당 유저, 프로필 이미지 리스트 줄때 사용한다.
 *
 * @generated from message retrica.ProfilesRequest
 */
export class ProfilesRequest extends Message<ProfilesRequest> {
  /**
   * @generated from field: string user_id = 1;
   */
  userId = "";

  constructor(data?: PartialMessage<ProfilesRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.ProfilesRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ProfilesRequest {
    return new ProfilesRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ProfilesRequest {
    return new ProfilesRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ProfilesRequest {
    return new ProfilesRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ProfilesRequest | PlainMessage<ProfilesRequest> | undefined, b: ProfilesRequest | PlainMessage<ProfilesRequest> | undefined): boolean {
    return proto3.util.equals(ProfilesRequest, a, b);
  }
}

/**
 * @generated from message retrica.ProfilesResponse
 */
export class ProfilesResponse extends Message<ProfilesResponse> {
  /**
   * @generated from field: retrica.ErrorCode error_code = 1;
   */
  errorCode = ErrorCode.UNKNOWN;

  /**
   * @generated from field: retrica.ProfileWrap profile = 2;
   */
  profile?: ProfileWrap;

  constructor(data?: PartialMessage<ProfilesResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.ProfilesResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "error_code", kind: "enum", T: proto3.getEnumType(ErrorCode) },
    { no: 2, name: "profile", kind: "message", T: ProfileWrap },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ProfilesResponse {
    return new ProfilesResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ProfilesResponse {
    return new ProfilesResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ProfilesResponse {
    return new ProfilesResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ProfilesResponse | PlainMessage<ProfilesResponse> | undefined, b: ProfilesResponse | PlainMessage<ProfilesResponse> | undefined): boolean {
    return proto3.util.equals(ProfilesResponse, a, b);
  }
}

/**
 * /a1/revisions
 * revision 정보를 가져 올 때 사용.
 * App이 launch 되거나 커넥션이 끊어진 상태에서 forground로 올때 call.
 * 로그인이 된 상태일 때만 Call 한다.
 *
 * @generated from message retrica.RevisionsRequest
 */
export class RevisionsRequest extends Message<RevisionsRequest> {
  constructor(data?: PartialMessage<RevisionsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.RevisionsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RevisionsRequest {
    return new RevisionsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RevisionsRequest {
    return new RevisionsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RevisionsRequest {
    return new RevisionsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: RevisionsRequest | PlainMessage<RevisionsRequest> | undefined, b: RevisionsRequest | PlainMessage<RevisionsRequest> | undefined): boolean {
    return proto3.util.equals(RevisionsRequest, a, b);
  }
}

/**
 * @generated from message retrica.RevisionsResponse
 */
export class RevisionsResponse extends Message<RevisionsResponse> {
  /**
   * SUCCESS
   *
   * @generated from field: retrica.ErrorCode error_code = 1;
   */
  errorCode = ErrorCode.UNKNOWN;

  /**
   * @generated from field: int64 friend = 2;
   */
  friend = protoInt64.zero;

  /**
   * @generated from field: int64 block = 3;
   */
  block = protoInt64.zero;

  /**
   * @generated from field: int64 added_me = 4;
   */
  addedMe = protoInt64.zero;

  /**
   * @generated from field: int64 recommend = 5;
   */
  recommend = protoInt64.zero;

  /**
   * @generated from field: int64 channels = 6;
   */
  channels = protoInt64.zero;

  /**
   * @generated from field: int64 notifications = 7;
   */
  notifications = protoInt64.zero;

  constructor(data?: PartialMessage<RevisionsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.RevisionsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "error_code", kind: "enum", T: proto3.getEnumType(ErrorCode) },
    { no: 2, name: "friend", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "block", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "added_me", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 5, name: "recommend", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 6, name: "channels", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 7, name: "notifications", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RevisionsResponse {
    return new RevisionsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RevisionsResponse {
    return new RevisionsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RevisionsResponse {
    return new RevisionsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: RevisionsResponse | PlainMessage<RevisionsResponse> | undefined, b: RevisionsResponse | PlainMessage<RevisionsResponse> | undefined): boolean {
    return proto3.util.equals(RevisionsResponse, a, b);
  }
}

/**
 * /a1/addAllFriends
 * 전체 친구추가 (FB, VK, CONTACT)
 * 가입후 CLP창 뜨기 직전에 자동친구를 해주나, 추후에는 변경.
 *
 * @generated from message retrica.AddAllFriendsRequest
 */
export class AddAllFriendsRequest extends Message<AddAllFriendsRequest> {
  /**
   * @generated from field: string facebook_access_token = 1;
   */
  facebookAccessToken = "";

  /**
   * @generated from field: string vkontakte_access_token = 2;
   */
  vkontakteAccessToken = "";

  constructor(data?: PartialMessage<AddAllFriendsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.AddAllFriendsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "facebook_access_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "vkontakte_access_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AddAllFriendsRequest {
    return new AddAllFriendsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AddAllFriendsRequest {
    return new AddAllFriendsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AddAllFriendsRequest {
    return new AddAllFriendsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: AddAllFriendsRequest | PlainMessage<AddAllFriendsRequest> | undefined, b: AddAllFriendsRequest | PlainMessage<AddAllFriendsRequest> | undefined): boolean {
    return proto3.util.equals(AddAllFriendsRequest, a, b);
  }
}

/**
 * @generated from message retrica.AddAllFriendsResponse
 */
export class AddAllFriendsResponse extends Message<AddAllFriendsResponse> {
  /**
   * SUCCESS
   *
   * @generated from field: retrica.ErrorCode error_code = 1;
   */
  errorCode = ErrorCode.UNKNOWN;

  /**
   * @generated from field: int64 friend_revision = 2;
   */
  friendRevision = protoInt64.zero;

  /**
   * @generated from field: int64 recommend_revision = 3;
   */
  recommendRevision = protoInt64.zero;

  /**
   * @generated from field: int64 added_me_revision = 4;
   */
  addedMeRevision = protoInt64.zero;

  /**
   * 친구 리스트
   *
   * @generated from field: repeated retrica.Friend friends = 5;
   */
  friends: Friend[] = [];

  /**
   * 추천 친구 리스트
   *
   * @generated from field: repeated retrica.Friend recommends = 6;
   */
  recommends: Friend[] = [];

  /**
   * AddedMe 친구 리스트
   *
   * @generated from field: repeated retrica.Friend added_mes = 7;
   */
  addedMes: Friend[] = [];

  constructor(data?: PartialMessage<AddAllFriendsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.AddAllFriendsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "error_code", kind: "enum", T: proto3.getEnumType(ErrorCode) },
    { no: 2, name: "friend_revision", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "recommend_revision", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "added_me_revision", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 5, name: "friends", kind: "message", T: Friend, repeated: true },
    { no: 6, name: "recommends", kind: "message", T: Friend, repeated: true },
    { no: 7, name: "added_mes", kind: "message", T: Friend, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AddAllFriendsResponse {
    return new AddAllFriendsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AddAllFriendsResponse {
    return new AddAllFriendsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AddAllFriendsResponse {
    return new AddAllFriendsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: AddAllFriendsResponse | PlainMessage<AddAllFriendsResponse> | undefined, b: AddAllFriendsResponse | PlainMessage<AddAllFriendsResponse> | undefined): boolean {
    return proto3.util.equals(AddAllFriendsResponse, a, b);
  }
}

/**
 * /a1/allFriends
 * 전체 친구리스트 내려주는 API
 *
 * @generated from message retrica.AllFriendsRequest
 */
export class AllFriendsRequest extends Message<AllFriendsRequest> {
  /**
   * @generated from field: int64 friend_revision = 1;
   */
  friendRevision = protoInt64.zero;

  /**
   * @generated from field: int64 block_revision = 2;
   */
  blockRevision = protoInt64.zero;

  /**
   * @generated from field: int64 recommend_revision = 3;
   */
  recommendRevision = protoInt64.zero;

  /**
   * @generated from field: int64 added_me_revision = 4;
   */
  addedMeRevision = protoInt64.zero;

  constructor(data?: PartialMessage<AllFriendsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.AllFriendsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "friend_revision", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "block_revision", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "recommend_revision", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "added_me_revision", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AllFriendsRequest {
    return new AllFriendsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AllFriendsRequest {
    return new AllFriendsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AllFriendsRequest {
    return new AllFriendsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: AllFriendsRequest | PlainMessage<AllFriendsRequest> | undefined, b: AllFriendsRequest | PlainMessage<AllFriendsRequest> | undefined): boolean {
    return proto3.util.equals(AllFriendsRequest, a, b);
  }
}

/**
 * @generated from message retrica.AllFriendsResponse
 */
export class AllFriendsResponse extends Message<AllFriendsResponse> {
  /**
   * @generated from field: retrica.ErrorCode error_code = 1;
   */
  errorCode = ErrorCode.UNKNOWN;

  /**
   * @generated from field: retrica.FriendsWrap friends_wrap = 2;
   */
  friendsWrap?: FriendsWrap;

  /**
   * @generated from field: retrica.Unread added_me_unread = 3;
   */
  addedMeUnread?: Unread;

  constructor(data?: PartialMessage<AllFriendsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.AllFriendsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "error_code", kind: "enum", T: proto3.getEnumType(ErrorCode) },
    { no: 2, name: "friends_wrap", kind: "message", T: FriendsWrap },
    { no: 3, name: "added_me_unread", kind: "message", T: Unread },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AllFriendsResponse {
    return new AllFriendsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AllFriendsResponse {
    return new AllFriendsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AllFriendsResponse {
    return new AllFriendsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: AllFriendsResponse | PlainMessage<AllFriendsResponse> | undefined, b: AllFriendsResponse | PlainMessage<AllFriendsResponse> | undefined): boolean {
    return proto3.util.equals(AllFriendsResponse, a, b);
  }
}

/**
 * /a2/channelContents
 * 채널의 컨텐츠 리스트를 반환한다.
 *
 * @generated from message retrica.ChannelContentsRequest
 */
export class ChannelContentsRequest extends Message<ChannelContentsRequest> {
  /**
   * @generated from field: string channel_id = 1;
   */
  channelId = "";

  /**
   * @generated from field: string content_id = 2;
   */
  contentId = "";

  /**
   * @generated from field: int64 count = 3;
   */
  count = protoInt64.zero;

  /**
   * true면 comment가 response에 포함되고 false면 content만 간다.
   *
   * @generated from field: bool ask_comments = 4;
   */
  askComments = false;

  /**
   * @generated from field: string DEPRECATED_thru = 5;
   */
  DEPRECATEDThru = "";

  /**
   * @generated from field: string DEPRECATED_source = 6;
   */
  DEPRECATEDSource = "";

  constructor(data?: PartialMessage<ChannelContentsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.ChannelContentsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "channel_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "content_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "ask_comments", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 5, name: "DEPRECATED_thru", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "DEPRECATED_source", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ChannelContentsRequest {
    return new ChannelContentsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ChannelContentsRequest {
    return new ChannelContentsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ChannelContentsRequest {
    return new ChannelContentsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ChannelContentsRequest | PlainMessage<ChannelContentsRequest> | undefined, b: ChannelContentsRequest | PlainMessage<ChannelContentsRequest> | undefined): boolean {
    return proto3.util.equals(ChannelContentsRequest, a, b);
  }
}

/**
 * @generated from message retrica.ChannelContentsResponse
 */
export class ChannelContentsResponse extends Message<ChannelContentsResponse> {
  /**
   * SUCCESS
   *
   * @generated from field: retrica.ErrorCode error_code = 1;
   */
  errorCode = ErrorCode.UNKNOWN;

  /**
   * @generated from field: retrica.Channel channel = 2;
   */
  channel?: Channel;

  /**
   * DEPRECATED: use content_content_v2 instead.
   *
   * @generated from field: repeated retrica.ChannelContent channel_contents = 3;
   */
  channelContents: ChannelContent[] = [];

  /**
   * @generated from field: repeated retrica.ChannelContentV2 channel_contents_v2 = 4;
   */
  channelContentsV2: ChannelContentV2[] = [];

  constructor(data?: PartialMessage<ChannelContentsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.ChannelContentsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "error_code", kind: "enum", T: proto3.getEnumType(ErrorCode) },
    { no: 2, name: "channel", kind: "message", T: Channel },
    { no: 3, name: "channel_contents", kind: "message", T: ChannelContent, repeated: true },
    { no: 4, name: "channel_contents_v2", kind: "message", T: ChannelContentV2, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ChannelContentsResponse {
    return new ChannelContentsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ChannelContentsResponse {
    return new ChannelContentsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ChannelContentsResponse {
    return new ChannelContentsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ChannelContentsResponse | PlainMessage<ChannelContentsResponse> | undefined, b: ChannelContentsResponse | PlainMessage<ChannelContentsResponse> | undefined): boolean {
    return proto3.util.equals(ChannelContentsResponse, a, b);
  }
}

/**
 * /a2/writeContentComment
 * Content에 comment을 작성한다.
 *
 * @generated from message retrica.WriteContentCommentRequest
 */
export class WriteContentCommentRequest extends Message<WriteContentCommentRequest> {
  /**
   * @generated from field: string content_id = 1;
   */
  contentId = "";

  /**
   * 200자 이상 보내면 앞에 200자만 write된다.
   *
   * @generated from field: string msg = 2;
   */
  msg = "";

  constructor(data?: PartialMessage<WriteContentCommentRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.WriteContentCommentRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "content_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "msg", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): WriteContentCommentRequest {
    return new WriteContentCommentRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): WriteContentCommentRequest {
    return new WriteContentCommentRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): WriteContentCommentRequest {
    return new WriteContentCommentRequest().fromJsonString(jsonString, options);
  }

  static equals(a: WriteContentCommentRequest | PlainMessage<WriteContentCommentRequest> | undefined, b: WriteContentCommentRequest | PlainMessage<WriteContentCommentRequest> | undefined): boolean {
    return proto3.util.equals(WriteContentCommentRequest, a, b);
  }
}

/**
 * @generated from message retrica.WriteContentCommentResponse
 */
export class WriteContentCommentResponse extends Message<WriteContentCommentResponse> {
  /**
   * SUCCESS
   * PERMISSION_DENIED: 컨텐츠에 write권한이 없을 때.(차단등)
   *
   * @generated from field: retrica.ErrorCode error_code = 1;
   */
  errorCode = ErrorCode.UNKNOWN;

  /**
   * DEPRECATED: use content_content_v2 instead.
   *
   * @generated from field: retrica.ChannelContent channel_content = 2;
   */
  channelContent?: ChannelContent;

  /**
   * @generated from field: retrica.Channel channel = 3;
   */
  channel?: Channel;

  /**
   * @generated from field: retrica.ChannelContentV2 channel_content_v2 = 4;
   */
  channelContentV2?: ChannelContentV2;

  constructor(data?: PartialMessage<WriteContentCommentResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.WriteContentCommentResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "error_code", kind: "enum", T: proto3.getEnumType(ErrorCode) },
    { no: 2, name: "channel_content", kind: "message", T: ChannelContent },
    { no: 3, name: "channel", kind: "message", T: Channel },
    { no: 4, name: "channel_content_v2", kind: "message", T: ChannelContentV2 },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): WriteContentCommentResponse {
    return new WriteContentCommentResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): WriteContentCommentResponse {
    return new WriteContentCommentResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): WriteContentCommentResponse {
    return new WriteContentCommentResponse().fromJsonString(jsonString, options);
  }

  static equals(a: WriteContentCommentResponse | PlainMessage<WriteContentCommentResponse> | undefined, b: WriteContentCommentResponse | PlainMessage<WriteContentCommentResponse> | undefined): boolean {
    return proto3.util.equals(WriteContentCommentResponse, a, b);
  }
}

/**
 * /a1/sendChannelContent
 * Photo/Video를 전송한다.
 * 각 Content별 제한
 * Photo: Max width or height: 1080px, Quality: 50%
 * Video: Max duration: 10초, Max width or height: 720px
 *
 * @generated from message retrica.SendChannelContentRequest
 */
export class SendChannelContentRequest extends Message<SendChannelContentRequest> {
  /**
   * 전송할 channel ids
   *
   * @generated from field: repeated string channel_ids = 1;
   */
  channelIds: string[] = [];

  /**
   * 전송할 friend ids
   *
   * @generated from field: repeated string friend_ids = 2;
   */
  friendIds: string[] = [];

  /**
   * 전송할 Content
   *
   * @generated from field: retrica.Content content = 3;
   */
  content?: Content;

  constructor(data?: PartialMessage<SendChannelContentRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.SendChannelContentRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "channel_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 2, name: "friend_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 3, name: "content", kind: "message", T: Content },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SendChannelContentRequest {
    return new SendChannelContentRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SendChannelContentRequest {
    return new SendChannelContentRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SendChannelContentRequest {
    return new SendChannelContentRequest().fromJsonString(jsonString, options);
  }

  static equals(a: SendChannelContentRequest | PlainMessage<SendChannelContentRequest> | undefined, b: SendChannelContentRequest | PlainMessage<SendChannelContentRequest> | undefined): boolean {
    return proto3.util.equals(SendChannelContentRequest, a, b);
  }
}

/**
 * @generated from message retrica.SendChannelContentResponse
 */
export class SendChannelContentResponse extends Message<SendChannelContentResponse> {
  /**
   * SUCCESS
   *
   * @generated from field: retrica.ErrorCode error_code = 1;
   */
  errorCode = ErrorCode.UNKNOWN;

  /**
   * 전송에 성공한 content만 결과가 내려온다. 실패는 안 옴.
   *
   * @generated from field: repeated retrica.ChannelContentV2 contents = 2;
   */
  contents: ChannelContentV2[] = [];

  constructor(data?: PartialMessage<SendChannelContentResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.SendChannelContentResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "error_code", kind: "enum", T: proto3.getEnumType(ErrorCode) },
    { no: 2, name: "contents", kind: "message", T: ChannelContentV2, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SendChannelContentResponse {
    return new SendChannelContentResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SendChannelContentResponse {
    return new SendChannelContentResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SendChannelContentResponse {
    return new SendChannelContentResponse().fromJsonString(jsonString, options);
  }

  static equals(a: SendChannelContentResponse | PlainMessage<SendChannelContentResponse> | undefined, b: SendChannelContentResponse | PlainMessage<SendChannelContentResponse> | undefined): boolean {
    return proto3.util.equals(SendChannelContentResponse, a, b);
  }
}

/**
 * /a1/channels
 * User의 channel list를 반환한다.
 * Server revision과 client revision이 같을 경우 빈리스트가 간다.
 *
 * @generated from message retrica.ChannelsRequest
 */
export class ChannelsRequest extends Message<ChannelsRequest> {
  /**
   * @generated from field: int64 channels_revision = 1;
   */
  channelsRevision = protoInt64.zero;

  constructor(data?: PartialMessage<ChannelsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.ChannelsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "channels_revision", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ChannelsRequest {
    return new ChannelsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ChannelsRequest {
    return new ChannelsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ChannelsRequest {
    return new ChannelsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ChannelsRequest | PlainMessage<ChannelsRequest> | undefined, b: ChannelsRequest | PlainMessage<ChannelsRequest> | undefined): boolean {
    return proto3.util.equals(ChannelsRequest, a, b);
  }
}

/**
 * @generated from message retrica.ChannelsResponse
 */
export class ChannelsResponse extends Message<ChannelsResponse> {
  /**
   * SUCCESS
   *
   * @generated from field: retrica.ErrorCode error_code = 1;
   */
  errorCode = ErrorCode.UNKNOWN;

  /**
   * @generated from field: int64 channels_revision = 2;
   */
  channelsRevision = protoInt64.zero;

  /**
   * @generated from field: repeated retrica.Channel channels = 3;
   */
  channels: Channel[] = [];

  constructor(data?: PartialMessage<ChannelsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.ChannelsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "error_code", kind: "enum", T: proto3.getEnumType(ErrorCode) },
    { no: 2, name: "channels_revision", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "channels", kind: "message", T: Channel, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ChannelsResponse {
    return new ChannelsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ChannelsResponse {
    return new ChannelsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ChannelsResponse {
    return new ChannelsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ChannelsResponse | PlainMessage<ChannelsResponse> | undefined, b: ChannelsResponse | PlainMessage<ChannelsResponse> | undefined): boolean {
    return proto3.util.equals(ChannelsResponse, a, b);
  }
}

/**
 * /a1/updateChannelSettings
 * Channel의 setting을 업데이트 한다.
 *
 * @generated from message retrica.UpdateChannelSettingsRequest
 */
export class UpdateChannelSettingsRequest extends Message<UpdateChannelSettingsRequest> {
  /**
   * @generated from field: string channel_id = 1;
   */
  channelId = "";

  /**
   * @generated from field: retrica.SettingType push = 2;
   */
  push = SettingType.ST_NONE;

  constructor(data?: PartialMessage<UpdateChannelSettingsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.UpdateChannelSettingsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "channel_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "push", kind: "enum", T: proto3.getEnumType(SettingType) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateChannelSettingsRequest {
    return new UpdateChannelSettingsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateChannelSettingsRequest {
    return new UpdateChannelSettingsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateChannelSettingsRequest {
    return new UpdateChannelSettingsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateChannelSettingsRequest | PlainMessage<UpdateChannelSettingsRequest> | undefined, b: UpdateChannelSettingsRequest | PlainMessage<UpdateChannelSettingsRequest> | undefined): boolean {
    return proto3.util.equals(UpdateChannelSettingsRequest, a, b);
  }
}

/**
 * @generated from message retrica.UpdateChannelSettingsResponse
 */
export class UpdateChannelSettingsResponse extends Message<UpdateChannelSettingsResponse> {
  /**
   * SUCCESS
   *
   * @generated from field: retrica.ErrorCode error_code = 1;
   */
  errorCode = ErrorCode.UNKNOWN;

  /**
   * @generated from field: string channel_id = 2;
   */
  channelId = "";

  /**
   * @generated from field: retrica.SettingType push = 3;
   */
  push = SettingType.ST_NONE;

  constructor(data?: PartialMessage<UpdateChannelSettingsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.UpdateChannelSettingsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "error_code", kind: "enum", T: proto3.getEnumType(ErrorCode) },
    { no: 2, name: "channel_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "push", kind: "enum", T: proto3.getEnumType(SettingType) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateChannelSettingsResponse {
    return new UpdateChannelSettingsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateChannelSettingsResponse {
    return new UpdateChannelSettingsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateChannelSettingsResponse {
    return new UpdateChannelSettingsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateChannelSettingsResponse | PlainMessage<UpdateChannelSettingsResponse> | undefined, b: UpdateChannelSettingsResponse | PlainMessage<UpdateChannelSettingsResponse> | undefined): boolean {
    return proto3.util.equals(UpdateChannelSettingsResponse, a, b);
  }
}

/**
 * /a1/shareCloudContent
 * 저장된 cloud content를 친구에게 보낸다.
 *
 * @generated from message retrica.ShareCloudContentRequest
 */
export class ShareCloudContentRequest extends Message<ShareCloudContentRequest> {
  /**
   * 전송할 channel ids
   *
   * @generated from field: repeated string channel_ids = 1;
   */
  channelIds: string[] = [];

  /**
   * 전송할 friend ids
   *
   * @generated from field: repeated string friend_ids = 2;
   */
  friendIds: string[] = [];

  /**
   * cloud content id
   *
   * @generated from field: string id = 3;
   */
  id = "";

  constructor(data?: PartialMessage<ShareCloudContentRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.ShareCloudContentRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "channel_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 2, name: "friend_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 3, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ShareCloudContentRequest {
    return new ShareCloudContentRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ShareCloudContentRequest {
    return new ShareCloudContentRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ShareCloudContentRequest {
    return new ShareCloudContentRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ShareCloudContentRequest | PlainMessage<ShareCloudContentRequest> | undefined, b: ShareCloudContentRequest | PlainMessage<ShareCloudContentRequest> | undefined): boolean {
    return proto3.util.equals(ShareCloudContentRequest, a, b);
  }
}

/**
 * @generated from message retrica.ShareCloudContentResponse
 */
export class ShareCloudContentResponse extends Message<ShareCloudContentResponse> {
  /**
   * SUCCESS
   *
   * @generated from field: retrica.ErrorCode error_code = 1;
   */
  errorCode = ErrorCode.UNKNOWN;

  /**
   * 전송에 성공한 content만 결과가 내려온다. 실패는 안 옴.
   *
   * @generated from field: repeated retrica.ChannelContentV2 contents = 2;
   */
  contents: ChannelContentV2[] = [];

  constructor(data?: PartialMessage<ShareCloudContentResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.ShareCloudContentResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "error_code", kind: "enum", T: proto3.getEnumType(ErrorCode) },
    { no: 2, name: "contents", kind: "message", T: ChannelContentV2, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ShareCloudContentResponse {
    return new ShareCloudContentResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ShareCloudContentResponse {
    return new ShareCloudContentResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ShareCloudContentResponse {
    return new ShareCloudContentResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ShareCloudContentResponse | PlainMessage<ShareCloudContentResponse> | undefined, b: ShareCloudContentResponse | PlainMessage<ShareCloudContentResponse> | undefined): boolean {
    return proto3.util.equals(ShareCloudContentResponse, a, b);
  }
}

/**
 * /a1/newGroup
 * 새로운 그룹방을 만든다.
 *
 * @generated from message retrica.NewGroupRequest
 */
export class NewGroupRequest extends Message<NewGroupRequest> {
  /**
   * Group에 초대하는 friend ids
   *
   * @generated from field: repeated string friend_ids = 1;
   */
  friendIds: string[] = [];

  constructor(data?: PartialMessage<NewGroupRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.NewGroupRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "friend_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): NewGroupRequest {
    return new NewGroupRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): NewGroupRequest {
    return new NewGroupRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): NewGroupRequest {
    return new NewGroupRequest().fromJsonString(jsonString, options);
  }

  static equals(a: NewGroupRequest | PlainMessage<NewGroupRequest> | undefined, b: NewGroupRequest | PlainMessage<NewGroupRequest> | undefined): boolean {
    return proto3.util.equals(NewGroupRequest, a, b);
  }
}

/**
 * @generated from message retrica.NewGroupResponse
 */
export class NewGroupResponse extends Message<NewGroupResponse> {
  /**
   * @generated from field: retrica.ErrorCode error_code = 1;
   */
  errorCode = ErrorCode.UNKNOWN;

  /**
   * @generated from field: retrica.Channel channel = 2;
   */
  channel?: Channel;

  constructor(data?: PartialMessage<NewGroupResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.NewGroupResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "error_code", kind: "enum", T: proto3.getEnumType(ErrorCode) },
    { no: 2, name: "channel", kind: "message", T: Channel },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): NewGroupResponse {
    return new NewGroupResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): NewGroupResponse {
    return new NewGroupResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): NewGroupResponse {
    return new NewGroupResponse().fromJsonString(jsonString, options);
  }

  static equals(a: NewGroupResponse | PlainMessage<NewGroupResponse> | undefined, b: NewGroupResponse | PlainMessage<NewGroupResponse> | undefined): boolean {
    return proto3.util.equals(NewGroupResponse, a, b);
  }
}

/**
 * /a1/addGroupMembers
 * 그룹방에 새로운 사용자를 추가 한다.
 *
 * @generated from message retrica.AddGroupMembersRequest
 */
export class AddGroupMembersRequest extends Message<AddGroupMembersRequest> {
  /**
   * @generated from field: string channel_id = 1;
   */
  channelId = "";

  /**
   * @generated from field: repeated string friend_ids = 2;
   */
  friendIds: string[] = [];

  constructor(data?: PartialMessage<AddGroupMembersRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.AddGroupMembersRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "channel_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "friend_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AddGroupMembersRequest {
    return new AddGroupMembersRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AddGroupMembersRequest {
    return new AddGroupMembersRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AddGroupMembersRequest {
    return new AddGroupMembersRequest().fromJsonString(jsonString, options);
  }

  static equals(a: AddGroupMembersRequest | PlainMessage<AddGroupMembersRequest> | undefined, b: AddGroupMembersRequest | PlainMessage<AddGroupMembersRequest> | undefined): boolean {
    return proto3.util.equals(AddGroupMembersRequest, a, b);
  }
}

/**
 * @generated from message retrica.AddGroupMembersResponse
 */
export class AddGroupMembersResponse extends Message<AddGroupMembersResponse> {
  /**
   * SUCCESS
   *
   * @generated from field: retrica.ErrorCode error_code = 1;
   */
  errorCode = ErrorCode.UNKNOWN;

  /**
   * @generated from field: retrica.Channel channel = 2;
   */
  channel?: Channel;

  constructor(data?: PartialMessage<AddGroupMembersResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.AddGroupMembersResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "error_code", kind: "enum", T: proto3.getEnumType(ErrorCode) },
    { no: 2, name: "channel", kind: "message", T: Channel },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AddGroupMembersResponse {
    return new AddGroupMembersResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AddGroupMembersResponse {
    return new AddGroupMembersResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AddGroupMembersResponse {
    return new AddGroupMembersResponse().fromJsonString(jsonString, options);
  }

  static equals(a: AddGroupMembersResponse | PlainMessage<AddGroupMembersResponse> | undefined, b: AddGroupMembersResponse | PlainMessage<AddGroupMembersResponse> | undefined): boolean {
    return proto3.util.equals(AddGroupMembersResponse, a, b);
  }
}

/**
 * /a1/leaveGroup
 * 그룹방을 나간다.
 *
 * @generated from message retrica.LeaveGroupRequest
 */
export class LeaveGroupRequest extends Message<LeaveGroupRequest> {
  /**
   * @generated from field: string channel_id = 1;
   */
  channelId = "";

  constructor(data?: PartialMessage<LeaveGroupRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.LeaveGroupRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "channel_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LeaveGroupRequest {
    return new LeaveGroupRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LeaveGroupRequest {
    return new LeaveGroupRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LeaveGroupRequest {
    return new LeaveGroupRequest().fromJsonString(jsonString, options);
  }

  static equals(a: LeaveGroupRequest | PlainMessage<LeaveGroupRequest> | undefined, b: LeaveGroupRequest | PlainMessage<LeaveGroupRequest> | undefined): boolean {
    return proto3.util.equals(LeaveGroupRequest, a, b);
  }
}

/**
 * @generated from message retrica.LeaveGroupResponse
 */
export class LeaveGroupResponse extends Message<LeaveGroupResponse> {
  /**
   * SUCCESS
   *
   * @generated from field: retrica.ErrorCode error_code = 1;
   */
  errorCode = ErrorCode.UNKNOWN;

  /**
   * @generated from field: string channel_id = 2;
   */
  channelId = "";

  constructor(data?: PartialMessage<LeaveGroupResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.LeaveGroupResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "error_code", kind: "enum", T: proto3.getEnumType(ErrorCode) },
    { no: 2, name: "channel_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LeaveGroupResponse {
    return new LeaveGroupResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LeaveGroupResponse {
    return new LeaveGroupResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LeaveGroupResponse {
    return new LeaveGroupResponse().fromJsonString(jsonString, options);
  }

  static equals(a: LeaveGroupResponse | PlainMessage<LeaveGroupResponse> | undefined, b: LeaveGroupResponse | PlainMessage<LeaveGroupResponse> | undefined): boolean {
    return proto3.util.equals(LeaveGroupResponse, a, b);
  }
}

/**
 * /a1/findUsername
 * Username으로 친구를 찾는다.
 *
 * @generated from message retrica.FindUsernameRequest
 */
export class FindUsernameRequest extends Message<FindUsernameRequest> {
  /**
   * @generated from field: string username = 1;
   */
  username = "";

  /**
   * range search 할지 유무
   *
   * @generated from field: bool range_search = 2;
   */
  rangeSearch = false;

  constructor(data?: PartialMessage<FindUsernameRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.FindUsernameRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "username", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "range_search", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FindUsernameRequest {
    return new FindUsernameRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FindUsernameRequest {
    return new FindUsernameRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FindUsernameRequest {
    return new FindUsernameRequest().fromJsonString(jsonString, options);
  }

  static equals(a: FindUsernameRequest | PlainMessage<FindUsernameRequest> | undefined, b: FindUsernameRequest | PlainMessage<FindUsernameRequest> | undefined): boolean {
    return proto3.util.equals(FindUsernameRequest, a, b);
  }
}

/**
 * @generated from message retrica.FindUsernameResponse
 */
export class FindUsernameResponse extends Message<FindUsernameResponse> {
  /**
   * SUCCESS
   *
   * @generated from field: retrica.ErrorCode error_code = 1;
   */
  errorCode = ErrorCode.UNKNOWN;

  /**
   * exact match search 인 경우
   *
   * @generated from field: retrica.Friend friend = 2;
   */
  friend?: Friend;

  /**
   * range search 인 경우
   *
   * @generated from field: repeated retrica.Friend friends = 3;
   */
  friends: Friend[] = [];

  constructor(data?: PartialMessage<FindUsernameResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.FindUsernameResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "error_code", kind: "enum", T: proto3.getEnumType(ErrorCode) },
    { no: 2, name: "friend", kind: "message", T: Friend },
    { no: 3, name: "friends", kind: "message", T: Friend, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FindUsernameResponse {
    return new FindUsernameResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FindUsernameResponse {
    return new FindUsernameResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FindUsernameResponse {
    return new FindUsernameResponse().fromJsonString(jsonString, options);
  }

  static equals(a: FindUsernameResponse | PlainMessage<FindUsernameResponse> | undefined, b: FindUsernameResponse | PlainMessage<FindUsernameResponse> | undefined): boolean {
    return proto3.util.equals(FindUsernameResponse, a, b);
  }
}

/**
 * /a1/findFriend
 * userID 로 친구를 찾는다.
 *
 * @generated from message retrica.FindFriendRequest
 */
export class FindFriendRequest extends Message<FindFriendRequest> {
  /**
   * @generated from field: string user_id = 1;
   */
  userId = "";

  /**
   * @generated from field: string username = 2;
   */
  username = "";

  constructor(data?: PartialMessage<FindFriendRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.FindFriendRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "username", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FindFriendRequest {
    return new FindFriendRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FindFriendRequest {
    return new FindFriendRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FindFriendRequest {
    return new FindFriendRequest().fromJsonString(jsonString, options);
  }

  static equals(a: FindFriendRequest | PlainMessage<FindFriendRequest> | undefined, b: FindFriendRequest | PlainMessage<FindFriendRequest> | undefined): boolean {
    return proto3.util.equals(FindFriendRequest, a, b);
  }
}

/**
 * @generated from message retrica.FindFriendResponse
 */
export class FindFriendResponse extends Message<FindFriendResponse> {
  /**
   * SUCCESS
   *
   * @generated from field: retrica.ErrorCode error_code = 1;
   */
  errorCode = ErrorCode.UNKNOWN;

  /**
   * @generated from field: retrica.Friend friend = 2;
   */
  friend?: Friend;

  constructor(data?: PartialMessage<FindFriendResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.FindFriendResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "error_code", kind: "enum", T: proto3.getEnumType(ErrorCode) },
    { no: 2, name: "friend", kind: "message", T: Friend },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FindFriendResponse {
    return new FindFriendResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FindFriendResponse {
    return new FindFriendResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FindFriendResponse {
    return new FindFriendResponse().fromJsonString(jsonString, options);
  }

  static equals(a: FindFriendResponse | PlainMessage<FindFriendResponse> | undefined, b: FindFriendResponse | PlainMessage<FindFriendResponse> | undefined): boolean {
    return proto3.util.equals(FindFriendResponse, a, b);
  }
}

/**
 * /a1/friends
 * 친구리스트를 받을 수 있다.
 * 서버 revision과 클라이언트 revision이 같으면 빈리스트가 온다.
 *
 * @generated from message retrica.FriendsRequest
 */
export class FriendsRequest extends Message<FriendsRequest> {
  /**
   * DEPRECATED
   *
   * @generated from field: int64 DEPRECATED_friend_revision = 1;
   */
  DEPRECATEDFriendRevision = protoInt64.zero;

  constructor(data?: PartialMessage<FriendsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.FriendsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "DEPRECATED_friend_revision", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FriendsRequest {
    return new FriendsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FriendsRequest {
    return new FriendsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FriendsRequest {
    return new FriendsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: FriendsRequest | PlainMessage<FriendsRequest> | undefined, b: FriendsRequest | PlainMessage<FriendsRequest> | undefined): boolean {
    return proto3.util.equals(FriendsRequest, a, b);
  }
}

/**
 * @generated from message retrica.FriendsResponse
 */
export class FriendsResponse extends Message<FriendsResponse> {
  /**
   * SUCCESS
   *
   * @generated from field: retrica.ErrorCode error_code = 1;
   */
  errorCode = ErrorCode.UNKNOWN;

  /**
   * @generated from field: repeated retrica.Friend list = 3;
   */
  list: Friend[] = [];

  /**
   * DEPRECATED
   *
   * @generated from field: int64 DEPRECATED_friend_revision = 2;
   */
  DEPRECATEDFriendRevision = protoInt64.zero;

  constructor(data?: PartialMessage<FriendsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.FriendsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "error_code", kind: "enum", T: proto3.getEnumType(ErrorCode) },
    { no: 3, name: "list", kind: "message", T: Friend, repeated: true },
    { no: 2, name: "DEPRECATED_friend_revision", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FriendsResponse {
    return new FriendsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FriendsResponse {
    return new FriendsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FriendsResponse {
    return new FriendsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: FriendsResponse | PlainMessage<FriendsResponse> | undefined, b: FriendsResponse | PlainMessage<FriendsResponse> | undefined): boolean {
    return proto3.util.equals(FriendsResponse, a, b);
  }
}

/**
 * /a1/addedMeFriends
 * 나를 추가한 친구 리스트를 반환한다.
 * 서버 revision과 클라이언트 revision이 같으면 빈리스트가 온다.
 *
 * @generated from message retrica.AddedMeFriendsRequest
 */
export class AddedMeFriendsRequest extends Message<AddedMeFriendsRequest> {
  /**
   * @generated from field: int64 DEPRECATED_added_me_revision = 1;
   */
  DEPRECATEDAddedMeRevision = protoInt64.zero;

  constructor(data?: PartialMessage<AddedMeFriendsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.AddedMeFriendsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "DEPRECATED_added_me_revision", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AddedMeFriendsRequest {
    return new AddedMeFriendsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AddedMeFriendsRequest {
    return new AddedMeFriendsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AddedMeFriendsRequest {
    return new AddedMeFriendsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: AddedMeFriendsRequest | PlainMessage<AddedMeFriendsRequest> | undefined, b: AddedMeFriendsRequest | PlainMessage<AddedMeFriendsRequest> | undefined): boolean {
    return proto3.util.equals(AddedMeFriendsRequest, a, b);
  }
}

/**
 * @generated from message retrica.AddedMeFriendsResponse
 */
export class AddedMeFriendsResponse extends Message<AddedMeFriendsResponse> {
  /**
   * SUCCESS
   *
   * @generated from field: retrica.ErrorCode error_code = 1;
   */
  errorCode = ErrorCode.UNKNOWN;

  /**
   * @generated from field: repeated retrica.Friend list = 3;
   */
  list: Friend[] = [];

  /**
   * DEPRECATED
   *
   * @generated from field: int64 DEPRECATED_added_me_revision = 2;
   */
  DEPRECATEDAddedMeRevision = protoInt64.zero;

  /**
   * @generated from field: retrica.Unread DEPRECATED_unread = 4;
   */
  DEPRECATEDUnread?: Unread;

  constructor(data?: PartialMessage<AddedMeFriendsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.AddedMeFriendsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "error_code", kind: "enum", T: proto3.getEnumType(ErrorCode) },
    { no: 3, name: "list", kind: "message", T: Friend, repeated: true },
    { no: 2, name: "DEPRECATED_added_me_revision", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "DEPRECATED_unread", kind: "message", T: Unread },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AddedMeFriendsResponse {
    return new AddedMeFriendsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AddedMeFriendsResponse {
    return new AddedMeFriendsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AddedMeFriendsResponse {
    return new AddedMeFriendsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: AddedMeFriendsResponse | PlainMessage<AddedMeFriendsResponse> | undefined, b: AddedMeFriendsResponse | PlainMessage<AddedMeFriendsResponse> | undefined): boolean {
    return proto3.util.equals(AddedMeFriendsResponse, a, b);
  }
}

/**
 * /a1/recommendFriends
 * 추천 친구를 반환 한다.
 * 서버 revision과 클라이언트 revision이 같으면 빈리스트가 온다.
 *
 * @generated from message retrica.RecommendFriendsRequest
 */
export class RecommendFriendsRequest extends Message<RecommendFriendsRequest> {
  /**
   * DEPRECATED
   *
   * @generated from field: int64 DEPRECATED_recommend_revision = 1;
   */
  DEPRECATEDRecommendRevision = protoInt64.zero;

  constructor(data?: PartialMessage<RecommendFriendsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.RecommendFriendsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "DEPRECATED_recommend_revision", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RecommendFriendsRequest {
    return new RecommendFriendsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RecommendFriendsRequest {
    return new RecommendFriendsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RecommendFriendsRequest {
    return new RecommendFriendsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: RecommendFriendsRequest | PlainMessage<RecommendFriendsRequest> | undefined, b: RecommendFriendsRequest | PlainMessage<RecommendFriendsRequest> | undefined): boolean {
    return proto3.util.equals(RecommendFriendsRequest, a, b);
  }
}

/**
 * @generated from message retrica.RecommendFriendsResponse
 */
export class RecommendFriendsResponse extends Message<RecommendFriendsResponse> {
  /**
   * SUCCESS
   *
   * @generated from field: retrica.ErrorCode error_code = 1;
   */
  errorCode = ErrorCode.UNKNOWN;

  /**
   * @generated from field: repeated retrica.Friend list = 3;
   */
  list: Friend[] = [];

  /**
   * DEPRECATED
   *
   * @generated from field: int64 DEPRECATED_recommend_revision = 2;
   */
  DEPRECATEDRecommendRevision = protoInt64.zero;

  constructor(data?: PartialMessage<RecommendFriendsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.RecommendFriendsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "error_code", kind: "enum", T: proto3.getEnumType(ErrorCode) },
    { no: 3, name: "list", kind: "message", T: Friend, repeated: true },
    { no: 2, name: "DEPRECATED_recommend_revision", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RecommendFriendsResponse {
    return new RecommendFriendsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RecommendFriendsResponse {
    return new RecommendFriendsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RecommendFriendsResponse {
    return new RecommendFriendsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: RecommendFriendsResponse | PlainMessage<RecommendFriendsResponse> | undefined, b: RecommendFriendsResponse | PlainMessage<RecommendFriendsResponse> | undefined): boolean {
    return proto3.util.equals(RecommendFriendsResponse, a, b);
  }
}

/**
 * /a1/unblockFriend
 * 친구 차단을 취소한다.
 *
 * @generated from message retrica.UnblockFriendRequest
 */
export class UnblockFriendRequest extends Message<UnblockFriendRequest> {
  /**
   * @generated from field: string user_id = 1;
   */
  userId = "";

  /**
   * @generated from field: string contact_name = 2;
   */
  contactName = "";

  constructor(data?: PartialMessage<UnblockFriendRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.UnblockFriendRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "contact_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UnblockFriendRequest {
    return new UnblockFriendRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UnblockFriendRequest {
    return new UnblockFriendRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UnblockFriendRequest {
    return new UnblockFriendRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UnblockFriendRequest | PlainMessage<UnblockFriendRequest> | undefined, b: UnblockFriendRequest | PlainMessage<UnblockFriendRequest> | undefined): boolean {
    return proto3.util.equals(UnblockFriendRequest, a, b);
  }
}

/**
 * @generated from message retrica.UnblockFriendResponse
 */
export class UnblockFriendResponse extends Message<UnblockFriendResponse> {
  /**
   * SUCESS
   *
   * @generated from field: retrica.ErrorCode error_code = 1;
   */
  errorCode = ErrorCode.UNKNOWN;

  /**
   * @generated from field: retrica.Friend friend = 6;
   */
  friend?: Friend;

  /**
   * DEPRECATED
   *
   * @generated from field: int64 DEPRECATED_friend_revision = 2;
   */
  DEPRECATEDFriendRevision = protoInt64.zero;

  /**
   * @generated from field: int64 DEPRECATED_block_revision = 3;
   */
  DEPRECATEDBlockRevision = protoInt64.zero;

  /**
   * @generated from field: int64 DEPRECATED_recommend_revision = 4;
   */
  DEPRECATEDRecommendRevision = protoInt64.zero;

  /**
   * @generated from field: int64 DEPRECATED_added_me_revision = 5;
   */
  DEPRECATEDAddedMeRevision = protoInt64.zero;

  constructor(data?: PartialMessage<UnblockFriendResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.UnblockFriendResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "error_code", kind: "enum", T: proto3.getEnumType(ErrorCode) },
    { no: 6, name: "friend", kind: "message", T: Friend },
    { no: 2, name: "DEPRECATED_friend_revision", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "DEPRECATED_block_revision", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "DEPRECATED_recommend_revision", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 5, name: "DEPRECATED_added_me_revision", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UnblockFriendResponse {
    return new UnblockFriendResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UnblockFriendResponse {
    return new UnblockFriendResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UnblockFriendResponse {
    return new UnblockFriendResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UnblockFriendResponse | PlainMessage<UnblockFriendResponse> | undefined, b: UnblockFriendResponse | PlainMessage<UnblockFriendResponse> | undefined): boolean {
    return proto3.util.equals(UnblockFriendResponse, a, b);
  }
}

/**
 * TODO: 이부분부터 정리 필요
 * /a1/sn/friendSelfies
 *
 * @generated from message retrica.SNFriendSelfiesRequest
 */
export class SNFriendSelfiesRequest extends Message<SNFriendSelfiesRequest> {
  constructor(data?: PartialMessage<SNFriendSelfiesRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.SNFriendSelfiesRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SNFriendSelfiesRequest {
    return new SNFriendSelfiesRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SNFriendSelfiesRequest {
    return new SNFriendSelfiesRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SNFriendSelfiesRequest {
    return new SNFriendSelfiesRequest().fromJsonString(jsonString, options);
  }

  static equals(a: SNFriendSelfiesRequest | PlainMessage<SNFriendSelfiesRequest> | undefined, b: SNFriendSelfiesRequest | PlainMessage<SNFriendSelfiesRequest> | undefined): boolean {
    return proto3.util.equals(SNFriendSelfiesRequest, a, b);
  }
}

/**
 * @generated from message retrica.SNFriendSelfiesResponse
 */
export class SNFriendSelfiesResponse extends Message<SNFriendSelfiesResponse> {
  /**
   * @generated from field: retrica.ErrorCode error_code = 1;
   */
  errorCode = ErrorCode.UNKNOWN;

  /**
   * 친구 selfies (최대 6개)
   *
   * @generated from field: repeated retrica.SelfieNetwork.SelfieWrap friend_wraps = 2;
   */
  friendWraps: SelfieNetwork_SelfieWrap[] = [];

  /**
   * 추천친구 selfies (최대 6개)
   *
   * @generated from field: repeated retrica.SelfieNetwork.SelfieWrap recommend_wraps = 3;
   */
  recommendWraps: SelfieNetwork_SelfieWrap[] = [];

  /**
   * @generated from field: map<string, retrica.Friend> friends_map = 4;
   */
  friendsMap: { [key: string]: Friend } = {};

  constructor(data?: PartialMessage<SNFriendSelfiesResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.SNFriendSelfiesResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "error_code", kind: "enum", T: proto3.getEnumType(ErrorCode) },
    { no: 2, name: "friend_wraps", kind: "message", T: SelfieNetwork_SelfieWrap, repeated: true },
    { no: 3, name: "recommend_wraps", kind: "message", T: SelfieNetwork_SelfieWrap, repeated: true },
    { no: 4, name: "friends_map", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "message", T: Friend} },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SNFriendSelfiesResponse {
    return new SNFriendSelfiesResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SNFriendSelfiesResponse {
    return new SNFriendSelfiesResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SNFriendSelfiesResponse {
    return new SNFriendSelfiesResponse().fromJsonString(jsonString, options);
  }

  static equals(a: SNFriendSelfiesResponse | PlainMessage<SNFriendSelfiesResponse> | undefined, b: SNFriendSelfiesResponse | PlainMessage<SNFriendSelfiesResponse> | undefined): boolean {
    return proto3.util.equals(SNFriendSelfiesResponse, a, b);
  }
}

/**
 * /a1/sn/myPage
 *
 * @generated from message retrica.SNMyPageRequest
 */
export class SNMyPageRequest extends Message<SNMyPageRequest> {
  /**
   * offset 관련(빈스트링 <최신부터>,  id <id 이후>)
   *
   * @generated from field: string selfie_id = 1;
   */
  selfieId = "";

  constructor(data?: PartialMessage<SNMyPageRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.SNMyPageRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "selfie_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SNMyPageRequest {
    return new SNMyPageRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SNMyPageRequest {
    return new SNMyPageRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SNMyPageRequest {
    return new SNMyPageRequest().fromJsonString(jsonString, options);
  }

  static equals(a: SNMyPageRequest | PlainMessage<SNMyPageRequest> | undefined, b: SNMyPageRequest | PlainMessage<SNMyPageRequest> | undefined): boolean {
    return proto3.util.equals(SNMyPageRequest, a, b);
  }
}

/**
 * @generated from message retrica.SNMyPageResponse
 */
export class SNMyPageResponse extends Message<SNMyPageResponse> {
  /**
   * @generated from field: retrica.ErrorCode error_code = 1;
   */
  errorCode = ErrorCode.UNKNOWN;

  /**
   * @generated from field: retrica.SelfieNetwork.SelfiePage selfie_page = 2;
   */
  selfiePage?: SelfieNetwork_SelfiePage;

  /**
   * @generated from field: map<string, retrica.Friend> friends_map = 3;
   */
  friendsMap: { [key: string]: Friend } = {};

  constructor(data?: PartialMessage<SNMyPageResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.SNMyPageResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "error_code", kind: "enum", T: proto3.getEnumType(ErrorCode) },
    { no: 2, name: "selfie_page", kind: "message", T: SelfieNetwork_SelfiePage },
    { no: 3, name: "friends_map", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "message", T: Friend} },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SNMyPageResponse {
    return new SNMyPageResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SNMyPageResponse {
    return new SNMyPageResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SNMyPageResponse {
    return new SNMyPageResponse().fromJsonString(jsonString, options);
  }

  static equals(a: SNMyPageResponse | PlainMessage<SNMyPageResponse> | undefined, b: SNMyPageResponse | PlainMessage<SNMyPageResponse> | undefined): boolean {
    return proto3.util.equals(SNMyPageResponse, a, b);
  }
}

/**
 * /a1/sn/userPage
 *
 * @generated from message retrica.SNUserPageRequest
 */
export class SNUserPageRequest extends Message<SNUserPageRequest> {
  /**
   * target id
   *
   * @generated from field: string user_id = 1;
   */
  userId = "";

  /**
   * offset 관련(빈스트링 <최신부터>,  id <id 이후>)
   *
   * @generated from field: string selfie_id = 2;
   */
  selfieId = "";

  constructor(data?: PartialMessage<SNUserPageRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.SNUserPageRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "selfie_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SNUserPageRequest {
    return new SNUserPageRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SNUserPageRequest {
    return new SNUserPageRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SNUserPageRequest {
    return new SNUserPageRequest().fromJsonString(jsonString, options);
  }

  static equals(a: SNUserPageRequest | PlainMessage<SNUserPageRequest> | undefined, b: SNUserPageRequest | PlainMessage<SNUserPageRequest> | undefined): boolean {
    return proto3.util.equals(SNUserPageRequest, a, b);
  }
}

/**
 * @generated from message retrica.SNUserPageResponse
 */
export class SNUserPageResponse extends Message<SNUserPageResponse> {
  /**
   * @generated from field: retrica.ErrorCode error_code = 1;
   */
  errorCode = ErrorCode.UNKNOWN;

  /**
   * @generated from field: retrica.SelfieNetwork.SelfiePage selfie_page = 2;
   */
  selfiePage?: SelfieNetwork_SelfiePage;

  /**
   * @generated from field: map<string, retrica.Friend> friends_map = 3;
   */
  friendsMap: { [key: string]: Friend } = {};

  constructor(data?: PartialMessage<SNUserPageResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.SNUserPageResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "error_code", kind: "enum", T: proto3.getEnumType(ErrorCode) },
    { no: 2, name: "selfie_page", kind: "message", T: SelfieNetwork_SelfiePage },
    { no: 3, name: "friends_map", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "message", T: Friend} },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SNUserPageResponse {
    return new SNUserPageResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SNUserPageResponse {
    return new SNUserPageResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SNUserPageResponse {
    return new SNUserPageResponse().fromJsonString(jsonString, options);
  }

  static equals(a: SNUserPageResponse | PlainMessage<SNUserPageResponse> | undefined, b: SNUserPageResponse | PlainMessage<SNUserPageResponse> | undefined): boolean {
    return proto3.util.equals(SNUserPageResponse, a, b);
  }
}

/**
 * /a1/sn/uploadSelfie
 *
 * @generated from message retrica.SNUploadSelfieRequest
 */
export class SNUploadSelfieRequest extends Message<SNUploadSelfieRequest> {
  /**
   * @generated from field: retrica.Content content = 1;
   */
  content?: Content;

  /**
   * @generated from field: repeated retrica.SelfieNetwork.HashTag tags = 2;
   */
  tags: SelfieNetwork_HashTag[] = [];

  /**
   * @generated from field: repeated retrica.SelfieNetwork.Decorator decorators = 3;
   */
  decorators: SelfieNetwork_Decorator[] = [];

  constructor(data?: PartialMessage<SNUploadSelfieRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.SNUploadSelfieRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "content", kind: "message", T: Content },
    { no: 2, name: "tags", kind: "message", T: SelfieNetwork_HashTag, repeated: true },
    { no: 3, name: "decorators", kind: "message", T: SelfieNetwork_Decorator, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SNUploadSelfieRequest {
    return new SNUploadSelfieRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SNUploadSelfieRequest {
    return new SNUploadSelfieRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SNUploadSelfieRequest {
    return new SNUploadSelfieRequest().fromJsonString(jsonString, options);
  }

  static equals(a: SNUploadSelfieRequest | PlainMessage<SNUploadSelfieRequest> | undefined, b: SNUploadSelfieRequest | PlainMessage<SNUploadSelfieRequest> | undefined): boolean {
    return proto3.util.equals(SNUploadSelfieRequest, a, b);
  }
}

/**
 * @generated from message retrica.SNUploadSelfieResponse
 */
export class SNUploadSelfieResponse extends Message<SNUploadSelfieResponse> {
  /**
   * @generated from field: retrica.ErrorCode error_code = 1;
   */
  errorCode = ErrorCode.UNKNOWN;

  /**
   * @generated from field: retrica.SelfieNetwork.Selfie selfie = 2;
   */
  selfie?: SelfieNetwork_Selfie;

  constructor(data?: PartialMessage<SNUploadSelfieResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.SNUploadSelfieResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "error_code", kind: "enum", T: proto3.getEnumType(ErrorCode) },
    { no: 2, name: "selfie", kind: "message", T: SelfieNetwork_Selfie },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SNUploadSelfieResponse {
    return new SNUploadSelfieResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SNUploadSelfieResponse {
    return new SNUploadSelfieResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SNUploadSelfieResponse {
    return new SNUploadSelfieResponse().fromJsonString(jsonString, options);
  }

  static equals(a: SNUploadSelfieResponse | PlainMessage<SNUploadSelfieResponse> | undefined, b: SNUploadSelfieResponse | PlainMessage<SNUploadSelfieResponse> | undefined): boolean {
    return proto3.util.equals(SNUploadSelfieResponse, a, b);
  }
}

/**
 * /a1/sn/deleteSelfie
 *
 * @generated from message retrica.SNDeleteSelfieRequest
 */
export class SNDeleteSelfieRequest extends Message<SNDeleteSelfieRequest> {
  /**
   * @generated from field: string selfie_id = 1;
   */
  selfieId = "";

  constructor(data?: PartialMessage<SNDeleteSelfieRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.SNDeleteSelfieRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "selfie_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SNDeleteSelfieRequest {
    return new SNDeleteSelfieRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SNDeleteSelfieRequest {
    return new SNDeleteSelfieRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SNDeleteSelfieRequest {
    return new SNDeleteSelfieRequest().fromJsonString(jsonString, options);
  }

  static equals(a: SNDeleteSelfieRequest | PlainMessage<SNDeleteSelfieRequest> | undefined, b: SNDeleteSelfieRequest | PlainMessage<SNDeleteSelfieRequest> | undefined): boolean {
    return proto3.util.equals(SNDeleteSelfieRequest, a, b);
  }
}

/**
 * @generated from message retrica.SNDeleteSelfieResponse
 */
export class SNDeleteSelfieResponse extends Message<SNDeleteSelfieResponse> {
  /**
   * @generated from field: retrica.ErrorCode error_code = 1;
   */
  errorCode = ErrorCode.UNKNOWN;

  /**
   * @generated from field: string selfie_id = 2;
   */
  selfieId = "";

  constructor(data?: PartialMessage<SNDeleteSelfieResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.SNDeleteSelfieResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "error_code", kind: "enum", T: proto3.getEnumType(ErrorCode) },
    { no: 2, name: "selfie_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SNDeleteSelfieResponse {
    return new SNDeleteSelfieResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SNDeleteSelfieResponse {
    return new SNDeleteSelfieResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SNDeleteSelfieResponse {
    return new SNDeleteSelfieResponse().fromJsonString(jsonString, options);
  }

  static equals(a: SNDeleteSelfieResponse | PlainMessage<SNDeleteSelfieResponse> | undefined, b: SNDeleteSelfieResponse | PlainMessage<SNDeleteSelfieResponse> | undefined): boolean {
    return proto3.util.equals(SNDeleteSelfieResponse, a, b);
  }
}

/**
 * /a1/sn/showSelfie
 *
 * @generated from message retrica.SNShowSelfieRequest
 */
export class SNShowSelfieRequest extends Message<SNShowSelfieRequest> {
  /**
   * @generated from field: string selfie_id = 1;
   */
  selfieId = "";

  constructor(data?: PartialMessage<SNShowSelfieRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.SNShowSelfieRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "selfie_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SNShowSelfieRequest {
    return new SNShowSelfieRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SNShowSelfieRequest {
    return new SNShowSelfieRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SNShowSelfieRequest {
    return new SNShowSelfieRequest().fromJsonString(jsonString, options);
  }

  static equals(a: SNShowSelfieRequest | PlainMessage<SNShowSelfieRequest> | undefined, b: SNShowSelfieRequest | PlainMessage<SNShowSelfieRequest> | undefined): boolean {
    return proto3.util.equals(SNShowSelfieRequest, a, b);
  }
}

/**
 * @generated from message retrica.SNShowSelfieResponse
 */
export class SNShowSelfieResponse extends Message<SNShowSelfieResponse> {
  /**
   * @generated from field: retrica.ErrorCode error_code = 1;
   */
  errorCode = ErrorCode.UNKNOWN;

  constructor(data?: PartialMessage<SNShowSelfieResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.SNShowSelfieResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "error_code", kind: "enum", T: proto3.getEnumType(ErrorCode) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SNShowSelfieResponse {
    return new SNShowSelfieResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SNShowSelfieResponse {
    return new SNShowSelfieResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SNShowSelfieResponse {
    return new SNShowSelfieResponse().fromJsonString(jsonString, options);
  }

  static equals(a: SNShowSelfieResponse | PlainMessage<SNShowSelfieResponse> | undefined, b: SNShowSelfieResponse | PlainMessage<SNShowSelfieResponse> | undefined): boolean {
    return proto3.util.equals(SNShowSelfieResponse, a, b);
  }
}

/**
 * /a1/sn/likeSelfie
 *
 * @generated from message retrica.SNLikeSelfieRequest
 */
export class SNLikeSelfieRequest extends Message<SNLikeSelfieRequest> {
  /**
   * @generated from field: string selfie_id = 1;
   */
  selfieId = "";

  constructor(data?: PartialMessage<SNLikeSelfieRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.SNLikeSelfieRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "selfie_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SNLikeSelfieRequest {
    return new SNLikeSelfieRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SNLikeSelfieRequest {
    return new SNLikeSelfieRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SNLikeSelfieRequest {
    return new SNLikeSelfieRequest().fromJsonString(jsonString, options);
  }

  static equals(a: SNLikeSelfieRequest | PlainMessage<SNLikeSelfieRequest> | undefined, b: SNLikeSelfieRequest | PlainMessage<SNLikeSelfieRequest> | undefined): boolean {
    return proto3.util.equals(SNLikeSelfieRequest, a, b);
  }
}

/**
 * @generated from message retrica.SNLikeSelfieResponse
 */
export class SNLikeSelfieResponse extends Message<SNLikeSelfieResponse> {
  /**
   * @generated from field: retrica.ErrorCode error_code = 1;
   */
  errorCode = ErrorCode.UNKNOWN;

  constructor(data?: PartialMessage<SNLikeSelfieResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.SNLikeSelfieResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "error_code", kind: "enum", T: proto3.getEnumType(ErrorCode) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SNLikeSelfieResponse {
    return new SNLikeSelfieResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SNLikeSelfieResponse {
    return new SNLikeSelfieResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SNLikeSelfieResponse {
    return new SNLikeSelfieResponse().fromJsonString(jsonString, options);
  }

  static equals(a: SNLikeSelfieResponse | PlainMessage<SNLikeSelfieResponse> | undefined, b: SNLikeSelfieResponse | PlainMessage<SNLikeSelfieResponse> | undefined): boolean {
    return proto3.util.equals(SNLikeSelfieResponse, a, b);
  }
}

/**
 * /a1/sn/discover
 *
 * @generated from message retrica.SNDiscoverRequest
 */
export class SNDiscoverRequest extends Message<SNDiscoverRequest> {
  constructor(data?: PartialMessage<SNDiscoverRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.SNDiscoverRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SNDiscoverRequest {
    return new SNDiscoverRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SNDiscoverRequest {
    return new SNDiscoverRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SNDiscoverRequest {
    return new SNDiscoverRequest().fromJsonString(jsonString, options);
  }

  static equals(a: SNDiscoverRequest | PlainMessage<SNDiscoverRequest> | undefined, b: SNDiscoverRequest | PlainMessage<SNDiscoverRequest> | undefined): boolean {
    return proto3.util.equals(SNDiscoverRequest, a, b);
  }
}

/**
 * @generated from message retrica.SNDiscoverResponse
 */
export class SNDiscoverResponse extends Message<SNDiscoverResponse> {
  /**
   * @generated from field: retrica.ErrorCode error_code = 1;
   */
  errorCode = ErrorCode.UNKNOWN;

  /**
   * @generated from field: repeated retrica.SelfieNetwork.HashTagWrap tag_wraps = 2;
   */
  tagWraps: SelfieNetwork_HashTagWrap[] = [];

  /**
   * @generated from field: map<string, retrica.Friend> friends_map = 3;
   */
  friendsMap: { [key: string]: Friend } = {};

  constructor(data?: PartialMessage<SNDiscoverResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.SNDiscoverResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "error_code", kind: "enum", T: proto3.getEnumType(ErrorCode) },
    { no: 2, name: "tag_wraps", kind: "message", T: SelfieNetwork_HashTagWrap, repeated: true },
    { no: 3, name: "friends_map", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "message", T: Friend} },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SNDiscoverResponse {
    return new SNDiscoverResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SNDiscoverResponse {
    return new SNDiscoverResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SNDiscoverResponse {
    return new SNDiscoverResponse().fromJsonString(jsonString, options);
  }

  static equals(a: SNDiscoverResponse | PlainMessage<SNDiscoverResponse> | undefined, b: SNDiscoverResponse | PlainMessage<SNDiscoverResponse> | undefined): boolean {
    return proto3.util.equals(SNDiscoverResponse, a, b);
  }
}

/**
 * /a1/sn/report
 *
 * @generated from message retrica.SNReportRequest
 */
export class SNReportRequest extends Message<SNReportRequest> {
  /**
   * 신고 대상 user id
   *
   * @generated from field: string user_id = 1;
   */
  userId = "";

  /**
   * 신고 대상 selfie_id
   *
   * @generated from field: string selfie_id = 2;
   */
  selfieId = "";

  /**
   * 신고 타입
   *
   * @generated from field: retrica.ReportType report_type = 3;
   */
  reportType = ReportType.RET_NONE;

  constructor(data?: PartialMessage<SNReportRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.SNReportRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "selfie_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "report_type", kind: "enum", T: proto3.getEnumType(ReportType) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SNReportRequest {
    return new SNReportRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SNReportRequest {
    return new SNReportRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SNReportRequest {
    return new SNReportRequest().fromJsonString(jsonString, options);
  }

  static equals(a: SNReportRequest | PlainMessage<SNReportRequest> | undefined, b: SNReportRequest | PlainMessage<SNReportRequest> | undefined): boolean {
    return proto3.util.equals(SNReportRequest, a, b);
  }
}

/**
 * @generated from message retrica.SNReportResponse
 */
export class SNReportResponse extends Message<SNReportResponse> {
  /**
   * @generated from field: retrica.ErrorCode error_code = 1;
   */
  errorCode = ErrorCode.UNKNOWN;

  constructor(data?: PartialMessage<SNReportResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.SNReportResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "error_code", kind: "enum", T: proto3.getEnumType(ErrorCode) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SNReportResponse {
    return new SNReportResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SNReportResponse {
    return new SNReportResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SNReportResponse {
    return new SNReportResponse().fromJsonString(jsonString, options);
  }

  static equals(a: SNReportResponse | PlainMessage<SNReportResponse> | undefined, b: SNReportResponse | PlainMessage<SNReportResponse> | undefined): boolean {
    return proto3.util.equals(SNReportResponse, a, b);
  }
}

/**
 * /a1/sn/campaignHashTags
 *
 * @generated from message retrica.SNCampaignHashTagsRequest
 */
export class SNCampaignHashTagsRequest extends Message<SNCampaignHashTagsRequest> {
  constructor(data?: PartialMessage<SNCampaignHashTagsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.SNCampaignHashTagsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SNCampaignHashTagsRequest {
    return new SNCampaignHashTagsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SNCampaignHashTagsRequest {
    return new SNCampaignHashTagsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SNCampaignHashTagsRequest {
    return new SNCampaignHashTagsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: SNCampaignHashTagsRequest | PlainMessage<SNCampaignHashTagsRequest> | undefined, b: SNCampaignHashTagsRequest | PlainMessage<SNCampaignHashTagsRequest> | undefined): boolean {
    return proto3.util.equals(SNCampaignHashTagsRequest, a, b);
  }
}

/**
 * @generated from message retrica.SNCampaignHashTagsResponse
 */
export class SNCampaignHashTagsResponse extends Message<SNCampaignHashTagsResponse> {
  /**
   * @generated from field: retrica.ErrorCode error_code = 1;
   */
  errorCode = ErrorCode.UNKNOWN;

  /**
   * @generated from field: repeated retrica.SelfieNetwork.HashTag tags = 2;
   */
  tags: SelfieNetwork_HashTag[] = [];

  constructor(data?: PartialMessage<SNCampaignHashTagsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.SNCampaignHashTagsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "error_code", kind: "enum", T: proto3.getEnumType(ErrorCode) },
    { no: 2, name: "tags", kind: "message", T: SelfieNetwork_HashTag, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SNCampaignHashTagsResponse {
    return new SNCampaignHashTagsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SNCampaignHashTagsResponse {
    return new SNCampaignHashTagsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SNCampaignHashTagsResponse {
    return new SNCampaignHashTagsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: SNCampaignHashTagsResponse | PlainMessage<SNCampaignHashTagsResponse> | undefined, b: SNCampaignHashTagsResponse | PlainMessage<SNCampaignHashTagsResponse> | undefined): boolean {
    return proto3.util.equals(SNCampaignHashTagsResponse, a, b);
  }
}

/**
 * /a1/sn/homeV1
 *
 * @generated from message retrica.SNHomeV1Request
 */
export class SNHomeV1Request extends Message<SNHomeV1Request> {
  constructor(data?: PartialMessage<SNHomeV1Request>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.SNHomeV1Request";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SNHomeV1Request {
    return new SNHomeV1Request().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SNHomeV1Request {
    return new SNHomeV1Request().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SNHomeV1Request {
    return new SNHomeV1Request().fromJsonString(jsonString, options);
  }

  static equals(a: SNHomeV1Request | PlainMessage<SNHomeV1Request> | undefined, b: SNHomeV1Request | PlainMessage<SNHomeV1Request> | undefined): boolean {
    return proto3.util.equals(SNHomeV1Request, a, b);
  }
}

/**
 * @generated from message retrica.SNHomeV1Response
 */
export class SNHomeV1Response extends Message<SNHomeV1Response> {
  /**
   * @generated from field: retrica.ErrorCode error_code = 1;
   */
  errorCode = ErrorCode.UNKNOWN;

  /**
   * @generated from field: repeated retrica.SelfieNetwork.Activity activities = 2;
   */
  activities: SelfieNetwork_Activity[] = [];

  /**
   * @generated from field: repeated retrica.SelfieNetwork.Selfie timelines = 3;
   */
  timelines: SelfieNetwork_Selfie[] = [];

  /**
   * 친구 selfies 1개
   *
   * @generated from field: repeated retrica.SelfieNetwork.SelfieWrap friend_wraps = 4;
   */
  friendWraps: SelfieNetwork_SelfieWrap[] = [];

  /**
   * 추천친구 selfies 1개
   *
   * @generated from field: repeated retrica.SelfieNetwork.SelfieWrap recommend_wraps = 5;
   */
  recommendWraps: SelfieNetwork_SelfieWrap[] = [];

  /**
   * @generated from field: map<string, retrica.Friend> friends_map = 6;
   */
  friendsMap: { [key: string]: Friend } = {};

  constructor(data?: PartialMessage<SNHomeV1Response>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.SNHomeV1Response";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "error_code", kind: "enum", T: proto3.getEnumType(ErrorCode) },
    { no: 2, name: "activities", kind: "message", T: SelfieNetwork_Activity, repeated: true },
    { no: 3, name: "timelines", kind: "message", T: SelfieNetwork_Selfie, repeated: true },
    { no: 4, name: "friend_wraps", kind: "message", T: SelfieNetwork_SelfieWrap, repeated: true },
    { no: 5, name: "recommend_wraps", kind: "message", T: SelfieNetwork_SelfieWrap, repeated: true },
    { no: 6, name: "friends_map", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "message", T: Friend} },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SNHomeV1Response {
    return new SNHomeV1Response().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SNHomeV1Response {
    return new SNHomeV1Response().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SNHomeV1Response {
    return new SNHomeV1Response().fromJsonString(jsonString, options);
  }

  static equals(a: SNHomeV1Response | PlainMessage<SNHomeV1Response> | undefined, b: SNHomeV1Response | PlainMessage<SNHomeV1Response> | undefined): boolean {
    return proto3.util.equals(SNHomeV1Response, a, b);
  }
}

/**
 * /a1/sn/myActivities
 *
 * @generated from message retrica.SNMyActivitiesRequest
 */
export class SNMyActivitiesRequest extends Message<SNMyActivitiesRequest> {
  constructor(data?: PartialMessage<SNMyActivitiesRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.SNMyActivitiesRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SNMyActivitiesRequest {
    return new SNMyActivitiesRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SNMyActivitiesRequest {
    return new SNMyActivitiesRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SNMyActivitiesRequest {
    return new SNMyActivitiesRequest().fromJsonString(jsonString, options);
  }

  static equals(a: SNMyActivitiesRequest | PlainMessage<SNMyActivitiesRequest> | undefined, b: SNMyActivitiesRequest | PlainMessage<SNMyActivitiesRequest> | undefined): boolean {
    return proto3.util.equals(SNMyActivitiesRequest, a, b);
  }
}

/**
 * @generated from message retrica.SNMyActivitiesResponse
 */
export class SNMyActivitiesResponse extends Message<SNMyActivitiesResponse> {
  /**
   * @generated from field: retrica.ErrorCode error_code = 1;
   */
  errorCode = ErrorCode.UNKNOWN;

  /**
   * @generated from field: repeated retrica.SelfieNetwork.Activity activities = 2;
   */
  activities: SelfieNetwork_Activity[] = [];

  constructor(data?: PartialMessage<SNMyActivitiesResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.SNMyActivitiesResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "error_code", kind: "enum", T: proto3.getEnumType(ErrorCode) },
    { no: 2, name: "activities", kind: "message", T: SelfieNetwork_Activity, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SNMyActivitiesResponse {
    return new SNMyActivitiesResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SNMyActivitiesResponse {
    return new SNMyActivitiesResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SNMyActivitiesResponse {
    return new SNMyActivitiesResponse().fromJsonString(jsonString, options);
  }

  static equals(a: SNMyActivitiesResponse | PlainMessage<SNMyActivitiesResponse> | undefined, b: SNMyActivitiesResponse | PlainMessage<SNMyActivitiesResponse> | undefined): boolean {
    return proto3.util.equals(SNMyActivitiesResponse, a, b);
  }
}

/**
 * /a1/sn/hashtags
 *
 * @generated from message retrica.SNHashtagsRequest
 */
export class SNHashtagsRequest extends Message<SNHashtagsRequest> {
  /**
   * tag name
   *
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * tag type
   *
   * @generated from field: retrica.HashTagType type = 2;
   */
  type = HashTagType.HTT_NONE;

  /**
   * id(timestamp id)
   *
   * @generated from field: string id = 3;
   */
  id = "";

  constructor(data?: PartialMessage<SNHashtagsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.SNHashtagsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "type", kind: "enum", T: proto3.getEnumType(HashTagType) },
    { no: 3, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SNHashtagsRequest {
    return new SNHashtagsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SNHashtagsRequest {
    return new SNHashtagsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SNHashtagsRequest {
    return new SNHashtagsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: SNHashtagsRequest | PlainMessage<SNHashtagsRequest> | undefined, b: SNHashtagsRequest | PlainMessage<SNHashtagsRequest> | undefined): boolean {
    return proto3.util.equals(SNHashtagsRequest, a, b);
  }
}

/**
 * @generated from message retrica.SNHashtagsResponse
 */
export class SNHashtagsResponse extends Message<SNHashtagsResponse> {
  /**
   * @generated from field: retrica.ErrorCode error_code = 1;
   */
  errorCode = ErrorCode.UNKNOWN;

  /**
   * @generated from field: retrica.SelfieNetwork.HashTagWrap tag_wrap = 2;
   */
  tagWrap?: SelfieNetwork_HashTagWrap;

  /**
   * @generated from field: map<string, retrica.Friend> friends_map = 3;
   */
  friendsMap: { [key: string]: Friend } = {};

  constructor(data?: PartialMessage<SNHashtagsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.SNHashtagsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "error_code", kind: "enum", T: proto3.getEnumType(ErrorCode) },
    { no: 2, name: "tag_wrap", kind: "message", T: SelfieNetwork_HashTagWrap },
    { no: 3, name: "friends_map", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "message", T: Friend} },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SNHashtagsResponse {
    return new SNHashtagsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SNHashtagsResponse {
    return new SNHashtagsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SNHashtagsResponse {
    return new SNHashtagsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: SNHashtagsResponse | PlainMessage<SNHashtagsResponse> | undefined, b: SNHashtagsResponse | PlainMessage<SNHashtagsResponse> | undefined): boolean {
    return proto3.util.equals(SNHashtagsResponse, a, b);
  }
}

/**
 * /a1/sn/squad
 *
 * @generated from message retrica.SNSquadRequest
 */
export class SNSquadRequest extends Message<SNSquadRequest> {
  /**
   * @generated from field: string squad_name = 1;
   */
  squadName = "";

  constructor(data?: PartialMessage<SNSquadRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.SNSquadRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "squad_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SNSquadRequest {
    return new SNSquadRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SNSquadRequest {
    return new SNSquadRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SNSquadRequest {
    return new SNSquadRequest().fromJsonString(jsonString, options);
  }

  static equals(a: SNSquadRequest | PlainMessage<SNSquadRequest> | undefined, b: SNSquadRequest | PlainMessage<SNSquadRequest> | undefined): boolean {
    return proto3.util.equals(SNSquadRequest, a, b);
  }
}

/**
 * @generated from message retrica.SNSquadResponse
 */
export class SNSquadResponse extends Message<SNSquadResponse> {
  /**
   * @generated from field: retrica.ErrorCode error_code = 1;
   */
  errorCode = ErrorCode.UNKNOWN;

  /**
   * squad 정보
   *
   * @generated from field: retrica.SelfieNetwork.SquadContainer.Squad squad = 2;
   */
  squad?: SelfieNetwork_SquadContainer_Squad;

  constructor(data?: PartialMessage<SNSquadResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.SNSquadResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "error_code", kind: "enum", T: proto3.getEnumType(ErrorCode) },
    { no: 2, name: "squad", kind: "message", T: SelfieNetwork_SquadContainer_Squad },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SNSquadResponse {
    return new SNSquadResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SNSquadResponse {
    return new SNSquadResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SNSquadResponse {
    return new SNSquadResponse().fromJsonString(jsonString, options);
  }

  static equals(a: SNSquadResponse | PlainMessage<SNSquadResponse> | undefined, b: SNSquadResponse | PlainMessage<SNSquadResponse> | undefined): boolean {
    return proto3.util.equals(SNSquadResponse, a, b);
  }
}

/**
 * /a1/sn/JoinableSquads
 *
 * @generated from message retrica.SNJoinableSquadsRequest
 */
export class SNJoinableSquadsRequest extends Message<SNJoinableSquadsRequest> {
  /**
   * id(timestamp_id)
   *
   * @generated from field: string id = 1;
   */
  id = "";

  constructor(data?: PartialMessage<SNJoinableSquadsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.SNJoinableSquadsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SNJoinableSquadsRequest {
    return new SNJoinableSquadsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SNJoinableSquadsRequest {
    return new SNJoinableSquadsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SNJoinableSquadsRequest {
    return new SNJoinableSquadsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: SNJoinableSquadsRequest | PlainMessage<SNJoinableSquadsRequest> | undefined, b: SNJoinableSquadsRequest | PlainMessage<SNJoinableSquadsRequest> | undefined): boolean {
    return proto3.util.equals(SNJoinableSquadsRequest, a, b);
  }
}

/**
 * @generated from message retrica.SNJoinableSquadsResponse
 */
export class SNJoinableSquadsResponse extends Message<SNJoinableSquadsResponse> {
  /**
   * @generated from field: retrica.ErrorCode error_code = 1;
   */
  errorCode = ErrorCode.UNKNOWN;

  /**
   * 현재 참여하지 있지 않은 추천 Squads
   *
   * @generated from field: repeated retrica.SelfieNetwork.SquadContainer.Squad recommend_squads = 2;
   */
  recommendSquads: SelfieNetwork_SquadContainer_Squad[] = [];

  /**
   * 현재 참여 하고 있지 않은 Squads
   *
   * @generated from field: repeated retrica.SelfieNetwork.SquadContainer.Squad squads = 3;
   */
  squads: SelfieNetwork_SquadContainer_Squad[] = [];

  constructor(data?: PartialMessage<SNJoinableSquadsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.SNJoinableSquadsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "error_code", kind: "enum", T: proto3.getEnumType(ErrorCode) },
    { no: 2, name: "recommend_squads", kind: "message", T: SelfieNetwork_SquadContainer_Squad, repeated: true },
    { no: 3, name: "squads", kind: "message", T: SelfieNetwork_SquadContainer_Squad, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SNJoinableSquadsResponse {
    return new SNJoinableSquadsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SNJoinableSquadsResponse {
    return new SNJoinableSquadsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SNJoinableSquadsResponse {
    return new SNJoinableSquadsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: SNJoinableSquadsResponse | PlainMessage<SNJoinableSquadsResponse> | undefined, b: SNJoinableSquadsResponse | PlainMessage<SNJoinableSquadsResponse> | undefined): boolean {
    return proto3.util.equals(SNJoinableSquadsResponse, a, b);
  }
}

/**
 * /a1/sn/searchSquads
 *
 * @generated from message retrica.SNSearchSquadsRequest
 */
export class SNSearchSquadsRequest extends Message<SNSearchSquadsRequest> {
  /**
   * keyword
   *
   * @generated from field: string keyword = 1;
   */
  keyword = "";

  constructor(data?: PartialMessage<SNSearchSquadsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.SNSearchSquadsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "keyword", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SNSearchSquadsRequest {
    return new SNSearchSquadsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SNSearchSquadsRequest {
    return new SNSearchSquadsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SNSearchSquadsRequest {
    return new SNSearchSquadsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: SNSearchSquadsRequest | PlainMessage<SNSearchSquadsRequest> | undefined, b: SNSearchSquadsRequest | PlainMessage<SNSearchSquadsRequest> | undefined): boolean {
    return proto3.util.equals(SNSearchSquadsRequest, a, b);
  }
}

/**
 * @generated from message retrica.SNSearchSquadsResponse
 */
export class SNSearchSquadsResponse extends Message<SNSearchSquadsResponse> {
  /**
   * @generated from field: retrica.ErrorCode error_code = 1;
   */
  errorCode = ErrorCode.UNKNOWN;

  /**
   * 현재 참여 하고 있지 않은 검색된 Squads
   *
   * @generated from field: repeated retrica.SelfieNetwork.SquadContainer.Squad squads = 2;
   */
  squads: SelfieNetwork_SquadContainer_Squad[] = [];

  constructor(data?: PartialMessage<SNSearchSquadsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.SNSearchSquadsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "error_code", kind: "enum", T: proto3.getEnumType(ErrorCode) },
    { no: 2, name: "squads", kind: "message", T: SelfieNetwork_SquadContainer_Squad, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SNSearchSquadsResponse {
    return new SNSearchSquadsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SNSearchSquadsResponse {
    return new SNSearchSquadsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SNSearchSquadsResponse {
    return new SNSearchSquadsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: SNSearchSquadsResponse | PlainMessage<SNSearchSquadsResponse> | undefined, b: SNSearchSquadsResponse | PlainMessage<SNSearchSquadsResponse> | undefined): boolean {
    return proto3.util.equals(SNSearchSquadsResponse, a, b);
  }
}

/**
 * /a1/sn/mySquads
 *
 * @generated from message retrica.SNMySquadsRequest
 */
export class SNMySquadsRequest extends Message<SNMySquadsRequest> {
  constructor(data?: PartialMessage<SNMySquadsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.SNMySquadsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SNMySquadsRequest {
    return new SNMySquadsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SNMySquadsRequest {
    return new SNMySquadsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SNMySquadsRequest {
    return new SNMySquadsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: SNMySquadsRequest | PlainMessage<SNMySquadsRequest> | undefined, b: SNMySquadsRequest | PlainMessage<SNMySquadsRequest> | undefined): boolean {
    return proto3.util.equals(SNMySquadsRequest, a, b);
  }
}

/**
 * @generated from message retrica.SNMySquadsResponse
 */
export class SNMySquadsResponse extends Message<SNMySquadsResponse> {
  /**
   * @generated from field: retrica.ErrorCode error_code = 1;
   */
  errorCode = ErrorCode.UNKNOWN;

  /**
   * 추천 Squads(가입중인거 제외)
   *
   * @generated from field: repeated retrica.SelfieNetwork.SquadContainer.Squad recommend_squads = 2;
   */
  recommendSquads: SelfieNetwork_SquadContainer_Squad[] = [];

  /**
   * 가입중인 Squads
   *
   * @generated from field: repeated retrica.SelfieNetwork.SquadContainer.Squad joining_squads = 3;
   */
  joiningSquads: SelfieNetwork_SquadContainer_Squad[] = [];

  constructor(data?: PartialMessage<SNMySquadsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.SNMySquadsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "error_code", kind: "enum", T: proto3.getEnumType(ErrorCode) },
    { no: 2, name: "recommend_squads", kind: "message", T: SelfieNetwork_SquadContainer_Squad, repeated: true },
    { no: 3, name: "joining_squads", kind: "message", T: SelfieNetwork_SquadContainer_Squad, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SNMySquadsResponse {
    return new SNMySquadsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SNMySquadsResponse {
    return new SNMySquadsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SNMySquadsResponse {
    return new SNMySquadsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: SNMySquadsResponse | PlainMessage<SNMySquadsResponse> | undefined, b: SNMySquadsResponse | PlainMessage<SNMySquadsResponse> | undefined): boolean {
    return proto3.util.equals(SNMySquadsResponse, a, b);
  }
}

/**
 * /a1/sn/joinSquad
 *
 * @generated from message retrica.SNJoinSquadRequest
 */
export class SNJoinSquadRequest extends Message<SNJoinSquadRequest> {
  /**
   * @generated from field: string squad_name = 1;
   */
  squadName = "";

  constructor(data?: PartialMessage<SNJoinSquadRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.SNJoinSquadRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "squad_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SNJoinSquadRequest {
    return new SNJoinSquadRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SNJoinSquadRequest {
    return new SNJoinSquadRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SNJoinSquadRequest {
    return new SNJoinSquadRequest().fromJsonString(jsonString, options);
  }

  static equals(a: SNJoinSquadRequest | PlainMessage<SNJoinSquadRequest> | undefined, b: SNJoinSquadRequest | PlainMessage<SNJoinSquadRequest> | undefined): boolean {
    return proto3.util.equals(SNJoinSquadRequest, a, b);
  }
}

/**
 * @generated from message retrica.SNJoinSquadResponse
 */
export class SNJoinSquadResponse extends Message<SNJoinSquadResponse> {
  /**
   * SUCCESS 또는 FULL 또는 권한 없음 error.
   *
   * @generated from field: retrica.ErrorCode error_code = 1;
   */
  errorCode = ErrorCode.UNKNOWN;

  /**
   * @generated from field: retrica.SelfieNetwork.SquadContainer.Squad join_squad = 2;
   */
  joinSquad?: SelfieNetwork_SquadContainer_Squad;

  constructor(data?: PartialMessage<SNJoinSquadResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.SNJoinSquadResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "error_code", kind: "enum", T: proto3.getEnumType(ErrorCode) },
    { no: 2, name: "join_squad", kind: "message", T: SelfieNetwork_SquadContainer_Squad },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SNJoinSquadResponse {
    return new SNJoinSquadResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SNJoinSquadResponse {
    return new SNJoinSquadResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SNJoinSquadResponse {
    return new SNJoinSquadResponse().fromJsonString(jsonString, options);
  }

  static equals(a: SNJoinSquadResponse | PlainMessage<SNJoinSquadResponse> | undefined, b: SNJoinSquadResponse | PlainMessage<SNJoinSquadResponse> | undefined): boolean {
    return proto3.util.equals(SNJoinSquadResponse, a, b);
  }
}

/**
 * /a1/sn/createSquad
 *
 * @generated from message retrica.SNCreateSquadRequest
 */
export class SNCreateSquadRequest extends Message<SNCreateSquadRequest> {
  /**
   * @generated from field: string squad_name = 1;
   */
  squadName = "";

  /**
   * @generated from field: string squad_display_name = 2;
   */
  squadDisplayName = "";

  /**
   * @generated from field: string theme = 3;
   */
  theme = "";

  constructor(data?: PartialMessage<SNCreateSquadRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.SNCreateSquadRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "squad_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "squad_display_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "theme", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SNCreateSquadRequest {
    return new SNCreateSquadRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SNCreateSquadRequest {
    return new SNCreateSquadRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SNCreateSquadRequest {
    return new SNCreateSquadRequest().fromJsonString(jsonString, options);
  }

  static equals(a: SNCreateSquadRequest | PlainMessage<SNCreateSquadRequest> | undefined, b: SNCreateSquadRequest | PlainMessage<SNCreateSquadRequest> | undefined): boolean {
    return proto3.util.equals(SNCreateSquadRequest, a, b);
  }
}

/**
 * @generated from message retrica.SNCreateSquadResponse
 */
export class SNCreateSquadResponse extends Message<SNCreateSquadResponse> {
  /**
   * @generated from field: retrica.ErrorCode error_code = 1;
   */
  errorCode = ErrorCode.UNKNOWN;

  /**
   * @generated from field: retrica.SelfieNetwork.SquadContainer.Squad create_squad = 2;
   */
  createSquad?: SelfieNetwork_SquadContainer_Squad;

  constructor(data?: PartialMessage<SNCreateSquadResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.SNCreateSquadResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "error_code", kind: "enum", T: proto3.getEnumType(ErrorCode) },
    { no: 2, name: "create_squad", kind: "message", T: SelfieNetwork_SquadContainer_Squad },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SNCreateSquadResponse {
    return new SNCreateSquadResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SNCreateSquadResponse {
    return new SNCreateSquadResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SNCreateSquadResponse {
    return new SNCreateSquadResponse().fromJsonString(jsonString, options);
  }

  static equals(a: SNCreateSquadResponse | PlainMessage<SNCreateSquadResponse> | undefined, b: SNCreateSquadResponse | PlainMessage<SNCreateSquadResponse> | undefined): boolean {
    return proto3.util.equals(SNCreateSquadResponse, a, b);
  }
}

/**
 * /a1/sn/leaveSquad
 *
 * @generated from message retrica.SNLeaveSquadRequest
 */
export class SNLeaveSquadRequest extends Message<SNLeaveSquadRequest> {
  /**
   * @generated from field: string squad_name = 1;
   */
  squadName = "";

  constructor(data?: PartialMessage<SNLeaveSquadRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.SNLeaveSquadRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "squad_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SNLeaveSquadRequest {
    return new SNLeaveSquadRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SNLeaveSquadRequest {
    return new SNLeaveSquadRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SNLeaveSquadRequest {
    return new SNLeaveSquadRequest().fromJsonString(jsonString, options);
  }

  static equals(a: SNLeaveSquadRequest | PlainMessage<SNLeaveSquadRequest> | undefined, b: SNLeaveSquadRequest | PlainMessage<SNLeaveSquadRequest> | undefined): boolean {
    return proto3.util.equals(SNLeaveSquadRequest, a, b);
  }
}

/**
 * @generated from message retrica.SNLeaveSquadResponse
 */
export class SNLeaveSquadResponse extends Message<SNLeaveSquadResponse> {
  /**
   * @generated from field: retrica.ErrorCode error_code = 1;
   */
  errorCode = ErrorCode.UNKNOWN;

  constructor(data?: PartialMessage<SNLeaveSquadResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.SNLeaveSquadResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "error_code", kind: "enum", T: proto3.getEnumType(ErrorCode) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SNLeaveSquadResponse {
    return new SNLeaveSquadResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SNLeaveSquadResponse {
    return new SNLeaveSquadResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SNLeaveSquadResponse {
    return new SNLeaveSquadResponse().fromJsonString(jsonString, options);
  }

  static equals(a: SNLeaveSquadResponse | PlainMessage<SNLeaveSquadResponse> | undefined, b: SNLeaveSquadResponse | PlainMessage<SNLeaveSquadResponse> | undefined): boolean {
    return proto3.util.equals(SNLeaveSquadResponse, a, b);
  }
}

/**
 * /a1/sn/squadCrews
 *
 * @generated from message retrica.SNSquadCrewsRequest
 */
export class SNSquadCrewsRequest extends Message<SNSquadCrewsRequest> {
  /**
   * @generated from field: string squad_name = 1;
   */
  squadName = "";

  /**
   * id(timestamp_id, joined_at)
   *
   * @generated from field: string id = 2;
   */
  id = "";

  constructor(data?: PartialMessage<SNSquadCrewsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.SNSquadCrewsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "squad_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SNSquadCrewsRequest {
    return new SNSquadCrewsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SNSquadCrewsRequest {
    return new SNSquadCrewsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SNSquadCrewsRequest {
    return new SNSquadCrewsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: SNSquadCrewsRequest | PlainMessage<SNSquadCrewsRequest> | undefined, b: SNSquadCrewsRequest | PlainMessage<SNSquadCrewsRequest> | undefined): boolean {
    return proto3.util.equals(SNSquadCrewsRequest, a, b);
  }
}

/**
 * @generated from message retrica.SNSquadCrewsResponse
 */
export class SNSquadCrewsResponse extends Message<SNSquadCrewsResponse> {
  /**
   * @generated from field: retrica.ErrorCode error_code = 1;
   */
  errorCode = ErrorCode.UNKNOWN;

  /**
   * @generated from field: repeated retrica.SelfieNetwork.SquadContainer.Crew crews = 2;
   */
  crews: SelfieNetwork_SquadContainer_Crew[] = [];

  constructor(data?: PartialMessage<SNSquadCrewsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.SNSquadCrewsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "error_code", kind: "enum", T: proto3.getEnumType(ErrorCode) },
    { no: 2, name: "crews", kind: "message", T: SelfieNetwork_SquadContainer_Crew, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SNSquadCrewsResponse {
    return new SNSquadCrewsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SNSquadCrewsResponse {
    return new SNSquadCrewsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SNSquadCrewsResponse {
    return new SNSquadCrewsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: SNSquadCrewsResponse | PlainMessage<SNSquadCrewsResponse> | undefined, b: SNSquadCrewsResponse | PlainMessage<SNSquadCrewsResponse> | undefined): boolean {
    return proto3.util.equals(SNSquadCrewsResponse, a, b);
  }
}

/**
 * /a1/sn/hotOrNot
 *
 * @generated from message retrica.SNHotOrNotRequest
 */
export class SNHotOrNotRequest extends Message<SNHotOrNotRequest> {
  /**
   * @generated from field: string hash = 1;
   */
  hash = "";

  /**
   * @generated from field: bool is_more = 2;
   */
  isMore = false;

  constructor(data?: PartialMessage<SNHotOrNotRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.SNHotOrNotRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "hash", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "is_more", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SNHotOrNotRequest {
    return new SNHotOrNotRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SNHotOrNotRequest {
    return new SNHotOrNotRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SNHotOrNotRequest {
    return new SNHotOrNotRequest().fromJsonString(jsonString, options);
  }

  static equals(a: SNHotOrNotRequest | PlainMessage<SNHotOrNotRequest> | undefined, b: SNHotOrNotRequest | PlainMessage<SNHotOrNotRequest> | undefined): boolean {
    return proto3.util.equals(SNHotOrNotRequest, a, b);
  }
}

/**
 * @generated from message retrica.SNHotOrNotResponse
 */
export class SNHotOrNotResponse extends Message<SNHotOrNotResponse> {
  /**
   * @generated from field: retrica.ErrorCode error_code = 1;
   */
  errorCode = ErrorCode.UNKNOWN;

  /**
   * @generated from field: string hash = 2;
   */
  hash = "";

  /**
   * 리스트 3개
   *
   * @generated from field: repeated retrica.SelfieNetwork.SelfieWrap wraps = 3;
   */
  wraps: SelfieNetwork_SelfieWrap[] = [];

  /**
   * @generated from field: map<string, retrica.Friend> friends_map = 4;
   */
  friendsMap: { [key: string]: Friend } = {};

  constructor(data?: PartialMessage<SNHotOrNotResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.SNHotOrNotResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "error_code", kind: "enum", T: proto3.getEnumType(ErrorCode) },
    { no: 2, name: "hash", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "wraps", kind: "message", T: SelfieNetwork_SelfieWrap, repeated: true },
    { no: 4, name: "friends_map", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "message", T: Friend} },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SNHotOrNotResponse {
    return new SNHotOrNotResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SNHotOrNotResponse {
    return new SNHotOrNotResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SNHotOrNotResponse {
    return new SNHotOrNotResponse().fromJsonString(jsonString, options);
  }

  static equals(a: SNHotOrNotResponse | PlainMessage<SNHotOrNotResponse> | undefined, b: SNHotOrNotResponse | PlainMessage<SNHotOrNotResponse> | undefined): boolean {
    return proto3.util.equals(SNHotOrNotResponse, a, b);
  }
}

/**
 * /a1/sn/hot
 *
 * @generated from message retrica.SNHotRequest
 */
export class SNHotRequest extends Message<SNHotRequest> {
  /**
   * @generated from field: string user_id = 1;
   */
  userId = "";

  /**
   * @generated from field: repeated string selfie_ids = 2;
   */
  selfieIds: string[] = [];

  constructor(data?: PartialMessage<SNHotRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.SNHotRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "selfie_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SNHotRequest {
    return new SNHotRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SNHotRequest {
    return new SNHotRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SNHotRequest {
    return new SNHotRequest().fromJsonString(jsonString, options);
  }

  static equals(a: SNHotRequest | PlainMessage<SNHotRequest> | undefined, b: SNHotRequest | PlainMessage<SNHotRequest> | undefined): boolean {
    return proto3.util.equals(SNHotRequest, a, b);
  }
}

/**
 * @generated from message retrica.SNHotResponse
 */
export class SNHotResponse extends Message<SNHotResponse> {
  /**
   * @generated from field: retrica.ErrorCode error_code = 1;
   */
  errorCode = ErrorCode.UNKNOWN;

  constructor(data?: PartialMessage<SNHotResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.SNHotResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "error_code", kind: "enum", T: proto3.getEnumType(ErrorCode) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SNHotResponse {
    return new SNHotResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SNHotResponse {
    return new SNHotResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SNHotResponse {
    return new SNHotResponse().fromJsonString(jsonString, options);
  }

  static equals(a: SNHotResponse | PlainMessage<SNHotResponse> | undefined, b: SNHotResponse | PlainMessage<SNHotResponse> | undefined): boolean {
    return proto3.util.equals(SNHotResponse, a, b);
  }
}

/**
 * /a1/markAsRead
 * Content, Comment, AddedMe unread를 read처리한다.
 *
 * @generated from message retrica.MarkAsReadRequest
 */
export class MarkAsReadRequest extends Message<MarkAsReadRequest> {
  /**
   * @generated from field: retrica.UnreadType unread_type = 1;
   */
  unreadType = UnreadType.UT_NONE;

  /**
   * Conent면 content_id, addedMe면 무시, Activity면 timestamp nano
   *
   * @generated from field: string id = 2;
   */
  id = "";

  constructor(data?: PartialMessage<MarkAsReadRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.MarkAsReadRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "unread_type", kind: "enum", T: proto3.getEnumType(UnreadType) },
    { no: 2, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): MarkAsReadRequest {
    return new MarkAsReadRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): MarkAsReadRequest {
    return new MarkAsReadRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): MarkAsReadRequest {
    return new MarkAsReadRequest().fromJsonString(jsonString, options);
  }

  static equals(a: MarkAsReadRequest | PlainMessage<MarkAsReadRequest> | undefined, b: MarkAsReadRequest | PlainMessage<MarkAsReadRequest> | undefined): boolean {
    return proto3.util.equals(MarkAsReadRequest, a, b);
  }
}

/**
 * @generated from message retrica.MarkAsReadResponse
 */
export class MarkAsReadResponse extends Message<MarkAsReadResponse> {
  /**
   * @generated from field: retrica.ErrorCode error_code = 1;
   */
  errorCode = ErrorCode.UNKNOWN;

  /**
   * @generated from field: retrica.Unread DEPRECATED_channel_unread = 2;
   */
  DEPRECATEDChannelUnread?: Unread;

  /**
   * @generated from field: retrica.Unread DEPRECATED_added_me_unread = 3;
   */
  DEPRECATEDAddedMeUnread?: Unread;

  /**
   * @generated from field: retrica.Unread activity_unread = 4;
   */
  activityUnread?: Unread;

  /**
   * @generated from field: retrica.Unread timeline_unread = 5;
   */
  timelineUnread?: Unread;

  constructor(data?: PartialMessage<MarkAsReadResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.MarkAsReadResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "error_code", kind: "enum", T: proto3.getEnumType(ErrorCode) },
    { no: 2, name: "DEPRECATED_channel_unread", kind: "message", T: Unread },
    { no: 3, name: "DEPRECATED_added_me_unread", kind: "message", T: Unread },
    { no: 4, name: "activity_unread", kind: "message", T: Unread },
    { no: 5, name: "timeline_unread", kind: "message", T: Unread },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): MarkAsReadResponse {
    return new MarkAsReadResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): MarkAsReadResponse {
    return new MarkAsReadResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): MarkAsReadResponse {
    return new MarkAsReadResponse().fromJsonString(jsonString, options);
  }

  static equals(a: MarkAsReadResponse | PlainMessage<MarkAsReadResponse> | undefined, b: MarkAsReadResponse | PlainMessage<MarkAsReadResponse> | undefined): boolean {
    return proto3.util.equals(MarkAsReadResponse, a, b);
  }
}

/**
 * /a1/unread
 * addedMe, channel의 unread를 가져온다.
 *
 * @generated from message retrica.UnreadRequest
 */
export class UnreadRequest extends Message<UnreadRequest> {
  constructor(data?: PartialMessage<UnreadRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.UnreadRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UnreadRequest {
    return new UnreadRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UnreadRequest {
    return new UnreadRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UnreadRequest {
    return new UnreadRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UnreadRequest | PlainMessage<UnreadRequest> | undefined, b: UnreadRequest | PlainMessage<UnreadRequest> | undefined): boolean {
    return proto3.util.equals(UnreadRequest, a, b);
  }
}

/**
 * @generated from message retrica.UnreadResponse
 */
export class UnreadResponse extends Message<UnreadResponse> {
  /**
   * @generated from field: retrica.ErrorCode error_code = 1;
   */
  errorCode = ErrorCode.UNKNOWN;

  /**
   * @generated from field: retrica.Unread DEPRECATED_channel_unread = 2;
   */
  DEPRECATEDChannelUnread?: Unread;

  /**
   * @generated from field: retrica.Unread DEPRECATED_added_me_unread = 3;
   */
  DEPRECATEDAddedMeUnread?: Unread;

  /**
   * @generated from field: retrica.Unread activity_unread = 4;
   */
  activityUnread?: Unread;

  /**
   * @generated from field: retrica.Unread timeline_unread = 5;
   */
  timelineUnread?: Unread;

  constructor(data?: PartialMessage<UnreadResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.UnreadResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "error_code", kind: "enum", T: proto3.getEnumType(ErrorCode) },
    { no: 2, name: "DEPRECATED_channel_unread", kind: "message", T: Unread },
    { no: 3, name: "DEPRECATED_added_me_unread", kind: "message", T: Unread },
    { no: 4, name: "activity_unread", kind: "message", T: Unread },
    { no: 5, name: "timeline_unread", kind: "message", T: Unread },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UnreadResponse {
    return new UnreadResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UnreadResponse {
    return new UnreadResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UnreadResponse {
    return new UnreadResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UnreadResponse | PlainMessage<UnreadResponse> | undefined, b: UnreadResponse | PlainMessage<UnreadResponse> | undefined): boolean {
    return proto3.util.equals(UnreadResponse, a, b);
  }
}

/**
 * /a1/sendCloudContent
 * MM 사진을 저장한다.
 *
 * @generated from message retrica.SendCloudContentRequest
 */
export class SendCloudContentRequest extends Message<SendCloudContentRequest> {
  /**
   * Client에 만든 Content의 Unique ID: timestamp nano#filesize length
   *
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * 전송할 Content
   *
   * @generated from field: retrica.Content content = 2;
   */
  content?: Content;

  constructor(data?: PartialMessage<SendCloudContentRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.SendCloudContentRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "content", kind: "message", T: Content },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SendCloudContentRequest {
    return new SendCloudContentRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SendCloudContentRequest {
    return new SendCloudContentRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SendCloudContentRequest {
    return new SendCloudContentRequest().fromJsonString(jsonString, options);
  }

  static equals(a: SendCloudContentRequest | PlainMessage<SendCloudContentRequest> | undefined, b: SendCloudContentRequest | PlainMessage<SendCloudContentRequest> | undefined): boolean {
    return proto3.util.equals(SendCloudContentRequest, a, b);
  }
}

/**
 * @generated from message retrica.SendCloudContentResponse
 */
export class SendCloudContentResponse extends Message<SendCloudContentResponse> {
  /**
   * SUCCESS
   *
   * @generated from field: retrica.ErrorCode error_code = 1;
   */
  errorCode = ErrorCode.UNKNOWN;

  /**
   * @generated from field: retrica.CloudContent cloud_content = 2;
   */
  cloudContent?: CloudContent;

  constructor(data?: PartialMessage<SendCloudContentResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.SendCloudContentResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "error_code", kind: "enum", T: proto3.getEnumType(ErrorCode) },
    { no: 2, name: "cloud_content", kind: "message", T: CloudContent },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SendCloudContentResponse {
    return new SendCloudContentResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SendCloudContentResponse {
    return new SendCloudContentResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SendCloudContentResponse {
    return new SendCloudContentResponse().fromJsonString(jsonString, options);
  }

  static equals(a: SendCloudContentResponse | PlainMessage<SendCloudContentResponse> | undefined, b: SendCloudContentResponse | PlainMessage<SendCloudContentResponse> | undefined): boolean {
    return proto3.util.equals(SendCloudContentResponse, a, b);
  }
}

/**
 * /a1/deleteCloudContents
 * MM 삭제한다.
 *
 * @generated from message retrica.DeleteCloudContentsRequest
 */
export class DeleteCloudContentsRequest extends Message<DeleteCloudContentsRequest> {
  /**
   * @generated from field: repeated string ids = 1;
   */
  ids: string[] = [];

  constructor(data?: PartialMessage<DeleteCloudContentsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.DeleteCloudContentsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeleteCloudContentsRequest {
    return new DeleteCloudContentsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteCloudContentsRequest {
    return new DeleteCloudContentsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeleteCloudContentsRequest {
    return new DeleteCloudContentsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: DeleteCloudContentsRequest | PlainMessage<DeleteCloudContentsRequest> | undefined, b: DeleteCloudContentsRequest | PlainMessage<DeleteCloudContentsRequest> | undefined): boolean {
    return proto3.util.equals(DeleteCloudContentsRequest, a, b);
  }
}

/**
 * @generated from message retrica.DeleteCloudContentsResponse
 */
export class DeleteCloudContentsResponse extends Message<DeleteCloudContentsResponse> {
  /**
   * SUCCESS
   *
   * @generated from field: retrica.ErrorCode error_code = 1;
   */
  errorCode = ErrorCode.UNKNOWN;

  /**
   * 삭제 완료된 ids
   *
   * @generated from field: repeated string ids = 2;
   */
  ids: string[] = [];

  constructor(data?: PartialMessage<DeleteCloudContentsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.DeleteCloudContentsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "error_code", kind: "enum", T: proto3.getEnumType(ErrorCode) },
    { no: 2, name: "ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeleteCloudContentsResponse {
    return new DeleteCloudContentsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteCloudContentsResponse {
    return new DeleteCloudContentsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeleteCloudContentsResponse {
    return new DeleteCloudContentsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: DeleteCloudContentsResponse | PlainMessage<DeleteCloudContentsResponse> | undefined, b: DeleteCloudContentsResponse | PlainMessage<DeleteCloudContentsResponse> | undefined): boolean {
    return proto3.util.equals(DeleteCloudContentsResponse, a, b);
  }
}

/**
 * /a1/cloudContents
 * MM 사진 리스트를 내려준다 (paging)
 *
 * @generated from message retrica.CloudContentsRequest
 */
export class CloudContentsRequest extends Message<CloudContentsRequest> {
  /**
   * offset 으로 이용(안넣어주면 처음부터 가져옴.)
   *
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: int64 count = 2;
   */
  count = protoInt64.zero;

  constructor(data?: PartialMessage<CloudContentsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.CloudContentsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CloudContentsRequest {
    return new CloudContentsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CloudContentsRequest {
    return new CloudContentsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CloudContentsRequest {
    return new CloudContentsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CloudContentsRequest | PlainMessage<CloudContentsRequest> | undefined, b: CloudContentsRequest | PlainMessage<CloudContentsRequest> | undefined): boolean {
    return proto3.util.equals(CloudContentsRequest, a, b);
  }
}

/**
 * @generated from message retrica.CloudContentsResponse
 */
export class CloudContentsResponse extends Message<CloudContentsResponse> {
  /**
   * SUCCESS
   *
   * @generated from field: retrica.ErrorCode error_code = 1;
   */
  errorCode = ErrorCode.UNKNOWN;

  /**
   * @generated from field: repeated retrica.CloudContent cloud_contents = 2;
   */
  cloudContents: CloudContent[] = [];

  /**
   * @generated from field: bool end_of_content = 3;
   */
  endOfContent = false;

  constructor(data?: PartialMessage<CloudContentsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.CloudContentsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "error_code", kind: "enum", T: proto3.getEnumType(ErrorCode) },
    { no: 2, name: "cloud_contents", kind: "message", T: CloudContent, repeated: true },
    { no: 3, name: "end_of_content", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CloudContentsResponse {
    return new CloudContentsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CloudContentsResponse {
    return new CloudContentsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CloudContentsResponse {
    return new CloudContentsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CloudContentsResponse | PlainMessage<CloudContentsResponse> | undefined, b: CloudContentsResponse | PlainMessage<CloudContentsResponse> | undefined): boolean {
    return proto3.util.equals(CloudContentsResponse, a, b);
  }
}

/**
 * /a1/retrinit
 * 시작시 가져올 데이터를 내려준다.
 *
 * @generated from message retrica.RetrinitRequest
 */
export class RetrinitRequest extends Message<RetrinitRequest> {
  constructor(data?: PartialMessage<RetrinitRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.RetrinitRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RetrinitRequest {
    return new RetrinitRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RetrinitRequest {
    return new RetrinitRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RetrinitRequest {
    return new RetrinitRequest().fromJsonString(jsonString, options);
  }

  static equals(a: RetrinitRequest | PlainMessage<RetrinitRequest> | undefined, b: RetrinitRequest | PlainMessage<RetrinitRequest> | undefined): boolean {
    return proto3.util.equals(RetrinitRequest, a, b);
  }
}

/**
 * @generated from message retrica.RetrinitResponse
 */
export class RetrinitResponse extends Message<RetrinitResponse> {
  /**
   * @generated from field: retrica.ErrorCode error_code = 1;
   */
  errorCode = ErrorCode.UNKNOWN;

  /**
   * @generated from field: retrica.Unread DEPRECATED_activity_unread = 2;
   */
  DEPRECATEDActivityUnread?: Unread;

  /**
   * @generated from field: retrica.Unread DEPRECATED_timeline_unread = 3;
   */
  DEPRECATEDTimelineUnread?: Unread;

  /**
   * @generated from field: bool DEPRECATED_display_grid = 4;
   */
  DEPRECATEDDisplayGrid = false;

  /**
   * @generated from field: retrica.RetrinitResponse.LikeResource DEPRECATED_like_resource = 5;
   */
  DEPRECATEDLikeResource?: RetrinitResponse_LikeResource;

  /**
   * @generated from field: string DEPRECATED_hotornot_hash = 6;
   */
  DEPRECATEDHotornotHash = "";

  /**
   * @generated from field: bool DEPRECATED_display_banner = 7;
   */
  DEPRECATEDDisplayBanner = false;

  constructor(data?: PartialMessage<RetrinitResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.RetrinitResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "error_code", kind: "enum", T: proto3.getEnumType(ErrorCode) },
    { no: 2, name: "DEPRECATED_activity_unread", kind: "message", T: Unread },
    { no: 3, name: "DEPRECATED_timeline_unread", kind: "message", T: Unread },
    { no: 4, name: "DEPRECATED_display_grid", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 5, name: "DEPRECATED_like_resource", kind: "message", T: RetrinitResponse_LikeResource },
    { no: 6, name: "DEPRECATED_hotornot_hash", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "DEPRECATED_display_banner", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RetrinitResponse {
    return new RetrinitResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RetrinitResponse {
    return new RetrinitResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RetrinitResponse {
    return new RetrinitResponse().fromJsonString(jsonString, options);
  }

  static equals(a: RetrinitResponse | PlainMessage<RetrinitResponse> | undefined, b: RetrinitResponse | PlainMessage<RetrinitResponse> | undefined): boolean {
    return proto3.util.equals(RetrinitResponse, a, b);
  }
}

/**
 * @generated from message retrica.RetrinitResponse.LikeResource
 */
export class RetrinitResponse_LikeResource extends Message<RetrinitResponse_LikeResource> {
  /**
   * @generated from field: string fingerprint = 1;
   */
  fingerprint = "";

  /**
   * @generated from field: string meta = 2;
   */
  meta = "";

  /**
   * @generated from field: string url = 3;
   */
  url = "";

  constructor(data?: PartialMessage<RetrinitResponse_LikeResource>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.RetrinitResponse.LikeResource";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "fingerprint", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "meta", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RetrinitResponse_LikeResource {
    return new RetrinitResponse_LikeResource().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RetrinitResponse_LikeResource {
    return new RetrinitResponse_LikeResource().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RetrinitResponse_LikeResource {
    return new RetrinitResponse_LikeResource().fromJsonString(jsonString, options);
  }

  static equals(a: RetrinitResponse_LikeResource | PlainMessage<RetrinitResponse_LikeResource> | undefined, b: RetrinitResponse_LikeResource | PlainMessage<RetrinitResponse_LikeResource> | undefined): boolean {
    return proto3.util.equals(RetrinitResponse_LikeResource, a, b);
  }
}

/**
 * /1/re/facebookSignup
 * Facebook으로 가입
 *
 * @generated from message retrica.FacebookSignupRequest
 */
export class FacebookSignupRequest extends Message<FacebookSignupRequest> {
  /**
   * @generated from field: string facebook_access_token = 1;
   */
  facebookAccessToken = "";

  /**
   * @generated from field: string username = 2;
   */
  username = "";

  /**
   * @generated from field: string push_token = 3;
   */
  pushToken = "";

  /**
   * DEPRECATED
   *
   * @generated from field: string DEPRECATED_device_model = 4;
   */
  DEPRECATEDDeviceModel = "";

  constructor(data?: PartialMessage<FacebookSignupRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.FacebookSignupRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "facebook_access_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "username", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "push_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "DEPRECATED_device_model", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FacebookSignupRequest {
    return new FacebookSignupRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FacebookSignupRequest {
    return new FacebookSignupRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FacebookSignupRequest {
    return new FacebookSignupRequest().fromJsonString(jsonString, options);
  }

  static equals(a: FacebookSignupRequest | PlainMessage<FacebookSignupRequest> | undefined, b: FacebookSignupRequest | PlainMessage<FacebookSignupRequest> | undefined): boolean {
    return proto3.util.equals(FacebookSignupRequest, a, b);
  }
}

/**
 * @generated from message retrica.FacebookSignupResponse
 */
export class FacebookSignupResponse extends Message<FacebookSignupResponse> {
  /**
   * SUCCESS, INVALID_USERNAME, USERNAME_ALREADY_TAKEN, FACEBOOK_TOKEN_INVALID
   *
   * @generated from field: retrica.ErrorCode error_code = 1;
   */
  errorCode = ErrorCode.UNKNOWN;

  /**
   * @generated from field: retrica.Account account = 2;
   */
  account?: Account;

  /**
   * DEPRECATED
   *
   * @generated from field: retrica.FriendsWrap DEPRECATED_friends_wrap = 3;
   */
  DEPRECATEDFriendsWrap?: FriendsWrap;

  constructor(data?: PartialMessage<FacebookSignupResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.FacebookSignupResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "error_code", kind: "enum", T: proto3.getEnumType(ErrorCode) },
    { no: 2, name: "account", kind: "message", T: Account },
    { no: 3, name: "DEPRECATED_friends_wrap", kind: "message", T: FriendsWrap },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FacebookSignupResponse {
    return new FacebookSignupResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FacebookSignupResponse {
    return new FacebookSignupResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FacebookSignupResponse {
    return new FacebookSignupResponse().fromJsonString(jsonString, options);
  }

  static equals(a: FacebookSignupResponse | PlainMessage<FacebookSignupResponse> | undefined, b: FacebookSignupResponse | PlainMessage<FacebookSignupResponse> | undefined): boolean {
    return proto3.util.equals(FacebookSignupResponse, a, b);
  }
}

/**
 * /1/re/facebookLogin
 * Facebook으로 로그인
 *
 * @generated from message retrica.FacebookLoginRequest
 */
export class FacebookLoginRequest extends Message<FacebookLoginRequest> {
  /**
   * @generated from field: string facebook_access_token = 1;
   */
  facebookAccessToken = "";

  /**
   * @generated from field: string push_token = 2;
   */
  pushToken = "";

  /**
   * DEPRECATED
   *
   * @generated from field: string DEPRECATED_device_model = 3;
   */
  DEPRECATEDDeviceModel = "";

  constructor(data?: PartialMessage<FacebookLoginRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.FacebookLoginRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "facebook_access_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "push_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "DEPRECATED_device_model", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FacebookLoginRequest {
    return new FacebookLoginRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FacebookLoginRequest {
    return new FacebookLoginRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FacebookLoginRequest {
    return new FacebookLoginRequest().fromJsonString(jsonString, options);
  }

  static equals(a: FacebookLoginRequest | PlainMessage<FacebookLoginRequest> | undefined, b: FacebookLoginRequest | PlainMessage<FacebookLoginRequest> | undefined): boolean {
    return proto3.util.equals(FacebookLoginRequest, a, b);
  }
}

/**
 * @generated from message retrica.FacebookLoginResponse
 */
export class FacebookLoginResponse extends Message<FacebookLoginResponse> {
  /**
   * SUCCESS, FACEBOOK_TOKEN_INVALID, FACEBOOK_SIGNUP_REDIRECT
   *
   * @generated from field: retrica.ErrorCode error_code = 1;
   */
  errorCode = ErrorCode.UNKNOWN;

  /**
   * @generated from field: retrica.Account account = 2;
   */
  account?: Account;

  constructor(data?: PartialMessage<FacebookLoginResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.FacebookLoginResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "error_code", kind: "enum", T: proto3.getEnumType(ErrorCode) },
    { no: 2, name: "account", kind: "message", T: Account },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FacebookLoginResponse {
    return new FacebookLoginResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FacebookLoginResponse {
    return new FacebookLoginResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FacebookLoginResponse {
    return new FacebookLoginResponse().fromJsonString(jsonString, options);
  }

  static equals(a: FacebookLoginResponse | PlainMessage<FacebookLoginResponse> | undefined, b: FacebookLoginResponse | PlainMessage<FacebookLoginResponse> | undefined): boolean {
    return proto3.util.equals(FacebookLoginResponse, a, b);
  }
}

/**
 * /1/re/vkontakteSignup
 * VK로 가입
 *
 * @generated from message retrica.VkontakteSignupRequest
 */
export class VkontakteSignupRequest extends Message<VkontakteSignupRequest> {
  /**
   * @generated from field: string vkontakte_access_token = 1;
   */
  vkontakteAccessToken = "";

  /**
   * @generated from field: string push_token = 2;
   */
  pushToken = "";

  /**
   * DEPRECATED
   *
   * @generated from field: string DEPRECATED_device_model = 3;
   */
  DEPRECATEDDeviceModel = "";

  constructor(data?: PartialMessage<VkontakteSignupRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.VkontakteSignupRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "vkontakte_access_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "push_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "DEPRECATED_device_model", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): VkontakteSignupRequest {
    return new VkontakteSignupRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): VkontakteSignupRequest {
    return new VkontakteSignupRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): VkontakteSignupRequest {
    return new VkontakteSignupRequest().fromJsonString(jsonString, options);
  }

  static equals(a: VkontakteSignupRequest | PlainMessage<VkontakteSignupRequest> | undefined, b: VkontakteSignupRequest | PlainMessage<VkontakteSignupRequest> | undefined): boolean {
    return proto3.util.equals(VkontakteSignupRequest, a, b);
  }
}

/**
 * @generated from message retrica.VkontakteSignupResponse
 */
export class VkontakteSignupResponse extends Message<VkontakteSignupResponse> {
  /**
   * SUCCESS, INVALID_USERNAME, USERNAME_ALREADY_TAKEN, VKONTAKTE_TOKEN_INVALID
   *
   * @generated from field: retrica.ErrorCode error_code = 1;
   */
  errorCode = ErrorCode.UNKNOWN;

  /**
   * @generated from field: retrica.Account account = 2;
   */
  account?: Account;

  /**
   * DEPRECATED
   *
   * @generated from field: retrica.FriendsWrap DEPRECATED_friends_wrap = 3;
   */
  DEPRECATEDFriendsWrap?: FriendsWrap;

  constructor(data?: PartialMessage<VkontakteSignupResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.VkontakteSignupResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "error_code", kind: "enum", T: proto3.getEnumType(ErrorCode) },
    { no: 2, name: "account", kind: "message", T: Account },
    { no: 3, name: "DEPRECATED_friends_wrap", kind: "message", T: FriendsWrap },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): VkontakteSignupResponse {
    return new VkontakteSignupResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): VkontakteSignupResponse {
    return new VkontakteSignupResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): VkontakteSignupResponse {
    return new VkontakteSignupResponse().fromJsonString(jsonString, options);
  }

  static equals(a: VkontakteSignupResponse | PlainMessage<VkontakteSignupResponse> | undefined, b: VkontakteSignupResponse | PlainMessage<VkontakteSignupResponse> | undefined): boolean {
    return proto3.util.equals(VkontakteSignupResponse, a, b);
  }
}

/**
 * /1/re/vkontakteLogin
 * VK로 로그인
 *
 * @generated from message retrica.VkontakteLoginRequest
 */
export class VkontakteLoginRequest extends Message<VkontakteLoginRequest> {
  /**
   * @generated from field: string vkontakte_access_token = 1;
   */
  vkontakteAccessToken = "";

  /**
   * @generated from field: string push_token = 2;
   */
  pushToken = "";

  /**
   * DEPRECATED
   *
   * @generated from field: string DEPRECATED_device_model = 3;
   */
  DEPRECATEDDeviceModel = "";

  constructor(data?: PartialMessage<VkontakteLoginRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.VkontakteLoginRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "vkontakte_access_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "push_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "DEPRECATED_device_model", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): VkontakteLoginRequest {
    return new VkontakteLoginRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): VkontakteLoginRequest {
    return new VkontakteLoginRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): VkontakteLoginRequest {
    return new VkontakteLoginRequest().fromJsonString(jsonString, options);
  }

  static equals(a: VkontakteLoginRequest | PlainMessage<VkontakteLoginRequest> | undefined, b: VkontakteLoginRequest | PlainMessage<VkontakteLoginRequest> | undefined): boolean {
    return proto3.util.equals(VkontakteLoginRequest, a, b);
  }
}

/**
 * @generated from message retrica.VkontakteLoginResponse
 */
export class VkontakteLoginResponse extends Message<VkontakteLoginResponse> {
  /**
   * SUCCESS, VKONTAKTE_TOKEN_INVALID, VKONTAKTE_SIGNUP_REDIRECT
   *
   * @generated from field: retrica.ErrorCode error_code = 1;
   */
  errorCode = ErrorCode.UNKNOWN;

  /**
   * @generated from field: retrica.Account account = 2;
   */
  account?: Account;

  constructor(data?: PartialMessage<VkontakteLoginResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "retrica.VkontakteLoginResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "error_code", kind: "enum", T: proto3.getEnumType(ErrorCode) },
    { no: 2, name: "account", kind: "message", T: Account },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): VkontakteLoginResponse {
    return new VkontakteLoginResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): VkontakteLoginResponse {
    return new VkontakteLoginResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): VkontakteLoginResponse {
    return new VkontakteLoginResponse().fromJsonString(jsonString, options);
  }

  static equals(a: VkontakteLoginResponse | PlainMessage<VkontakteLoginResponse> | undefined, b: VkontakteLoginResponse | PlainMessage<VkontakteLoginResponse> | undefined): boolean {
    return proto3.util.equals(VkontakteLoginResponse, a, b);
  }
}

