import { SubTitle } from 'components/PageTitles';
import { useFetchLookUpTables } from 'hooks/useFetchLookUpTables';
import { useRef } from 'react';
import { LookUpTableListView } from './components/LookUpTableListView';
import { NewLookUpTableForm } from './components/NewLookUpTableForm';
import { LookUpTable } from 'types/proto/retriver-struct_pb';
import { Union } from 'utils/utils';

export function New() {
  const lastItemRef = useRef(null);
  const [list, setList, hasMore] = useFetchLookUpTables({
    lastItemRef: lastItemRef,
  });

  return (
    <>
      <SubTitle>Add a Lookup Table</SubTitle>
      <NewLookUpTableForm
        onAdd={(lut: LookUpTable) => {
          setList((prev) => Union([lut], prev) as LookUpTable[]);
        }}
      />
      <hr style={{ borderTop: 'grey' }} />
      <LookUpTableListView
        list={list}
        hasMore={hasMore}
        lastItemRef={lastItemRef}
        onDelete={(key: React.Key) =>
          setList((prev) => prev.filter((lut: LookUpTable) => lut.id !== key))
        }
      />
    </>
  );
}
