import { Tag } from 'antd';
import { UserRole, UserRoles } from 'types/user';

function tagColor(role: UserRole) {
  switch (role) {
    case UserRoles.Admin:
      return 'red';
    case UserRoles.Creator:
      return 'cyan';
  }
}

export const RoleTag = (props: {
  role: string;
  closable?: boolean;
  onClose?: (tag: string) => void;
  onClick?: (tag: string) => void;
}) => {
  const color = tagColor(props.role as UserRole);
  return (
    <Tag
      color={color}
      closable={props.closable}
      onClose={() => props.onClose?.(props.role)}
      onClick={() => props.onClick?.(props.role)}
    >
      {props.role}
    </Tag>
  );
};
