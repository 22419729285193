import { useFetchPacks } from 'hooks/useFetchPacks';
import { PackList, Selections } from 'pages/Packs/components/PackList';
import { SearchPackForm } from 'pages/Search/components/SearchPackForm';
import { useRef, useState } from 'react';
import { MaterialType } from 'types/material';
import viewStyle from 'styles/view.module.css';

export interface SearchPackViewProps {
  selections?: Selections;
}

export function SearchPackView(props: SearchPackViewProps) {
  const [query, setQuery] = useState<{
    id?: string;
    title?: string;
    type?: string;
    authorId?: string;
    materialType?: MaterialType;
  }>({});
  const lastItemRef = useRef(null);

  const [list, , hasMore] = useFetchPacks({ ...query, lastItemRef });

  const onSubmit = (values: {
    id?: string;
    title?: string;
    type?: string;
    authorId?: string;
    materialType?: string;
  }) => {
    if (values.id && values.id.length > 0) {
      setQuery({ id: values.id });
    } else {
      const mType =
        values.materialType && values.materialType !== 'All'
          ? (values.materialType.toLowerCase() as MaterialType)
          : undefined;
      setQuery({
        title: values.title,
        type: values.type,
        authorId: values.authorId,
        materialType: mType,
      });
    }
  };

  return (
    <>
      <SearchPackForm onSubmit={onSubmit} />
      <PackList
        className={`${viewStyle.viewbox} ${viewStyle.wrap} ${viewStyle.maxHeight600}`}
        list={list}
        itemSize="compact"
        selections={props.selections}
        lastItemRef={lastItemRef}
        hasMore={hasMore}
      />
    </>
  );
}
