import { Badge, Space } from 'antd';

function MaterialLegend() {
  return (
    <Space size={10}>
      <Badge color="var(--template-badge-color)" text="Template" />
      <Badge color="var(--layout-badge-color)" text="Layout" />
      <Badge color="var(--sticker-badge-color)" text="Sticker" />
      <Badge color="var(--font-badge-color)" text="Font" />
      <Badge color="var(--background-badge-color)" text="Background" />
      <Badge color="var(--placeholder-badge-color)" text="Placeholder" />
      <Badge color="var(--color-badge-color)" text="Color" />
      <Badge color="var(--text-badge-color)" text="Text" />
      <Badge color="var(--combo-badge-color)" text="Combo" />
    </Space>
  );
}

const exports = MaterialLegend;
export default exports;
