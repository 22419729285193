import { BundleListView } from './BundleListView';
import { BundlePool } from './BundlePool';

export function BundleListEditView() {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        height: '90vh',
      }}
    >
      <BundleListView />
      <div style={{ width: '20px' }} />
      <BundlePool />
    </div>
  );
}
