import { Button, Popover, Space } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { HexAlphaColorPicker, HexColorInput } from 'react-colorful';
import { PlusOutlined } from '@ant-design/icons';

export interface ColorPickerProps {
  onChange?: (value: string) => void;
  onAddColor?: (value: string) => void;
}

export function ColorPicker(props: ColorPickerProps) {
  const [value, setValue] = useState<string>('');
  const colorDivRef = useRef<HTMLDivElement>(null);

  const handleChange = (newColor: string) => {
    setValue(newColor);
    props.onChange?.(newColor);
  };

  useEffect(() => {
    if (colorDivRef.current == null) {
      return;
    }
    colorDivRef.current.style.backgroundColor = value;
  }, [value]);

  return (
    <Popover
      placement="rightBottom"
      trigger="click"
      content={
        <Space size="large">
          <div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: '1rem',
              }}
            >
              <HexColorInput
                alpha
                prefixed
                color={value}
                onChange={handleChange}
              />
              {props.onAddColor && (
                <Button
                  shape="circle"
                  icon={<PlusOutlined />}
                  size="small"
                  style={{ marginLeft: '1rem' }}
                  onClick={() => props.onAddColor?.(value)}
                />
              )}
            </div>
            <HexAlphaColorPicker color={value} onChange={handleChange} />
          </div>
        </Space>
      }
    >
      <div
        ref={colorDivRef}
        style={{
          width: '20px',
          height: '20px',
          borderStyle: 'solid',
          borderWidth: '1px',
          borderColor: 'var(--border-color)',
        }}
      />
    </Popover>
  );
}
