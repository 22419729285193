import viewStyle from 'styles/view.module.css';
import useNotification from 'antd/es/notification/useNotification';
import { ThumbnailView } from 'components/Thumbnail/ThumbnailView';
import { ThumbnailSizes } from 'components/Thumbnail/Constants';
import { Button, Popover, Tag } from 'antd';
import RawJson from 'utils/RawJson';
import { abbStr } from 'types/material';
import { CopyOutlined, ExpandOutlined } from '@ant-design/icons';
import { copyId } from 'types/interfaces';
import { PackView, PackViewProps } from './PackView';

export function PackCompactView(props: PackViewProps) {
  let p = props.pack;
  const [noti, ctxHolder] = useNotification();
  let mainClassName =
    `${viewStyle.viewitem} ${viewStyle.middle}` +
    (props.selected ? ` ${viewStyle.selected}` : '');

  return (
    <>
      {ctxHolder}
      <div
        className={mainClassName}
        style={{
          flexDirection: 'row',
          justifyContent: 'start',
        }}
        onClick={() => props.onClick?.(p)}
      >
        <ThumbnailView
          size={ThumbnailSizes.compact}
          src={p.thumbnailUrl ?? ''}
          preview={false}
          isFree={p.isFree}
          // style={{ width: 50, height: 50 }}
        />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-around',
            height: '100%',
            marginLeft: '1rem',
          }}
        >
          {p.title}
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}
            onClick={(e) => e.stopPropagation()}
          >
            <Popover content={RawJson(p)} trigger="click">
              <Tag color={`var(--pack-badge-color)`} style={{ marginRight: 0 }}>
                <span style={{ fontSize: '0.7rem' }}>
                  {abbStr(p.materialType)}
                </span>
              </Tag>
            </Popover>
            <Button size="small" type="link" onClick={(e) => copyId(p, noti)}>
              <CopyOutlined />
            </Button>
            <Popover
              content={<PackView pack={props.pack} size={'full'} />}
              trigger="click"
            >
              <Button size="small" type="link" style={{ margin: -10 }}>
                <ExpandOutlined />
              </Button>
            </Popover>
          </div>
        </div>
      </div>
    </>
  );
}
