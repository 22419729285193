export const ThumbnailSizes = {
  full: {
    width: 160,
    height: 160,
  },
  half: {
    width: 160,
    height: 80,
  },
  compact: {
    width: 80,
    height: 80,
  },
  mini: {
    width: 40,
    height: 40,
  },
} as const;
export type ThumbnailSize =
  (typeof ThumbnailSizes)[keyof typeof ThumbnailSizes];
