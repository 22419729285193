import React from 'react';
import { MaterialView } from 'components/MaterialViews/MaterialView';
import viewStyle from 'styles/view.module.css';
import { Material } from 'types/interfaces';
import { Input } from 'antd';
import useNotification from 'antd/es/notification/useNotification';

export interface DictElement {
  material?: Material;
  key: string;
  id: string;
}

interface ByDictPreviewProps {
  dict: DictElement[];
  onChangeKey: (index: number, newKey: string) => void;
  onRemove: (id: string) => void;
  onItemMove: (src: number, dest: number) => void;
}

export function PreviewByDict(props: ByDictPreviewProps) {
  const [noti, ctxHolder] = useNotification();

  const keySet = props.dict.map((elem) => elem.key);

  return (
    <React.Fragment>
      {ctxHolder}
      <div
        className={`${viewStyle.viewbox} ${viewStyle.wrap} ${viewStyle.maxHeight600}`}
      >
        {props.dict.map((elem, index) => {
          return (
            elem.material && (
              <div
                key={elem.id}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <Input
                  size="small"
                  style={{
                    width: '80px',
                    marginTop: '0.2rem',
                  }}
                  maxLength={1}
                  value={elem.key}
                  onChange={(e) => {
                    const newKey = e.target.value;
                    if (keySet.includes(newKey)) {
                      noti.error({
                        message: 'Duplicated key',
                        description: `The key "${newKey}" is already assigned`,
                        placement: 'top',
                        duration: 2,
                      });
                      return;
                    }
                    props.onChangeKey(index, e.target.value);
                  }}
                />
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <MaterialView
                    size="compact"
                    material={elem.material}
                    editable={false}
                    draggable={false}
                  />
                </div>
              </div>
            )
          );
        })}
      </div>
    </React.Fragment>
  );
}
