import { CheckOutlined, FileAddOutlined } from '@ant-design/icons';
import { Button, Form, Input, Space, Upload } from 'antd';
import { UploadFile } from 'antd/lib';
import { useForm } from 'antd/lib/form/Form';
import { FileUploader } from 'components/FileUploader';
import { useRef, useState } from 'react';
import {
  LookUpTableDataAcceptTypes,
  LookUpTableImageAcceptTypes,
} from 'types/material';
import { LookUpTable } from 'types/proto/retriver-struct_pb';
import { ulid } from 'ulid';
import componentStyle from 'styles/common.module.css';
import { Retriver } from 'api/retriver/retriver';
import { NewAddLookUpTableRequest } from 'api/retriver/requestFactory/lookuptables';

export interface NewLookUpTableFormProps {
  onAdd: (lut: LookUpTable) => void;
}

export function NewLookUpTableForm(props: NewLookUpTableFormProps) {
  const [form] = useForm();
  const [id, setId] = useState<string>(ulid());
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [previewUrl, setPreviewUrl] = useState<string>();
  const [previewInputDisabled, setPreviewInputDisabled] =
    useState<boolean>(false);
  const [originUrl, setOriginUrl] = useState<string>();
  const [originInputDisabled, setOriginInputDisabled] =
    useState<boolean>(false);
  const [lutUrl, setLutUrl] = useState<string>('');
  const [lutInputDisabled, setLutInputDisabled] = useState<boolean>(false);
  const lutBytes = useRef<Uint8Array>();

  const beforeUpload = async (file: Blob) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      if (e.target?.result) {
        lutBytes.current = new Uint8Array(e.target.result as ArrayBuffer);
        setLutInputDisabled(true);
      }
    };
    reader.readAsArrayBuffer(file);
    return false;
  };

  const onSubmit = async () => {
    if (!previewUrl || !originUrl || (!lutBytes.current && !lutUrl)) {
      console.log('some of required fields not provided');
      return;
    }
    setIsLoading(true);
    const result = (
      await Retriver.do(
        NewAddLookUpTableRequest({
          id: id,
          name: form.getFieldValue('name'),
          previewUrl: previewUrl,
          originUrl: originUrl,
          lutData: lutBytes.current ?? lutUrl,
        })
      )
    )?.addLookUpTableResponse?.lookUpTable;
    setIsLoading(false);
    if (result) {
      form.resetFields();
      setId(ulid());
      setPreviewUrl(undefined);
      setOriginUrl(undefined);
      lutBytes.current = undefined;
      props.onAdd(result);
    }
  };

  return (
    <Form
      form={form}
      onFinish={onSubmit}
      labelCol={{ span: 3 }}
      labelAlign="left"
      style={{ margin: '1rem 0' }}
    >
      <Form.Item
        label="Name"
        name="name"
        wrapperCol={{ span: 5 }}
        rules={[{ required: true, message: 'Name is required' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Preview URL"
        name="previewUrl"
        rules={[{ required: true, message: 'Preview image is required' }]}
      >
        <Space.Compact style={{ width: '90%' }}>
          <Input
            value={previewUrl}
            disabled={previewInputDisabled}
            onChange={(e) => setPreviewUrl(e.target.value)}
          />
          <FileUploader
            storage="retriver"
            accept={LookUpTableImageAcceptTypes}
            filename={`luts/${id}/preview`}
            container={''}
            onAdd={(file: UploadFile, id: string) => {
              if (file.url) {
                setPreviewUrl(file.url);
                setPreviewInputDisabled(true);
              } else {
                setPreviewInputDisabled(false);
              }
            }}
            onRemove={(id: string) => {
              setPreviewUrl(undefined);
              setPreviewInputDisabled(false);
            }}
          />
        </Space.Compact>
      </Form.Item>
      <Form.Item
        label="Origin URL"
        name="originUrl"
        rules={[{ required: true, message: 'Origin image is required' }]}
      >
        <Space.Compact style={{ width: '90%' }}>
          <Input
            value={originUrl}
            disabled={originInputDisabled}
            onChange={(e) => setOriginUrl(e.target.value)}
          />
          <FileUploader
            storage="retriver"
            accept={LookUpTableImageAcceptTypes}
            filename={`luts/${id}/origin`}
            container={''}
            onAdd={(file: UploadFile, id: string) => {
              if (file.url) {
                setOriginUrl(file.url);
                setOriginInputDisabled(true);
              } else {
                setOriginInputDisabled(false);
              }
            }}
            onRemove={(id: string) => {
              setOriginUrl(undefined);
              setOriginInputDisabled(false);
            }}
          />
        </Space.Compact>
      </Form.Item>
      <Form.Item
        label="LUT"
        name="lut"
        rules={[{ required: true, message: 'LUT data file is required' }]}
      >
        <Space.Compact style={{ width: '80%' }}>
          <Input
            value={lutUrl}
            disabled={lutInputDisabled}
            onChange={(e) => setLutUrl(e.target.value)}
          />
          <Upload
            accept={LookUpTableDataAcceptTypes}
            maxCount={1}
            multiple={false}
            beforeUpload={beforeUpload}
            onRemove={() => {
              setLutUrl('');
              lutBytes.current = undefined;
              setLutInputDisabled(false);
            }}
          >
            <Button icon={<FileAddOutlined />} />
          </Upload>
        </Space.Compact>
      </Form.Item>
      <Form.Item>
        <Button
          className={componentStyle.buttonOrange}
          type="primary"
          icon={<CheckOutlined />}
          htmlType="submit"
          loading={isLoading}
        >
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
}
