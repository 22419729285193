import { Message, Status } from 'types/message';
import { useFetchMessages } from 'hooks/useFetchMessages';
import { SubTitle } from 'components/PageTitles';
import { Button, Popover, Table, Tag, Typography } from 'antd';
import {
  ClockCircleOutlined,
  MinusCircleOutlined,
  SendOutlined,
  StopOutlined,
} from '@ant-design/icons';
import { ulid } from 'ulid';
import { NotificationCardView } from './components/NotificationCardView';
import RawJson from 'utils/RawJson';
import { Grapeseed } from 'api/grapeseed';
import componentStyle from 'styles/common.module.css';

export function Messages() {
  const [list, setList] = useFetchMessages();

  const onCancel = async (m: Message) => {
    const result = await Grapeseed.DELETE(`/api/messages/${m.id}`);
    if (result) {
      setList((prev) => {
        const index = prev.indexOf(m);
        prev.splice(index, 1, result);
        return JSON.parse(JSON.stringify(prev));
      });
    }
  };

  const renderNotification = (m: Message) => {
    return (
      <Popover content={RawJson(m)} trigger="click">
        <NotificationCardView notification={m.notification} />
      </Popover>
    );
  };

  const renderStatus = (s: Status) => {
    return (
      <>
        <div>
          <Tag
            color={s.sentAt ? (s.canceledAt ? 'default' : 'blue') : 'default'}
          >
            <SendOutlined />
          </Tag>{' '}
          {s.sentAt ? s.sentAt.toLocaleString() : '-'}
        </div>
        <div>
          <Tag
            color={
              s.scheduledAt
                ? s.sentAt || s.canceledAt
                  ? 'default'
                  : 'green'
                : 'default'
            }
          >
            <ClockCircleOutlined />
          </Tag>{' '}
          {s.scheduledAt ? s.scheduledAt.toLocaleString() : '-'}
        </div>
        <div>
          <Tag color={s.canceledAt ? 'red' : 'default'}>
            <MinusCircleOutlined />
          </Tag>{' '}
          {s.canceledAt ? s.canceledAt.toLocaleString() : '-'}
        </div>
      </>
    );
  };

  const columns = [
    {
      title: 'Message',
      key: 'notification',
      render: renderNotification,
      width: '30%',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: renderStatus,
    },
    {
      title: 'Target',
      dataIndex: 'topic',
      key: 'target',
      render: (t: string) => <Typography.Text code>{t}</Typography.Text>,
    },
    {
      title: 'Created',
      dataIndex: 'requestedAt',
      key: 'created',
      render: (c: Date) => c.toLocaleString(),
    },
    {
      title: 'Cancel',
      key: 'cancel',
      render: (_: any, record: Message) => {
        return (
          <Button
            danger
            icon={<StopOutlined />}
            type="text"
            disabled={
              record.status.sentAt || record.status.canceledAt ? true : false
            }
            onClick={() => onCancel(record)}
          />
        );
      },
    },
  ];

  return (
    <>
      <SubTitle>Message List</SubTitle>
      <div style={{ textAlign: 'right' }}>
        <span className={componentStyle.hinttextOrange}>
          * Caution: 발송되지 않은 알림을 취소하더라도, 예약 시간이 너무 가까운
          경우 이미 발송 큐에 들어가서 실제로는 알림이 발송될 수도 있습니다.
        </span>
      </div>
      <Table
        dataSource={list}
        columns={columns}
        rowKey={(record: Message) =>
          `${record.id ?? ulid()}_${record.status.canceledAt}`
        }
      />
    </>
  );
}
