import { Color } from 'types/common';
import { ApiError } from './errors';
import { Identifiable } from 'types/interfaces';

export function GrapeReviver(k: string, v: any) {
  if (k.endsWith('At')) {
    return new Date(parseInt(v) * 1000);
  }
  if (k === 'byDictionary') {
    return new Map(Object.entries(v));
  }
  return v;
}

export function GrapeSerializer(k: string, v: any) {
  if (k.endsWith('At')) {
    return Math.floor(Date.parse(v).valueOf() / 1000);
  }
  if (v instanceof Map) {
    return [...v].reduce((acc, cur) => {
      return { ...acc, [cur[0]]: cur[1] };
    }, {});
  }
  return v;
}

export async function OnResponse(res: Response) {
  if (!res.ok) {
    let response = await res.text();
    let errText: string;
    try {
      const errJson = JSON.parse(response);
      errText = errJson['error'];
    } catch (e) {
      errText = response;
    }
    throw new ApiError(res.status, res.statusText, errText);
  }
  return res.text();
}

export function FormatBytes(n: number) {
  const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  let l = 0;
  while (n >= 1024 && ++l) {
    n = n / 1024;
  }
  return n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l];
}

export function Union(arr: Identifiable[], m: Identifiable[]): Identifiable[] {
  if (!m) {
    return arr;
  }
  const seen = new Map<string, boolean>();
  return [...arr, ...m].filter((item) => {
    if (seen.has(item.id)) {
      return false;
    }
    seen.set(item.id, true);
    return true;
  });
}

export function ParseColorText(s: string) {
  if (s.startsWith('#')) {
    s = s.replace('#', '');
  }
  if (s.length === 6) {
    s += 'FF';
  }
  if (s.length !== 8) {
    return undefined;
  }
  const code = parseInt(s, 16);
  if (!code) {
    return undefined;
  }
  const red = (code >>> 24) / 255.0;
  const green = ((code >>> 16) & 0x00ff) / 255.0;
  const blue = ((code >>> 8) & 0x0000ff) / 255.0;
  const alpha = (code & 0x000000ff) / 255.0;
  const c: Color = {};
  if (red !== 1.0) c.red = red;
  if (green !== 1.0) c.green = green;
  if (blue !== 1.0) c.blue = blue;
  if (alpha !== 1.0) c.alpha = alpha;
  return c;
}

export function ParseColor(c: Color) {
  return (
    '#' +
    (
      '00' +
      Math.round((c.red ?? 1.0) * 255.0)
        .toString(16)
        .toUpperCase()
    ).slice(-2) +
    (
      '00' +
      Math.round((c.green ?? 1.0) * 255.0)
        .toString(16)
        .toUpperCase()
    ).slice(-2) +
    (
      '00' +
      Math.round((c.blue ?? 1.0) * 255.0)
        .toString(16)
        .toUpperCase()
    ).slice(-2) +
    (
      '00' +
      Math.round((c.alpha ?? 1.0) * 255.0)
        .toString(16)
        .toUpperCase()
    ).slice(-2)
  );
}

export function HaveSameContents<T>(a: Array<T>, b: Array<T>) {
  for (const v of new Set([...a, ...b])) {
    if (a.filter((e) => e === v).length !== b.filter((e) => e === v).length) {
      return false;
    }
  }
  return true;
}

export function QueryParams(uri: string): Map<string, string> | undefined {
  const params = new Map<string, string>();
  let queryParts = uri.split('?');
  if (queryParts.length < 2) {
    return undefined;
  }
  const queries = queryParts[1].split('&');
  queries.forEach((query) => {
    const kv = query.split('=');
    if (kv.length === 2) {
      params.set(kv[0], kv[1]);
    }
  });
  return params;
}

export function Path(uri: string): string {
  return uri.split('?')[0];
}

export function isValidHttpUrl(str: string): boolean {
  let url: URL;
  try {
    url = new URL(str);
  } catch (_) {
    return false;
  }
  return url.protocol === 'http:' || url.protocol === 'https:';
}
